import styled from '@emotion/styled'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, CircularProgress, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import ExpirePopup from 'components/common/ExpirePopup'
import dataProvider from 'providers/dataProvider'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAdminContextProvider } from 'providers/adminContextProvider';
const NotificationListing = () => {


    let token = localStorage.getItem('token');
    const { setNetworkErr } = useAdminContextProvider()
    const [notifications, setNotifications] = useState([])
    const [loading, setLoading] = useState(false)

    const [tokenExpireMsg, setTokenExpireMsg] = useState("")
    const [tokenExpOpen, setTokenExpOpen] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        fetchNotifications()
    }, [])


    const fetchNotifications = () => {

        setLoading(true)
        dataProvider.getList('notification',
            {
                params: {
                    token: token,
                    pagination: {
                        page: 1,
                        perPage: 1000
                    },
                    sort: { order: 'desc', field: 'id' },
                    filter: {}
                }
            })
            .then(({ data }) => {
                setNotifications(data)
                setLoading(false)
            })
            .catch(error => {
                setLoading(false)
                // console.log(error?.response?.data)
                // console.log(error?.response?.data?.expire)
                if (error.message === 'Network Error') {
                    setNetworkErr(true)
                }

                if (error?.response?.data?.expire === true) {
                    setTokenExpireMsg(error?.response?.data?.message)
                    setTokenExpOpen(true)
                }

            })
    }



    const StyledButton = styled(Button)(({ themse }) => ({
        color: "white",
        display: 'flex',
        alignItems: 'center',
        gap: "5px",
        width: "130px",
        height: "35px"

    }))
    return (
        <>
            {
                tokenExpOpen ? <ExpirePopup tokenExpireMsg={tokenExpireMsg} /> :
                    <Paper sx={{ margin: "30px 0", padding: "15px" }}>
                        <Stack flexDirection={"row"} justifyContent={"space-between"} my={2}>
                            <Typography variant='h6' py={2} textTransform={'uppercase'} >Notification Listing</Typography>
                            <StyledButton variant='contained' onClick={() => navigate('/notification/create')} >
                                <span>Send new</span>
                                <FontAwesomeIcon icon={faPaperPlane} />
                            </StyledButton>
                        </Stack>

                        {
                            loading ? <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"center"}>
                                <CircularProgress color="primary" size={45} />
                            </Stack>
                                :
                                notifications.length === 0 ?
                                    <Typography sx={{ m: 3, textAlign: "center" }}>Empty Notifications</Typography>
                                    :
                                    <TableContainer sx={{ marginTop: "20px" }}>
                                        <Table sx={{ minWidth: 650, '& th, & td': { padding: '10px' } }} size="small" aria-label="a dense table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell >
                                                        <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                            Title
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell >
                                                        <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                            Date
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell >
                                                        <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                            Description
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell >
                                                        <Typography sx={{ fontSize: '1rem' }} fontWeight="bold" textAlign={"center"}>
                                                            Image
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    notifications.map((notification) => (
                                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                            <TableCell sx={{ padding: '10px', height: '90px', width: "25%" }}>
                                                                <Typography sx={{ fontSize: '1rem' }}>{notification?.title}</Typography>
                                                            </TableCell>
                                                            <TableCell sx={{ padding: '10px', height: '90px', width: "25%" }}>
                                                                <Typography sx={{ fontSize: '1rem' }}>{notification?.created_on}</Typography>
                                                            </TableCell>
                                                            <TableCell sx={{ padding: '10px', height: '90px', width: "25%" }}>
                                                                <Typography sx={{
                                                                    fontSize: '1rem',
                                                                    width: "300px",
                                                                    display: '-webkit-box',
                                                                    WebkitBoxOrient: 'vertical',
                                                                    overflow: 'hidden',
                                                                    WebkitLineClamp: 2 // Number of lines to show before truncating
                                                                }}>{notification?.description}</Typography>
                                                            </TableCell>
                                                            <TableCell sx={{ padding: '10px', height: '90px', width: "30%", textAlign: "center" }}>
                                                                {
                                                                    notification?.notification_image ? <img src={notification?.notification_image} alt="" srcset="" width={"85px"} height={"85px"} /> :
                                                                        <Typography sx={{ fontSize: '1rem' }}>Empty Image</Typography>
                                                                }
                                                            </TableCell>
                                                        </TableRow>

                                                    ))
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                        }



                    </Paper>
            }
        </>
    )
}

export default NotificationListing