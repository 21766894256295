import React, { useEffect, useState } from 'react'
import "assets/css/admin/admin-user.css"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Avatar, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Paper, Stack, Tooltip, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import dataProvider from 'providers/dataProvider';
import DeleteClientModal from './delete';
import styled from '@emotion/styled';
import ExpirePopup from 'components/common/ExpirePopup';
import { CheckCircle, DoDisturb, ThumbDownAlt, ThumbUp, Visibility } from '@mui/icons-material';
// import DeleteUserModal from '../user/DeleteUserModal';
import { useAdminContextProvider } from 'providers/adminContextProvider';
const Clients = () => {


  const { setNetworkErr } = useAdminContextProvider()
  let token = localStorage.getItem('token');

  const [deleteDetails, setDeleteDetails] = useState({
    id: "",
    first_name: ""
  })

  const [approveDetails, setApproveDetails] = useState({
    id: "",
    first_name: ""
  })

  const [isdeleteOpen, setIdDeleteOpen] = useState(false);
  const [isUserLoading, setIsUserLoading] = useState(false)

  const [deleteLoading, setDeleteLoading] = useState(false)
  const [approveLoading, setApproveLoading] = useState(false)

  const [approveOpen, setIsApproveOpen] = useState(false)

  const [tokenExpireMsg, setTokenExpireMsg] = useState("")
  const [tokenExpOpen, setTokenExpOpen] = useState(false)

  const [userDetails, setUserDetails] = useState([])



  useEffect(() => {
    fetchUsersData()
  }, []);

  const navigate = useNavigate()



  const fetchUsersData = async () => {
    setIsUserLoading(true)
    dataProvider.getList('users/employers', {
      params: {
        token: token,
        pagination: { page: 1, perPage: 1000 },
        sort: { order: 'desc', field: 'id' },
        filter: { role: 'client' }
      }
    })
      .then(({ data }) => {
        setUserDetails(data)
        // console.log(data);
        setIsUserLoading(false)
      })
      .catch(error => {
        setIsUserLoading(false)
        // console.log(error?.response?.data)

        if (error.message === 'Network Error') {
          setNetworkErr(true)
        }

        if (error?.response?.data?.expire === true) {
          setTokenExpireMsg(error?.response?.data?.message)
          setTokenExpOpen(true)
        }
      })
  };

  const getInitials = (name) => {
    return name ? name[0].toUpperCase() : '';
  }

  // disable
  const handleDelteModalOpen = (id, first_name) => {
    setIdDeleteOpen(true)
    setDeleteDetails((prevState) => ({
      ...prevState,
      id: id,
      first_name: first_name
    }))
  }

  const handleCloseDeleteModal = () => {
    setIdDeleteOpen(false)
    setDeleteDetails((prevState) => ({
      ...prevState,
      id: "",
      first_name: ""
    }))
  }

  const handleConfirmDeleteModal = (id) => {
    let data = {
      'id': id,
      'status': 0
    }

    setDeleteLoading(true)
    dataProvider.updateProfile('users', data).then((data) => {
      fetchUsersData()
      setIdDeleteOpen(false)
      setDeleteLoading(false)
    }).catch((e) => {
      // console.log(e)
      setDeleteLoading(false)
    })
  }


  //enable
  const handleOpenApprove = (id, first_name) => {
    setApproveDetails((prevState) => ({
      ...prevState,
      id: id,
      first_name: first_name
    }))
    setIsApproveOpen(true)
  }

  const handleCloseApprove = () => {
    setApproveDetails((prevState) => ({
      ...prevState,
      id: "",
      first_name: ""
    }))
    setIsApproveOpen(false)
  }

  const handleConfirmApprove = (id) => {
    let data = {
      'id': id,
      'status': 1
    }
    setApproveLoading(true)
    dataProvider.updateProfile('users', data).then((data) => {
      fetchUsersData()
      setIsApproveOpen(false)
      setApproveLoading(false)
      // console.log(data);
    }).catch((e) => {
      // console.log(e)
      setApproveLoading(false)
    })
  }



  let deleteModalProps = {
    isdeleteOpen: isdeleteOpen,
    handleCloseDeleteModal: handleCloseDeleteModal,
    handleConfirmDeleteModal: handleConfirmDeleteModal,
    deleteDetails: deleteDetails,
    deleteLoading: deleteLoading
  }

  let popuProps = {
    tokenExpireMsg: tokenExpireMsg
  }



  const SlotBox = styled(Box)(({ theme }) => ({
    margin: "20px 10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  }));


  return (
    <>

      {
        tokenExpOpen ? <ExpirePopup {...popuProps} /> :
          <Paper sx={{ marginTop: "20px" }} >
            <Box p={2}>
              <SlotBox>
                <Typography variant='h6' py={2} textTransform={'uppercase'} >Employer listing</Typography>
                <Tooltip title='Create Employer'>
                  <Button variant='contained' sx={{ color: "white", backgroundImage: "linear-gradient(98deg, #9cc177, #6ba134 94%)" }} onClick={() => navigate('/employer/create')}>Create</Button>
                </Tooltip>
              </SlotBox>

              {/* Delete Modal */}
              {/* <DeleteClientModal {...deleteModalProps} /> */}
              {/* Delete Modal */}

              {
                isUserLoading ? <div className="loader-position">
                  <CircularProgress color="primary" size={45} />
                </div>
                  :
                  userDetails.length === 0 ?
                    <Typography sx={{ m: 3, textAlign: "center" }}>Empty Employers</Typography>
                    :
                    <TableContainer>
                      <Table sx={{ minWidth: 650, '& th, & td': { padding: '10px' } }} size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell >
                              <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                Employer
                              </Typography>
                            </TableCell>
                            <TableCell >
                              <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                Employer Type
                              </Typography>
                            </TableCell>
                            <TableCell >
                              <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                Email
                              </Typography>
                            </TableCell>
                            <TableCell >
                              <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                Phone Number
                              </Typography>
                            </TableCell>

                            <TableCell >
                              <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                Actions
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                            userDetails.map((user) => (
                              <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell component="th" scope="row">
                                  <div className="profile-image-table">
                                    <Avatar src={user?.avatar_path} >{getInitials(user?.first_name)}</Avatar>
                                    <Typography sx={{ fontSize: '1rem', fontWeight: '600' }}>
                                      {user?.first_name}
                                      {" "}
                                      {user?.last_name}
                                    </Typography>
                                  </div>
                                </TableCell>
                                <TableCell ><Typography sx={{ fontSize: '1rem' }}>{user.employer_type}</Typography></TableCell>
                                <TableCell ><Typography sx={{ fontSize: '1rem' }}>{user.email}</Typography></TableCell>
                                <TableCell ><Typography sx={{ fontSize: '1rem' }}>{user.phone}</Typography></TableCell>
                                {/* <TableCell ><Typography sx={{ fontSize: '1rem' }}>{user.role === 'client' && 'Employer'}</Typography></TableCell> */}
                                <TableCell >


                                  <div className="user-action-buttons">
                                    {/* {
                                      user?.status === 0 &&
                                      <Tooltip title='Approve'>
                                        <IconButton onClick={() => handleOpenApprove(user?._id, user?.first_name)}>
                                          <ThumbUp color='primary' />
                                        </IconButton>
                                      </Tooltip>
                                    }

                                    {
                                      user?.status === 1 &&
                                      <Tooltip title='Disable'>
                                        <IconButton onClick={() => handleDelteModalOpen(user?._id, user?.first_name)}>
                                          <ThumbDownAlt sx={{ color: 'red' }} />
                                        </IconButton>
                                      </Tooltip>
                                    }


                                    {
                                      user?.status === 0 &&
                                      <Tooltip title='Disabled'>
                                        <DoDisturb color='error' />
                                      </Tooltip>
                                    }

                                    {
                                      user?.status === 1 &&
                                      <Tooltip title='Enabled'>
                                        <CheckCircle color='primary' />
                                      </Tooltip>
                                    } */}





                                  </div>

                                  <Stack flexDirection={"row"} alignItems={"center"} gap={1}>


                                    {
                                      user?.status === 0 &&

                                      <Button variant='contained'
                                        sx={{
                                          color: "white", width: "20px", display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center"
                                        }} size='small'
                                        onClick={() => handleOpenApprove(user?._id, user?.first_name)}
                                      >Enable</Button>


                                    }

                                    {
                                      user?.status === 1 &&
                                      <Button color='error' variant='contained' sx={{
                                        color: "white", width: "20px", display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                      }} size='small'
                                        onClick={() => handleDelteModalOpen(user?._id, user?.first_name)}
                                      >Disable</Button>
                                    }

                                    <Tooltip title='View'>
                                      <IconButton onClick={() => navigate(`/employer/edit/${user._id}`)}>
                                        <Visibility sx={{ color: '#0066b2' }} />
                                      </IconButton>
                                    </Tooltip>
                                  </Stack>
                                </TableCell>
                              </TableRow>
                            ))
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
              }


            </Box>
          </Paper>
      }


      <Dialog
        open={approveOpen}
        onClose={() => handleCloseDeleteModal()}
      >
        <DialogTitle >
          {`Are you sure  want to Enable ${approveDetails.first_name} ?`}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => handleCloseApprove()}>Cancel</Button>
          <Button onClick={() => handleConfirmApprove(approveDetails.id)} autoFocus color='primary' disabled={approveLoading}>
            {
              approveLoading ? <CircularProgress size={15} color='primary' /> : 'Enable'
            }
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={isdeleteOpen}
        onClose={() => handleCloseDeleteModal()}
      >
        <DialogTitle >
          {`Are you sure  want to Disable ${deleteDetails.first_name} ?`}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => handleCloseDeleteModal()}>Cancel</Button>
          <Button onClick={() => handleConfirmDeleteModal(deleteDetails.id)} autoFocus color='error' disabled={deleteLoading}>
            {
              deleteLoading ? <CircularProgress size={15} color='error' /> : 'Disable'
            }
          </Button>
        </DialogActions>
      </Dialog>



    </>
  )
}

export default Clients