import React, { useEffect, useState } from 'react'
import 'assets/css/login.css'
import logo from "assets/images/hirehoodLogo.png"
import { Alert, Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Paper, Select, Snackbar, Stack, TextField, Typography } from '@mui/material'
import { VisibilityOutlined, VisibilityOffOutlined, InputTwoTone, LocalHospitalOutlined, AdminPanelSettingsOutlined } from '@mui/icons-material'
import { Link, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled'
import bgImage from '../../assets/images/nwbg.png'
import dataProvider from 'providers/dataProvider'


const Register = () => {

  const navigate = useNavigate()


  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false)
  const [isRegisterLoading, setIsRegisterLoading] = useState(false)
  const [isRegisterErrorModal, setIsRegisterErrorModal] = useState(false)
  const [typeLoading, setTypeLoading] = useState(false)
  const [employerTypeData, setEmployerTypeData] = useState([])

  const [credentialError, setCredentialError] = useState("")

  const [registerDetails, setRegisterDetails] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    employer_type: "",
    manager_name: "",
    hospital_name: "",
    password: "",
    confirmPassword: "",
  })

  const [registerError, setRegisterError] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    employer_type: "",
    manager_name: "",
    hospital_name: "",
    password: "",
    confirmPassword: "",
  })


  const [isRegisterError, setIsRegisterError] = useState({
    isFirst_name: false,
    isLast_name: false,
    isPhone: false,
    isEmployerType: false,
    isHospitalName: false,
    isManagerName: false,
    isEmail: false,
    isPassword: false,
    isConfirmPassword: false,
  })



  let firstNameRegex = /^[a-zA-Z\-']+$/;
  let lastNameRegex = /^[a-zA-Z\-']+$/;
  let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  let phoneNumberRegex = /^\d{9}$/;
  let passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]).{6,}$/;

  useEffect(() => {
    fetchEmployerType()
  }, [])


  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleClickConfirmShowPassword = () => setshowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };



  const handleChage = (e, regex, isError) => {
    const { name, value } = e.target;


    if (name === 'confirmPassword') {
      setRegisterDetails((prevState) => ({
        ...prevState,
        ["confirmPassword"]: value
      }))
      handleSetError(value, regex, name, isError, registerDetails.password)
    } else {
      setRegisterDetails((prevState) => ({
        ...prevState,
        [name]: value
      }))
      handleSetError(value, regex, name, isError)
    }
  }

  const handleWithoutRegxpChange = (e) => {

    const { name, value } = e.target;



    setRegisterDetails((prevState) => ({ ...prevState, [name]: value }))

    if (name === 'employer_type') {
      setRegisterError((prevState) => ({ ...prevState, employer_type: '' }))
      setIsRegisterError((prevState) => ({ ...prevState, isEmployerType: false }))
    }

    if (name === 'hospital_name') {
      setRegisterError((prevState) => ({ ...prevState, hospital_name: '' }))
      setIsRegisterError((prevState) => ({ ...prevState, isHospitalName: false }))
    }

    if (name === 'manager_name') {
      setRegisterError((prevState) => ({ ...prevState, manager_name: '' }))
      setIsRegisterError((prevState) => ({ ...prevState, isManagerName: false }))
    }


  }


  const handleSetError = (inputValue, regex, fieldName, isError, checkingPassword) => {
    if (!regex.test(inputValue)) {
      setRegisterError((prevState) => ({
        ...prevState,
        [fieldName]: getErrorMessage(fieldName)
      }));

      setIsRegisterError((prevState) => ({
        ...prevState,
        [isError]: true
      }));
    } else if (fieldName === "confirmPassword" && inputValue !== checkingPassword) {
      setRegisterError((prevState) => ({
        ...prevState,
        [fieldName]: getErrorMessage("confirmPasswordMismatch")
      }));

      setIsRegisterError((prevState) => ({
        ...prevState,
        [isError]: true
      }));
    } else {
      setRegisterError((prevState) => ({
        ...prevState,
        [fieldName]: ""
      }));

      setIsRegisterError((prevState) => ({
        ...prevState,
        [isError]: false
      }));
    }
  };


  const getErrorMessage = (fieldName) => {
    switch (fieldName) {
      case "username":
        return "Invalid username format";
      case "email":
        return "Invalid email format";
      case "password":
        return "Password must contain six characters, capital, number, and special character";
      case "confirmPassword":
        return "Password must contain six characters, capital, number, and special character";
      case "confirmPasswordMismatch":
        return "Password does not match";
      case "phone":
        return "Phone number must contain 9 digits";
      default:
        return "Invalid format";
    }
  };




  const handleClose = () => {
    setIsRegisterErrorModal(false)
  }


  const handleRegister = () => {
    const { first_name, last_name, email, phone, password, confirmPassword, employer_type, hospital_name, manager_name } = registerDetails



    let hasErr = false;

    const setError = (errorfield, errorStatus, filedname, fieldError) => {
      setIsRegisterError((prevState) => ({ ...prevState, [errorfield]: errorStatus }));
      setRegisterError((prevState) => ({ ...prevState, [filedname]: fieldError }))
      hasErr = true;
    }


    let errorWord = 'Please fill this field'

    if (first_name === '') setError('isFirst_name', true, 'first_name', 'First Name is required')
    if (last_name === '') setError('isLast_name', true, 'last_name', 'Last Name is required')
    if (email === '') setError('isEmail', true, 'email', 'Email is required')
    if (phone === '') setError('isPhone', true, 'phone', 'Phone number is required')
    if (password === '') setError('isPassword', true, 'password', 'Password is required')
    if (employer_type === '') setError('isEmployerType', true, 'employer_type', 'Employer type is required')
    if (hospital_name === '') setError('isHospitalName', true, 'hospital_name', 'Hospital name is required')
    if (manager_name === '') setError('isManagerName', true, 'manager_name', 'Manager  name is required')
    if (confirmPassword === '') setError('isConfirmPassword', true, 'confirmPassword', 'Confirm password is required')
    if (password !== confirmPassword) setError('isConfirmPassword', true, 'confirmPassword', 'Password does not match')



    if (!hasErr) {

      const data = {
        'first_name': first_name,
        'last_name': last_name,
        'email': email,
        'phone': phone,
        'password': password,
        'employer_type': employer_type,
        'hospital_name': hospital_name,
        'manager_name': manager_name,
        'role': 'client'
      }

      setIsRegisterLoading(true)
      // setIsRegisterLoading(false)
      // console.log(data);
      dataProvider.create('users/employer', data).then((data) => {
        navigate('/')
        setIsRegisterLoading(false)
      }).catch((e) => {
        setIsRegisterLoading(false)
        setCredentialError(e.response.data.msg)
        setIsRegisterErrorModal(true)
      })
    }

  }


  const BgImage1 = styled(Box)(({ theme }) => ({
    backgroundImage: `url(${bgImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    width: "100%",
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }));





  const BgImage2 = styled(Box)(({ theme }) => ({
    backgroundImage: `url(${bgImage})`,
    backgroundRepeat: "no-repeat",
    height: "100vh",
    width: "100%", // Set width to 100%
    backgroundSize: "100% 100%",
    transform: "scaleX(-1)", // Apply the scaleX transformation
  }));

  const LoginBox = styled(Box)(({ theme }) => ({
    boxShadow: "0px 2px 1px -1px rgba(58, 53, 65, 0.2), 0px 1px 1px 0px rgba(58, 53, 65, 0.14), 0px 1px 3px 0px rgba(58, 53, 65, 0.12)",
    borderRadius: "6px"
  }));


  const fetchEmployerType = () => {
    setTypeLoading(true)
    dataProvider.getList('employer_type', {
      params: {
        token: '',
        pagination: { page: 1, perPage: 1000 },
        sort: { order: 'desc', field: 'id' },
        filter: {}
      }
    })
      .then(({ data }) => {
        // console.log(data)
        setEmployerTypeData(data)
        setTypeLoading(false)
      })
      .catch(error => {
        setTypeLoading(false)

        // if (error.message === 'Network Error') {
        //   setNetworkErr(true)
        // }


      })
  }




  return (
    <>

      <Box sx={{
        backgroundImage: `url(${bgImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}>
        <Box p={3}
          my={3}
          sx={{
            boxShadow: "0px 2px 1px -1px rgba(58, 53, 65, 0.2), 0px 1px 1px 0px rgba(58, 53, 65, 0.14), 0px 1px 3px 0px rgba(58, 53, 65, 0.12)",
            borderRadius: "6px",
            minHeight: "40px",
            width: "400px",
            backgroundColor: "white"
          }}
        >
          <Stack flexDirection={'row'} alignItems={'center'} gap={1} justifyContent={"center"}>
            <img src={logo} alt="" width={"60px"} height={"60px"} />
            <Typography variant='h6' textTransform={"uppercase"}>hirehood</Typography>
          </Stack>

          <Stack my={2}>
            <Typography variant='h5' sx={{ color: "rgba(58, 53, 65, 0.87)", fontSize: "1.4993rem" }} fontWeight={"bold"}>Adventure starts here 🚀</Typography>
            <Typography sx={{ color: "grey", marginTop: "10px" }}>Make your app management easy and fun!</Typography>
          </Stack>

          <Grid container spacing={2}>




            <Grid item md={12} xs={12}>
              <FormControl fullWidth error={isRegisterError.isEmployerType} >
                <InputLabel >Employer Type</InputLabel>
                <Select
                  value={registerDetails.employer_type}
                  label="Employer Type"
                  onChange={(e) => handleWithoutRegxpChange(e)}
                  name='employer_type'
                >
                  {
                    typeLoading ? (

                      <MenuItem disabled>Loading...</MenuItem>
                    ) :
                      employerTypeData.length === 0 ?
                        <MenuItem disabled>Staff type is not added</MenuItem> :
                        employerTypeData?.map((type) => (
                          <MenuItem value={type?.type}>{type?.type}</MenuItem>
                        ))
                  }
                </Select>
                <FormHelperText>{isRegisterError.isEmployerType && registerError.employer_type}</FormHelperText>
              </FormControl>
            </Grid>


            <Grid item md={12} xs={12}>
              <TextField

                label="Hospital / Nursing  name"
                variant="outlined"
                name='hospital_name'
                onChange={(e) => handleWithoutRegxpChange(e)}

                fullWidth
                error={isRegisterError.isHospitalName}
                helperText={isRegisterError.isHospitalName && registerError.hospital_name}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocalHospitalOutlined />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>


            <Grid item md={12}>
              <TextField
                label="Manager name"
                type='text'
                variant="outlined"
                fullWidth
                name='manager_name'
                onChange={(e) => handleWithoutRegxpChange(e)}
                value={registerDetails.manager_name}
                error={isRegisterError.isManagerName}
                helperText={isRegisterError.isManagerName && registerError.manager_name}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AdminPanelSettingsOutlined />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>



            <Grid item md={12} xs={12}>
              <TextField
                id="outlined-username-input"
                label="Firstname"
                type="text"
                name='first_name'
                onChange={(e) => handleChage(e, firstNameRegex, 'isFirst_name')}
                autoComplete="off"
                fullWidth
                color='primary'
                error={isRegisterError.isFirst_name}
                helperText={registerError.first_name && registerError.first_name}
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <TextField
                id="outlined-username-input"
                label="Lastname"
                type="text"
                name='last_name'
                onChange={(e) => handleChage(e, lastNameRegex, 'isLast_name')}
                autoComplete="off"
                fullWidth
                color='primary'
                error={isRegisterError.isLast_name}
                helperText={registerError.last_name && registerError.last_name}
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <TextField
                id="outlined-email-input"
                label="Email"
                type="Email"
                name='email'
                onChange={(e) => handleChage(e, emailRegex, 'isEmail')}
                autoComplete="off"
                fullWidth
                color='primary'
                error={isRegisterError.isEmail}
                helperText={registerError.email && registerError.email}
              />
            </Grid>


            <Grid item md={12} xs={12}>
              <TextField
                id="outlined-username-input"
                label="Phone"
                type="number"
                name='phone'
                onChange={(e) => handleChage(e, phoneNumberRegex, 'isPhone')}
                autoComplete="off"
                fullWidth
                color='primary'
                error={isRegisterError.isPhone}
                helperText={registerError.phone && registerError.phone}
                InputProps={{
                  startAdornment: <InputAdornment position="start">+353</InputAdornment>,
                }}
              />
            </Grid>


            <Grid item md={12} xs={12}>
              <FormControl variant="outlined"
                fullWidth
                error={isRegisterError.isPassword}
              >
                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  name='password'
                  onChange={(e) => handleChage(e, passwordRegex, 'isPassword')}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
                <FormHelperText>{registerError.password && registerError.password}</FormHelperText>
              </FormControl>
            </Grid>


            <Grid item md={12} xs={12}>
              <FormControl variant="outlined"
                fullWidth
                error={isRegisterError.isConfirmPassword}
              >
                <InputLabel htmlFor="outlined-adornment-password">Confirm password</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showConfirmPassword ? 'text' : 'password'}
                  name='confirmPassword'
                  onChange={(e) => handleChage(e, passwordRegex, 'isConfirmPassword')}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickConfirmShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Confirm password"
                />
                <FormHelperText>{registerError.confirmPassword && registerError.confirmPassword}</FormHelperText>
              </FormControl>
            </Grid>

          </Grid>

          <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} my={1}>
            {/* <FormControlLabel
              control={<Checkbox />}
              label={<Typography variant="body2">I agree to <Link className='react-link'>privacy policy & terms</Link></Typography>}
            /> */}
            {/* <Link to='/ForgotPassword' className='react-link' style={{ fontSize: "0.875rem" }}>Forgot Password?</Link> */}
          </Stack>

          <Grid item md={12}>
            <Button variant="contained" fullWidth color='primary'
              style={{ color: 'white', padding: '5px', fontSize: 'medium', margin: '10px 0', height: "40px" }}
              disabled={Object.values(registerError).some((error) => error !== "") || isRegisterLoading}
              onClick={() => handleRegister()}
            >
              {isRegisterLoading ? <CircularProgress size={25} sx={{ color: 'white' }} /> : 'Signup'}
            </Button>
          </Grid>

          <Stack flexDirection={'row'} gap={1} justifyContent={"center"} my={2}>
            <Typography variant="body2">Already have an account?</Typography>
            <Link to='/' className='react-link' style={{ fontSize: "0.875rem" }}> Sign in instead</Link>
          </Stack>

        </Box>
      </Box>


      <Snackbar open={isRegisterErrorModal}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {credentialError}
        </Alert>
      </Snackbar>


    </>
  )
}

export default Register