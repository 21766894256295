import React, { useEffect, useState } from 'react'
import "assets/css/admin/admin-user.css"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Avatar, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Paper, Select, Stack, Switch, TextField, Tooltip, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import dataProvider from 'providers/dataProvider';
import DeleteUserModal from './delete';
import styled from '@emotion/styled';
import ExpirePopup from 'components/common/ExpirePopup';
import jsPDF from 'jspdf';
import ExcelJS from 'exceljs';
import { ArrowBackIosNewOutlined, ArrowForwardIosOutlined, CheckCircle, Close, Delete, DoDisturb, FilterAlt, HighlightOff, ThumbDownAlt, ThumbUp, ThumbUpOffAltOutlined, Visibility } from '@mui/icons-material';
import { useAdminContextProvider } from 'providers/adminContextProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faFilePdf, fas } from '@fortawesome/free-solid-svg-icons';
import useMediaQuery from '@mui/material/useMediaQuery';
import AppToast from 'components/common/Toast';

const Users = () => {

  let token = localStorage.getItem('token');
  const { setNetworkErr } = useAdminContextProvider()

  const isMobile = useMediaQuery('(min-width:600px)');

  const [isDisableOpen, setDisableOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleteModalOpen2, setIsDeleteModalOpen2] = useState(false);
  const [approveOpen, setIsApproveOpen] = useState(false)


  const [isDeleteLoading, setIsDeleteLoading] = useState(false)
  const [isUserLoading, setIsUserLoading] = useState(false)
  const [disableLoading, setDisableLoading] = useState(false)
  const [approveLoading, setApproveLoading] = useState(false)


  const [disableDetails, setDisableDetails] = useState({
    id: "",
    first_name: ""
  })

  const [approveDetails, setApproveDetails] = useState({
    id: "",
    first_name: ""
  })

  const [deleteDetails, setDeleteDetails] = useState({
    id: "",
    trimmed_full_name: "",
    full_name: ""
  })

  const [checkDeleteErr, setDeleteCheckErr] = useState(true)




  const [tokenExpireMsg, setTokenExpireMsg] = useState("")
  const [tokenExpOpen, setTokenExpOpen] = useState(false)

  const [userDetails, setUserDetails] = useState([])

  const [customerLength, setCustomerLength] = useState(null)
  let [paginationCount, setPaginationCount] = useState(1)

  const [filterData, setFilterData] = useState({
    staff_role: "",

  })

  const [typeLoading, setTypeLoading] = useState(false)
  const [staffTypeData, setStaffTypeData] = useState([])

  const [filterFields, setFilterFileds] = useState({
    role: "customer"
  })

  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');



  useEffect(() => {
    fetchUsersData()
    fetchStaffType()
  }, [paginationCount, filterFields]);

  const navigate = useNavigate()


  const fetchUsersData = async () => {

    setIsUserLoading(true)
    dataProvider.getList('users/customers',
      {
        params: {
          token: token,
          pagination: {
            page: paginationCount,
            perPage: 10
          },
          sort: { order: 'desc', field: 'id' },
          filter: filterFields
        }
      })
      .then(({ data }) => {
        setCustomerLength(data.length)
        setUserDetails(data)
        setIsUserLoading(false)
        // console.log(data);
      })
      .catch(error => {
        setIsUserLoading(false)
        // console.log(error?.response?.data)

        if (error.message === 'Network Error') {
          setNetworkErr(true)
        }

        if (error?.response?.data?.expire === true) {
          setTokenExpireMsg(error?.response?.data?.message)
          setTokenExpOpen(true)
        }
      })
  };

  const fetchStaffType = () => {
    setTypeLoading(true)
    dataProvider.getList('staff_type', {
      params: {
        token: token,
        pagination: { page: 1, perPage: 1000 },
        sort: { order: 'desc', field: 'id' },
        filter: {}
      }
    })
      .then(({ data }) => {
        // console.log(data)
        setStaffTypeData(data)
        setTypeLoading(false)
      })
      .catch(error => {
        setTypeLoading(false)
        console.log(error?.response?.data)
        console.log(error?.response?.data?.expire)

        if (error.message === 'Network Error') {
          setNetworkErr(true)
        }
        if (error?.response?.data?.expire === true) {
          setTokenExpireMsg(error?.response?.data?.message)
          setTokenExpOpen(true)
        }
      })
  }


  const getInitials = (name) => {
    return name ? name[0].toUpperCase() : '';
  }

  // Disable 

  const handleDisableModalOpen = (id, first_name) => {
    setDisableOpen(true)
    setDisableDetails((prevState) => ({
      ...prevState,
      id: id,
      first_name: first_name
    }))
  }

  const handleCloseDisableModal = () => {
    setDisableOpen(false)
    setDisableDetails((prevState) => ({
      ...prevState,
      id: "",
      first_name: "",
    }))
  }

  const handleConfirmDisableModal = (id) => {
    let data = {
      'id': id,
      'status': 0
    }

    setDisableLoading(true)
    dataProvider.updateProfile('users', data).then((data) => {
      fetchUsersData()
      setDisableOpen(false)
      setDisableLoading(false)
    }).catch((e) => {
      console.log(e)
      setDisableLoading(false)
    })
  }


  // Delete

  const handleDeleteModalOpen = (id, first_name, last_name) => {
    setIsDeleteModalOpen(true)
    let trimmedFullName = `${first_name.trim()}${last_name.trim()}`
    setDeleteDetails((prevState) => ({
      ...prevState,
      id: id,
      trimmed_full_name: trimmedFullName,
      full_name: `${first_name} ${last_name}`
    }))
  }

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false)
    setDeleteDetails((prevState) => ({
      ...prevState,
      id: "",
      full_name: "",
    }))
  }

  const handleConfirmDeleteModalOpoen = (id) => {
    setIsDeleteModalOpen(false)
    setIsDeleteModalOpen2(true)
  }

  const handleDeleteConfirmClose = () => {
    setIsDeleteModalOpen2(false)
    setDeleteCheckErr(false)
  }

  const handleConfirmDelete = (id) => {

    let data = {
      'id': id
    }
    setIsDeleteLoading(true)
    dataProvider.deleteUser('users/customer', data).then((data) => {
      console.log(data);
      fetchUsersData()
      setIsDeleteLoading(false)
      setIsDeleteModalOpen2(false)
    }).catch((e) => {
      // console.log(e?.response?.data)
      setToastMessage(
        typeof e?.response?.data === 'string' && e.response.headers['content-type'].includes('application/json')
          ? e.response.data
          : 'An error occurred. Please try again later.'
      );
      setToastOpen(true);
      setIsDeleteLoading(false)
    })
    // setIsDeleteModalOpen2(false)
    // console.log(deleteDetails);
  }





  // Approve 

  const handleOpenApprove = (id, first_name) => {
    setApproveDetails((prevState) => ({
      ...prevState,
      id: id,
      first_name: first_name
    }))
    setIsApproveOpen(true)
  }

  const handleCloseApprove = () => {
    setApproveDetails((prevState) => ({
      ...prevState,
      id: "",
      first_name: ""
    }))
    setIsApproveOpen(false)
  }

  const handleConfirmApprove = (id) => {
    let data = {
      'id': id,
      'status': 1
    }
    setApproveLoading(true)
    dataProvider.updateProfile('users', data).then((data) => {
      fetchUsersData()
      setIsApproveOpen(false)
      setApproveLoading(false)
    }).catch((e) => {
      console.log(e)
      setApproveLoading(false)
    })
  }


  // console.log(approveDetails);



  let popuProps = {
    tokenExpireMsg: tokenExpireMsg
  }

  const SlotBox = styled(Box)(({ theme }) => ({
    margin: "20px 10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  }));


  const handlePrevPage = () => {
    setPaginationCount((prevCount) => prevCount - 1)

  }

  const hanldNextPage = () => {
    setPaginationCount((prevCount) => prevCount + 1)

  }

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilterData((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }


  const handleFilterValue = () => {
    if (filterData.staff_role !== "") setFilterFileds((prevState) => ({ ...prevState, ["staff_role"]: filterData.staff_role }))
    setPaginationCount(1)
  }


  const handlePdf = (userDetails) => {

    const pdfDoc = new jsPDF();

    const title = 'Staff Listing';

    const headerColor = '#8fb569';

    const columns = ['Staff', 'Staff Type', 'Email', 'Phone Number'];

    const rows = userDetails.map((rowData) => [
      `${rowData?.first_name}${" "}${rowData?.last_name}`,
      rowData?.staff_role,
      rowData?.email,
      rowData?.phone,
    ]);

    pdfDoc.text(title, 14, 10);

    pdfDoc.autoTable({
      head: [columns],
      body: rows,
      startY: 20,
      theme: 'plain', // Optional: 'striped', 'grid', 'plain'
      headStyles: {
        fillColor: headerColor,
        textColor: '#ffffff', // Optional: Text color for header
      },
    });

    // Save the PDF or open it in a new tab
    pdfDoc.save('staffs.pdf');
  };


  const handleExcel = async (userDetails) => {


    // Create a new workbook
    const workbook = new ExcelJS.Workbook();

    // Add a worksheet to the workbook
    const worksheet = workbook.addWorksheet('Staffs');



    worksheet.columns = [
      { header: 'Staff', key: 'staff', width: 20, style: { font: { bold: true }, alignment: { horizontal: 'left' } } },
      { header: 'Staff Type', key: 'staffType', width: 20, style: { font: { bold: true }, alignment: { horizontal: 'left' } } },
      { header: 'Email', key: 'email', width: 20, style: { font: { bold: true }, alignment: { horizontal: 'left' } } },
      { header: 'Phone Number', key: 'phoneNumber', width: 20, style: { font: { bold: true }, alignment: { horizontal: 'left' } } },
    ];


    try {

      userDetails.forEach((user) => {
        worksheet.addRow({
          staff: `${user?.first_name}${" "}${user?.last_name}`,
          staffType: user?.staff_role,
          email: user?.email,
          phoneNumber: user?.phone,
        });
      });

    } catch (error) {
      console.error('Error converting client sign to base64:', error);
    }



    // Create a buffer from the workbook
    workbook.xlsx.writeBuffer().then((buffer) => {
      // Convert the buffer to a Blob
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Create a download link and trigger the download
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'staffs.xlsx';
      link.click();
    });
  };

  const handleDeleteCheck = (event) => {
    const { value } = event.target
    // console.log(value);
    // console.log(deleteDetails.trimmed_full_name);
    if (value !== deleteDetails.trimmed_full_name) {
      // console.log('not mathcing');
      setDeleteCheckErr(true)
    } else {
      setDeleteCheckErr(false)
    }


  }


  return (

    <>

      {
        tokenExpOpen ? <ExpirePopup {...popuProps} /> :
          <Paper sx={{ marginTop: "20px" }}>
            <Box p={2}>

              <Stack
                flexDirection={isMobile ? "row" : "column"}
                alignItems={"center"} justifyContent={"space-between"}>


                <Grid container spacing={2} my={3} alignItems={'center'} >

                  <Grid item md={4} xs={12}>
                    <TextField
                      style={{ width: "100%" }}
                      name='staff_role'
                      select
                      label="Staff type"
                      value={filterData.staff_role}
                      onChange={handleFilterChange}
                      // margin="normal"
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            {
                              filterData.staff_role &&
                              <IconButton onClick={() => setFilterData((prev) => ({ ...prev, staff_role: "" }))}>
                                <Close sx={{ color: '#909090' }} />
                              </IconButton>
                            }

                          </InputAdornment>
                        )
                      }}
                    >
                      {
                        typeLoading ? (

                          <MenuItem disabled>Loading...</MenuItem>
                        ) :
                          staffTypeData?.map((type) => (
                            <MenuItem value={type?.type}>{type?.type}</MenuItem>
                          ))
                      }
                    </TextField>


                    {/* <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Staff type</InputLabel>
                      <Select
                        name='staff_role'
                        value={filterData.staff_role}
                        label="Staff type"
                        onChange={handleFilterChange}
                      >
                        {
                          typeLoading ? (

                            <MenuItem disabled>Loading...</MenuItem>
                          ) :
                            staffTypeData?.map((type) => (
                              <MenuItem value={type?.type}>{type?.type}</MenuItem>
                            ))
                        }

                      </Select>
                    </FormControl> */}
                  </Grid>

                  <Grid item md={2} flexDirection={'row'} xs={12}>
                    <Button
                      variant='contained'
                      sx={{
                        color: "white", backgroundImage: "linear-gradient(98deg, #9cc177, #6ba134 94%)",
                        width: "100%",
                        height: "53px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                      }}
                      endIcon={<FilterAlt />}
                      onClick={() => handleFilterValue()}
                    >Filter</Button>
                  </Grid>
                </Grid>

                <Stack flexDirection={"column"} alignItems={"center"} >
                  <Typography variant='h6' py={1} textTransform={'uppercase'} >Export Data</Typography>
                  <Stack flexDirection={"row"} gap={2}>
                    <Button variant='contained' size='small' sx={{ color: "white" }}
                      onClick={() => handleExcel(userDetails)}
                      endIcon={<FontAwesomeIcon icon={faFileExcel} />}
                      disabled={userDetails.length === 0}
                    >
                      Excel
                    </Button>
                    <Button variant='contained' size='small' sx={{ color: "white" }}
                      onClick={() => handlePdf(userDetails)}
                      endIcon={<FontAwesomeIcon icon={faFilePdf} />}
                      disabled={userDetails.length === 0}
                    >PDF</Button>
                  </Stack>
                </Stack>
              </Stack>

              {/* Filters */}


              <SlotBox>
                <Typography variant='h6' py={2} textTransform={'uppercase'} >Staff listing</Typography>

                <Tooltip title='Create Staff'>
                  <Button variant='contained'
                    sx={{ color: "white", backgroundImage: "linear-gradient(98deg, #9cc177, #6ba134 94%)" }}
                    onClick={() => navigate('/staff/create')}
                  >Create</Button>
                </Tooltip>
              </SlotBox>


              {
                isUserLoading ? <div className="loader-position">
                  <CircularProgress color="primary" size={45} />
                </div>
                  :
                  userDetails.length === 0 ?
                    <Typography sx={{ m: 3, textAlign: "center" }}>Empty Staffs</Typography>
                    :
                    <>

                      <TableContainer>
                        <Table sx={{ minWidth: 650, '& th, & td': { padding: '10px' } }} size="small" aria-label="a dense table">
                          <TableHead>
                            <TableRow>
                              <TableCell >
                                <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                  Staff
                                </Typography>
                              </TableCell>
                              <TableCell >
                                <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                  Staff Type
                                </Typography>
                              </TableCell>
                              <TableCell >
                                <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                  Email
                                </Typography>
                              </TableCell>
                              <TableCell >
                                <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                  Phone
                                </Typography>
                              </TableCell>
                              <TableCell >
                                <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                  Actions
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {
                              userDetails.map((user) => (
                                <TableRow
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                  <TableCell component="th" scope="row">
                                    <div className="profile-image-table">
                                      <Avatar src={user?.avatar_path} >{getInitials(user?.first_name)}</Avatar>
                                      <Typography sx={{
                                        fontSize: '1rem', fontWeight: '600',
                                        // color: 'rgba(58, 53, 65, 0.68)'
                                      }}>
                                        {user?.first_name}
                                        {" "}
                                        {user?.last_name}
                                      </Typography>
                                    </div>
                                  </TableCell>
                                  <TableCell ><Typography sx={{ fontSize: '1rem' }}>{user.staff_role}</Typography></TableCell>
                                  <TableCell ><Typography sx={{ fontSize: '1rem' }}>{user.email}</Typography></TableCell>
                                  <TableCell ><Typography sx={{ fontSize: '1rem' }}>{user.phone}</Typography></TableCell>
                                  <TableCell >
                                    <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                                      {
                                        user?.status === 0 &&

                                        <Button variant='contained'
                                          sx={{
                                            color: "white", width: "20px", display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                          }} size='small'
                                          onClick={() => handleOpenApprove(user?._id, user?.first_name)}
                                        >Enable</Button>

                                      }

                                      {
                                        user?.status === 1 &&
                                        <Button color='error' variant='contained' sx={{
                                          color: "white", width: "20px", display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center"
                                        }} size='small'
                                          onClick={() => handleDisableModalOpen(user?._id, user?.first_name)}
                                        >Disable</Button>
                                      }


                                      <Tooltip title='View'>
                                        <IconButton onClick={() => navigate(`/staff/${user._id}`)}>
                                          <Visibility sx={{ color: '#0066b2' }} />
                                        </IconButton>
                                      </Tooltip>

                                      <Tooltip title='Delete'>
                                        <IconButton onClick={() => handleDeleteModalOpen(user?._id, user?.first_name, user?.last_name)}>
                                          <Delete color='error' />
                                        </IconButton>
                                      </Tooltip>
                                    </Stack>
                                  </TableCell>
                                </TableRow>
                              ))
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>


                      {/* Custom Pagination */}
                      <Stack flexDirection={"row"} justifyContent={"flex-end"} alignItems={"center"} gap={1}>
                        <Tooltip title='Prev' >
                          <IconButton disabled={paginationCount === 1} onClick={() => handlePrevPage()}>
                            <ArrowBackIosNewOutlined sx={{ fontSize: "17px" }} />
                          </IconButton>
                        </Tooltip>


                        <Fab size="small" color="primary" elevation="0" sx={{ color: 'white', boxShadow: 'none' }}>
                          {paginationCount}
                        </Fab>

                        <Tooltip title='Next' >
                          <IconButton disabled={customerLength < 10} onClick={() => hanldNextPage()}>
                            <ArrowForwardIosOutlined sx={{ fontSize: "17px" }} />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </>
              }
            </Box>
          </Paper>
      }

      {/* Enable */}
      <Dialog
        open={approveOpen}
        onClose={() => handleCloseApprove()}
      >
        <DialogTitle >
          {`Are you sure  want to Enable ${approveDetails.first_name} ?`}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => handleCloseApprove()}>Cancel</Button>
          <Button onClick={() => handleConfirmApprove(approveDetails.id)} autoFocus color='primary' disabled={approveLoading}>
            {
              approveLoading ? <CircularProgress size={15} color='primary' /> : 'Enable'
            }
          </Button>
        </DialogActions>
      </Dialog>

      {/* Disable */}
      <Dialog
        open={isDisableOpen}
        onClose={() => handleCloseDisableModal()}

      >
        <DialogTitle >
          {`Are you sure  want to Disable ${disableDetails.first_name} ?`}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => handleCloseDisableModal()}>Cancel</Button>
          <Button onClick={() => handleConfirmDisableModal(disableDetails.id)} autoFocus color='error' disabled={disableLoading}>
            {
              disableLoading ? <CircularProgress size={15} color='error' /> : 'Disable'
            }
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Modal */}
      <Dialog
        open={isDeleteModalOpen}
        onClose={() => handleCloseDeleteModal()}
        sx={{ '& .MuiDialog-paper': { width: '600px', maxWidth: '600px' } }}
      >
        <DialogTitle >
          {`Are you sure  want to delete ?`}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => handleCloseDeleteModal()}>Cancel</Button>
          <Button onClick={() => handleConfirmDeleteModalOpoen()} autoFocus color='error'>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Double Check Delete */}
      <Dialog
        open={isDeleteModalOpen2}
        onClose={() => setIsDeleteModalOpen2(false)}
        sx={{ '& .MuiDialog-paper': { width: '600px', maxWidth: '600px' } }}
      > <DialogTitle >
          {`Delete staff ${deleteDetails.full_name}`}
        </DialogTitle>
        <DialogContent sx={{ my: 2 }}>
          <DialogContentText id="alert-dialog-slide-description">
            <Typography sx={{ color: "black" }} fontWeight={'bold'} variant='body1'>{`To confirm, type staff name "${deleteDetails.trimmed_full_name}" in the box below`}</Typography>
            <TextField variant="outlined" fullWidth size='small' sx={{ mt: 1 }} onChange={(event) => handleDeleteCheck(event)} error={checkDeleteErr} />
          </DialogContentText>
        </DialogContent>


        <DialogActions>
          <Button onClick={() => handleDeleteConfirmClose()}>Cancel</Button>
          <Button onClick={() => handleConfirmDelete(deleteDetails.id)} autoFocus color='error' disabled={checkDeleteErr | isDeleteLoading}>

            {
              isDeleteLoading ? <CircularProgress size={15} color='error' /> : 'Delete'
            }
          </Button>
        </DialogActions>
      </Dialog>


      {/* Toast  */}
      <AppToast
        toastOpen={toastOpen}
        toastClose={() => setToastOpen(false)}
        message={toastMessage}
      />


    </>


  )
}

export default Users

