import React, { useEffect, useState } from 'react'
import 'assets/css/admin/create-user.css'
import { Alert, Box, Button, CircularProgress, FormHelperText, Grid, IconButton, InputAdornment, MenuItem, OutlinedInput, Paper, Select, Snackbar, TextField, Tooltip } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { AccountCircleOutlined, AdminPanelSettings, AdminPanelSettingsOutlined, ArrowBackOutlined, EmailOutlined, HttpsOutlined, LocalHospital, LocalHospitalOutlined, LocalPhoneOutlined, ManageAccountsOutlined, VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material'
import dataProvider from 'providers/dataProvider';
import styled from '@emotion/styled';
import { useAdminContextProvider } from 'providers/adminContextProvider';
const ClientCreate = () => {

    const navigate = useNavigate();
    const location = useLocation();
    let token = localStorage.getItem('token');

    const { setNetworkErr } = useAdminContextProvider()

    const [showPassword, setShowPassword] = useState(false);
    const [confirmshowPassword, setConfirmShowPassword] = useState(false);

    const [buttonloading, setButtonLoading] = useState(false)

    const [errroModal, setErrorModal] = useState(false)

    const [credentialError, setCrendentialError] = useState("")

    const [userDetails, setUserDetails] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phoneNumber: "",
        password: "",
        confirmPassword: ""
    })


    const [userDetailsError, setUserDetailsError] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phoneNumber: "",
        hospital_name: "",
        password: "",
        employerType: "",
        managerName: "",
        confirmPassword: ""
    })

    const [isUserDetailsError, setIsUserDetailsError] = useState({
        isfirst_name: false,
        islast_name: false,
        isEmail: false,
        isPhoneNumber: false,
        isEmployerType: false,
        isHospitalName: false,
        isManagerName: false,
        isPassword: false,
        isConfirmPassword: false
    })

    const [employerType, setEmployerType] = useState("")
    const [managerName, setManagerName] = useState("")
    const [hospitalName, setHospitalName] = useState("")


    const firstNameRegex = /^[a-zA-Z\- ']+$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneNumberRegex = /^\d{9}$/;
    let passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]).{6,}$/;

    const [typeLoading, setTypeLoading] = useState(false)
    const [employerTypeData, setEmployerTypeData] = useState([])

    useEffect(() => {
        fetchEmployerType()
    }, [])

    const fetchEmployerType = () => {
        setTypeLoading(true)
        dataProvider.getList('employer_type', {
            params: {
                token: token,
                pagination: { page: 1, perPage: 1000 },
                sort: { order: 'desc', field: 'id' },
                filter: {}
            }
        })
            .then(({ data }) => {
                // console.log(data)
                setEmployerTypeData(data)
                setTypeLoading(false)
            })
            .catch(error => {
                setTypeLoading(false)

                if (error.message === 'Network Error') {
                    setNetworkErr(true)
                }


            })
    }



    const handleInputChange = (e, regex, isError) => {

        const { name, value } = e.target


        if (name === 'confirmPassword') {

            setUserDetails((prevState) => ({ ...prevState, confirmPassword: value }))

            handleInputErrors(value, regex, name, isError, userDetails.password)

        } else {
            setUserDetails((prevState) => ({ ...prevState, [name]: value }))

            handleInputErrors(value, regex, name, isError)
        }
    }

    const handleInputErrors = (inputValue, regex, filedname, isError, checkingPassword) => {


        // if (!regex.test(inputValue) || (filedname === "confirmPassword" && inputValue !== checkingPassword)) {
        //     setUserDetailsError((prevState) => ({ ...prevState, [filedname]: getErrorMessage(filedname) }))
        //     setIsUserDetailsError((prevState) => ({ ...prevState, [isError]: true }))
        // } else {
        //     setUserDetailsError((prevState) => ({ ...prevState, [filedname]: "" }))
        //     setIsUserDetailsError((prevState) => ({ ...prevState, [isError]: false }))
        // }


        if (!regex.test(inputValue)) {
            setUserDetailsError((prevState) => ({
                ...prevState,
                [filedname]: getErrorMessage(filedname)
            }));

            setIsUserDetailsError((prevState) => ({
                ...prevState,
                [isError]: true
            }));
        } else if (filedname === "confirmPassword" && inputValue !== checkingPassword) {
            setUserDetailsError((prevState) => ({
                ...prevState,
                [filedname]: getErrorMessage("confirmPasswordMismatch")
            }));

            setIsUserDetailsError((prevState) => ({
                ...prevState,
                [isError]: true
            }));
        } else {
            setUserDetailsError((prevState) => ({
                ...prevState,
                [filedname]: ""
            }));

            setIsUserDetailsError((prevState) => ({
                ...prevState,
                [isError]: false
            }));
        }
    }


    const getErrorMessage = (filedname) => {
        switch (filedname) {
            case "first_name":
                return "Invalid  format";
            case "last_name":
                return "Invalid  format";
            case "email":
                return "Invalid email format";
            case "phoneNumber":
                return "Phone number contains  9 digits"
            case "password":
                return "Password contains six characters, capital, number, special character";
            case "confirmPassword":
                return "Password contains six characters, capital, number, special character";
            case "confirmPasswordMismatch":
                return "Password does not match";
            default:
                return "Invalid format"
        }
    }


    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickConfirmShowPassword = () => setConfirmShowPassword((confirmshowPassword) => !confirmshowPassword);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleEmployerType = (e) => {
        const { name, value } = e.target;
        setEmployerType(value)
        setIsUserDetailsError((prevState) => ({ ...prevState, isEmployerType: false }));
        setUserDetailsError((prevState) => ({ ...prevState, employerType: "" }))
    }

    const handleManager = (e) => {
        setManagerName(e.target.value)
        setIsUserDetailsError((prevState) => ({ ...prevState, isManagerName: false }));
        setUserDetailsError((prevState) => ({ ...prevState, managerName: "" }))
    }

    const handleHospital = (e) => {
        setHospitalName(e.target.value)
        setIsUserDetailsError((prevState) => ({ ...prevState, isHospitalName: false }));
        setUserDetailsError((prevState) => ({ ...prevState, hospital_name: "" }))
    }



    const handleSubmit = () => {
        const { first_name, last_name, email, phoneNumber, password, confirmPassword } = userDetails;
        let hasError = false;

        const setError = (errorfield, errorStatus, filedname, fieldError) => {
            setIsUserDetailsError((prevState) => ({ ...prevState, [errorfield]: errorStatus }));
            setUserDetailsError((prevState) => ({ ...prevState, [filedname]: fieldError }))
            hasError = true;
        };



        let errorWord = 'Please fill this field'
        // console.log({ employerType, managerName, userDetails });

        if (first_name === '') setError('isfirst_name', true, 'first_name', 'First Name is required');
        if (last_name === '') setError('islast_name', true, 'last_name', 'Last Name is required');
        if (email === '') setError('isEmail', true, 'email', 'Email is required');
        if (phoneNumber === '') setError('isPhoneNumber', true, 'phoneNumber', 'Phone number is required');
        if (password === '') setError('isPassword', true, 'password', 'Password is required');
        if (confirmPassword === '') setError('isConfirmPassword', true, 'confirmPassword', 'Confirm password is required');
        if (hospitalName === '') setError('isHospitalName', true, 'hospital_name', 'Hospital/Nurse name  is required');
        if (employerType === '' || employerType === null) setError('isEmployerType', true, 'employerType', 'Select an employer type')
        if (managerName === '' || managerName === null) setError('isManagerName', true, 'managerName', 'Manager name is required')
        if (password !== confirmPassword) setError('isConfirmPassword', true, 'confirmPassword', 'Password does not match');


        if (!hasError) {

            const data = {
                'first_name': first_name,
                'last_name': last_name,
                'email': email,
                'phone': phoneNumber,
                'password': password,
                'role': 'client',
                'employer_type': employerType,
                'manager_name': managerName,
                //
                'hospital_name': hospitalName,
            }

            // console.log(data);


            setButtonLoading(true)
            dataProvider.create('users/employer', data).then((data) => {
                navigate('/employer')
                setButtonLoading(false)
            }).catch((e) => {
                // console.log(e?.response?.data?.msg);
                setButtonLoading(false)
                setCrendentialError(e?.response?.data?.msg)
                setErrorModal(true)
            })
        }
    };

    const CreateButton = styled(Button)(({ theme }) => ({
        color: "white",
        // backgroundImage: "linear-gradient(98deg, #9cc177, #6ba134 94%)",
        height: "40px",
        width: "100px",
        display: "flex",
        justifyContent: "center"

    }));




    return (
        <>
            <Paper sx={{ margin: "20px 0" }}>
                <Box p={2} >

                    <Tooltip title='Back'>
                        <IconButton onClick={() => navigate('/employer')}>
                            <ArrowBackOutlined />
                        </IconButton>
                    </Tooltip>

                    <h3 className='newuserText' style={{ marginBottom: "20px" }}>Create a new employer</h3>

                    <Grid container spacing={2} >



                        {/* Employer Type */}
                        <Grid item md={6} xs={12}>
                            <FormControl fullWidth error={isUserDetailsError.isEmployerType} >
                                <InputLabel >Employer Type</InputLabel>
                                <Select
                                    value={employerType}
                                    label="Employer Type"
                                    onChange={handleEmployerType}
                                >
                                    {
                                        typeLoading ? (

                                            <MenuItem disabled>Loading...</MenuItem>
                                        ) :
                                            employerTypeData.length === 0 ?
                                                <MenuItem disabled>Staff type is not added</MenuItem> :
                                                employerTypeData?.map((type) => (
                                                    <MenuItem value={type?.type}>{type?.type}</MenuItem>
                                                ))
                                    }
                                </Select>
                                <FormHelperText>{isUserDetailsError.isEmployerType && userDetailsError.employerType}</FormHelperText>
                            </FormControl>
                        </Grid>

                        {/* Hospital */}
                        <Grid item md={6} xs={12}>
                            <TextField

                                label="Hospital / Nursing  name"
                                variant="outlined"
                                name='hospital_name'
                                onChange={(e) => handleHospital(e)}
                                fullWidth
                                error={isUserDetailsError.isHospitalName}
                                helperText={userDetailsError.hospital_name && userDetailsError.hospital_name}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LocalHospitalOutlined />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>


                        {/* First Name */}
                        <Grid item md={6} xs={12}>
                            <TextField id="outlined-basic"
                                label="Firstname"
                                variant="outlined"
                                name='first_name'
                                onChange={(e) => handleInputChange(e, firstNameRegex, 'isfirst_name')}
                                fullWidth
                                error={isUserDetailsError.isfirst_name}
                                helperText={userDetailsError.first_name && userDetailsError.first_name}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AccountCircleOutlined />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>

                        {/* Last Name */}
                        <Grid item md={6} xs={12}>
                            <TextField id="outlined-basic"
                                label="Lastname"
                                variant="outlined"
                                name='last_name'
                                onChange={(e) => handleInputChange(e, firstNameRegex, 'islast_name')}
                                fullWidth
                                error={isUserDetailsError.islast_name}
                                helperText={userDetailsError.last_name && userDetailsError.last_name}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AccountCircleOutlined />
                                        </InputAdornment>
                                    ),
                                }}

                            />
                        </Grid>

                        {/* Email */}
                        <Grid item md={6} xs={12}>
                            <TextField id="outlined-basic"
                                label="Email"
                                type='email'
                                variant="outlined"
                                fullWidth
                                error={isUserDetailsError.isEmail}
                                helperText={userDetailsError.email && userDetailsError.email}
                                name='email'
                                onChange={(e) => handleInputChange(e, emailRegex, 'isEmail')}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <EmailOutlined />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>

                        {/* Phone */}
                        <Grid item md={6} xs={12}>
                            <TextField id="outlined-basic"
                                label="Phone number"
                                type='number'
                                variant="outlined"
                                fullWidth
                                name='phoneNumber'
                                onChange={(e) => handleInputChange(e, phoneNumberRegex, 'isPhoneNumber')}
                                error={isUserDetailsError.isPhoneNumber}
                                helperText={userDetailsError.phoneNumber && userDetailsError.phoneNumber}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">+353</InputAdornment>,
                                }}
                            />
                        </Grid>

                        {/* Manager */}
                        <Grid item md={12} xs={12}>
                            <TextField
                                label="Manager name"
                                type='text'
                                variant="outlined"
                                fullWidth
                                onChange={(e) => handleManager(e)}
                                value={managerName}
                                error={isUserDetailsError.isManagerName}
                                helperText={isUserDetailsError.isManagerName && userDetailsError.managerName}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AdminPanelSettingsOutlined />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>

                        {/* Password */}
                        <Grid item md={6} xs={12}>
                            <FormControl variant="outlined" fullWidth error={isUserDetailsError.isPassword} required >
                                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={showPassword ? 'text' : 'password'}
                                    name='password'
                                    onChange={(e) => handleInputChange(e, passwordRegex, 'isPassword')}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <HttpsOutlined />
                                        </InputAdornment>
                                    }
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                />
                                <FormHelperText>{userDetailsError.password && userDetailsError.password}</FormHelperText>
                            </FormControl>
                        </Grid>

                        {/*Confrim  Password */}
                        <Grid item md={6} xs={12}>

                            <FormControl variant="outlined" fullWidth error={isUserDetailsError.isConfirmPassword} >
                                <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-confrim-password"
                                    type={confirmshowPassword ? 'text' : 'password'}
                                    name='confirmPassword'
                                    onChange={(e) => handleInputChange(e, passwordRegex, 'isConfirmPassword')}
                                    startAdornment={ // This is where you can add the InputAdornment
                                        <InputAdornment position="start">
                                            <HttpsOutlined />
                                        </InputAdornment>
                                    }
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickConfirmShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {confirmshowPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Confirm Password"
                                />
                                <FormHelperText>{userDetailsError.confirmPassword && userDetailsError.confirmPassword}</FormHelperText>
                            </FormControl>
                        </Grid>


                    </Grid>

                    <Box sx={{ display: "flex", justifyContent: "end", marginTop: "10px" }}>

                        <CreateButton
                            variant='contained'
                            disabled={Object.values(userDetailsError).some((error) => error !== "") || buttonloading}
                            onClick={() => handleSubmit()}
                        >
                            {
                                buttonloading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : 'Create'
                            }
                        </CreateButton>

                    </Box>


                    <Snackbar open={errroModal}
                        autoHideDuration={6000}
                        onClose={() => setErrorModal(false)}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                        <Alert onClose={() => setErrorModal(false)} severity="error" sx={{ width: '100%' }}>
                            {credentialError}
                        </Alert>
                    </Snackbar>
                </Box>
            </Paper>
        </>
    )
}

export default ClientCreate