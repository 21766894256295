import styled from '@emotion/styled';
import { Box, Button, CircularProgress, Grid, Paper, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ShadwoImage from 'assets/images/triangle-light.png'
import Trophy from 'assets/images/trophy.png'
import { AccountCircleOutlined, AttachMoneyOutlined, CalendarMonth, DocumentScanner, PermIdentityOutlined, PersonOutline, TrendingUp } from '@mui/icons-material';
import Graph from './graph';
import RequestTable from './requestTable';
import dataProvider from 'providers/dataProvider';
import { useNavigate } from 'react-router-dom';
import AcceptedRequest from './acceptedRequests';
import OngoingRequests from './ongointRequests';
import CompletedRequests from './completedRequest';
import ExpirePopup from 'components/common/ExpirePopup';
import { useClientContextProvider } from 'providers/clientContextProvider'
const Dashboard = () => {

  let firstName = localStorage.getItem('firstName');
  let token = localStorage.getItem('token');
  let loggedUserId = localStorage.getItem('userId');
  const { setNetworkErr } = useClientContextProvider()
  const navigate = useNavigate()

  const [tokenExpireMsg, setTokenExpireMsg] = useState("")
  const [tokenExpOpen, setTokenExpOpen] = useState(false)

  const [dashboardDetails, setDashboardDetails] = useState({})
  const [fetchLoading, setFetchLoading] = useState(false)

  const [shiftMonth, setShiftMonth] = useState([])
  const [shiftValues, setShiftValues] = useState([])

  const [dayKey, setDayKey] = useState([])
  const [dayValue, setDayValue] = useState([])

  useEffect(() => {
    fetchClentDahboard()
  }, [])

  const fetchClentDahboard = () => {

    setFetchLoading(true)
    dataProvider.getList('dashboard/client/' + loggedUserId, {
      params: {
        token: token,
        pagination: { page: 1, perPage: 1000 },
        sort: { order: 'desc', field: 'id' },
        filter: {}
      }
    })
      .then(({ data }) => {
        // console.log(data?.shiftMonth)
        // console.log(data);
        setDashboardDetails(data)

        const shiftMonthKey = Object.keys(data?.shiftMonth);
        const shiftMonthValue = Object.values(data?.shiftMonth)
        setShiftMonth(shiftMonthKey)
        setShiftValues(shiftMonthValue)

        const dayKey = Object.keys(data?.shiftByDay);
        const dayValue = Object.values(data?.shiftByDay)
        setDayKey(dayKey)
        setDayValue(dayValue)
        setFetchLoading(false)
      })
      .catch(error => {
        // console.log(error?.response?.data)
        // console.log(error?.response?.data?.expire)
        if (error.message === 'Network Error') {
          setNetworkErr(true)
        }

        setFetchLoading(false)
        if (error?.response?.data?.expire === true) {
          setTokenExpireMsg(error?.response?.data?.message)
          setTokenExpOpen(true)
        }
      })
  }



  const UserBox = styled(Paper)(({ theme }) => ({
    // boxShadow: "0px 2px 10px 0px rgba(58, 53, 65, 0.1)",
    // color: "rgba(58, 53, 65, 0.87)",
    // backgroundColor: "#FFF",
    padding: "30px 15px",
    borderRadius: "6px",
    position: "relative"
  }));

  const SellerWord = styled(Typography)(({ theme }) => ({
    fontWeight: "400",
    fontSize: "0.875rem",
    letterSpacing: "0.25px",
    margin: "6px 0"
  }))

  const ShadowImage = styled(Box)(({ theme }) => ({
    width: "150px",
    height: "160px",
    position: "absolute",
    bottom: "0",
    right: "0"
  }))

  const TrophyImage = styled(Box)(({ theme }) => ({
    width: "80px",
    height: "100px",
    position: "absolute",
    bottom: "20px",
    right: "30px"
  }))


  const StatisticCard = styled(Paper)(({ theme }) => ({
    // boxShadow: "0px 2px 10px 0px rgba(58, 53, 65, 0.1)",
    padding: "15px",
    borderRadius: "6px",

  }));

  const StatisticBox = styled(Box)(({ theme }) => ({
    // backgroundColor: "lightblue",
    // padding: "15px",
    borderRadius: "6px",
    display: "flex",
    alignItems: "center",
    gap: "10px"

  }));


  const StatisticsIconBox = styled(Box)(({ theme }) => ({
    height: "44px",
    width: "44px",
    boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    color: "#FFF",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }));

  const GraphBox = styled(Paper)(({ theme }) => ({
    // boxShadow: "0px 2px 10px 0px rgba(58, 53, 65, 0.1)",
    // color: "rgba(58, 53, 65, 0.87)",
    // backgroundColor: "#FFF",
    padding: "10px",
    borderRadius: "6px",
  }))

  const months = shiftMonth
  const monthData = shiftValues
  const days = dayKey
  const dayData = dayValue

  const monthlyGraphProps = {
    title: "Monthly Overview",
    category: months,
    data: monthData,
    columnWidth: '35%'

  }

  const weeklyGraphProps = {
    title: "Weekly Overview",
    category: days,
    data: dayData,
    columnWidth: '30%'
  }


  return (
    <>
      {
        tokenExpOpen ? <ExpirePopup tokenExpireMsg={tokenExpireMsg} /> :
          fetchLoading ? <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"center"} style={{ height: '65vh' }}>
            <CircularProgress color='primary' size={50} />
          </Stack> :
            <>
              <Grid container spacing={2} my={2} >

                <Grid item md={12} xs={12} lg={4} >
                  <UserBox>
                    <Typography variant='h6' fontWeight={'500'} >Welcome {firstName}!</Typography>
                    <SellerWord>Shifts this month</SellerWord>
                    <Typography variant='h5' sx={{ lineHeight: "1.334", fontWeight: "530", margin: "1rem 0", color: "#8fb569" }}>
                      {dashboardDetails?.shiftCount?.monthTotal} / {dashboardDetails?.shiftCount?.total}
                    </Typography>
                    <Button variant='contained' size='small'
                      sx={{ color: "white", backgroundImage: "linear-gradient(98deg, #9cc177, #6ba134 94%)", fontWeight: "bold" }}
                      onClick={() => navigate('/shift')}
                    >View Shifts</Button>

                    <ShadowImage>
                      <img src={ShadwoImage} width={"100%"} height={"100%"} alt="" srcset="" />
                    </ShadowImage>

                    <TrophyImage>
                      <img src={Trophy} width={"100%"} height={"100%"} alt="" srcset="" />
                    </TrophyImage>
                  </UserBox>
                </Grid>

                <Grid item md={12} lg={8}>
                  <StatisticCard>
                    <Typography variant='h6' fontWeight={'500'} >Statistics Card</Typography>

                    <Grid container my={1} spacing={2}>

                      <Grid item md={6} xs={6}  >
                        <StatisticBox>
                          <StatisticsIconBox sx={{ backgroundColor: "#9155FD" }}>
                            <CalendarMonth />
                          </StatisticsIconBox>

                          <Box>
                            <Typography fontSize={'small'} >Shift Request Approved</Typography>
                            <Typography variant='h6'>{dashboardDetails?.shiftRequestCount?.approve}</Typography>
                          </Box>
                        </StatisticBox>
                      </Grid>


                      <Grid item md={6} xs={6}>
                        <StatisticBox>
                          <StatisticsIconBox sx={{ backgroundColor: "#56CA00" }}>
                            <DocumentScanner />
                          </StatisticsIconBox>

                          <Box>
                            <Typography fontSize={'small'} >Document Requests</Typography>
                            <Typography variant='h6' >{dashboardDetails?.docRequestCount?.request}</Typography>
                          </Box>
                        </StatisticBox>
                      </Grid>


                      <Grid item md={6} xs={6} >
                        <StatisticBox>
                          <StatisticsIconBox sx={{ backgroundColor: "#FFB400" }}>
                            <CalendarMonth />
                          </StatisticsIconBox>

                          <Box>
                            <Typography fontSize={'small'} >Shift Request Accepted</Typography>
                            <Typography variant='h6' >{dashboardDetails?.shiftRequestCount?.accept}</Typography>
                          </Box>
                        </StatisticBox>
                      </Grid>


                      <Grid item md={6} xs={6} >
                        <StatisticBox>
                          <StatisticsIconBox sx={{ backgroundColor: "#16B1FF" }}>
                            <DocumentScanner />
                          </StatisticsIconBox>

                          <Box>
                            <Typography fontSize={'small'} >Document Requests Approved</Typography>
                            <Typography variant='h6' >{dashboardDetails?.docRequestCount?.approve}</Typography>
                          </Box>
                        </StatisticBox>
                      </Grid>

                    </Grid>
                  </StatisticCard>
                </Grid>
              </Grid>

              <Grid container spacing={2}>

                <Grid item md={12} xs={12} lg={7}>
                  <GraphBox>
                    <Graph {...monthlyGraphProps} />
                  </GraphBox>
                </Grid>

                <Grid item md={12} xs={12} lg={5}>
                  <GraphBox>
                    <Graph {...weeklyGraphProps} />
                  </GraphBox>
                </Grid>
              </Grid>


              <Grid container spacing={2} my={2}>
                <Grid item md={12} xs={12} lg={12}>
                  <GraphBox>
                    <AcceptedRequest token={token} />
                  </GraphBox>
                </Grid>
              </Grid>

              <Grid container spacing={2} my={2}>
                <Grid item md={12} xs={12} lg={12}>
                  <GraphBox>
                    <OngoingRequests token={token} />
                  </GraphBox>
                </Grid>
              </Grid>

              <Grid container spacing={2} my={2}>
                <Grid item md={12} xs={12} lg={12}>
                  <GraphBox>
                    <CompletedRequests token={token} />
                  </GraphBox>
                </Grid>
              </Grid>
            </>
      }



      {/* <Grid container spacing={2}>

        <Grid item md={12} my={2} xs={12}>
          <GraphBox>
            <RequestTable />
          </GraphBox>
        </Grid>


      </Grid> */}



    </>
  )
}

export default Dashboard