import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Avatar, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Paper, TextField, Tooltip, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import CreateAreaModal from './create';
import EditAreaModal from './edit';
import DeleteAreaModal from './delete';
import { CheckCircle, DoNotDisturb } from '@mui/icons-material';
import dataProvider from 'providers/dataProvider';
import styled from '@emotion/styled';
import ExpirePopup from 'components/common/ExpirePopup';
import { useAdminContextProvider } from 'providers/adminContextProvider';
const AreaList = () => {

  let token = localStorage.getItem('token');
  const { setNetworkErr } = useAdminContextProvider()

  // Dailoges
  const [iscreateArea, setIscreateArea] = useState(false)
  const [isEditArea, setIsEditArea] = useState(false)
  const [iseDeleteModal, setIsdeleteModal] = useState(false)

  const [areaFetchloading, setFetchAreaLoading] = useState(false)

  const [buttonloading, setButtonLoading] = useState(false)


  const [selectedEditDetails, setEditDetails] = useState({
    id: "",
    area: "",
    locationId: "",
    status: 1
  })

  const [selectedDeleteDetails, setSelectedDeleteDetails] = useState({
    id: "",
    area: ""
  })

  const [locationData, setLocationData] = useState([])
  const [areaData, setAreaData] = useState([])

  const [tokenExpireMsg, setTokenExpireMsg] = useState("")
  const [tokenExpOpen, setTokenExpOpen] = useState(false)





  // Null Error

  const [areaNullError, setAreaNullError] = useState(false)

  useEffect(() => {
    fetchAreaData()
    fetchLocationData()
  }, [])



  const fetchAreaData = () => {
    setFetchAreaLoading(true)
    dataProvider.getList('areas',
      {
        params: {
          token: token,
          pagination: {
            page: 1,
            perPage: 1000
          },
          sort: { order: 'desc', field: 'id' },
          filter: {}
        }
      })
      .then(({ data }) => {
        setAreaData(data)
        setFetchAreaLoading(false)
      })
      .catch(error => {
        setFetchAreaLoading(false)
        // console.log(error?.response?.data)
        // console.log(error?.response?.data?.expire)
        if (error.message === 'Network Error') {
          setNetworkErr(true)
        }
        if (error?.response?.data?.expire === true) {
          setTokenExpireMsg(error?.response?.data?.message)
          setTokenExpOpen(true)
        }
      })
  }


  const fetchLocationData = () => {

    dataProvider.getList('locations',
      {
        params: {
          token: token,
          pagination: {
            page: 1,
            perPage: 1000
          },
          sort: { order: 'desc', field: 'id' },
          filter: {}
        }
      })
      .then(({ data }) => {
        setLocationData(data)
      })
      .catch(error => {
        // console.log(error?.response?.data)
        // console.log(error?.response?.data?.expire)
        if (error.message === 'Network Error') {
          setNetworkErr(true)
        }

        if (error?.response?.data?.expire === true) {
          setTokenExpireMsg(error?.response?.data?.message)
          setTokenExpOpen(true)
        }
      })
  }




  //Create
  const handleConfirmCreateArea = (location, area, status) => {

    if (location === "" || location === null || area === "" || area === null) {
      setAreaNullError(true)
    } else {

      let data = {
        'area': area,
        'locationId': location,
        'status': status
      }

      setButtonLoading(true)
      dataProvider.create('areas', data).then((data) => {
        setButtonLoading(false)
        setIscreateArea(false)
        setAreaNullError(false)
        fetchAreaData()
      }).catch((e) => {
        console.log(e)
        setButtonLoading(false)
      })
    }
  }
  //Create




  // Edit
  const handleEditmodelOpen = (id, area, status, locationId) => {
    setIsEditArea(true)
    setEditDetails((prevState) => ({
      ...prevState,
      id: id,
      area: area,
      status: status,
      locationId: locationId,
    }))
  }


  const hanldleCloseEditModal = () => {
    setAreaNullError(false)
    setIsEditArea(false)
    setEditDetails((prevState) => ({
      ...prevState,
      id: "",
      area: "",
      status: 0
    }))
  }


  const handleEditArea = (id, locationId, area, status) => {
    // console.log({ id, locationId, area, status })


    if (area === "" || area === null || locationId === "" || locationId === null) {
      setAreaNullError(true)
    } else {

      let data = {
        'id': id,
        'locationId': locationId,
        'area': area,
        'status': status
      }

      setButtonLoading(true)

      dataProvider.update('areas', data).then((data) => {
        // console.log(data)
        setButtonLoading(false)
        setIsEditArea(false)
        setAreaNullError(false)
        fetchAreaData()
      }).catch((e) => {
        setButtonLoading(false)
        console.log(e)
      })
    }

  }
  // Edit



  //Delete
  const handleDeleteModalOpen = (id, area) => {
    setIsdeleteModal(true)
    setSelectedDeleteDetails((prevState) => ({
      ...prevState,
      id: id,
      area: area
    }))
  }


  const handleCloseDeleteModal = () => {
    setIsdeleteModal(false)
    setSelectedDeleteDetails((prevState) => ({
      ...prevState,
      id: null,
    }))
  }



  const handleConfirmDelete = (id) => {
    let data = {
      'id': id
    }

    setButtonLoading(true)
    dataProvider.delete('areas', data).then((data) => {
      setSelectedDeleteDetails((prevState) => ({
        ...prevState,
        id: "",
      }))
      setButtonLoading(false)
      setIsdeleteModal(false)
      fetchAreaData()
    }).catch((e) => {
      console.log(e)
      setButtonLoading(false)
    })
  }

  //Delete



  // Props
  let createAreaModalProps = {
    iscreateArea: iscreateArea,
    setIscreateArea: setIscreateArea,
    handleConfirmCreateArea: handleConfirmCreateArea,
    locationData: locationData,
    areaNullError: areaNullError,
    buttonloading: buttonloading,
    setAreaNullError: setAreaNullError
  }


  let editAreaModalprops = {
    isEditArea: isEditArea,
    handleEditArea: handleEditArea,
    selectedEditDetails: selectedEditDetails,
    hanldleCloseEditModal: hanldleCloseEditModal,
    buttonloading: buttonloading,
    areaNullError: areaNullError,
    locationData: locationData,
    setAreaNullError: setAreaNullError
  }

  let deleteModalprops = {
    iseDeleteModal: iseDeleteModal,
    handleCloseDeleteModal: handleCloseDeleteModal,
    handleConfirmDelete: handleConfirmDelete,
    selectedDeleteDetails: selectedDeleteDetails,
    buttonloading: buttonloading
  }

  const SlotBox = styled(Box)(({ theme }) => ({
    margin: "20px 10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  }));



  return (
    <>


      {
        tokenExpOpen ? <ExpirePopup tokenExpireMsg={tokenExpireMsg} /> :
          <Paper sx={{ marginTop: "20px" }}>
            <Box p={2}>
              <SlotBox>
                <Typography variant='h6' py={2} textTransform={'uppercase'} >Area listing</Typography>
                <Tooltip title='Create user'>
                  <Button variant='contained' sx={{ color: "white", backgroundImage: "linear-gradient(98deg, #9cc177, #6ba134 94%)" }}
                    onClick={() => setIscreateArea(true)}
                  >Create</Button>
                </Tooltip>
              </SlotBox>


              {/* Create Area */}
              <CreateAreaModal {...createAreaModalProps} />
              {/* Create Area */}

              {/* Edit Area */}
              <EditAreaModal {...editAreaModalprops} />
              {/* Edit Area */}


              {/* Delete modal */}
              <DeleteAreaModal {...deleteModalprops} />
              {/* Delete modal */}

              {
                areaFetchloading ? <div className="loader-position">
                  <CircularProgress color="primary" size={45} />
                </div>
                  :
                  areaData.length === 0 ?
                    <Typography sx={{ m: 3, textAlign: "center" }}>Empty Areas</Typography>
                    :
                    <TableContainer>
                      <Table sx={{ minWidth: 650, '& th, & td': { padding: '10px' } }} size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ width: "40%" }} >
                              <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                Location
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ width: "40%" }} >
                              <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                Area
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ width: "40%" }} >
                              <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                Status
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ width: "40%" }} >
                              <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                Actions
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>

                          {
                            areaData.map((area) => (

                              <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell ><Typography sx={{ fontSize: '1rem' }}>{area?.locationId?.location}</Typography></TableCell>
                                <TableCell ><Typography sx={{ fontSize: '1rem' }}>{area?.area}</Typography></TableCell>
                                <TableCell >
                                  <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                    {
                                      area?.status === 1 ?
                                        <>
                                          <CheckCircle style={{ color: '#8fb569', width: "22px", height: "22px" }} />
                                          <Typography fontWeight='bold'>Active</Typography>
                                        </>
                                        :
                                        <>
                                          <DoNotDisturb style={{ color: 'red', width: "22px", height: "22px" }} />
                                          <Typography fontWeight='bold'>Inactive</Typography>
                                        </>
                                    }
                                  </Box>
                                </TableCell>
                                <TableCell >


                                  <div className="user-action-buttons">
                                    <Tooltip title='Edit'>
                                      <IconButton onClick={() => handleEditmodelOpen(area?._id, area?.area, area?.status, area?.locationId?._id)}>
                                        <EditIcon sx={{ color: '#0066b2' }} />
                                      </IconButton>
                                    </Tooltip>

                                    <Tooltip title='Delete'>
                                      <IconButton onClick={() => handleDeleteModalOpen(area?._id, area?.area)}>
                                        <DeleteIcon sx={{ color: 'red' }} />
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                </TableCell>
                              </TableRow>
                            ))
                          }



                        </TableBody>
                      </Table>
                    </TableContainer>
              }
            </Box>
          </Paper>
      }

    </>
  )
}

export default AreaList