import React from 'react'
import { TableBody, TableContainer, Table, TableHead, TableRow, TableCell, Box, Typography, IconButton, Stack, Avatar, Tooltip } from '@mui/material'
import { CheckCircle, DoNotDisturb, Visibility } from '@mui/icons-material'
const WithDrawalRequestTable = ({ payments, editmodalOpen }) => {
    return (
        <Box mt={2}>
            <TableContainer>
                <Table sx={{ minWidth: 650, '& th, & td': { padding: '10px' } }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell >
                                <Typography sx={{ fontSize: '1rem' }} fontWeight="bold" >
                                    Request Id
                                </Typography>
                            </TableCell>
                            <TableCell  >
                                <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                    Employer
                                </Typography>
                            </TableCell>
                            <TableCell  >
                                <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                    Amount
                                </Typography>
                            </TableCell>
                            <TableCell  >
                                <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                    Date
                                </Typography>
                            </TableCell>
                            <TableCell  >
                                <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                    Status
                                </Typography>
                            </TableCell>
                            <TableCell  >
                                <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                    Action
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            payments?.map((payment) => (
                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    key={payment?.reqId}
                                >
                                    <TableCell ><Typography sx={{ fontSize: '1rem' }} >{payment?.reqId}</Typography></TableCell>
                                    <TableCell >
                                        <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                                            <Avatar></Avatar>
                                            <Typography sx={{ fontSize: '1rem' }}>
                                                {payment?.employer}
                                            </Typography>
                                        </Stack>
                                    </TableCell>
                                    <TableCell ><Typography sx={{ fontSize: '1rem' }} >{`$${payment?.amount}`}</Typography></TableCell>
                                    <TableCell ><Typography sx={{ fontSize: '1rem' }} >{payment?.date}</Typography></TableCell>
                                    <TableCell ><Typography sx={{ fontSize: '1rem' }}>
                                        <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                            {
                                                payment?.status === 1 ?
                                                    <>
                                                        <CheckCircle style={{ color: '#8fb569', width: "22px", height: "22px" }} />
                                                        <Typography >Active</Typography>
                                                    </>
                                                    :
                                                    <>
                                                        <DoNotDisturb style={{ color: 'red', width: "22px", height: "22px" }} />
                                                        <Typography >Inactive</Typography>
                                                    </>
                                            }
                                        </Box>
                                    </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Tooltip title='View'>
                                            <IconButton onClick={() => editmodalOpen(payment?.reqId)} >
                                                <Visibility sx={{ color: '#0066b2' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

export default WithDrawalRequestTable