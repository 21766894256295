import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'

const DeleteLocationModal = ({ iseDeleteModal, handleCloseDeleteModal, handleConfirmDelete, deletedDetails, buttonLoading }) => {

    const { id, location } = deletedDetails


    return (
        <>

            <Dialog
                open={iseDeleteModal}
                onClose={() => handleCloseDeleteModal()}
            >
                <DialogTitle >
                    {`Are you sure  want to delete location ${location}?`}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => handleCloseDeleteModal()}>Cancel</Button>
                    <Button onClick={() => handleConfirmDelete(id)} color='error' disabled={buttonLoading}>
                        {
                            buttonLoading ? <CircularProgress size={15} color='error' /> : 'Delete'
                        }
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default DeleteLocationModal