import React, { useEffect, useState } from 'react'
import 'assets/css/admin/edit-user.css'
import dataProvider from 'providers/dataProvider'
import { Alert, Box, Button, CircularProgress, Fab, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Paper, Select, Snackbar, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AddPhotoAlternate, ArrowForwardIosOutlined, Cancel, CancelPresentation, CheckCircle, CloudUpload, Delete, DoNotDisturb, Download, Edit, PictureAsPdf, Visibility } from '@mui/icons-material';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import pdfImage from "assets/images/certifacate.png"
import imageIcon from "assets/images/image_icon.png"
import pdfIcon from "assets/images/pdf_icon.png"
import { Document, Page, pdfjs } from 'react-pdf';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';
import styled from '@emotion/styled';
import closeIcon from 'assets/images/signs-close-icon-png.png'



const CertificateTable = ({ userid }) => {

    pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
    const [certificatesData, setcertificatesData] = useState([])

    const [selectedFile, setselectedFile] = useState({
        file: "",
        blob: "",
        isPdf: false
    })

    const [title, setTitle] = useState("")
    const [status, setStatus] = useState(1)
    const [error, setError] = useState(false)






    const [iscertificateModalOpen, setIsCertificateModalOpen] = useState(false)
    const [isDataLoading, setIsDataLoading] = useState(false)

    const [certificateUpload, setCertificateUpload] = useState(false)
    const [certificateEdit, setCertificateEdit] = useState(false)
    const [deleteCertificate, setDeleteCertificate] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)


    const [docPath, setDocPath] = useState("")
    const [certificateTitle, setCertificateTitle] = useState("")




    const [certificateLoading, setCertificateLoading] = useState({})

    const [editDetails, setEditDetails] = useState({
        title: "",
        doc_path: "",
        id: "",
        status: 1
    })

    const [pageNumber, setPageNumber] = useState(1)
    const [numPages, setNumPages] = useState(null);

    const [deleteId, setDeleteId] = useState("")

    useEffect(() => {
        fetchcertificatesData()
    }, [])

    const fetchcertificatesData = () => {
        setIsDataLoading(true)
        dataProvider.getOne('users/customers', { id: userid }).then(({ data }) => {
            setIsDataLoading(false)
            setcertificatesData(data?.docs)
        }).catch((err) => {
            setIsDataLoading(false)
            console.log(err)
        })
    }

    const handleOpenCertificateModal = (doc_path, title) => {
        setIsCertificateModalOpen(true)
        setDocPath(doc_path)
        console.log(doc_path);
        setCertificateTitle(title)

    }





    const handleDownloadCertificate = (doc_path, id) => {
        let checkPdf = isPdf(doc_path)
        if (checkPdf) {
            downloadPdf(doc_path, id)
        } else {
            downloadImage(doc_path, id)
        }
    }

    const handleUploadCertificate = () => {
        setCertificateUpload(true)
    }




    const truncateString = (str, maxLength) => {
        if (str.length > maxLength) {
            return str.substring(0, maxLength) + '....';
        }
        return str;
    }


    const downloadImage = (imageUrl, id) => {

        const fileName = 'certificate.jpg';

        setCertificateLoading((prevState) => ({
            ...prevState,
            [id]: true
        }))
        fetch(imageUrl)
            .then(response => response.blob())
            .then(blob => {
                // Create a temporary link element
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);

                // Append the link to the body
                document.body.appendChild(link);

                // Programmatically click the link to trigger the download
                link.click();

                // Remove the link
                link.parentNode.removeChild(link);
                setCertificateLoading((prevState) => ({
                    ...prevState,
                    [id]: false
                }))
            })
            .catch(error => {
                console.error('Error downloading image:', error);
                setCertificateLoading((prevState) => ({
                    ...prevState,
                    [id]: false
                }))
            });
    }

    const downloadPdf = (pdfUrl, id) => {
        const fileName = 'certificate.pdf'; // Set the file name with .pdf extension

        setCertificateLoading((prevState) => ({
            ...prevState,
            [id]: true
        }))
        fetch(pdfUrl)
            .then(response => response.blob())
            .then(blob => {
                // Create a temporary link element
                const url = window.URL.createObjectURL(new Blob([blob], { type: 'application/pdf' })); // Set content type as application/pdf
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);

                // Append the link to the body
                document.body.appendChild(link);

                // Programmatically click the link to trigger the download
                link.click();

                // Remove the link
                link.parentNode.removeChild(link);
                setCertificateLoading((prevState) => ({
                    ...prevState,
                    [id]: false
                }))
            })
            .catch(error => {
                console.error('Error downloading image:', error);
                setCertificateLoading((prevState) => ({
                    ...prevState,
                    [id]: false
                }))
            });
    }


    const isPdf = (path) => {
        const trimmedPath = path.trim();
        return trimmedPath.toLowerCase().endsWith('.pdf');
    };

    const SendButton = styled(Button)(({ theme }) => ({
        // color: "white",
        width: "100px",
        display: "flex",
        alignItems: "center",
        gap: "5px"
    }));

    const handleImg = () => {
        const fileInput = document.getElementById("profileFile")
        if (fileInput) {
            fileInput.click();
        }
    };


    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];


        if (selectedFile) {

            let fileBlob = URL.createObjectURL(selectedFile)



            if (isPdf(selectedFile?.name)) {
                setselectedFile((prevState) => ({
                    ...prevState,
                    file: selectedFile,
                    blob: fileBlob,
                    isPdf: true
                }))
            } else {
                setselectedFile((prevState) => ({
                    ...prevState,
                    file: selectedFile,
                    blob: fileBlob,
                    isPdf: false
                }))
            }

        }
    }


    const handleCloseImage = () => {
        setselectedFile((prevState) => ({
            ...prevState,
            file: "",
            blob: "",
            isPdf: false
        }))
    }

    const certificateClose = () => {
        setselectedFile((prevState) => ({
            ...prevState,
            file: "",
            blob: "",
            isPdf: false
        }))
        setCertificateUpload(false)
    }

    const handleSubitCertficate = () => {

        let selectedFileCopy = selectedFile.file

        if (title === "" || title === null || !title || selectedFile.file === "" || selectedFile.file === null || !selectedFile.file) {
            setError(true)
        } else {

            let data = {
                'document': selectedFileCopy,
                'customerId': userid,
                'title': title,
                'status': status
            }

            setSubmitLoading(true)
            dataProvider.uploadCertificate('documents', data).then((data) => {
                setSubmitLoading(false)
                // console.log(data);
                setselectedFile((prevState) => ({
                    ...prevState,
                    file: "",
                    blob: "",
                    isPdf: false
                }))
                setTitle("")
                setCertificateUpload(false)
                fetchcertificatesData()
            }).catch((e) => {
                console.log(e)
                setSubmitLoading(false)
            })
        }
    }



    // Edit

    const handleCertficateOpen = (certificate) => {

        const { _id, title, doc_path, status } = certificate

        setEditDetails((prevState) => ({
            ...prevState,
            id: _id,
            title: title,
            doc_path: doc_path,
            status: status
        }))
        setCertificateEdit(true)
    }

    const handleCertificateClose = () => {
        setEditDetails((prevState) => ({
            ...prevState,
            id: "",
            title: "",
            doc_path: "",
            status: ""
        }))

        setselectedFile((prevState) => ({
            ...prevState,
            file: "",
            blob: "",
            isPdf: false
        }))
        setCertificateEdit(false)
    }

    const handleCertificateEdit = () => {
        const { title, id, status } = editDetails

        let selectedFileCopy = selectedFile.file

        if (title === "" || title === null || !title) {
            setError(true)
        } else {
            const data = {
                'id': id,
                'title': title,
                status: status
            }

            if (selectedFile.file !== '' && selectedFile.file !== null) {
                data["document"] = selectedFile.file
            }

            setSubmitLoading(true)
            dataProvider.updateCertificate('documents', data).then((data) => {

                console.log(data);
                setSubmitLoading(false)
                setEditDetails((prevState) => ({
                    ...prevState,
                    doc_path: "",
                    id: "",
                    title: "",
                    // status: 1
                }))

                setselectedFile((prevState) => ({
                    ...prevState,
                    file: "",
                    blob: "",
                    isPdf: false
                }))

                fetchcertificatesData()

                setCertificateEdit(false)

            }).catch((e) => {
                console.log(e)
                setSubmitLoading(false)

            })
        }
    }



    const handleLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);

    };



    // Delete

    const handleDeleteOpen = (certificate) => {
        const { _id, title, doc_path, status } = certificate
        setDeleteId(_id)
        setDeleteCertificate(true)
    }

    const handleDeleteClose = () => {
        setDeleteCertificate(false)
        setDeleteId("")
    }

    const handleDelete = () => {
        const data = {
            'id': deleteId
        }
        setSubmitLoading(true)
        dataProvider.delete('documents', data).then((data) => {
            setSubmitLoading(false)
            setDeleteCertificate(false)
            setDeleteId("")
            fetchcertificatesData()
        }).catch((e) => {
            console.log(e)

        })
    }








    return (

        <>

            <Stack flexDirection={"row"} justifyContent={"end"} p={1}>

                <Button variant='contained' sx={{ color: "white" }} endIcon={<CloudUpload />} onClick={() => handleUploadCertificate()}>Upload</Button>

            </Stack>
            {
                isDataLoading ?
                    <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"center"}  >
                        <CircularProgress color="primary" size={40} />
                    </Stack>
                    :

                    certificatesData.length === 0 ?
                        <Typography py={2} sx={{ textAlign: "center" }}>Empty Certificates</Typography> :
                        <Box p={2}>
                            <h4 className='edit-word' style={{ textAlign: "center", textTransform: "uppercase" }}>Certificates</h4>
                            <TableContainer sx={{ margin: "10px 0" }}>
                                <Table sx={{ minWidth: 650, '& th, & td': { padding: '10px' } }} size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell >
                                                <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                    Title
                                                </Typography>
                                            </TableCell>
                                            <TableCell >
                                                <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                    Status
                                                </Typography>
                                            </TableCell>
                                            <TableCell >
                                                <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                    Certificate
                                                </Typography>
                                            </TableCell>
                                            <TableCell >
                                                <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                    Actions
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            certificatesData.map((certifcate) => (
                                                <TableRow
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >

                                                    <TableCell ><Typography sx={{ fontSize: '1rem' }}>{certifcate?.title}</Typography></TableCell>
                                                    <TableCell ><Typography sx={{ fontSize: '1rem' }}>
                                                        <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                                            {
                                                                certifcate?.status === 1 ?
                                                                    <>
                                                                        <CheckCircle style={{ color: '#8fb569', width: "22px", height: "22px" }} />
                                                                        <Typography fontWeight='bold'>Active</Typography>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <DoNotDisturb style={{ color: 'red', width: "22px", height: "22px" }} />
                                                                        <Typography fontWeight='bold'>Inactive</Typography>
                                                                    </>
                                                            }
                                                        </Box>
                                                    </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Tooltip title='Open certificate' onClick={() => handleOpenCertificateModal(certifcate?.doc_path, certifcate?.title)}>
                                                            <Box sx={{ display: "flex", alignItems: "center", gap: "5px", cursor: "pointer" }}>
                                                                <img src={isPdf(certifcate?.doc_path) ? pdfIcon : imageIcon} alt="certificate_file" height='20px' width='20px' srcset="" />
                                                                <Link className='react-link' style={{ textDecoration: 'underline' }} >
                                                                    {truncateString(certifcate.doc_path, 40)}
                                                                </Link>
                                                            </Box>
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell >
                                                        <div className="user-action-buttons">
                                                            <Stack flexDirection={"row"} justifyContent={"center"} alignItems={"center"}>
                                                                {
                                                                    certificateLoading[certifcate?._id] ?
                                                                        <CircularProgress color="primary" size={28} /> :
                                                                        <Tooltip title='Download'>
                                                                            <IconButton onClick={() => handleDownloadCertificate(certifcate?.doc_path, certifcate?._id)}>
                                                                                <Download sx={{ color: '#0066b2' }} />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                }
                                                                <Tooltip title='Edit'>
                                                                    <IconButton onClick={() => handleCertficateOpen(certifcate)}>
                                                                        <Edit sx={{ color: '#0066b2' }} />
                                                                    </IconButton>
                                                                </Tooltip>

                                                                <Tooltip title='Delete'>
                                                                    <IconButton onClick={() => handleDeleteOpen(certifcate)}>
                                                                        <Delete sx={{ color: '#FF033E' }} />
                                                                    </IconButton>
                                                                </Tooltip>

                                                            </Stack>

                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
            }



            {/* Certificate View Modal */}
            <Dialog
                open={iscertificateModalOpen}
                onClose={() => setIsCertificateModalOpen(false)}
                scroll='paper'
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                fullWidth
            >
                <DialogTitle id="scroll-dialog-title">{certificateTitle}</DialogTitle>
                <DialogContent >
                    <DialogContentText
                        id="scroll-dialog-description"
                        tabIndex={-1}
                    >
                        {
                            isPdf(docPath) ? (
                                // <div >
                                //     <Document file={docPath} onLoadSuccess={handleLoadSuccess} >
                                //         <Page
                                //             renderTextLayer={false}
                                //             renderAnnotationLayer={false}
                                //             customTextRenderer={false}
                                //             pageNumber={pageNumber} />
                                //     </Document>

                                //     {/* <embed src=
                                //         {selectedFile.blob}
                                //         width="100%"
                                //         height="100%" /> */}
                                // </div>
                                <>

                                    <Grid item md={12} lg={12} xl={12}>
                                        <Box sx={{
                                            padding: "10px",
                                            height: "500px",
                                            width: "100%",
                                            position: "relative",
                                            // marginBottom: "10px",
                                        }}>
                                            {/* <embed src=
                                                {docPath}
                                                width="100%"
                                                height="100%" /> */}

                                            <Document file={docPath} onLoadSuccess={handleLoadSuccess} >
                                                <Page
                                                    renderTextLayer={false}
                                                    renderAnnotationLayer={false}
                                                    customTextRenderer={false}
                                                    pageNumber={pageNumber} />
                                            </Document>
                                        </Box>
                                    </Grid>
                                </>
                            ) : (
                                <img src={docPath} alt="Certificate" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                            )
                        }

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {
                        isPdf(docPath) && <Box >
                            {/* <Tooltip title='Prev'> */}
                            <IconButton onClick={() => setPageNumber((prevPage) => prevPage - 1)} disabled={pageNumber <= 1}>
                                <ArrowBackIosNewOutlinedIcon
                                    fontSize='small'
                                />
                            </IconButton>
                            {/* </Tooltip> */}


                            <Button>{pageNumber}</Button>

                            {/* <Tooltip title='Next'> */}
                            <IconButton onClick={() => setPageNumber((prevPage) => prevPage + 1)}
                                disabled={pageNumber >= numPages}>
                                <ArrowForwardIosOutlined
                                    fontSize='small'

                                />
                            </IconButton>
                            {/* </Tooltip > */}
                        </Box>
                    }

                    <Button onClick={() => setIsCertificateModalOpen(false)}>Ok</Button>
                </DialogActions>
            </Dialog>





            {/* Upload Certificate */}
            <Dialog
                open={certificateUpload}
                onClose={() => setCertificateUpload(false)}
                scroll='paper'
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                fullWidth
            >
                <DialogTitle id="scroll-dialog-title">Upload Certificate</DialogTitle>
                <DialogContent >
                    <DialogContentText
                        id="scroll-dialog-description"
                        tabIndex={-1}
                    >
                        <Grid container spacing={2} my={1}>
                            <Grid item md={12} xs={12}>
                                <TextField
                                    id="outlined-basic"
                                    label="Title"
                                    fullWidth
                                    variant="outlined"

                                    onChange={(e) => setTitle(e.target.value)}
                                />
                            </Grid>


                            <Grid item md={12} xs={12}>
                                <TextField id="outlined-basic"
                                    label={selectedFile?.file?.name ? selectedFile?.file?.name : 'Select a file'}
                                    variant="outlined"
                                    fullWidth
                                    onClick={handleImg}
                                    // defaultValue={selectedFile?.file?.name ? selectedFile?.file?.name : 'Select a file' }
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <AddPhotoAlternate />
                                            </InputAdornment>
                                        ),
                                        readOnly: true,
                                    }}
                                />
                            </Grid>

                            {/* Show Image */}
                            {
                                selectedFile.blob && !selectedFile.isPdf &&
                                <Grid item md={12}>
                                    <Paper sx={{
                                        padding: "10px",
                                        height: "200px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",

                                        // width: "120px",
                                        position: "relative",
                                        marginBottom: "10px",
                                    }}>

                                        <img src={closeIcon} alt="" width={"25px"}
                                            height={"25px"} srcset=""
                                            style={{ color: "#f10909", position: "absolute", top: "0", right: "0", cursor: "pointer" }}
                                            onClick={() => handleCloseImage()}
                                        />

                                        <Box sx={{ width: "200px", height: "100%", }}>

                                            <img src={selectedFile.blob} alt="" srcset="" width={"100%"} height={"100%"} />
                                        </Box>

                                    </Paper>
                                </Grid>
                            }


                            {/* Show Pdf */}
                            {
                                selectedFile.blob && selectedFile.isPdf &&
                                <Grid item md={12}>
                                    <Paper sx={{
                                        padding: "10px",
                                        height: "500px",
                                        // width: "100%",
                                        position: "relative",
                                        marginBottom: "10px",
                                    }}>

                                        <img src={closeIcon} alt="" width={"25px"}
                                            height={"25px"} srcset=""
                                            style={{ color: "#f10909", position: "absolute", top: "0", right: "0", cursor: "pointer" }}
                                            onClick={() => handleCloseImage()}
                                        />



                                        <embed src=
                                            {selectedFile.blob}
                                            width="100%"
                                            height="100%" />
                                    </Paper>
                                </Grid>
                            }



                            <Grid item md={12} xs={12}>
                                <FormControl fullWidth sx={{ margin: "10px 0" }}>
                                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Status"
                                        onChange={(e) => setStatus(e.target.value)}
                                        name="status"
                                        value={status}
                                    >
                                        <MenuItem value={0}>Inactive</MenuItem>
                                        <MenuItem value={1}>Active</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <input
                                type="file"
                                id='profileFile'
                                onChange={(e) => handleFileChange(e)}
                                accept="image/*,.pdf"
                                style={{ display: 'none' }}
                            />
                        </Grid>

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => certificateClose()}>Cancel</Button>
                    <Button onClick={() => handleSubitCertficate()} disabled={submitLoading}>
                        {
                            submitLoading ? <CircularProgress size={15} color='primary' /> : 'Upload'
                        }
                    </Button>
                </DialogActions>
            </Dialog>


            {/* Edit Upload Certificate */}
            <Dialog
                open={certificateEdit}
                onClose={() => handleCertificateClose()}
                scroll='paper'
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                fullWidth
            >
                <DialogTitle id="scroll-dialog-title">Edit Certificate</DialogTitle>
                <DialogContent >
                    <DialogContentText
                        id="scroll-dialog-description"
                        tabIndex={-1}
                    >
                        <Grid container spacing={2} my={1}>
                            <Grid item md={12} xs={12}>
                                <TextField
                                    id="outlined-basic"
                                    label="Title"
                                    fullWidth
                                    variant="outlined"
                                    value={editDetails.title}
                                    onChange={(e) => setEditDetails((prevState) => ({ ...prevState, title: e.target.value }))}
                                />
                            </Grid>


                            <Grid item md={12} xs={12}>
                                <TextField id="outlined-basic"
                                    label={'Select a file'}
                                    variant="outlined"
                                    fullWidth
                                    onClick={handleImg}
                                    // defaultValue={selectedFile?.file?.name ? selectedFile?.file?.name : 'Select a file' }
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <AddPhotoAlternate />
                                            </InputAdornment>
                                        ),
                                        readOnly: true,
                                    }}
                                />
                            </Grid>

                            {/* Show Image */}
                            {
                                selectedFile.blob && !selectedFile.isPdf &&
                                <Grid item md={12}>
                                    <Typography my={1} variant='button'>Selected file</Typography>
                                    <Paper sx={{
                                        padding: "10px",
                                        height: "200px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",

                                        // width: "120px",
                                        position: "relative",
                                        marginBottom: "10px",
                                    }}>

                                        <img src={closeIcon} alt="" width={"25px"}
                                            height={"25px"} srcset=""
                                            style={{ color: "#f10909", position: "absolute", top: "0", right: "0", cursor: "pointer" }}
                                            onClick={() => handleCloseImage()}
                                        />

                                        <Box sx={{ width: "200px", height: "100%", }}>

                                            <img src={selectedFile.blob} alt="" srcset="" width={"100%"} height={"100%"} />
                                        </Box>

                                    </Paper>
                                </Grid>
                            }


                            {/* Show Pdf */}
                            {
                                selectedFile.blob && selectedFile.isPdf &&
                                <Grid item md={12}>
                                    <Typography my={1} variant='button'>Selected file</Typography>
                                    <Paper sx={{
                                        padding: "10px",
                                        height: "500px",
                                        // width: "100%",
                                        position: "relative",
                                        marginBottom: "10px",
                                    }}>

                                        <img src={closeIcon} alt="" width={"25px"}
                                            height={"25px"} srcset=""
                                            style={{ color: "#f10909", position: "absolute", top: "0", right: "0", cursor: "pointer" }}
                                            onClick={() => handleCloseImage()}
                                        />



                                        <embed src=
                                            {selectedFile.blob}
                                            width="100%"
                                            height="100%" />
                                    </Paper>
                                </Grid>
                            }


                            <Grid item md={12} xs={12}>
                                <FormControl fullWidth sx={{ margin: "10px 0" }}>
                                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Status"
                                        onChange={(e) => setEditDetails((prevState) => ({ ...prevState, status: e.target.value }))}
                                        name="status"
                                        value={editDetails.status}
                                    >
                                        <MenuItem value={0}>Inactive</MenuItem>
                                        <MenuItem value={1}>Active</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>







                            {/* Uploaded Files */}
                            {
                                editDetails.doc_path &&
                                <Grid item md={12}>

                                    <Typography my={1} variant='button'>Uploaded file</Typography>
                                    <Paper sx={{
                                        padding: "10px",
                                        height: "200px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",

                                        // width: "120px",
                                        position: "relative",
                                        marginBottom: "10px",
                                    }}>


                                        {
                                            isPdf(editDetails.doc_path) ?
                                                <embed src=
                                                    {editDetails.doc_path}
                                                    width="100%"
                                                    height="100%" /> :
                                                <Box sx={{ width: "200px", height: "100%", }}>
                                                    <img src={editDetails.doc_path} alt="" srcset="" width={"100%"} height={"100%"} />
                                                </Box>
                                        }
                                    </Paper>
                                </Grid>
                            }

                            <input
                                type="file"
                                id='profileFile'
                                onChange={(e) => handleFileChange(e)}
                                accept="image/*,.pdf"
                                style={{ display: 'none' }}
                            />
                        </Grid>

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleCertificateClose()}>Cancel</Button>
                    <Button onClick={() => handleCertificateEdit()} disabled={submitLoading}>
                        {
                            submitLoading ? <CircularProgress size={15} color='primary' /> : 'Edit'
                        }
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Delete Certificate */}
            <Dialog
                open={deleteCertificate}
                onClose={() => handleDeleteClose()}
                scroll='paper'
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                fullWidth
            >
                <DialogTitle id="scroll-dialog-title">Are you want to delete this  Certificate</DialogTitle>

                <DialogActions>
                    <Button onClick={() => handleDeleteClose()}>Cancel</Button>
                    <Button sx={{ color: '#FF033E' }} onClick={() => handleDelete()} disabled={submitLoading}>
                        {
                            submitLoading ? <CircularProgress size={15} sx={{ color: '#FF033E' }} /> : 'Delete'
                        }
                    </Button>
                </DialogActions>
            </Dialog>


            {/* Error Snack bar */}
            <Snackbar open={error}
                autoHideDuration={6000}
                onClose={() => setError(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={() => setError(false)} severity="error" variant="filled" sx={{ width: '100%' }}>
                    <Typography>Please fill all fields</Typography>
                </Alert>
            </Snackbar>
        </>
    )
}

export default CertificateTable