import { Button, Dialog, DialogActions, DialogTitle, Typography } from '@mui/material'
import authProvider from 'providers/authProvider'
import React from 'react'

const ExpirePopup = ({ tokenExpireMsg }) => {


    const handleLogout = () => {
        authProvider.logout().then((response) => {
            window.location.href = "/";
        }).catch((err) => {
            console.log(err)
        })
    }

    return (
        <>
            <Dialog
                open={true}
            // onClose={() => handleCloseDeleteModal()}
            >
                <DialogTitle >
                    <Typography variant='h6'>{tokenExpireMsg}</Typography>
                </DialogTitle>
                <DialogActions >
                    <Button variant='contained'
                        color='primary'
                        sx={{ color: "white", backgroundImage: "linear-gradient(98deg, #9cc177, #6ba134 94%)" }}
                        onClick={() => handleLogout()}
                    >
                        Login
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ExpirePopup