import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import dataProvider from 'providers/dataProvider';
import "assets/css/client/shift-request.css"
import { Avatar, Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogTitle, Divider, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Paper, Select, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { AccessTime, AccessTimeOutlined, AccountCircleOutlined, CalendarMonth, CalendarMonthOutlined, Cancel, CheckCircle, DescriptionOutlined, Email, EmailOutlined, HighlightOff, Home, LockClockOutlined, PeopleAltOutlined, PersonAddAlt1Outlined, Phone, PhoneIphoneOutlined, PhoneIphoneSharp, PictureAsPdfOutlined, QueryBuilderOutlined, TitleOutlined, TocOutlined } from '@mui/icons-material';
import HomeOutlined from '@mui/icons-material/HomeOutlined';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import styled from '@emotion/styled';
import ExpirePopup from 'components/common/ExpirePopup';
import dayjs from 'dayjs';
import { faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as XLSX from 'xlsx';
import html2canvas from 'html2canvas';
import ExcelJS from 'exceljs';
import jsPDF from 'jspdf'
import html2pdf from 'html2pdf.js';
import hirehoodLogo from 'assets/images/hirehoodLogo.png'

const ShiftRequests = () => {

  let token = localStorage.getItem('token');

  const { shiftId } = useParams()

  const location = useLocation();

  const shiftDetails = location.state;

  const navigate = useNavigate()



  let loggedUserId = localStorage.getItem('userId');


  const [approveModal, setApproveModal] = useState(false)
  // const [rejectModal, setRejectModal] = useState(false)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [requestLoading, setRequestLoading] = useState(false)
  const [shiftLoading, setShiftLoading] = useState(false)

  const [pdfLoading, setPdfLoading] = useState(false)
  const [excelLoading, setExcelLoading] = useState(false)

  const [selectedDetails, setSelectedDetails] = useState({
    id: "",
    shiftId: "",
    customerId: ""
  })



  const [requestData, setRequestData] = useState([])
  const [shiftData, setShiftData] = useState({})

  const [tokenExpireMsg, setTokenExpireMsg] = useState("")
  const [tokenExpOpen, setTokenExpOpen] = useState(false)




  useEffect(() => {
    fetchOneShiftData()
  }, [])


  const fetchRequestData = async () => {
    setRequestLoading(true)
    dataProvider.getList('shift/single/request/' + shiftId, {
      params: {
        token: token,
        pagination: {
          page: 1,
          perPage: 1000
        },
        sort: { order: 'desc', field: '_id' },
        filter: { clientId: loggedUserId }
      }
    }).then(({ data }) => {
      // console.log(data);
      setRequestData(data)
      setRequestLoading(false)
    }).catch((error) => {
      setRequestLoading(false)
      if (error?.response?.data?.expire === true) {
        setTokenExpireMsg(error?.response?.data?.message)
        setTokenExpOpen(true)
      }
    })
  };

  const fetchOneShiftData = () => {
    setShiftLoading(true)
    dataProvider.getOne('shift', { id: shiftId }).then(({ data }) => {
      if (data.shift_status === 'pending') {
        fetchRequestData()
      }
      // console.log(data)
      setShiftData(data)
      setShiftLoading(false)
    }).catch((err) => {
      if (err?.response?.data?.message) {
        navigate("*")
      }
      setShiftLoading(false)
    })
  }


  // Accept  

  const handleOpenApprove = (id, shiftId, customerId) => {
    setSelectedDetails((prevState) => ({
      ...prevState,
      id: id,
      shiftId: shiftId,
    }))
    setApproveModal(true)
  }

  const handleCloseApprove = () => {
    setSelectedDetails((prevState) => ({
      ...prevState,
      id: "",
      shiftId: "",
    }))
    setApproveModal(false)
  }



  //  when click approve  update shift to  "shift_status": "ongoing" and add requestId -> in this request id have client,approved customer etc....
  //  because multiple request approve from single client,  will not send against this shift

  const handleConfrimApprove = () => {

    const { id, shiftId } = selectedDetails

    const data = {
      'id': shiftId,
      'requestId': id,
    }


    setButtonLoading(true)
    // setButtonLoading(false)

    console.log(data);
    dataProvider.update('shift/client', data).then((data) => {
      fetchRequestData()
      fetchOneShiftData()
      setButtonLoading(false)
      setApproveModal(false)
      console.log(data)
    }).catch((e) => {
      setButtonLoading(false)
      console.log(e)
    })

  }



  const handleExcel = async (shiftData) => {


    setExcelLoading(true)
    const { title, shift_type, clientId, locationId, areaId, date, time_from, time_to, description, customerId,
      distance, ending_point, starting_point, client_sign, customer_sign } = shiftData


    // Create a new workbook
    const workbook = new ExcelJS.Workbook();

    // Add a worksheet to the workbook
    const worksheet = workbook.addWorksheet('TimeSheet');



    worksheet.columns = [
      { header: 'Role', key: 'role', width: 20, style: { font: { bold: true }, alignment: { horizontal: 'left' } } },
      { header: 'Employer', key: 'employer', width: 20, style: { font: { bold: true }, alignment: { horizontal: 'left' } } },
      { header: 'Staff', key: 'staff', width: 20, style: { font: { bold: true }, alignment: { horizontal: 'left' } } },
      { header: 'Shift Type', key: 'shiftType', width: 20, style: { font: { bold: true }, alignment: { horizontal: 'left' } } },
      { header: 'Location', key: 'location', width: 20, style: { font: { bold: true }, alignment: { horizontal: 'left' } } },
      { header: 'Area', key: 'area', width: 20, style: { font: { bold: true }, alignment: { horizontal: 'left' } } },
      { header: 'Date', key: 'date', width: 20, style: { font: { bold: true }, alignment: { horizontal: 'left' } } },
      { header: 'Time From', key: 'timeFrom', width: 20, style: { font: { bold: true }, alignment: { horizontal: 'left' } } },
      { header: 'Time To', key: 'timeTo', width: 20, style: { font: { bold: true }, alignment: { horizontal: 'left' } } },
      { header: 'Starting Eircode', key: 'startingPoint', width: 20, style: { font: { bold: true }, alignment: { horizontal: 'left' } } },
      { header: 'Ending Eircode', key: 'endingPoint', width: 20, style: { font: { bold: true }, alignment: { horizontal: 'left' } } },
      { header: 'Distance', key: 'distance', width: 20, style: { font: { bold: true }, alignment: { horizontal: 'left' } } },
      // {
      //   header: 'Description',
      //   key: 'desc',
      //   width: 50,
      //   style: { font: { bold: true }, alignment: { horizontal: 'left' } }
      // },
      { header: 'Employer Sign', key: 'empSign', width: 50, style: { font: { bold: true }, alignment: { horizontal: 'center' } } },
      { header: 'Staff Sign', key: 'staffSign', width: 50, style: { font: { bold: true }, alignment: { horizontal: 'center' } } },
    ];


    try {
      const clientSignBase64 = await convertToBase64(client_sign);
      const customerSignBase64 = await convertToBase64(customer_sign);



      worksheet.addRow({
        role: title,
        employer: clientId?.first_name,
        staff: customerId?.first_name,
        shiftType: shift_type,
        location: locationId?.location,
        area: areaId?.area,
        date: date && dayjs(date, { dateFormat: 'DD-MM-YYYY' }).format('DD-MM-YYYY'),
        timeFrom: time_from,
        timeTo: time_to,
        startingPoint: starting_point,
        endingPoint: ending_point,
        distance: distance,
        // desc: description,
      });



      const clientSignpic = workbook.addImage({
        base64: clientSignBase64,
        extension: 'png',
      });

      const customerSignpic = workbook.addImage({
        base64: customerSignBase64,
        extension: 'png',
      });

      worksheet.addImage(clientSignpic, {
        tl: { col: 12, row: 2 },
        ext: { width: 200, height: 100 },
      });

      worksheet.addImage(customerSignpic, {
        tl: { col: 13, row: 2 },
        ext: { width: 200, height: 100 },
      });



    } catch (error) {
      console.error('Error converting client sign to base64:', error);
    }



    // Create a buffer from the workbook
    workbook.xlsx.writeBuffer().then((buffer) => {
      // Convert the buffer to a Blob
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      setExcelLoading(false)
      // Create a download link and trigger the download
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'timesheet.xlsx';
      link.click();
    });
  };



  let isPdfGenerationInProgress = false;

  const handlePdf = async (staff) => {
    // Check if PDF generation is already in progress
    if (isPdfGenerationInProgress) {
      return;
    }

    // Set the flag to indicate that PDF generation is now in progress
    isPdfGenerationInProgress = true;
    setPdfLoading(true)

    const { title, shift_type, clientId, locationId, areaId, date, time_from, time_to, description, customerId,
      distance, ending_point, starting_point, client_sign, customer_sign, hospital_name, ward, floor, breaking_hours, date_to, breaking_reason } = staff






    try {
      const clientSignBase64 = await convertToBase64(client_sign);
      const customerSignBase64 = await convertToBase64(customer_sign);
      const logoBase64 = await convertToBase64(hirehoodLogo)

      const htmlContent =
    `
    <div>
    <div style="display: flex;align-items: center;margin: 10px 0;">
        <img src=${logoBase64} style="width: 80px; height: 80px;" />
        <h2>Hirehood</h2>
    </div>
    <hr>
    <div>
        <h2 style="text-align: center;text-transform: uppercase;margin: 30px 0;">Time Sheet Details</h2>

        <div style="display: flex; justify-content: space-between; margin: 10px;">
            <div style="flex-basis: calc(50% - 5px);">
                <div
                    style="display: flex; flex-direction: column; gap: 20px; padding: 10px; border: 1px solid #ccc;border-radius: 5px;">

                    <div style="display: flex;align-items: center;gap: 10px;">
                        <h3>Title</h3>
                        <span>:</span>
                        <p>${title}</p>
                    </div>


                    <div style="display: flex;align-items: center;gap: 10px;">
                        <h3>Ward</h3>
                        <span>:</span>
                        <p>${ward}</p>
                    </div>


                    <div style="display: flex;align-items: center;gap: 10px;">
                        <h3>Staff</h3>
                        <span>:</span>
                        <p>${customerId?.first_name}${" "}${customerId?.last_name}</p>
                    </div>


                    <div style="display: flex;align-items: center;gap: 10px;">
                        <h3>Location</h3>
                        <span>:</span>
                        <p>${locationId?.location}</p>
                    </div>


                    <div style="display: flex;align-items: center;gap: 10px;">
                        <h3>Date From</h3>
                        <span>:</span>
                        <p>${date && new Date(date).toLocaleDateString('en-GB', { timeZone: 'UTC' })}</p>
                    </div>



                    <div style="display: flex;align-items: center;gap: 10px;">
                        <h3>Time From</h3>
                        <span>:</span>
                        <p>${time_from}</p>
                    </div>

                    <div style="display: flex;align-items: center;gap: 10px;">
                        <h3>Starting Eircode</h3>
                        <span>:</span>
                        <p>${starting_point}</p>
                    </div>


                    <div style="display: flex;align-items: center;gap: 10px;">
                        <h3>Distance</h3>
                        <span>:</span>
                        <p>${distance}</p>
                    </div>

                    <div style="display: flex;align-items: center;gap: 10px;">
                        <h3>Break Minutes</h3>
                        <span>:</span>
                        <p>${breaking_hours ? breaking_hours : 'No Break Minutes'}</p>
                    </div>


                    <!-- Add more left column content here -->
                </div>
            </div>

            <div style="flex-basis: calc(50% - 5px);">
                <div
                    style="display: flex; flex-direction: column; gap: 20px; padding: 10px; border: 1px solid #ccc;border-radius: 5px;">


                    <div style="display: flex;align-items: center;gap: 10px;">
                        <h3>Hosptial Name</h3>
                        <span>:</span>
                        <p>${hospital_name}</p>
                    </div>


                    <div style="display: flex;align-items: center;gap: 10px;">
                        <h3>Manager name</h3>
                        <span>:</span>
                        <p>${floor}</p>
                    </div>

                    <div style="display: flex;align-items: center;gap: 10px;">
                        <h3>Employer</h3>
                        <span>:</span>
                        <p>${clientId?.first_name}${" "}${clientId?.last_name}</p>
                    </div>


                    <div style="display: flex;align-items: center;gap: 10px;">
                        <h3>Area</h3>
                        <span>:</span>
                        <p>${areaId?.area}</p>
                    </div>

                    <div style="display: flex;align-items: center;gap: 10px;">
                        <h3>Date To</h3>
                        <span>:</span>
                        <p>${date_to && new Date(date_to).toLocaleDateString('en-GB', { timeZone: 'UTC' })}</p>
                    </div>



                    <div style="display: flex;align-items: center;gap: 10px;">
                        <h3>Time To</h3>
                        <span>:</span>
                        <p>${time_to}</p>
                    </div>


                    <div style="display: flex;align-items: center;gap: 10px;">
                        <h3>Shfit Type</h3>
                        <span>:</span>
                        <p>${shift_type}</p>
                    </div>




                    <div style="display: flex;align-items: center;gap: 10px;">
                        <h3>Ending Eircode</h3>
                        <span>:</span>
                        <p>${ending_point}</p>
                    </div>

                    <!-- Add more right column content here -->
                </div>
            </div>
        </div>


        <div style="margin: 10px;">
            <div
                style="display: flex; flex-direction: column; gap: 20px; padding: 10px; border: 1px solid #ccc;border-radius: 5px;">


                <div style="display: ${breaking_reason ? 'flex' : 'none'};align-items: start;gap: 10px;margin: 10px 0;">
                    <h3>Break Reason</h3>
                    <span>:</span>
                    <p>${breaking_reason ? breaking_reason : 'No Break Reason'}</p>
                </div>

                <div style="display: flex;align-items: center;gap: 10px;">
                    <h3>Description</h3>
                    <span>:</span>
                    <p>${description}</p>
                </div>

                <!-- Add more right column content here -->
            </div>
        </div>


    </div>
    <hr>
    <div style="margin: 30px 0;">
        <div style="display: flex;align-items:center;justify-content: space-around;">
            <div>
                <h3>Signature( Submitted by Staff )</h3>
                <img src=${customerSignBase64} alt="" style="width: 100px; height: 100px;margin: 10px 0;">
            </div>

            <div>
                <h3>Signature( Submitted by Employer )</h3>
                <img src=${clientSignBase64} alt="" style="width: 100px; height: 100px;margin: 10px 0;">
            </div>
        </div>
    </div>

</div>
    `


      const element = document.createElement('div');
      element.innerHTML = htmlContent;

      html2pdf(element, {
        margin: 10,
        filename: 'timesheet.pdf',
        image: { type: 'png', quality: 0.98 },
        html2canvas: { scale: 1 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
      }).outputPdf().then((pdfBlob) => {
        // Reset the flag now that PDF generation is complete
        isPdfGenerationInProgress = false;
        setPdfLoading(false)
        // Create a Blob URL for the PDF
        const blobUrl = URL.createObjectURL(pdfBlob);

        // Create a link element and trigger the download
        const downloadLink = document.createElement('a');
        downloadLink.href = blobUrl;
        downloadLink.download = 'timesheet.pdf';
        downloadLink.click();

        // Release the Blob URL to free up resources
        URL.revokeObjectURL(blobUrl);
      });

    } catch (error) {
      console.error('Error converting client sign to base64:', error);
    }



    // Example HTML-like content

  };


  const convertToBase64 = (imageUrl) => {
    return new Promise((resolve, reject) => {
      fetch(imageUrl)
        .then(response => response.blob())
        .then(blob => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve(reader.result);
          };
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
        .catch(reject);
    });
  }


  const ClientSign = styled(Box)({
    height: "200px",
    border: "1px solid #e4dede",
    margin: "20px",
    borderRadius: "10px",
    padding: "10px"
  })

  const ClientSignBox = styled(Box)({
    padding: "10px"
  })


  const DetailsSign = styled(Box)({
    // height: "170px",
    border: "1px solid #e4dede",
    margin: "20px",
    borderRadius: "10px",
    padding: "10px"
  })



  return (
    <>



      <Grid container spacing={2} my={2}>
        <Grid item md={12} xs={12}>
          <Paper sx={{ padding: "10px" }}>
            {
              shiftLoading ? <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"center"}>
                <CircularProgress color="primary" size={45} />
              </Stack>
                :
                <Box>

                  <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
                    <Typography fontWeight={"bold"} >Shift Details</Typography>
                    {
                      shiftData?.shift_status === 'completed' &&
                      // <Stack flexDirection={'row'} alignItems={"center"}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {/* {
                    excelLoading ? <CircularProgress size={23} sx={{ margin: "0 10px" }} /> :
                      <Tooltip title="Excel">
                        <IconButton onClick={() => handleExcel(shiftData)} disabled={excelLoading}>
                          <FontAwesomeIcon icon={faFileExcel} style={{ color: "#45993d", }} />
                        </IconButton>
                      </Tooltip>
                  } */}


                        <Button variant='contained'
                          sx={{
                            color: "white",
                            backgroundImage: "linear-gradient(98deg, #9cc177, #6ba134 94%)",
                            width: "100px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "40px"
                          }}
                          onClick={() => handlePdf(shiftData)} disabled={pdfLoading}
                          endIcon={pdfLoading ? null : <FontAwesomeIcon icon={faFilePdf} style={{ color: "#fff", }} />}
                        >
                          {
                            pdfLoading ? <CircularProgress size={23} sx={{ margin: "0 10px", color: "white" }} /> : 'Export'
                          }

                        </Button>



                      </Box>
                      // </Stack>
                    }
                  </Stack>

                  {/* Shift Details */}
                  <Grid container spacing={2} my={2}>

                    {/* Title */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        label="Title"
                        variant="outlined"
                        fullWidth
                        disabled
                        value={shiftData?.title}
                        size='small'
                      />
                    </Grid>

                    {/* Hospital Name */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        label="Hospital Name"
                        variant="outlined"
                        fullWidth
                        disabled
                        value={shiftData?.hospital_name}
                        size='small'
                      />
                    </Grid>

                    {/* Ward */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        label="Ward"
                        variant="outlined"
                        fullWidth
                        name='ward'
                        value={shiftData?.ward}
                        disabled
                        size='small'
                      />
                    </Grid>


                    {/* Cost center */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        label="Manger Name"
                        variant="outlined"
                        fullWidth
                        name='costCenter'
                        value={shiftData?.floor}
                        disabled
                        size='small'
                      />
                    </Grid>

                    {/* Location */}
                    <Grid item md={6} xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Location</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={10}
                          label="Location"
                          disabled
                          size='small'
                        >
                          <MenuItem value={10}>{shiftData?.locationId?.location}</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    {/* Area */}
                    <Grid item md={6} xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-area-select-label">Area</InputLabel>
                        <Select
                          labelId="demo-area-select-label"
                          id="demo-area-select-label"
                          value={10}
                          label="Area"
                          disabled
                          size='small'
                        >
                          <MenuItem value={10}>{shiftData?.areaId?.area}</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>



                    {/* Time from */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        labelId="demo-timefrom-select-label"

                        label="Time from"
                        variant="outlined"
                        fullWidth
                        value={shiftData?.time_from}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <AccessTimeOutlined />
                            </InputAdornment>
                          ),
                        }}
                        disabled
                        size='small'
                      />
                    </Grid>

                    {/* Time to */}
                    <Grid item md={6} xs={12}>

                      <TextField id="outlined-basic"
                        labelId="demo-timeto-select-label"
                        label="Time to"
                        variant="outlined"
                        fullWidth
                        value={shiftData?.time_to}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <AccessTimeOutlined />
                            </InputAdornment>
                          ),
                        }}
                        disabled
                        size='small'
                      />
                    </Grid>


                    {/* Status */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        id="outlined-multiline-static"
                        label="Status"
                        multiline
                        value={shiftData?.status === 0 ? 'Inactive' : 'Active'}
                        maxRows={4}
                        fullWidth
                        disabled
                        size='small'
                      />
                    </Grid>

                    {/* Staff Type */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        id="shiftType"
                        label="Staff type"
                        multiline
                        value={shiftData?.shift_type}
                        maxRows={4}
                        fullWidth
                        disabled
                        size='small'
                      />
                    </Grid>

                    {/* Date */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        labelId="demo-date-select-label"
                        id="demo-area-date-label"
                        label="Date From"
                        variant="outlined"
                        fullWidth
                        value={
                          // shiftData?.date && dayjs(shiftData?.date).format('DD-MM-YYYY')
                          new Date(shiftData.date).toLocaleDateString('en-GB', { timeZone: 'UTC' })
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <CalendarMonthOutlined />
                            </InputAdornment>
                          ),
                        }}
                        size='small'
                        disabled
                      />
                    </Grid>


                    {/* Date */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        labelId="demo-date-select-label"
                        id="demo-area-date-label"
                        label="Date To"
                        variant="outlined"
                        fullWidth
                        value={
                          // shiftData?.date && dayjs(shiftData?.date).format('DD-MM-YYYY')
                          new Date(shiftData.date_to).toLocaleDateString('en-GB', { timeZone: 'UTC' })
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <CalendarMonthOutlined />
                            </InputAdornment>
                          ),
                        }}
                        size='small'
                        disabled
                      />
                    </Grid>


                    {/* Description */}
                    <Grid item md={12} xs={12}>
                      <TextField
                        id="outlined-multiline-static"
                        label="Description"
                        multiline
                        value={shiftData?.description}
                        maxRows={4}
                        fullWidth
                        disabled
                        size='small'
                      />
                    </Grid>
                  </Grid>
                </Box>
            }
          </Paper>
        </Grid>

        <Grid item md={12} xs={12}>
          <Paper>
            {
              shiftLoading === false && <Stack flexDirection={'row'} alignItems={"center"} justifyContent={"space-between"} py={2}>
                {/* <Typography fontWeight={"bold"} sx={{ padding: "10px" }}>{shiftData?.requestId ? `Accepted Request ${shiftData?.requestId?.requestBy === 'admin' ? '(Admin)' : '(You)'}` : 'Requests'}</Typography> */}
                <Typography fontWeight={"bold"} p={2}>{`Accepted Request`}</Typography>
              </Stack>
            }

            {

              shiftLoading ? <Stack py={2} flexDirection={"row"} justifyContent={"center"} alignItems={"center"}>
                <CircularProgress color="primary" size={45} />
              </Stack>
                :

                // in shift, approved request id in this shift, just show the accepted request customer
                shiftData?.requestId ?
                  <>

                    <List sx={{ width: '100%', bgcolor: '#add387', color: "white" }}>
                      <ListItem secondaryAction={
                        <Chip label={shiftData?.shift_status}
                          color='primary'
                          sx={{
                            color: "grey",
                            backgroundColor: "white",
                            textTransform: "uppercase",
                            fontSize: 'small',
                            width: '120px',
                            height: '30px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontWeight: "bold",


                            '@media (max-width: 600px)': {
                              fontSize: 'x-small',
                              width: '80px',
                            }
                          }}
                        />
                      }>
                        <ListItemAvatar>
                          <Avatar sx={{ width: "45px", height: "45px" }} src={shiftData?.customerId?.avatar_path} ></Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          sx={{
                            marginLeft: "20px",
                            '@media (max-width: 600px)': {
                              marginLeft: "0"
                            }
                          }}
                          primary={<Typography fontWeight='bold' sx={{
                            '@media (max-width: 600px)': {
                              fontSize: "15px"
                            }
                          }}>{shiftData?.customerId?.first_name}{" "}{shiftData?.customerId?.last_name}</Typography>}
                          secondary={
                            <Box sx={{
                              display: "flex", flexDirection: "column", gap: "10px", marginTop: "5px", color: "white",

                            }}>

                              <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                <Stack flexDirection={'row'} alignItems={'center'} gap={'5px'}
                                  sx={{
                                    '@media (max-width: 600px)': {
                                      fontSize: "10px"
                                    }
                                  }}
                                >
                                  <EmailOutlined sx={{
                                    fontSize: "15px",

                                  }} />
                                  <Typography sx={{
                                    fontSize: "15px",

                                  }}>{shiftData?.customerId?.email}</Typography>
                                </Stack>

                                <Stack flexDirection={'row'} alignItems={'center'} gap={'5px'} sx={{
                                  '@media (max-width: 600px)': {
                                    fontSize: "10px"
                                  }
                                }}>
                                  <PhoneIphoneOutlined sx={{ fontSize: "15px" }} />
                                  <Typography sx={{ fontSize: "15px" }}>{shiftData?.customerId?.phone}</Typography>
                                </Stack>
                              </Box>


                              <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                <Stack flexDirection={'row'} alignItems={'center'} gap={'5px'} sx={{
                                  '@media (max-width: 600px)': {
                                    fontSize: "10px"
                                  }
                                }}>
                                  <PersonAddAlt1Outlined sx={{ fontSize: "15px" }} />
                                  <Typography sx={{ fontSize: "15px" }}>{shiftData?.customerId?.role === 'customer' && 'Staff'}</Typography>
                                </Stack>


                                {/* <Stack flexDirection={'row'} alignItems={'center'} gap={'5px'} sx={{
                                  '@media (max-width: 600px)': {
                                    fontSize: "10px"
                                  }
                                }}>
                                  <HomeOutlined sx={{ fontSize: "15px" }} />
                                  <Typography sx={{ fontSize: "15px" }}>{shiftData?.customerId?.address}</Typography>
                                </Stack> */}
                              </Box>
                            </Box>
                          }
                        />
                      </ListItem>
                      {/* <Divider /> */}
                    </List>

                    {/* Completed time sheet details */}
                    {
                      shiftData?.shift_status === 'completed' &&
                      <Box mt={2}>
                        <DetailsSign>
                          <Stack flexDirection={"column"} alignItems={"start"} gap={2} sx={{ padding: "10px" }}>
                            <Typography fontWeight={"bold"}>Distance : {shiftData?.distance}</Typography>
                            <Typography fontWeight={"bold"}>Starting Eircode : {shiftData?.starting_point}</Typography>
                            <Typography fontWeight={"bold"}>Ending Eircode : {shiftData?.ending_point}</Typography>
                            <Typography fontWeight={"bold"}>Break Minutes  : {shiftData?.breaking_hours ? shiftData?.breaking_hours : 'Break Minutes is not take'}</Typography>
                            {
                              shiftData?.breaking_hours === '' &&
                              <Typography fontWeight={"bold"}>Break Reason  : {shiftData?.breaking_reason}</Typography>
                            }
                          </Stack>
                        </DetailsSign>
                        <ClientSignBox>
                          <Typography fontWeight={"bold"} >Staff Sign</Typography>

                          <ClientSign>
                            <img src={shiftData?.customer_sign} width='100%' height='100%' loading='eager' style={{ objectFit: "contain" }} />
                          </ClientSign>
                        </ClientSignBox>

                        <ClientSignBox>
                          <Typography fontWeight={"bold"} >Employer Sign</Typography>
                          <ClientSign>
                            <img src={shiftData?.client_sign} width='100%' height='100%' loading='eager' style={{ objectFit: "contain" }} />
                          </ClientSign>
                        </ClientSignBox>

                      </Box>
                    }
                  </>
                  :
                  // else  show the requests against this shift 
                  requestLoading ? <Stack py={2} flexDirection={"row"} justifyContent={"center"} alignItems={"center"}>
                    <CircularProgress color="primary" size={45} />
                  </Stack> :
                    requestData.length === 0 ?
                      <Typography py={2} textAlign={"center"}>Empty Requests</Typography>
                      :
                      requestData.map((req) => (
                        <>
                          <Divider></Divider>
                          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                            <ListItem secondaryAction={
                              req?.shiftId?.shift_status !== 'ongoing' && req?.status === 'accept' ?
                                < Button variant="contained"
                                  onClick={() => handleOpenApprove(req._id, req?.shiftId?._id)}
                                  sx={{ color: "white", width: "150px" }}
                                  endIcon={<CheckCircle />}
                                >Approve</Button> :
                                req?.shiftId?.shift_status !== 'ongoing' && req?.status === 'reject' &&
                                <Button variant='outlined' color='error' sx={{ width: "150px" }} endIcon={<Cancel />}>Rejected</Button>
                              // < Button variant="contained"
                              //   // onClick={() => handleOpenApprove(req._id, req?.shiftId?._id)}
                              //   sx={{ color: "white", width: "150px" }}
                              //   color='error'
                              //   endIcon={<CheckCircle />}
                              // >Rejected</Button>
                            }>
                              <ListItemAvatar>
                                <Avatar sx={{ width: "50px", height: "50px" }} src={req?.customerId?.avatar_path}></Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                sx={{ marginLeft: "20px" }}
                                primary={<Typography fontWeight='bold'>{req?.customerId?.first_name}{" "}{req?.customerId?.last_name}</Typography>}
                                secondary={
                                  <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", marginTop: "5px" }}>

                                    <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                      <Stack flexDirection={'row'} alignItems={'center'} gap={'5px'}>
                                        <EmailOutlined sx={{ fontSize: "15px" }} />
                                        <Typography sx={{ fontSize: "15px" }}>{req?.customerId?.email}</Typography>
                                      </Stack>

                                      <Stack flexDirection={'row'} alignItems={'center'} gap={'5px'}>
                                        <PhoneIphoneOutlined sx={{ fontSize: "15px" }} />
                                        <Typography sx={{ fontSize: "15px" }}>{req?.customerId?.phone}</Typography>
                                      </Stack>
                                    </Box>


                                    <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                      <Stack flexDirection={'row'} alignItems={'center'} gap={'5px'}>
                                        <PersonAddAlt1Outlined sx={{ fontSize: "15px" }} />
                                        <Typography sx={{ fontSize: "15px" }}>{req?.customerId?.role === 'customer' && 'Staff'}</Typography>
                                      </Stack>

                                      <Stack flexDirection={'row'} alignItems={'center'} gap={'5px'}>
                                        <PeopleAltOutlined sx={{ fontSize: "15px" }} />
                                        <Typography sx={{ fontSize: "15px" }}>{req?.customerId?.staff_role}</Typography>
                                      </Stack>
                                    </Box>
                                  </Box>
                                }
                              />
                            </ListItem>
                          </List >
                        </>
                      ))
            }
          </Paper>
        </Grid>
      </Grid >

      <Dialog
        open={approveModal}
        onClose={() => handleCloseApprove()}
        scroll='paper'
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
      >
        <DialogTitle id="scroll-dialog-title">
          <Typography variant='h6'>ARE YOU SURE YOU WANT TO ACCEPT THIS REQUEST?</Typography>

        </DialogTitle>

        <DialogActions>
          <Button onClick={() => handleCloseApprove()}>Cancel</Button>
          <Button onClick={() => handleConfrimApprove()} disabled={buttonLoading}>
            {
              buttonLoading ? <CircularProgress size={15} color='primary' /> : 'Approve'
            }
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ShiftRequests