import React, { useEffect, useState } from 'react'
import 'assets/css/admin/edit-user.css'
import { Alert, Avatar, Box, Button, CircularProgress, FormControl, FormHelperText, Grid, IconButton, Input, InputAdornment, InputLabel, MenuItem, OutlinedInput, Paper, Select, Snackbar, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { PersonOutline, MailOutlineOutlined, PhoneIphoneOutlined, ManageAccountsOutlined, CameraAltOutlined, CalendarMonthOutlined, DocumentScannerOutlined, VisibilityOutlined, VisibilityOffOutlined, EventAvailableOutlined, AttachMoney, AttachMoneyOutlined, AccountBalanceOutlined } from '@mui/icons-material'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import dataProvider from 'providers/dataProvider'
import CertificateTable from './certificate'
import CustomerRequest from './customerShifts'
import CustomerShifts from './customerShifts'
import Calender from './calender'
import styled from '@emotion/styled'
import AvailabilityCalender from '../available_staffs/availabilty'
import WithDrawalRequestTable from 'components/common/Tables/with_drawal_request_table'

const EditUser = () => {



    const { userid } = useParams()


    let token = localStorage.getItem('token');



    const navigate = useNavigate()



    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let phoneNumberRegex = /^\d{9}$/;
    const firstNameRegex = /^[a-zA-Z\- ']+$/;
    const addressRegex = /^[a-zA-Z0-9\s.,#-]+$/;
    let passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]).{6,}$/;



    const [updatedDetails, setUpdatedDetails] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phoneNumber: "",
        password: "",
        address: "",
    })

    const [updatedDetailsError, setUpdatedDetailsError] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phoneNumber: "",
        password: "",
        address: "",
    })


    const [isUpdatedDetails, setIsUpdatedDetails] = useState({
        isFirst_name: "",
        isLast_name: "",
        isEmail: "",
        isPhoneNumber: "",
        isPassword: "",
        isAddress: "",
    })

    const [hourlyRate, setHourlyRate] = useState(0)


    const [oneUserDetails, setOneUserDetails] = useState({})
    const [credentialError, setCredentialError] = useState("")
    const [staffRole, setStaffRole] = useState("")
    const [roleErr, setRoleErr] = useState(false)

    const [selectedImage, setSelectedImage] = useState({ file: null, url: null })

    const [isUserLoading, setIsUserLoading] = useState(false)
    const [isUpdateButtonLoading, setIsUpdateButtonLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const [isupdateErrModal, setIsUpdatedErrModal] = useState(false)



    const [showPages, setShowPages] = useState('edit')


    const [staffTypeData, setStaffTypeData] = useState([])
    const [typeLoading, setTypeLoading] = useState(false)



    useEffect(() => {
        fetchOneUserData()
        fetchStaffType()
    }, [])



    const fetchOneUserData = () => {
        setIsUserLoading(true)
        dataProvider.getOne('users/customers', { id: userid }).then(({ data }) => {
            setOneUserDetails(data?.user)
            setUpdatedDetails((prevState) => ({
                ...prevState,
                first_name: data?.user?.first_name,
                last_name: data?.user?.last_name,
                email: data?.user?.email,
                phoneNumber: data?.user?.phone,
                // address: data?.user?.address,
            }))

            setHourlyRate(data?.user?.hourly_fee)

            setStaffRole(data?.user?.staff_role)
            setIsUserLoading(false)
            // console.log(data?.user);
            // console.log(data);
        }).catch((err) => {
            // console.log(err)
            // console.log(err?.response?.data?.message)
            if (err?.response?.data?.message) {
                navigate("*")
            }
            setIsUserLoading(false)

        })
    }


    const fetchStaffType = () => {
        setTypeLoading(true)
        dataProvider.getList('staff_type', {
            params: {
                token: token,
                pagination: { page: 1, perPage: 1000 },
                sort: { order: 'desc', field: 'id' },
                filter: {}
            }
        })
            .then(({ data }) => {
                // console.log(data)
                setStaffTypeData(data)
                setTypeLoading(false)
            })
            .catch(error => {
                setTypeLoading(false)
                console.log(error?.response?.data)
                console.log(error?.response?.data?.expire)
                // if (error?.response?.data?.expire === true) {
                //     setTokenExpireMsg(error?.response?.data?.message)
                //     setTokenExpOpen(true)
                // }
            })
    }



    const updatedDetailsChange = (e, regex, errorValue) => {
        const { name, value } = e.target;
        setUpdatedDetails((prevState) => ({ ...prevState, [name]: value }))
        handleUpdateErrors(value, regex, name, errorValue)
    }


    const handleUpdateErrors = (inputValue, regex, fieldName, errorValue) => {
        if (inputValue.trim() === "") {
            // If the field is empty, clear the error message and set error state to false
            setUpdatedDetailsError((prevState) => ({ ...prevState, [fieldName]: "" }));
            setIsUpdatedDetails((prevState) => ({ ...prevState, [errorValue]: false }));
        } else if (!regex.test(inputValue)) {
            // If the field is not empty but doesn't match the regex, show the error message
            setUpdatedDetailsError((prevState) => ({ ...prevState, [fieldName]: getErrorMessage(fieldName) }));
            setIsUpdatedDetails((prevState) => ({ ...prevState, [errorValue]: true }));
        } else {
            // If the field is not empty and matches the regex, clear the error message and set error state to false
            setUpdatedDetailsError((prevState) => ({ ...prevState, [fieldName]: "" }));
            setIsUpdatedDetails((prevState) => ({ ...prevState, [errorValue]: false }));
        }
    }


    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];

        if (selectedFile) {
            const imageUrl = URL.createObjectURL(selectedFile);
            setSelectedImage((prevState) => ({
                ...prevState,
                file: selectedFile,
                url: imageUrl
            }))
        }
    }

    const handleProfileImageSelect = () => {
        const fileInput = document.getElementById("profileFile")
        if (fileInput) {
            fileInput.click();
        }
    };


    const getErrorMessage = (filedname) => {
        switch (filedname) {
            case "email":
                return "Invalid email format";
            case "phoneNumber":
                return "Phone number contains 9 digits"
            case "first_name":
                return "Invalid  format"
            case "last_name":
                return "Invalid  format"
            case "password":
                return "Password contains six characters, capital, number, special character";
            case "address":
                return "Invalid  format"
            default:
                return "Invalid format"
        }
    }


    const handleClickShowPassword = () => setShowPassword((show) => !show);


    const getInitials = (name) => {
        return name ? name[0].toUpperCase() : '';
    }


    // submit
    const handleEditSubmit = () => {
        const { first_name, last_name, phoneNumber, password, address, email } = updatedDetails;
        let avatar_path = selectedImage.file


        let hasError = false;


        const setError = (errorfield, errorStatus, filedname, fieldError) => {
            setIsUpdatedDetails((prevState) => ({ ...prevState, [errorfield]: errorStatus }));
            setUpdatedDetailsError((prevState) => ({ ...prevState, [filedname]: fieldError }))
            setRoleErr(errorStatus)
            hasError = true;
        };

        let errorWord = 'Please fill this field'

        if (email === '') setError('isEmail', true, 'email', errorWord);
        if (phoneNumber === '') setError('isPhoneNumber', true, 'phoneNumber', errorWord);
        if (staffRole === '' || staffRole === null || staffRole === undefined) setError('', true, '', '')



        if (!hasError) {

            let data = {
                'id': userid,
                'email': email,
                'phone': phoneNumber,
                // 'hourly_rate': hourlyRate
            }

            if (first_name !== '') data["first_name"] = first_name
            if (last_name !== '') data["last_name"] = last_name
            if (password !== '') data["password"] = password
            if (address !== '' && address !== undefined) data["address"] = address
            if (selectedImage.file !== null && selectedImage.file !== "") {
                data["avatar_path"] = avatar_path
            }
            if (staffRole !== '') data["staff_role"] = staffRole
            if (hourlyRate !== '') data["hourly_fee"] = Number(hourlyRate)


            // console.log(data,'data')


            setIsUpdateButtonLoading(true)
            // setIsUpdateButtonLoading(false)
            setRoleErr(false)

            // console.log(data);

            dataProvider.updateProfile('users', data).then((data) => {

                // console.log(data);

                setIsUpdateButtonLoading(false)

                setUpdatedDetails((prevState) => ({
                    ...prevState,
                    password: ""
                }))

                setSelectedImage((prevState) => ({
                    ...prevState,
                    file: "",
                    url: ""
                }))

                fetchOneUserData()
                // console.log(data)

            }).catch((e) => {
                console.log(e)
                setIsUpdatedErrModal(true)
                setCredentialError(e.response.data.message)
                setIsUpdateButtonLoading(false)
            })
        }

    }



    const showPagesCondition = (pages) => {
        setShowPages((prevPages) => (prevPages === pages ? 'edit' : pages));
    }




    let certificateTableProps = {
        userid: userid
    }

    let customerShiftProps = {
        userid: userid,
        oneUserDetails: oneUserDetails
    }

    let customerCalenderProps = {
        userid: userid
    }

    const DetailsBox = styled(Paper)(({ theme }) => ({
        padding: "10px"
    }));


    const handleRoleChange = (e) => {
        const { name, value } = e.target;
        setStaffRole(value)
    }


    const payments = [
        {
            reqId: "1",
            employer: "John Carter",
            amount: "123",
            date: "14-05-2024",
            status: 1,
        },
        {
            reqId: "2",
            employer: "Missa Geminez",
            amount: "34",
            date: "13-05-2024",
            status: 1,
        },
        {
            reqId: "3",
            employer: "Steve Wales",
            amount: "56",
            date: "17-05-2024",
            status: 0,
        },
        {
            reqId: "4",
            employer: "Show Shane",
            amount: "45",
            date: "15-05-2024",
            status: 1,
        },
        {
            reqId: "5",
            employer: "John Carter",
            amount: "123",
            date: "14-05-2024",
            status: 1,
        },
    ]




    return (
        <>
            <Grid container spacing={1} my={2}>
                <Grid item md={12} xs={12} lg={4} >
                    <Paper sx={{ position: "sticky", top: "0", '@media (max-width: 600px)': { position: 'static', top: 'auto' } }}>
                        {
                            isUserLoading ?
                                <Stack flexDirection={"row"} justifyContent={"center"} alignItems={"center"}>
                                    <CircularProgress color="primary" size={45} sx={{ margin: "10px 0" }} />
                                </Stack>
                                :
                                <Box p={1.5} >
                                    <Stack flexDirection={"row"} alignItems={"center"} gap={1} mt={1}>
                                        <Avatar src={oneUserDetails?.avatar_path}>{getInitials(oneUserDetails?.first_name)}</Avatar>
                                        <Typography fontWeight={'bold'}>{oneUserDetails?.first_name}{" "}{oneUserDetails?.last_name}</Typography>
                                    </Stack>


                                    <Typography my={2} fontWeight={'bold'}>Account Details</Typography>

                                    <Stack flexDirection={"column"} gap={2}>
                                        <Stack flexDirection={"row"} alignItems={"center"} gap={2}>
                                            <PersonOutline fontSize='small' />
                                            <Typography>{oneUserDetails?.first_name}{" "}{oneUserDetails?.last_name}</Typography>
                                        </Stack>

                                        <Stack flexDirection={"row"} alignItems={"center"} gap={2}>
                                            <MailOutlineOutlined fontSize='small' />
                                            <Typography>{oneUserDetails?.email}</Typography>
                                        </Stack>

                                        <Stack flexDirection={"row"} alignItems={"center"} gap={2}>
                                            <PhoneIphoneOutlined fontSize='small' />
                                            <Typography>+353{" "}{oneUserDetails?.phone}</Typography>
                                        </Stack>

                                        {/* <Stack flexDirection={"row"} alignItems={"center"} gap={2}>
                                            <ManageAccountsOutlined fontSize='small' />
                                            <Typography>{oneUserDetails?.role === 'customer' && 'Staff'}</Typography>
                                        </Stack> */}

                                        <Stack flexDirection={"row"} alignItems={"center"} gap={2}>
                                            <CalendarMonthOutlined fontSize='small' />
                                            <Link className='react-link' style={{ textDecoration: 'underline' }} onClick={() => showPagesCondition('shift')} >
                                                Shift
                                            </Link>
                                        </Stack>

                                        <Stack flexDirection={"row"} alignItems={"center"} gap={2}>
                                            <DocumentScannerOutlined fontSize='small' />
                                            <Link className='react-link' style={{ textDecoration: 'underline' }} onClick={() => showPagesCondition('certificate')}>
                                                Certificates
                                            </Link>
                                        </Stack>

                                        <Stack flexDirection={"row"} alignItems={"center"} gap={2}>
                                            <CalendarMonthOutlined fontSize='small' />
                                            <Link className='react-link' style={{ textDecoration: 'underline' }} onClick={() => showPagesCondition('calender')}>
                                                Calendar
                                            </Link>
                                        </Stack>
                                        {/* 
                                        <Stack flexDirection={"row"} alignItems={"center"} gap={2}>
                                            <EventAvailableOutlined fontSize='small' />
                                            <Link className='react-link' style={{ textDecoration: 'underline' }} onClick={() => showPagesCondition('availabiltyCalender')}>
                                                Availability
                                            </Link>
                                        </Stack> */}

                                        {/* <Stack flexDirection={"row"} alignItems={"center"} gap={2}>
                                            <AccountBalanceOutlined fontSize='small' />
                                            <Link className='react-link' style={{ textDecoration: 'underline' }} onClick={() => showPagesCondition('withDraw')}>
                                                Withdrawal Requests
                                            </Link>
                                        </Stack> */}
                                    </Stack>
                                </Box>
                        }
                    </Paper>
                </Grid>

                <Grid item md={12} xs={12} lg={8}>
                    <Paper>
                        {
                            isUserLoading ? <Stack flexDirection={"row"} justifyContent={"center"} alignItems={"center"}>
                                <CircularProgress color="primary" size={45} sx={{ margin: "10px 0" }} />
                            </Stack>
                                :
                                showPages === 'certificate' ? (<CertificateTable {...certificateTableProps} />)
                                    :
                                    showPages === 'shift' ? (<>
                                        <CustomerShifts {...customerShiftProps} />
                                    </>) :
                                        showPages === 'calender' ? (
                                            <>
                                                <Calender {...customerCalenderProps} />
                                            </>
                                        ) : showPages === 'withDraw' ? (
                                            <>
                                                <Box p={2}>
                                                    <h4 className='edit-word' style={{ textAlign: "center", textTransform: "uppercase", marginBottom: "20px" }}>Requests</h4>
                                                    <WithDrawalRequestTable payments={payments} />
                                                </Box>

                                            </>
                                        )
                                            // Edit Section
                                            :
                                            <Box p={1.5}>
                                                <Typography my={2} fontWeight={'bold'}>Edit</Typography>
                                                <Grid container>
                                                    <Grid item md={6} xs={6}>
                                                        <Stack flexDirection={'column'} gap={2.6}>

                                                            {/* First name */}
                                                            <TextField
                                                                id="standard-basic"
                                                                type='text'
                                                                variant="standard"
                                                                fullWidth
                                                                name='first_name'
                                                                placeholder='First Name'
                                                                label='First name'
                                                                onChange={(e) => updatedDetailsChange(e, firstNameRegex, 'isFirst_name')}
                                                                helperText={updatedDetailsError.first_name && updatedDetailsError.first_name}
                                                                error={isUpdatedDetails.isFirst_name}
                                                                value={updatedDetails.first_name}
                                                            />

                                                            {/*Last name */}
                                                            <TextField
                                                                id="standard-basic"
                                                                type='text'
                                                                variant="standard"
                                                                fullWidth
                                                                name='last_name'
                                                                label='Last name'
                                                                placeholder='Last Name'
                                                                onChange={(e) => updatedDetailsChange(e, firstNameRegex, 'isLast_name')}
                                                                helperText={updatedDetailsError.last_name && updatedDetailsError.last_name}
                                                                error={isUpdatedDetails.isLast_name}
                                                                value={updatedDetails.last_name}
                                                            />
                                                            {/* Email */}
                                                            <TextField
                                                                id="standard-basic"
                                                                type='email'
                                                                placeholder='Email'
                                                                variant="standard"
                                                                fullWidth
                                                                name='email'
                                                                label='Email'
                                                                onChange={(e) => updatedDetailsChange(e, emailRegex, 'isEmail')}
                                                                helperText={updatedDetailsError.email && updatedDetailsError.email}
                                                                error={isUpdatedDetails.isEmail}
                                                                value={updatedDetails.email}
                                                            />


                                                            {/* Phone */}
                                                            <TextField
                                                                id="standard-basic"
                                                                type='number'
                                                                placeholder="Phone number"
                                                                variant="standard"
                                                                fullWidth
                                                                name='phoneNumber'
                                                                label='Phone Number'
                                                                onChange={(e) => updatedDetailsChange(e, phoneNumberRegex, 'isPhoneNumber')}
                                                                InputProps={{
                                                                    startAdornment: <InputAdornment position="start">+353</InputAdornment>,
                                                                }}
                                                                helperText={updatedDetailsError.phoneNumber && updatedDetailsError.phoneNumber}
                                                                error={isUpdatedDetails.isPhoneNumber}
                                                                value={updatedDetails.phoneNumber}
                                                            />

                                                            {/* Staff Type */}
                                                            <TextField
                                                                id="employer-type"
                                                                select
                                                                label="Staff Type"
                                                                value={staffRole}
                                                                onChange={handleRoleChange}
                                                                variant="standard"
                                                                error={roleErr}
                                                                helperText={roleErr && 'Please Select a Staff type'}
                                                            >
                                                                {
                                                                    typeLoading ? (

                                                                        <MenuItem disabled>Loading...</MenuItem>
                                                                    ) :
                                                                        staffTypeData.length === 0 ?
                                                                            <MenuItem disabled>Staff type is not added</MenuItem> :
                                                                            staffTypeData?.map((type) => (
                                                                                <MenuItem value={type?.type}>{type?.type}</MenuItem>
                                                                            ))
                                                                }
                                                            </TextField>



                                                            <FormControl variant="outlined"
                                                                fullWidth
                                                                error={isUpdatedDetails.isPassword}
                                                            >
                                                                <Input
                                                                    id="outlined-adornment-password"
                                                                    type={showPassword ? 'text' : 'password'}
                                                                    name='password'
                                                                    onChange={(e) => updatedDetailsChange(e, passwordRegex, 'isPassword')}
                                                                    endAdornment={
                                                                        <InputAdornment position="end">
                                                                            <IconButton
                                                                                aria-label="toggle password visibility"
                                                                                onClick={handleClickShowPassword}
                                                                                edge="end"
                                                                            >
                                                                                {showPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    }
                                                                    placeholder='Password'
                                                                />
                                                                <FormHelperText>{updatedDetailsError.password && updatedDetailsError.password}</FormHelperText>
                                                            </FormControl>

                                                            {/* Hourly Rate */}
                                                            <TextField
                                                                id="standard-basic"
                                                                type='number'
                                                                placeholder="Hourly Rate"
                                                                variant="standard"
                                                                fullWidth
                                                                name='hourlyRate'
                                                                label='Hourly Rate'
                                                                // defaultValue={0}
                                                                onChange={(e) => setHourlyRate(e.target.value)}
                                                                value={hourlyRate}
                                                                InputProps={{
                                                                    startAdornment: <InputAdornment position="start">
                                                                        <AttachMoneyOutlined />
                                                                    </InputAdornment>,
                                                                }}

                                                            />


                                                        </Stack>
                                                    </Grid>
                                                    <Grid item md={6} xs={6}>
                                                        <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'center'}>
                                                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "10px" }}>
                                                                <Box sx={{ position: "relative" }}>
                                                                    <Avatar src={
                                                                        selectedImage.url ? (selectedImage.url) : (oneUserDetails?.avatar_path)
                                                                    }
                                                                        sx={{ width: "80px", height: "80px" }}
                                                                    >
                                                                        {getInitials(oneUserDetails?.first_name)}
                                                                    </Avatar>

                                                                    <Tooltip title="Upload Image" placement="right-start">
                                                                        <IconButton
                                                                            onClick={handleProfileImageSelect}
                                                                            sx={{ position: "absolute", bottom: "-2px", right: "0", backgroundColor: "#E8E8E8" }}
                                                                        >
                                                                            <CameraAltOutlined style={{ fontSize: '15px' }} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <input
                                                                        type="file"
                                                                        id='profileFile'
                                                                        onChange={(e) => handleFileChange(e)}
                                                                        style={{ display: 'none' }}
                                                                    />
                                                                </Box>

                                                                <Button
                                                                    variant='contained'
                                                                    sx={{
                                                                        color: "white",
                                                                        // backgroundImage: "linear-gradient(98deg, #9cc177, #6ba134 94%)",
                                                                        width: "80px",
                                                                        height: "40px",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        justifyContent: "center"
                                                                    }}
                                                                    disabled={Object.values(updatedDetailsError).some((error) => error !== "") || isUpdateButtonLoading}
                                                                    onClick={() => handleEditSubmit()}
                                                                >
                                                                    {isUpdateButtonLoading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : 'Save'}
                                                                </Button>
                                                            </Box>
                                                        </Stack>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                        }
                    </Paper>

                    <Snackbar open={isupdateErrModal}
                        autoHideDuration={6000}
                        onClose={() => setIsUpdatedErrModal(false)}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                        <Alert onClose={() => setIsUpdatedErrModal(false)} severity="error" sx={{ width: '100%' }}>
                            {credentialError}
                        </Alert>
                    </Snackbar>
                </Grid>
            </Grid>


        </>
    )
}

export default EditUser