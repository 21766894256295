import { Alert, Button, CircularProgress, FormControl, FormHelperText, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Snackbar, Stack, TextField, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import "assets/css/admin/shift.css"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ArrowBackOutlined } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { DatePicker, DesktopDatePicker, DesktopTimePicker, TimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import dataProvider from 'providers/dataProvider';
import { useEffect } from 'react';
import ExpirePopup from 'components/common/ExpirePopup';

const ShiftEdit = () => {


    let token = localStorage.getItem('token');
    const { shiftId } = useParams();

    const navigate = useNavigate()


    const [shiftDetails, setShiftDetails] = useState({
        title: "",
        locationId: "",
        areaId: "",
        description: "",
        hospitalName: "",
        ward: "",
        costCenter: "",
        status: 1
    })

    const [date, setDate] = useState("")
    const [timeFrom, setTimeFrom] = useState("")
    const [timeTo, setTimeTo] = useState("")

    const [addedTimeFrom, setAddedTimeFrom] = useState("")
    const [addedTimeTo, setAddedTimeTo] = useState("")



    const [shiftNullAlert, setShiftNullAlert] = useState(false)

    const [locationLoading, setLocationLoading] = useState(false)
    const [areaLoading, setAreaLoading] = useState(false)
    const [buttonloading, setButtonLoading] = useState(false)


    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)
    const [isTimePickerOpenFrom, setIsTimePickerOpenFrom] = useState(false)
    const [isTimePickerOpenTo, setIsTimePickerOpenTo] = useState(false)

    const [userFetchLoading, setUserFetchLoading] = useState(false)

    const [location, setLocation] = useState([])
    const [area, setArea] = useState([])


    const [tokenExpireMsg, setTokenExpireMsg] = useState("")
    const [tokenExpOpen, setTokenExpOpen] = useState(false)


    const [shiftType, setShiftType] = useState("")


    const [staffTypeData, setStaffTypeData] = useState([])
    const [typeLoading, setTypeLoading] = useState(false)

    const [timeFromOrigin, setTimeFromOrgin] = useState("")
    const [timeToOrigin, setTimeToOrgin] = useState("");


    const [addDateFrom, setaddDateFrom] = useState(null)
    const [addDateTo, setaddDateTo] = useState(null)



    useEffect(() => {
        fetchAreaData()
        fetchLocationData()
        fetchOneShiftData()
        fetchStaffType()
    }, [])


    const fetchAreaData = (locationId) => {

        setAreaLoading(true)
        dataProvider.getList('areas',
            {
                params: {
                    token: token,
                    pagination: {
                        page: 1,
                        perPage: 1000
                    },
                    sort: { order: 'desc', field: 'id' },
                    filter: { locationId: locationId }
                }
            })
            .then(({ data }) => {
                setArea(data)
                setAreaLoading(false)
            })
            .catch(error => {
                setAreaLoading(false)
                // console.log(error)
                // console.log(error?.response?.data)
                // console.log(error?.response?.data?.expire)
                if (error?.response?.data?.expire === true) {
                    setTokenExpireMsg(error?.response?.data?.message)
                    setTokenExpOpen(true)
                }
            })
    }


    const fetchLocationData = () => {
        setLocationLoading(true)
        dataProvider.getList('locations',
            {
                params: {
                    token: token,
                    pagination: {
                        page: 1,
                        perPage: 1000
                    },
                    sort: { order: 'desc', field: 'id' },
                    filter: {}
                }
            })
            .then(({ data }) => {
                setLocation(data)
                setLocationLoading(false)
            })
            .catch(error => {
                setLocationLoading(false)
                // console.log(error?.response?.data)
                // console.log(error?.response?.data?.expire)
                if (error?.response?.data?.expire === true) {
                    setTokenExpireMsg(error?.response?.data?.message)
                    setTokenExpOpen(true)
                }
            })
    }

    const fetchOneShiftData = () => {
        setUserFetchLoading(true)
        dataProvider.getOne('shift', { id: shiftId }).then(({ data }) => {

            //console.log(data);
            setShiftDetails((prevState) => ({
                ...prevState,
                title: data?.title,
                description: data?.description,
                locationId: data?.locationId?._id,
                areaId: data?.areaId?._id,
                hospitalName: data?.hospital_name,
                ward: data?.ward,
                costCenter: data?.floor
            }));

            fetchAreaData(data?.locationId?._id)
            // fetchStaffType(data?.shift_type)
            setShiftType(data?.shift_type)
            formattedDateTime(data)
            setUserFetchLoading(false)
        }).catch((err) => {
            console.log(err?.response?.data?.message)
            if (err?.response?.data?.message) {
                navigate("*")
            }
            setUserFetchLoading(false)
        })
    }



    const fetchStaffType = (type) => {
        setTypeLoading(true)
        dataProvider.getList('staff_type', {
            params: {
                token: token,
                pagination: { page: 1, perPage: 1000 },
                sort: { order: 'desc', field: 'id' },
                filter: {}
            }
        })
            .then(({ data }) => {
                // console.log(data)
                setStaffTypeData(data)
                setTypeLoading(false)
            })
            .catch(error => {
                setTypeLoading(false)
                console.log(error?.response?.data)
                console.log(error?.response?.data?.expire)
                // if (error?.response?.data?.expire === true) {
                //     setTokenExpireMsg(error?.response?.data?.message)
                //     setTokenExpOpen(true)
                // }
            })
    }

    // Format Date,Time for DatePicker,TimePicker
    const formattedDateTime = (data) => {

        // In this, date and time is formatting,because datepicker,timepicker 
        // can understand this format else show Invalid Date or time

        const newShift = new Date(data?.date_to);
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        const dateFormatter = new Intl.DateTimeFormat('en-US', { timeZone: 'UTC' }, options);
        const shiftDate = dateFormatter.format(newShift).replace(/\//g, '-');
        setDate(shiftDate)

        const formattedTimeFrom = dayjs(data?.time_from, 'hh:mm A');
        const formattedTimeTo = dayjs(data?.time_to, 'hh:mm A');
        setTimeFrom(formattedTimeFrom);
        setTimeTo(formattedTimeTo)


        const twentyFourFormatTimeFrom = dayjs(formattedTimeFrom).format('HH:mm')
        const twentyFourFormatTimeTo = dayjs(formattedTimeTo).format('HH:mm')

        setAddedTimeFrom(`T${twentyFourFormatTimeFrom}:00.000Z`)
        setAddedTimeTo(`T${twentyFourFormatTimeTo}:00.000Z`)


        const parsedAddDateFrom = dayjs(data?.time_from, 'HH:mm A');
        const parsedAddDateTo = dayjs(data?.time_to, 'HH:mm A');

        //console.log({ from: parsedAddDateFrom, to: parsedAddDateTo });
        setaddDateFrom(parsedAddDateFrom)
        setaddDateTo(parsedAddDateTo)

    }



    const handleChange = (e) => {
        const { name, value } = e.target;
        setShiftDetails((prevState) => ({
            ...prevState,
            [name]: value
        }))

        if (name === 'locationId') {
            // setSelectedLocationId(value)
            setShiftDetails((prevState) => ({
                ...prevState,
                areaId: ""
            }));
            fetchAreaData(value)
        }
    }



    const handleFromTime = (time) => {
        //console.log(time);
        const twentyFourFormat = dayjs(time).format('HH:mm')
        setTimeFrom(time)
        setaddDateFrom(time)
        setAddedTimeFrom(`T${twentyFourFormat}:00.000Z`)
    }

    const handleToTime = (time) => {
        const twentyFourFormat = dayjs(time).format('HH:mm')
        setTimeTo(time)
        setaddDateTo(time)
        setAddedTimeTo(`T${twentyFourFormat}:00.000Z`)
    }


    const handleDate = (newDate) => {
        setDate(newDate);
    }



    const handleRoleChange = (e) => {
        const { name, value } = e.target;
        setShiftType(value)
    }


    const handleShiftSubmit = () => {

        const { title, areaId, locationId, status, description, costCenter, hospitalName, ward } = shiftDetails

        if (title === '' || title === null ||
            description === '' || description === null ||
            areaId === "" || areaId === null ||
            locationId === "" || locationId === null ||
            date === "" || date === null ||
            timeFrom === "" || timeFrom === null || timeFrom === 'Invalid Date' ||
            timeTo === "" || timeTo === null || timeTo === 'Invalid Date' ||
            shiftType === '' || shiftType === null || !shiftType || shiftType === undefined ||
            costCenter === '' || costCenter === null || !costCenter || costCenter === undefined ||
            hospitalName === '' || hospitalName === null ||
            ward === '' || ward === null || !ward || ward === undefined
        ) {
            setShiftNullAlert(true)
        } else {

            let updatedDate;
            if (addDateTo <= addDateFrom) {
                updatedDate = dayjs(date).add(1, 'day').format('YYYY-MM-DD');
            }

            const formattedTimeFrom = dayjs(timeFrom).format('hh:mm A');
            const formattedTimeTo = dayjs(timeTo).format('hh:mm A');
            const formattedDate = dayjs(date).format('YYYY-MM-DD');

            const data = {
                'id': shiftId,
                'title': title,
                // 'clientId': userid,
                'locationId': locationId,
                'areaId': areaId,
                'date': `${formattedDate}${addedTimeFrom}`,
                'date_to': `${updatedDate ? updatedDate : formattedDate}${addedTimeTo}`,
                'time_from': formattedTimeFrom,
                'time_to': formattedTimeTo,
                'description': description,
                // 'status': status,
                'shift_type': shiftType,
                'floor': costCenter,
                'ward': ward,
            }

            setButtonLoading(true)
            // setButtonLoading(false)
            // console.log({ addDateFrom, addDateTo });
            // console.log(data);


            dataProvider.update('shift', data).then((data) => {
                // console.log(data)
                setButtonLoading(false)
                navigate(`/shift`)
            }).catch((e) => {
                setButtonLoading(false)
                // setButtonLoading(false)
                // console.log(e)
                // console.log(e?.response?.data?.msg);
            })
        }
    }




    return (
        <>
            {
                tokenExpOpen ? <ExpirePopup tokenExpireMsg={tokenExpireMsg} /> :
                    <>
                        <Grid container spacing={2} my={2}>
                            <Grid item md={12}>

                                {
                                    userFetchLoading ?
                                        <Paper sx={{ padding: "10px" }}>
                                            <Stack flexDirection={'row'} justifyContent={'center'} alignItems={"center"}>
                                                <CircularProgress />
                                            </Stack>
                                        </Paper>
                                        :
                                        <Paper sx={{ padding: "10px" }}>
                                            <Tooltip title='Back'>
                                                <IconButton onClick={() => navigate(`/shift`)} >
                                                    <ArrowBackOutlined />
                                                </IconButton>
                                            </Tooltip>

                                            <Typography variant='h6' py={2} sx={{ textAlign: "center", textTransform: "uppercase" }}>Edit Shift</Typography>

                                            <Grid container spacing={2} >

                                                {/* Title */}
                                                <Grid item md={6} xs={12}>
                                                    <TextField
                                                        label="Title"
                                                        variant="outlined"
                                                        fullWidth
                                                        name='title'
                                                        value={shiftDetails.title}
                                                        onChange={handleChange}
                                                    />
                                                </Grid>

                                                {/* Hospital Name */}
                                                <Grid item md={6} xs={12}>
                                                    <TextField id="outlined-basic"
                                                        label="Hospital Name"
                                                        variant="outlined"
                                                        fullWidth
                                                        disabled
                                                        value={shiftDetails.hospitalName}
                                                    // name='hospitalName'
                                                    // onChange={handleChange}
                                                    />
                                                </Grid>

                                                {/* Ward */}
                                                <Grid item md={6} xs={12}>
                                                    <TextField id="outlined-basic"
                                                        label="Ward"
                                                        variant="outlined"
                                                        fullWidth
                                                        name='ward'
                                                        value={shiftDetails.ward}
                                                        onChange={handleChange}
                                                    />
                                                </Grid>

                                                {/* Cost center */}
                                                <Grid item md={6} xs={12}>
                                                    <TextField id="outlined-basic"
                                                        label="Manager name"
                                                        variant="outlined"
                                                        fullWidth
                                                        name='costCenter'
                                                        value={shiftDetails.costCenter}
                                                        onChange={handleChange}
                                                    />
                                                </Grid>

                                                {/* Location */}
                                                <Grid item md={6} xs={12}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Location</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            label="Location"
                                                            name='locationId'
                                                            value={shiftDetails.locationId}
                                                            onChange={handleChange}
                                                        >

                                                            {locationLoading ? (
                                                                // Render a loader while location data is being fetched
                                                                <MenuItem disabled>Loading...</MenuItem>
                                                            ) : (
                                                                // Render the location data once it is available
                                                                location.map((locationData) => (
                                                                    locationData?.status === 1 &&
                                                                    <MenuItem key={locationData?._id} value={locationData?._id}>
                                                                        {locationData?.location}
                                                                    </MenuItem>
                                                                ))
                                                            )}

                                                        </Select>
                                                    </FormControl>
                                                </Grid>

                                                {/* Area */}
                                                <Grid item md={6} xs={12}>
                                                    <FormControl fullWidth>
                                                        <InputLabel >Area</InputLabel>
                                                        <Select
                                                            label="Area"
                                                            name='areaId'
                                                            value={shiftDetails.areaId}
                                                            onChange={handleChange}
                                                        >
                                                            {
                                                                areaLoading ? (
                                                                    // Render a loader while location data is being fetched
                                                                    <MenuItem disabled>Loading...</MenuItem>
                                                                ) :
                                                                    (

                                                                        area.length === 0 ?
                                                                            (<MenuItem disabled>Select a location</MenuItem>) :
                                                                            area.map((areaData) => (
                                                                                areaData?.status === 1 &&
                                                                                (<MenuItem key={areaData?._id} value={areaData?._id}>{areaData?.area}</MenuItem>)
                                                                            ))
                                                                    )
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </Grid>

                                                {/* Status */}
                                                {/* <Grid item md={6} xs={12}>
                                                    <FormControl fullWidth>
                                                        <InputLabel >Status</InputLabel>
                                                        <Select

                                                            label="Status"
                                                            name='status'
                                                            onChange={handleChange}
                                                            value={shiftDetails.status}
                                                        >
                                                            <MenuItem value={1}>Active</MenuItem>
                                                            <MenuItem value={0}>Inactive</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid> */}



                                                {/* Select Staff Type */}
                                                <Grid item md={6} xs={12}>
                                                    <FormControl fullWidth  >
                                                        <InputLabel >Select  Staff Type</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={shiftType}
                                                            label="Select a Customer"
                                                            onChange={handleRoleChange}
                                                        >
                                                            {
                                                                typeLoading ? (

                                                                    <MenuItem disabled>Loading...</MenuItem>
                                                                ) :
                                                                    staffTypeData?.map((type) => (
                                                                        <MenuItem value={type?.type}>{type?.type}</MenuItem>
                                                                    ))
                                                            }

                                                        </Select>

                                                    </FormControl>
                                                </Grid>

                                                {/* Date */}
                                                <Grid item md={6} xs={12}>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        {/* <div onClick={handleFieldClick} style={{ position: 'relative', width: '100%' }}> */}
                                                        {/* Your input field or any other content goes here */}
                                                        <DesktopDatePicker
                                                            label='Select Date'
                                                            open={isDatePickerOpen}
                                                            onClose={() => setIsDatePickerOpen(false)}
                                                            format="DD-MM-YYYY"
                                                            onChange={handleDate}
                                                            value={dayjs(date, 'M-D-YYYY').format('YYYY-MM-DD') < dayjs().format('YYYY-MM-DD') ? null : dayjs(date)}
                                                            disablePast
                                                            slotProps={{
                                                                textField: {
                                                                    onClick: () => setIsDatePickerOpen(true),
                                                                },
                                                            }}
                                                            // renderInput={(params) => <input {...params} />}
                                                            // style={{ position: 'absolute', top: '100%', zIndex: 1 }}
                                                            sx={{ width: "100%" }}
                                                        />
                                                        {/* </div> */}
                                                    </LocalizationProvider>
                                                </Grid>




                                                {/* Time From */}
                                                <Grid item md={6} xs={12}>
                                                    {/* <div onClick={handleTimeFiledClickFrom} style={{ position: 'relative', width: '100%' }}> */}
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DesktopTimePicker
                                                            open={isTimePickerOpenFrom}
                                                            onClose={() => setIsTimePickerOpenFrom(false)}
                                                            label="Time From"
                                                            sx={{ width: "100%" }}
                                                            value={timeFrom}
                                                            timeSteps={{ hours: 2, minutes: 30 }}
                                                            onChange={(e) => handleFromTime(e)}
                                                            shouldDisableTime={(value, timeView) => {
                                                                return dayjs(timeTo).format('hh:mm A') === dayjs(value).format('hh:mm A')
                                                            }}
                                                            slotProps={{
                                                                textField: {
                                                                    onClick: () => setIsTimePickerOpenFrom(true),
                                                                },
                                                            }}
                                                        // renderInput={(params) => <input {...params} />}
                                                        // style={{ position: 'absolute', top: '100%', zIndex: 1 }}
                                                        />
                                                    </LocalizationProvider>
                                                    {/* </div> */}
                                                </Grid>

                                                {/* Time To */}
                                                <Grid item md={6} xs={12}>
                                                    {/* <div onClick={handleTimeFiledClickTo} style={{ position: 'relative', width: '100%' }}> */}
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DesktopTimePicker
                                                            open={isTimePickerOpenTo}
                                                            onClose={() => setIsTimePickerOpenTo(false)}
                                                            label="Time To"
                                                            sx={{ width: "100%" }}
                                                            value={timeTo}
                                                            timeSteps={{ hours: 2, minutes: 30 }}
                                                            onChange={(e) => handleToTime(e)}
                                                            slotProps={{
                                                                textField: {
                                                                    onClick: () => setIsTimePickerOpenTo(true),
                                                                },
                                                            }}
                                                            shouldDisableTime={(value, timeView) => {
                                                                return dayjs(timeFrom).format('hh:mm A') === dayjs(value).format('hh:mm A')
                                                            }}
                                                        // renderInput={(params) => <input {...params} />}
                                                        // style={{ position: 'absolute', top: '100%', zIndex: 1 }}
                                                        />
                                                    </LocalizationProvider>
                                                    {/* </div> */}
                                                </Grid>

                                                {/* Description */}
                                                <Grid item md={12} xs={12}>
                                                    <TextField
                                                        label="Description"
                                                        multiline
                                                        rows={4}
                                                        fullWidth
                                                        placeholder='Description....'
                                                        name='description'
                                                        value={shiftDetails.description}
                                                        onChange={handleChange}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Stack flexDirection={'row'} justifyContent={"end"} my={2}>
                                                <Button
                                                    variant="contained"
                                                    sx={{
                                                        color: 'white',
                                                        width: '150px',
                                                        height: '40px',
                                                        position: 'relative',
                                                    }}
                                                    onClick={() => handleShiftSubmit()}
                                                    disabled={buttonloading}
                                                >
                                                    {buttonloading ? (
                                                        <CircularProgress
                                                            size={25}
                                                            sx={{
                                                                color: 'white',
                                                                position: 'absolute',
                                                                top: '50%',
                                                                left: '50%',
                                                                marginTop: '-12.5px',
                                                                marginLeft: '-12.5px',
                                                            }}
                                                        />
                                                    ) : (
                                                        'Save'
                                                    )}
                                                </Button>
                                            </Stack>


                                        </Paper>

                                }

                            </Grid>
                        </Grid>

                        <Snackbar open={shiftNullAlert}
                            autoHideDuration={6000}
                            onClose={() => setShiftNullAlert(false)}
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        >
                            <Alert onClose={() => setShiftNullAlert(false)} severity="error" variant="filled" sx={{ width: '100%' }}>
                                Please fill all fields
                            </Alert>
                        </Snackbar>
                    </>
            }
        </>
    )
}

export default ShiftEdit