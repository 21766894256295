import styled from '@emotion/styled';
import { Avatar, Box, CircularProgress, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import ExpirePopup from 'components/common/ExpirePopup';
import dayjs from 'dayjs';
import dataProvider from 'providers/dataProvider';
import React, { useEffect, useState } from 'react'

const ShiftRequests = ({ clientId }) => {




    let token = localStorage.getItem('token');
    const [requests, setRequests] = useState([])
    const [requestLoading, setRequestLoading] = useState(false)

    const [tokenExpireMsg, setTokenExpireMsg] = useState("")
    const [tokenExpOpen, setTokenExpOpen] = useState(false)


    const StatusBox = styled(Box)(({ theme }) => ({
        padding: "3px 0",
        width: "100px",
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "5px",
    }));

    useEffect(() => {
        fetchRequest()
    }, [])


    const fetchRequest = async () => {
        setRequestLoading(true)
        dataProvider.getList('shift/request', {
            params: {
                token: token,
                pagination: { page: 1, perPage: 1000 },
                sort: { order: 'desc', field: '_id' },
                filter: { clientId: clientId }
            }
        })
            .then(({ data }) => {
                // console.log(data)
                setRequestLoading(false)
                setRequests(data)
            })
            .catch(error => {
                // setIsUserLoading(false)
                setRequestLoading(false)
                console.log(error?.response?.data)
                console.log(error?.response?.data?.expire)
                if (error?.response?.data?.expire === true) {
                    setTokenExpireMsg(error?.response?.data?.message)
                    setTokenExpOpen(true)
                }
            })
    };


  const statusColors = {
        'pending': '#FFC72C',
        'ongoing': '#0077c0',
        'completed': '#8cc751',
        'not_fulfilled': '#dc3545'
    };

    const SlotBox = styled(Box)(({ theme }) => ({
        margin: "20px 10px",
        display: "flex",
        alignItems: "center",
        // justifyContent: "space-between"
    }));

    return (
        <>

            {

                tokenExpOpen ? <ExpirePopup tokenExpireMsg={tokenExpireMsg} /> :
                    <Box p={2}>
                        {
                            requestLoading ? <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"center"}>
                                <CircularProgress color="primary" size={45} />
                            </Stack> :

                                requests.length === 0 ?
                                    <Typography sx={{ m: 3, textAlign: "center" }}>Empty Requests</Typography> :
                                    <>
                                        <Typography variant='h6' pb={2} textTransform={'uppercase'} textAlign={'center'} >Shift Requests</Typography>
                                        <TableContainer >
                                            <Table sx={{ minWidth: 650, '& th, & td': { padding: '10px' } }} size="small" aria-label="a dense table">
                                                <TableHead >
                                                    <TableRow>
                                                        <TableCell sx={{ width: "30%" }} >
                                                            <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                                Title
                                                            </Typography>
                                                        </TableCell>

                                                        <TableCell sx={{ width: "50%" }} >
                                                            <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                                Date
                                                            </Typography>
                                                        </TableCell>

                                                        <TableCell sx={{ width: "20%" }} >
                                                            <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">

                                                                Employer
                                                            </Typography>
                                                        </TableCell>

                                                        <TableCell sx={{ width: "20%" }} >
                                                            <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                                Staff
                                                            </Typography>
                                                        </TableCell>


                                                        <TableCell sx={{ width: "10%" }} >
                                                            <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                                Status
                                                            </Typography>
                                                        </TableCell>


                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        requests.map((req) => (
                                                            <TableRow
                                                                key={req._id}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell ><Typography sx={{ fontSize: '1rem' }}>{req?.shiftId?.title}</Typography></TableCell>

                                                                <TableCell >
                                                                    <div className="slot-date-time">
                                                                        <Typography sx={{ fontSize: '1rem' }}>
                                                                            {req?.shiftId?.date_to && dayjs(req?.shiftId?.date_to, { dateFormat: 'DD-MM-YYYY' }).format('DD-MM-YYYY')}
                                                                        </Typography>
                                                                        <Typography sx={{ fontSize: '.9rem', color: "secondary" }}>{req?.shiftId?.time_from} - {req?.shiftId?.time_to}</Typography>
                                                                    </div>
                                                                </TableCell>

                                                                <TableCell component="th" scope="row">
                                                                    <div className="profile-image-table">
                                                                        <Avatar src={req?.clientId?.avatar_path} />
                                                                        <Typography sx={{ fontSize: '1rem', fontWeight: '600' }}>{req?.clientId?.first_name}</Typography>
                                                                    </div>
                                                                </TableCell>

                                                                <TableCell component="th" scope="row">
                                                                    <div className="profile-image-table">
                                                                        <Avatar src={req?.customerId?.avatar_path} />
                                                                        <Typography sx={{ fontSize: '1rem', fontWeight: '600' }}>{req?.customerId?.first_name}</Typography>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell >
                                                                    <StatusBox sx={{ backgroundColor: statusColors[req?.shiftId?.shift_status] }}>
                                                                        {req?.shiftId?.shift_status.charAt(0).toUpperCase() + req?.shiftId?.shift_status.slice(1)}
                                                                    </StatusBox>
                                                                </TableCell>
                                                                <TableCell >
                                                                    <Box sx={{ display: "flex", alignItems: "center" }}>





                                                                    </Box>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </>
                        }

                    </Box>
            }


        </>
    )
}

export default ShiftRequests