import styled from '@emotion/styled';
import { Avatar, Box, Button, Chip, CircularProgress, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import dayjs from 'dayjs';
import dataProvider from 'providers/dataProvider';
import React, { useEffect, useState } from 'react'
import { useAdminContextProvider } from 'providers/adminContextProvider';
import ExpirePopup from 'components/common/ExpirePopup';
import jsPDF from 'jspdf';
import ExcelJS from 'exceljs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons';
const ShfitRequests = () => {


  let token = localStorage.getItem('token');
  const { setNetworkErr } = useAdminContextProvider()
  const [requests, setRequests] = useState([])
  const [requestLoading, setRequestLoading] = useState(false)


  const [tokenExpireMsg, setTokenExpireMsg] = useState("")
  const [tokenExpOpen, setTokenExpOpen] = useState(false)

  const [excelLoading, setExcelLoading] = useState(false)


  const StatusBox = styled(Box)(({ theme }) => ({
    padding: "3px 0",
    width: "100px",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px",
  }));

  useEffect(() => {
    fetchRequest()
  }, [])


  const fetchRequest = async () => {
    setRequestLoading(true)
    dataProvider.getList('shift', {
      params: {
        token: token,
        pagination: { page: 1, perPage: 1000 },
        sort: { order: 'desc', field: '_id' },
        filter: {
          // requestBy: 'self'
          shift_status: 'ongoing'
        }
      }
    })
      .then(({ data }) => {
        // console.log(data)
        setRequestLoading(false)
        setRequests(data)
      })
      .catch(error => {
        // setIsUserLoading(false)
        setRequestLoading(false)
        // console.log(error?.response?.data)
        // console.log(error?.response?.data?.expire)
        if (error.message === 'Network Error') {
          setNetworkErr(true)
        }
        if (error?.response?.data?.expire === true) {
          setTokenExpireMsg(error?.response?.data?.message)
          setTokenExpOpen(true)
        }
      })
  };


  const handlePdf = (requests) => {

    const pdfDoc = new jsPDF();

    const title = 'Recent Shifts';


    const columns = ['Title', 'Date From', 'Date To', 'Time From', 'Time To', 'Employer', 'Staff', 'Status'];

    const headerColor = '#8fb569';


    const rows = requests.map((rowData) => [
      rowData?.title,
      rowData?.date && new Date(rowData?.date).toLocaleDateString('en-GB', { timeZone: 'UTC' }),
      rowData?.date_to && new Date(rowData?.date_to).toLocaleDateString('en-GB', { timeZone: 'UTC' }),
      rowData?.time_from,
      rowData?.time_to,
      rowData.clientId?.first_name,
      rowData.customerId?.first_name,
      'Ongoing'
    ]);

    pdfDoc.text(title, 14, 10);


    pdfDoc.autoTable({
      head: [columns],
      body: rows,
      startY: 20,
      theme: 'plain', // Optional: 'striped', 'grid', 'plain'
      headStyles: {
        fillColor: headerColor,
        textColor: '#ffffff', // Optional: Text color for header
      },
    });

    // Save the PDF or open it in a new tab
    pdfDoc.save('recent_shifts.pdf');
  };




  const handleExcel = async (req) => {

    console.log(req)

    setExcelLoading(true)



    // Create a new workbook
    const workbook = new ExcelJS.Workbook();

    // Add a worksheet to the workbook
    const worksheet = workbook.addWorksheet('Recent Shifts');



    worksheet.columns = [
      { header: 'Title', key: 'title', width: 20, style: { font: { bold: true }, alignment: { horizontal: 'left' } } },
      { header: 'Date From', key: 'dateFrom', width: 20, style: { font: { bold: true }, alignment: { horizontal: 'left' } } },
      { header: 'Date To', key: 'dateTo', width: 20, style: { font: { bold: true }, alignment: { horizontal: 'left' } } },
      { header: 'Time From', key: 'timeFrom', width: 20, style: { font: { bold: true }, alignment: { horizontal: 'left' } } },
      { header: 'Time To', key: 'timeTo', width: 20, style: { font: { bold: true }, alignment: { horizontal: 'left' } } },
      { header: 'Employer', key: 'employer', width: 20, style: { font: { bold: true }, alignment: { horizontal: 'left' } } },
      { header: 'Staff', key: 'staff', width: 20, style: { font: { bold: true }, alignment: { horizontal: 'left' } } },
      { header: 'Status', key: 'status', width: 20, style: { font: { bold: true }, alignment: { horizontal: 'left' } } },

    ];


    try {

      req.forEach((item) => {
        worksheet.addRow({
          title: item?.title,
          dateFrom: item?.date && new Date(item?.date).toLocaleDateString('en-GB', { timeZone: 'UTC' }),
          dateTo: item?.date_to && new Date(item?.date_to).toLocaleDateString('en-GB', { timeZone: 'UTC' }),
          timeFrom: item?.time_from,
          timeTo: item?.time_to,
          employer: item.clientId?.first_name,
          staff: item?.customerId?.first_name,
          status: 'Ongoing'
        });
      });

    } catch (error) {
      console.error('Error converting client sign to base64:', error);
    }



    // Create a buffer from the workbook
    workbook.xlsx.writeBuffer().then((buffer) => {
      // Convert the buffer to a Blob
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      setExcelLoading(false)
      // Create a download link and trigger the download
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'recent_shifts.xlsx';
      link.click();
    });
  };


  const statusColors = {
    'pending': '#FFC72C',
    'ongoing': '#0077c0',
    'completed': '#8cc751',
    'not_fulfilled': '#dc3545'
  };

  const SlotBox = styled(Box)(({ theme }) => ({
    margin: "20px 10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  }));


  const StatusChip = styled(Chip)(({ theme }) => ({
    width: "100px",
    height: "25px",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

  }))

  return (
    <>

      {
        tokenExpOpen ? <ExpirePopup tokenExpireMsg={tokenExpireMsg} /> :
          <Paper sx={{ marginTop: "20px" }}>
            <Box p={2}>
              <SlotBox>

                <Typography variant='h6' py={2} textTransform={'uppercase'} >Recent Shifts</Typography>
                <Stack flexDirection={"column"} alignItems={"center"}>
                  <Typography variant='h6' py={2} textTransform={'uppercase'} >Export Data</Typography>
                  <Stack flexDirection={"row"} gap={2}>
                    <Button variant='contained' size='small' sx={{ color: "white" }}
                      onClick={() => handleExcel(requests)}
                      endIcon={<FontAwesomeIcon icon={faFileExcel} />}
                      disabled={requests.length === 0}
                    >
                      {
                        excelLoading ? <CircularProgress size={10} sx={{ margin: "0 10px" }} /> : 'Excel'
                      }
                    </Button>
                    <Button variant='contained' size='small' sx={{ color: "white" }}
                      onClick={() => handlePdf(requests)}
                      endIcon={<FontAwesomeIcon icon={faFilePdf} />}
                      disabled={requests.length === 0}
                    >PDF</Button>
                  </Stack>
                </Stack>
              </SlotBox>
              {
                requestLoading ? <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"center"}>
                  <CircularProgress color="primary" size={45} />
                </Stack> :

                  requests.length === 0 ?
                    <Typography sx={{ m: 3, textAlign: "center" }}>Empty Shifts</Typography> :
                    <TableContainer >
                      <Table sx={{ minWidth: 650, '& th, & td': { padding: '10px' } }} size="small" aria-label="a dense table">
                        <TableHead >
                          <TableRow>
                            <TableCell sx={{ width: "30%" }} >
                              <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                Title
                              </Typography>
                            </TableCell>

                            <TableCell sx={{ width: "30%" }} >
                              <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                Date
                              </Typography>
                            </TableCell>

                            <TableCell sx={{ width: "20%" }} >
                              <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">

                                Employer
                              </Typography>
                            </TableCell>

                            <TableCell sx={{ width: "20%" }} >
                              <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                Staff
                              </Typography>
                            </TableCell>


                            <TableCell sx={{ width: "20%" }} >
                              <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                Status
                              </Typography>
                            </TableCell>


                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                            requests.map((req) => (
                              <TableRow
                                key={req._id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell ><Typography sx={{ fontSize: '1rem' }}>{req?.title}</Typography></TableCell>

                                <TableCell >
                                  <div className="slot-date-time">
                                    <Typography sx={{ fontSize: '1rem' }}>
                                      {/* {req?.date_to && dayjs(req?.date_to, { dateFormat: 'DD-MM-YYYY' }).format('DD-MM-YYYY')} */}
                                      {new Date(req?.date).toLocaleDateString('en-GB', { timeZone: 'UTC' })}
                                      {" - "}
                                      {new Date(req?.date_to).toLocaleDateString('en-GB', { timeZone: 'UTC' })}
                                    </Typography>
                                    <Typography sx={{ fontSize: '.9rem', color: "secondary" }}>{req?.time_from} - {req?.time_to}</Typography>
                                  </div>
                                </TableCell>

                                <TableCell component="th" scope="row">
                                  <div className="profile-image-table">
                                    <Avatar src={req?.clientId?.avatar_path} />
                                    <Typography sx={{ fontSize: '1rem', fontWeight: '600' }}>{req?.clientId?.first_name}</Typography>
                                  </div>
                                </TableCell>

                                <TableCell component="th" scope="row">
                                  <div className="profile-image-table">
                                    <Avatar src={req?.customerId?.avatar_path} />
                                    <Typography sx={{ fontSize: '1rem', fontWeight: '600' }}>{req?.customerId?.first_name}</Typography>
                                  </div>
                                </TableCell>
                                <TableCell >
                                  {/* <StatusBox sx={{ backgroundColor: statusColors[req?.shiftId?.shift_status] }}>
                                    {req?.shiftId?.shift_status.charAt(0).toUpperCase() + req?.shiftId?.shift_status.slice(1)}
                                  </StatusBox> */}

                                  <StatusChip label={'Ongoing'}
                                    sx={{ backgroundColor: '#0077c0' }}>

                                  </StatusChip>
                                </TableCell>
                                <TableCell >
                                  <Box sx={{ display: "flex", alignItems: "center" }}>

                                  </Box>
                                </TableCell>
                              </TableRow>
                            ))
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
              }

            </Box>
          </Paper>
      }

    </>
  )
}


export default ShfitRequests