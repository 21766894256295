import { Box, Chip, CircularProgress, Fab, Fade, Grid, Grow, IconButton, Paper, Stack, TextField, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import styled from '@emotion/styled';
import dataProvider from 'providers/dataProvider';
import dayjs from 'dayjs';
import { Circle } from '@mui/icons-material';
const Calender = ({ userid }) => {


    const [dates, setDates] = useState([])
    const [slotes, setSlotes] = useState([])
    const [endDate, setEndDate] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [formattedDate, setFormattedDate] = useState(dayjs(new Date()).format('YYYY-MM-DD'))
    const [currentIndex, setCurrentIndex] = useState(0);
    const [slotLoading, setSloatLoading] = useState(false)



    useEffect(() => {
        calculateDates()
        fetchCustomerCalenderData()
    }, [])


    const fetchCustomerCalenderData = () => {
        setSloatLoading(true)
        dataProvider.getOne('calendar', { id: userid }).then(({ data }) => {
             //console.log(data);
            setSlotes(data)
            setSloatLoading(false)
        }).catch((err) => {
            console.log(err)
            setSloatLoading(false)
        })
    }

    const calculateDates = () => {
        const currentDate = new Date();
        const next30Days = Array.from({ length: 30 }, (_, index) => {
            const date = new Date();
            date.setDate(currentDate.getDate() + index);
            return date;
        });

        setDates(next30Days);
        setEndDate(next30Days[next30Days.length - 1]);



        const todayIndex = next30Days.findIndex(date =>
            date.toDateString() === currentDate.toDateString()
        );

        if (todayIndex !== -1) {
            setSelectedDate(next30Days[todayIndex]);
            setCurrentIndex(Math.max(0, todayIndex - 6));
        }
    }


    const handleSelectedDate = (newDate) => {
        const formattedDate = dayjs(newDate).format('YYYY-MM-DD');
        // console.log('Formatted Date (ISOString):', formattedDate);
        setSelectedDate(newDate)
        setFormattedDate(formattedDate)
    }

    const handlePrevClick = () => {
        if (currentIndex > 0) {
            const formattedDateISOString = dayjs(dates[currentIndex - 1]).format('YYYY-MM-DD');
            setCurrentIndex((prevIndex) => prevIndex - 1);
            setSelectedDate(dates[currentIndex - 1]);
            setFormattedDate(formattedDateISOString)
        }
    };

    const handleNextClick = () => {
        if (currentIndex < dates.length - 7) {
            const formattedDateISOString = dayjs(dates[currentIndex + 1]).format('YYYY-MM-DD');
            setCurrentIndex((prevIndex) => prevIndex + 1);
            setSelectedDate(dates[currentIndex + 1]);
            setFormattedDate(formattedDateISOString)
        }

    };



    const getShortDayName = (dayIndex) => {
        const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
        return days[dayIndex];
    };

    const getShortMonthName = (monthIndex) => {
        const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
        return months[monthIndex];
    };

    const CustomPagination = styled(Box)(({ theme }) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px",
        [theme.breakpoints.down("sm")]: {
            gap: "5px", // Set gap to 5px for small devices
        },
    }))

    const DateBox = styled(Box)(({ theme }) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: "2px",
        // color: "white",
        padding: "10px 20px",
        borderRadius: "5px",
        cursor: "pointer",
        width: "20px",
        height: "60px",
        [theme.breakpoints.down("sm")]: {
            width: "5px", // Set gap to 5px for small devices
        },
    }));


    const SlotBoxWrapper = styled(Box)(({ theme }) => ({
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        justifyContent: "center",
        gap: "10px",
        marginTop: "20px",
        // marginLeft: "10%",
        // marginRight: "4%",

    }))



    const SlotBox = styled(Box)(({ theme }) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
        padding: "10px 0",
        borderRadius: "5px",
        width: "calc(35% - 100px)",
        [theme.breakpoints.down("sm")]: {
            width: "calc(30% - 10px)",
        },
    }));


    // console.log(endDate, 'endate');


    return (
        <>
            <Box p={2}>

                <h4 className='edit-word' style={{ textAlign: "center", textTransform: "uppercase", marginBottom: "20px" }}>Calendar</h4>

                <CustomPagination>
                    {/* <Tooltip title='Prev'> */}
                    <IconButton onClick={() => handlePrevClick()} disabled={currentIndex === 0}>
                        <ArrowBackIosNewOutlinedIcon sx={{ fontSize: "17px" }} />
                    </IconButton>
                    {/* </Tooltip> */}

                    {
                        dates.slice(currentIndex, currentIndex + 7).map((date, index) => (
                            <DateBox
                                key={index}
                                onClick={() => handleSelectedDate(date)}
                                sx={{
                                    backgroundColor: date === selectedDate ? "#8fb569" : "white",
                                    color: date === selectedDate ? "white" : "black"
                                }}
                            >
                                <Typography fontSize={"10px"} fontWeight={"bold"}>
                                    {getShortMonthName(date.getMonth())}
                                </Typography>
                                <Typography fontWeight={"bold"} fontSize={"20px"}>
                                    {date.getDate()}
                                </Typography>
                                <Typography fontSize={"10px"} fontWeight={"bold"}>
                                    {getShortDayName(date.getDay())}
                                </Typography>

                            </DateBox>
                        ))
                    }

                    {/* <Tooltip title="Next"> */}
                    <IconButton onClick={() => handleNextClick()} disabled={endDate <= new Date()}>
                        <ArrowForwardIosOutlinedIcon sx={{ fontSize: "17px" }} />
                    </IconButton>
                    {/* </Tooltip> */}
                </CustomPagination>



                {/* <h4 className='edit-word' style={{ textAlign: "center", textTransform: "uppercase", marginTop: "20px" }}>Slots</h4> */}

                {slotLoading ? <Stack flexDirection={"row"} justifyContent={"center"} alignItems={"center"}>
                    <CircularProgress color="primary" size={45} />
                </Stack>
                    :
                    <Grid container spacing={1} my={2}>

                        {
                            slotes.map((slote) => (
                                dayjs(slote?.date).format('YYYY-MM-DD') === formattedDate &&
                                <>
                                    {
                                        slote?.slots?.map((nestedSlot) => (
                                            <Grow in={dayjs(slote?.date).format('YYYY-MM-DD') === formattedDate} timeout={500} >
                                                <Grid item md={3} xs={4}>
                                                    <Chip label={nestedSlot} sx={{
                                                        width: "100%",
                                                        backgroundColor: slote?.slots?.length !== 0 ? "#8fb569" : "#dadada",
                                                        color: "white",
                                                    }}>
                                                    </Chip>
                                                </Grid>
                                            </Grow>
                                        ))

                                    }

                                    {
                                        slote?.notes &&
                                        <Grow in={dayjs(slote?.date).format('YYYY-MM-DD') === formattedDate} timeout={500} >
                                            <Grid item md={12} xs={12} mt={2}>
                                                <Box>
                                                    <Typography variant='subtitle2' fontWeight={"bold"} my={1} textTransform={'uppercase'}>Additional Notes</Typography>
                                                    <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                                                        {/* <Circle sx={{ fontSize: '.5rem' }} /> */}
                                                        <Typography>{slote?.notes}</Typography>
                                                    </Stack>
                                                </Box>
                                            </Grid>
                                        </Grow>
                                    }
                                </>
                            ))
                        }

                    </Grid>
                }


            </Box>
        </>
    )
}

export default Calender