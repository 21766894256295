import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import "assets/css/admin/shift-listing.css"
import { Avatar, Box, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { AccessTimeOutlined, Assignment, CalendarMonthOutlined } from '@mui/icons-material';
import dataProvider from 'providers/dataProvider';
import dayjs from 'dayjs';
import styled from '@emotion/styled';


const ShiftViewModal = ({ shiftViewModal, handleCloseShiftViewModal, handleOpenShiftViewModal, selectedShiftDetails, setShiftViewModal }) => {


    const PopUpHeaderBox = styled(Box)(({ theme }) => ({
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    }))

    const AssignButton = styled(Button)(({ theme }) => ({
        color: "white"
    }))





    return (
        <>
            <Dialog
                open={shiftViewModal}
                onClose={handleCloseShiftViewModal}
                scroll='paper'
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                fullWidth
            >
                <PopUpHeaderBox>
                    <DialogTitle id="scroll-dialog-title">Shift View</DialogTitle>

                </PopUpHeaderBox>

                <DialogContent >
                    <DialogContentText
                        id="scroll-dialog-description"
                        tabIndex={-1}
                    >

                        <Stack flexDirection={"column"} alignItems={"center"} justifyContent={"center"} gap={2}>
                            <Avatar
                                src={selectedShiftDetails?.clientId?.avatar_path}
                                sx={{ width: "90px", height: "90px" }}
                            />
                            <Typography
                                sx={{ fontSize: '1rem', fontWeight: '600', color: 'rgba(58, 53, 65, 0.68)' }}>
                                {selectedShiftDetails?.clientId?.first_name}</Typography>
                        </Stack>

                        <Grid container my={1} spacing={2}>

                            {/* Title */}
                            <Grid item md={6} xs={12}>
                                <TextField id="outlined-basic"
                                    label="Title"
                                    variant="outlined"
                                    fullWidth
                                    disabled
                                    value={selectedShiftDetails?.title}
                                />

                            </Grid>

                            {/* Hospital Name */}
                            <Grid item md={6} xs={12}>
                                <TextField
                                    label="Hospital Name"
                                    variant="outlined"
                                    fullWidth
                                    disabled
                                    value={selectedShiftDetails?.hospital_name}
                                // name='hospitalName'
                                // onChange={handleChange}
                                />
                            </Grid>

                            {/* Ward */}
                            <Grid item md={6} xs={12}>
                                <TextField
                                    label="Ward"
                                    variant="outlined"
                                    fullWidth
                                    name='ward'
                                    value={selectedShiftDetails?.ward}
                                    disabled
                                />
                            </Grid>


                            {/* Cost center */}
                            <Grid item md={6} xs={12}>
                                <TextField
                                    label="Manager name"
                                    variant="outlined"
                                    fullWidth
                                    name='costCenter'
                                    value={selectedShiftDetails?.floor}
                                    disabled
                                />
                            </Grid>

                            {/* Location */}
                            <Grid item md={6} xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Location</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={10}
                                        label="Location"
                                        disabled
                                    >
                                        <MenuItem value={10}>{selectedShiftDetails?.locationId?.location}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>


                            {/* Area */}
                            <Grid item md={6} xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Area</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={10}
                                        label="Area"
                                        disabled
                                    >
                                        <MenuItem value={10}>{selectedShiftDetails?.areaId?.area}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            {/* Status */}
                            <Grid item md={6} xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel >Status</InputLabel>
                                    <Select

                                        value={10}
                                        label="Status"
                                        disabled
                                    >
                                        <MenuItem value={10}>{selectedShiftDetails?.status === 0 ? 'Inactive' : 'Active'}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            {/* Shift Type */}
                            <Grid item md={6} xs={12}>
                                <TextField id="outlined-basic"
                                    label="Staff Type"
                                    variant="outlined"
                                    fullWidth
                                    value={selectedShiftDetails?.shift_type}
                                    // InputProps={{
                                    //     endAdornment: (
                                    //         <InputAdornment position="end">
                                    //             <CalendarMonthOutlined />
                                    //         </InputAdornment>
                                    //     ),
                                    // }}
                                    disabled
                                />
                            </Grid>

                            {/* Date */}
                            <Grid item md={6} xs={12}>
                                <TextField
                                    label="Date From"
                                    variant="outlined"
                                    fullWidth
                                    value={
                                        new Date(selectedShiftDetails?.date).toLocaleDateString('en-GB', { timeZone: 'UTC' })
                                    }
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <CalendarMonthOutlined />
                                            </InputAdornment>
                                        ),
                                    }}
                                    disabled
                                />
                            </Grid>


                            {/* Date */}
                            <Grid item md={6} xs={12}>
                                <TextField
                                    label="Date To"
                                    variant="outlined"
                                    fullWidth
                                    value={
                                        new Date(selectedShiftDetails?.date_to).toLocaleDateString('en-GB', { timeZone: 'UTC' })
                                    }
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <CalendarMonthOutlined />
                                            </InputAdornment>
                                        ),
                                    }}
                                    disabled
                                />
                            </Grid>

                            {/* Time From */}
                            <Grid item md={6} xs={12}>
                                <TextField
                                    label="Time from"
                                    variant="outlined"
                                    fullWidth
                                    value={selectedShiftDetails?.time_from}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <AccessTimeOutlined />
                                            </InputAdornment>
                                        ),
                                    }}
                                    disabled
                                />
                            </Grid>

                            {/* Time to */}
                            <Grid item md={6} xs={12}>
                                <TextField id="outlined-basic"
                                    label="Time to"
                                    variant="outlined"
                                    fullWidth
                                    value={selectedShiftDetails?.time_to}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <AccessTimeOutlined />
                                            </InputAdornment>
                                        ),
                                    }}
                                    disabled
                                />
                            </Grid>

                            {/* Description */}
                            <Grid item md={12} xs={12}>
                                <TextField
                                    id="outlined-multiline-static"
                                    label="Description"
                                    multiline
                                    value={selectedShiftDetails?.description}
                                    rows={4}
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                        </Grid>

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseShiftViewModal}>Ok</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ShiftViewModal