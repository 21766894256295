import { Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, Snackbar, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ErrorIcon from '@mui/icons-material/Error';
const EditAreaModal = ({ isEditArea, handleEditArea, selectedEditDetails,
  hanldleCloseEditModal, buttonloading, areaNullError, locationData, setAreaNullError }) => {


  const { id, area, status, locationId } = selectedEditDetails


  // console.log(selectedEditDetails);


  const [createAreaDetails, setCreateAreaDetails] = useState({
    status: 1,
    area: "",
    locationID: ""
  })



  useEffect(() => {
    setCreateAreaDetails((prevState) => ({
      ...prevState,
      status: status || 1,
      area: area || '',
      locationID: locationId,
    }));
  }, [selectedEditDetails]);


  const handleChange = (e) => {
    const { name, value } = e.target

    setCreateAreaDetails((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }



  return (
    <>
      <Dialog open={isEditArea} onClose={() => hanldleCloseEditModal()} fullWidth>
        <DialogTitle>Edit Area</DialogTitle>
        <DialogContent sx={{ m: 2 }}>



          <FormControl fullWidth>
            {/* <InputLabel >Location</InputLabel> */}
            <Select

              name="locationID"
              // label="Location"
              value={createAreaDetails.locationID}
              onChange={(e) => handleChange(e)}
            >
              {
                locationData.map((locData) => (
                  <MenuItem value={locData._id}>{locData.location}</MenuItem>
                ))
              }

            </Select>
          </FormControl>

          <TextField
            label="Area"
            type="text"
            fullWidth
            variant="outlined"
            sx={{ margin: "10px 0" }}
            name="area"
            onChange={(e) => handleChange(e)}
            value={createAreaDetails.area}
          />


          <FormControl fullWidth sx={{ margin: "10px 0" }}>
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Status"
              name="status"
              onChange={(e) => handleChange(e)}
              value={createAreaDetails.status}
            >
              <MenuItem value={0}>Inactive</MenuItem>
              <MenuItem value={1}>Active</MenuItem>
            </Select>
          </FormControl>

          {/* {
            areaNullError && <Box sx={{ display: "flex", alignItems: "center", gap: "5px", margin: "10px 0", color: 'red' }}>
              <ErrorIcon />
              <Typography>Please fill all fields</Typography>
            </Box>
          } */}

          <Snackbar open={areaNullError}
            autoHideDuration={6000}
            onClose={() => setAreaNullError(false)}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <Alert onClose={() => setAreaNullError(false)} severity="error" variant="filled" sx={{ width: '100%' }}>
              Please fill all fields
            </Alert>
          </Snackbar>


        </DialogContent>
        <DialogActions>
          <Button onClick={() => hanldleCloseEditModal()}>Cancel</Button>
          <Button onClick={() => handleEditArea(
            id,
            createAreaDetails.locationID,
            createAreaDetails.area,
            createAreaDetails.status
          )}
            disabled={buttonloading}
          >
            {
              buttonloading ? <CircularProgress size={15} color='primary' /> : 'Edit'
            }
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default EditAreaModal