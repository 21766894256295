import React, { useState } from 'react'
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, CircularProgress, Typography } from '@mui/material';
import dataProvider from 'providers/dataProvider';

const RejectRequestViewModal = ({ isRejectView, handleRejectViewClose, setIrejectView, selectedDetails,fetchRequestData }) => {

    const { id } = selectedDetails
    const [buttonLoading, setButtonLoading] = useState(false)


    const handleRejectConfirm = () => {


        let data = {
            'id': id,
            'status': 'reject'
        }



        setButtonLoading(true)
        dataProvider.update('documents/request', data).then((data) => {
            // console.log(data)
            setButtonLoading(false)
            setIrejectView(false)
            fetchRequestData()
        }).catch((e) => {
            console.log(e)
        })


    }


    return (
        <>
            <Dialog
                open={isRejectView}
                onClose={() => handleRejectViewClose()}
                scroll='paper'
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                fullWidth
            >
                <DialogTitle id="scroll-dialog-title">
                    <Typography variant='h6'>ARE YOU SURE YOU WANT TO REJECT THIS REQUEST?</Typography>

                </DialogTitle>
                <DialogContent >
                    <DialogContentText
                        id="scroll-dialog-description"
                        tabIndex={-1}
                    >
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleRejectViewClose()}>Cancel</Button>
                    <Button color="error" onClick={() => handleRejectConfirm()}>
                        {
                            buttonLoading ? <CircularProgress size={15} color='error' /> : 'Reject'
                        }
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default RejectRequestViewModal