import { AddCardOutlined, AttachMoneyOutlined, CardTravelOutlined, CheckCircle, DoNotDisturb, Edit, PercentOutlined, Visibility } from '@mui/icons-material'
import { Alert, Avatar, Box, Button, CircularProgress, Divider, Grid, IconButton, InputAdornment, MenuItem, Paper, Slide, Snackbar, Stack, TextField, Tooltip, Typography, } from '@mui/material'
import { TableBody, TableContainer, Table, TableHead, TableRow, TableCell } from '@mui/material'
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, } from '@mui/material'
import ExpirePopup from 'components/common/ExpirePopup'
import TransactionTable from 'components/common/Tables/transaction_table'
import { useAdminContextProvider } from 'providers/adminContextProvider'
import dataProvider from 'providers/dataProvider'

import React, { startTransition, useState } from 'react'
import { useEffect } from 'react'

const Payments = () => {

    let token = localStorage.getItem('token');
    const [createOpen, setCreateOpen] = useState(false)
    const [editOpen, setEditOpen] = useState(false)
    const [transactionId, setTransactionId] = useState("")
    const [desc, setDesc] = useState("")

    const [transactionStatus, setTransactionStatus] = useState(null)
    const [status, setStatus] = useState(1)

    const [buttonLoading, setButtonLoading] = useState(false)

    const [toast, setToast] = useState(false)
    const [toastMessage, setToastMessage] = useState(false)


    const { setNetworkErr } = useAdminContextProvider()

    const [tokenExpireMsg, setTokenExpireMsg] = useState("")
    const [tokenExpOpen, setTokenExpOpen] = useState(false)

    const [holidayPercentage, setHolidaypercentage] = useState(null);

    const [transactions, setTransactions] = useState([])
    const [withdrawals, setWithDrawals] = useState([]);


    const [confButtonDisable, setConfButtonDisable] = useState(false);

    const [transactionModal, setTransactionModal] = useState(false);
    const [withDrawModel, setWithDrawModel] = useState(false);

    const [paymentDetails, setPaymentDetails] = useState({})

    const [fetchLoading, setFetchLoading] = useState(false)
    const [transactionLoading, setTransactionLoading] = useState(false)
    const [withDrawalLoading, setWithDrawalLoading] = useState(false)

    useEffect(() => {
        fetchHolidayPercentage()
        fetchTransactions()
        fetchWithDrawalRequest()
    }, [])

    const fetchHolidayPercentage = () => {

        dataProvider.getList('settings',
            {
                params: {
                    token: token,
                    pagination: {
                        page: 1,
                        perPage: 1000
                    },
                    sort: { order: 'desc', field: 'id' },
                    filter: {}
                }
            })
            .then(({ data }) => {
                // console.log(data?.holiday_percentage);
                setHolidaypercentage(data?.holiday_percentage)

            })
            .catch(error => {
                // console.log(error?.response?.data)
                // console.log(error?.response?.data?.expire)
                if (error.message === 'Network Error') {
                    setNetworkErr(true)
                }

                if (error?.response?.data?.expire === true) {
                    setTokenExpireMsg(error?.response?.data?.message)
                    setTokenExpOpen(true)
                }

            })
    }

    const fetchTransactions = () => {
        setTransactionLoading(true)
        dataProvider.getList('transactions',
            {
                params: {
                    token: token,
                    pagination: {
                        page: 1,
                        perPage: 1000
                    },
                    sort: { order: 'desc', field: 'id' },
                    filter: { "type": "earnings" }
                }
            })
            .then(({ data }) => {
                //console.log(data);
                setTransactions(data)
                setTransactionLoading(false)
            })
            .catch(error => {
                // console.log(error);
                // console.log(error?.response?.data)
                console.log(error?.response?.data?.expire)
                if (error.message === 'Network Error') {
                    setNetworkErr(true)
                }

                if (error?.response?.data?.expire === true) {
                    console.log('dfdfdf');
                    setTokenExpireMsg(error?.response?.data?.message)
                    setTokenExpOpen(true)
                }
                setTransactionLoading(false)
            })
    }

    const fetchWithDrawalRequest = () => {
        setWithDrawalLoading(true)
        dataProvider.getList('transactions',
            {
                params: {
                    token: token,
                    pagination: {
                        page: 1,
                        perPage: 1000
                    },
                    sort: { order: 'desc', field: 'id' },
                    filter: { "type": "withdrawal" }
                }
            })
            .then(({ data }) => {
                //console.log(data);
                setWithDrawals(data)
                setWithDrawalLoading(false)
            })
            .catch(error => {
                console.log(error);
                // console.log(error?.response?.data)
                // console.log(error?.response?.data?.expire)
                if (error.message === 'Network Error') {
                    setNetworkErr(true)
                }

                if (error?.response?.data?.expire === true) {
                    setTokenExpireMsg(error?.response?.data?.message)
                    setTokenExpOpen(true)
                }
                setWithDrawalLoading(false)
            })
    }






    const createmodalClose = () => {
        setCreateOpen(false)
    }


    const transactionModalOpen = (transaction) => {
        setPaymentDetails(transaction)
        setTransactionModal(true)
    }

    const transactionModalClose = () => {
        setTransactionModal(false)
    }

    const transactionModalConfirm = () => {
        setTransactionModal(false)
    }


    const withDrawModalOpen = (transaction) => {
        // console.log(transaction);
        setPaymentDetails(transaction)
        setTransactionId(transaction?._id)
        setTransactionStatus(transaction?.status)
        setDesc(transaction?.desc)
        setWithDrawModel(true)
    }

    const withDrawModalClose = () => {
        setWithDrawModel(false)
    }

    const withDrawModalConfirm = () => {

        let data = {
            'id': transactionId,
            'desc': desc,
            'status': status,
            'token': token
        }

        console.log(data)

        setButtonLoading(true);
        // setButtonLoading(false);
        dataProvider.updateTransaction('transactions', data).then((data) => {
            setButtonLoading(false);
            setWithDrawModel(false)
            fetchTransactions()
            fetchWithDrawalRequest()
            fetchHolidayPercentage()
            console.log(data);
        }).catch((e) => {
            setToast(true)
            setToastMessage(e?.response?.data?.message)
            setButtonLoading(false);
        })
    }




    // confirms

    const createConfirm = () => {
        let convertToNumber = Number(holidayPercentage)


        // console.log(typeof (convertToNumber));
        let data = {
            'holiday_percentage': convertToNumber
        }

        //console.log(data);
        setButtonLoading(true);
        dataProvider.updateSettings('settings', data).then((data) => {
            // console.log(data);
            setButtonLoading(false);
            //setToast(true)
            fetchHolidayPercentage()
            createmodalClose()
        }).catch((e) => {
            console.log(e);
            setToast(true)
            setToastMessage(e?.response?.data?.message)

        })
    }



    const toastClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setToast(false);
    };

    const handleHolidayPercentage = (e) => {
        setHolidaypercentage(e.target.value)
        setConfButtonDisable(true)
        if (e.target.value === "") {
            setConfButtonDisable(false)
        }
    }

    let popuProps = {
        tokenExpireMsg: tokenExpireMsg
    }


    return (
        <>
            <Paper sx={{ marginTop: "20px" }}>
                <Box p={2}>
                    <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography variant='h6' py={2} textTransform={'uppercase'} >Payments</Typography>
                        <Button variant='contained' sx={{ color: "white", backgroundImage: "linear-gradient(98deg, #9cc177, #6ba134 94%)" }}
                            endIcon={<AddCardOutlined />} onClick={() => setCreateOpen(true)}>
                            Holiday Pay
                        </Button>
                    </Stack>

                    {
                        tokenExpOpen ? <ExpirePopup {...popuProps} /> :
                            <TransactionTable
                                transactions={transactions}
                                transactionModalOpen={transactionModalOpen}
                                withdrawals={withdrawals}
                                withDrawModalOpen={withDrawModalOpen}
                                transactionLoading={transactionLoading}
                                withDrawalLoading={withDrawalLoading}
                            />
                    }

                </Box>
            </Paper>



            <PaymentModal
                open={createOpen}
                close={createmodalClose}
                title={"Add Holiday Pay "}
                confirm={createConfirm}
                buttonLoading={buttonLoading}
                confButtonDisable={confButtonDisable}
                isconfirmButton={true}
            >
                <TextField
                    type='number'
                    margin="dense"
                    label={'Percetage Rate'}
                    variant="outlined"
                    fullWidth
                    onChange={(e) => handleHolidayPercentage(e)}
                    value={holidayPercentage}
                    disabled={buttonLoading}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <PercentOutlined />
                            </InputAdornment>
                        ),

                    }}
                />
            </PaymentModal>


            <PaymentModal
                open={transactionModal}
                close={transactionModalClose}
                title={"Transactions"}
                confirm={transactionModalConfirm}
                buttonLoading={buttonLoading}
                confButtonDisable={true}
                isconfirmButton={false}
            >
                <UsersTranscationDetails paymentDetails={paymentDetails} />
            </PaymentModal>

            {/* Withdrwal  */}
            <PaymentModal
                open={withDrawModel}
                close={withDrawModalClose}
                title={"Edit Holiday Pay Information"}
                confirm={withDrawModalConfirm}
                buttonLoading={buttonLoading}
                confButtonDisable={true}
                isconfirmButton={transactionStatus === 0}
            >


                <UsersTranscationDetails paymentDetails={paymentDetails} />

                <Divider sx={{ margin: "20px 0" }} />

                {

                    transactionStatus === 0 &&
                    <TextField
                        margin="dense"
                        select
                        label="Status"
                        fullWidth
                        variant="outlined"
                        defaultValue={1}
                        onChange={(e) => setStatus(e.target.value)}
                        value={status}
                        disabled={buttonLoading}
                    >
                        <MenuItem value={1}>Paid</MenuItem>
                        {/* <MenuItem value={0}>Inactive</MenuItem> */}
                    </TextField>
                }


                <TextField
                    margin="dense"
                    multiline
                    rows={3}
                    label="Description"
                    variant="outlined"
                    fullWidth
                    disabled={buttonLoading || transactionStatus === 1}
                    onChange={(e) => setDesc(e.target.value)}
                    value={desc}
                />
            </PaymentModal>

            <Toast toastOpen={toast} toastClose={toastClose} message={toastMessage} />
        </>
    )
}





const PaymentModal = ({ open, children, title, confirm, close, buttonLoading, confButtonDisable, isconfirmButton }) => {
    return (
        <Dialog open={open} fullWidth onClose={close}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions>
                <Button onClick={close} disabled={buttonLoading}>Cancel</Button>
                {
                    isconfirmButton &&
                    <Button
                        onClick={() => confirm()}
                        disabled={buttonLoading || !confButtonDisable}
                        type='submit'
                    >
                        {
                            buttonLoading ? <CircularProgress size={15} color='primary' /> : 'Confirm'
                        }
                    </Button>
                }

            </DialogActions>
        </Dialog>
    )
}

const Toast = ({ toastOpen, toastClose, message }) => {
    const SlideTransition = (props) => {
        return <Slide {...props} direction="up" />;
    };
    return (
        <Snackbar
            open={toastOpen}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            autoHideDuration={3000}
            onClose={toastClose}
            TransitionComponent={SlideTransition}
        >
            <Alert
                onClose={toastClose}
                severity="error"
                variant="filled"
                sx={{ width: '100%' }}
            >
                {message}
            </Alert>
        </Snackbar>
    )
}

const UsersTranscationDetails = ({ paymentDetails }) => {
    return (
        <>
            <Grid container spacing={1}>
                <Grid item md={6}>
                    <TextField
                        margin="dense"
                        label="Shift Id"
                        variant="outlined"
                        fullWidth
                        disabled={true}
                        value={paymentDetails?.shiftId?._id}
                        size="small"
                    />
                </Grid>
                <Grid item md={6}>
                    <TextField
                        margin="dense"
                        label="Shift Name"
                        variant="outlined"
                        fullWidth
                        value={paymentDetails?.shiftId?.title}
                        disabled={true}
                        size="small"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item md={6}>
                    <TextField
                        margin="dense"
                        label="Date"
                        variant="outlined"
                        fullWidth
                        disabled={true}
                        value={paymentDetails?.created_on ? new Date(paymentDetails.created_on).toLocaleDateString('en-GB') : ''}
                        size="small"
                    />
                </Grid>
                <Grid item md={6}>
                    <TextField
                        margin="dense"
                        label="Employer"
                        variant="outlined"
                        fullWidth
                        disabled={true}
                        value={paymentDetails?.shiftId?.clientId?.first_name}
                        size="small"
                    />
                </Grid>
            </Grid>
            <Typography variant='body1' fontWeight={"bold"} mt={2} mb={1}>Payment Details</Typography>
            <Grid container spacing={1}>
                <Grid item md={6}>
                    <TextField
                        margin="dense"
                        label="Holiday Pay"
                        variant="outlined"
                        fullWidth
                        disabled={true}
                        value={paymentDetails?.holiday_fee}
                        size="small"
                    />
                </Grid>
                <Grid item md={6}>
                    <TextField
                        margin="dense"
                        label="Job Pay"
                        variant="outlined"
                        fullWidth
                        disabled={true}
                        value={paymentDetails?.job_fee}
                        size="small"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item md={12}>
                    <TextField
                        margin="dense"
                        label="Total Pay"
                        variant="outlined"
                        fullWidth
                        disabled={true}
                        value={paymentDetails?.total_fee}
                        size="small"
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default Payments