import AdminSidebar from 'components/sidebar/AdminSidebar'
import "assets/css/admin/adminsidebar.css"
import "assets/css/admin/admin-layout.css"
import React, { useState } from 'react'
import AdminHeader from 'components/header/AdminHeader'
import routes from 'routes/Admin'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Box, Grid, Paper } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import styled from '@emotion/styled'
import { useAdminContextProvider } from 'providers/adminContextProvider'
import Err500 from 'components/common/500'

const AdminLayout = () => {

    let isLoggedIn = localStorage.getItem('isLoggedIn');

    const { networkErr } = useAdminContextProvider()

    // console.log(networkErr);

    const getRoutes = () => {
        return routes.map((prop, key) => {
            // if (!isLoggedIn) {
            //     return (<Navigate to={{ pathname: '/login' }} />);
            // }

            if (prop.layout === "/admin") {
                return (
                    <Route
                        path={prop.path}
                        element={<prop.component />}
                        key={key}
                        exact
                    />
                );
            } else {
                return null;
            }
        });
    };

    const [dark, setDark] = useState(false)


    const SideBarBox = styled(Box)(({ theme }) => ({
        width: "270px",
        boxShadow: theme.palette.sidebar.boxShadow,
        borderRight: theme.palette.sidebar.borderRight,
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
        [theme.breakpoints.up('xl')]: {
            width: "350px",  // Adjust the width for large devices
        },
        position: "fixed",
        zIndex: "1",
        top: "0",
        left: "0",
        height: "100%",
        overflowY: "auto",
        maxHeight: "100vh",
        backgroundColor: theme.palette.backgroundColor.main,
        '&::-webkit-scrollbar': {
            width: '0', // Set the width of the scrollbar
        },
    }));


    const ContentBox = styled(Box)(({ theme }) => ({
        marginLeft: "270px",
        [theme.breakpoints.up('xl')]: {
            marginLeft: "350px",  // Adjust the width for large devices
        },
        padding: "10px",
        backgroundColor: theme.palette.backgroundColor.main,
        // width: "100%",
        // backgroundColor: "#121212",
        height: "100%",
        minHeight: "100vh",
        [theme.breakpoints.down('md')]: {
            marginLeft: "0",
        },
    }));

    const theme = createTheme({
        palette: {
            // mode: 'dark', 
            primary: {
                main: '#8fb569',
            },
            secondary: {
                main: '#1976d2'
            },
            backgroundColor: {
                main: "#F4F5FA"
            },
            sidebar: {
                boxShadow: "7px 0px 12px #fff",
                borderRight: '1px solid #fff'
            },
            sidebarColor: {
                selectedColor: "white",
                unselectedColor: "black"
            }
        },
    });

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
            primary: {
                main: '#8fb569',
            },
            secondary: {
                main: '#1976d2'
            },
            backgroundColor: {
                main: "#121212"
            },
            sidebar: {
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                borderRight: '1px solid #121212'
            },
            sidebarColor: {
                selectedColor: "black",
                unselectedColor: "white"
            }
        },
    });

    return (
        <>

            <ThemeProvider theme={dark === false ? theme : darkTheme}>

                {/* <Box sx={{ display: "flex" }}> */}
                <SideBarBox>
                    <AdminSidebar />
                </SideBarBox>

                <ContentBox>
                    {
                        networkErr ? <Err500 /> :
                            <div >
                                <header>
                                    <AdminHeader setDark={setDark} dark={dark} />
                                </header>

                                <section >
                                    <Routes>
                                        {getRoutes(routes)}
                                    </Routes>
                                </section>
                            </div>
                    }

                </ContentBox>

                {/* </Box> */}
            </ThemeProvider>

        </>
    )
}

export default AdminLayout