import React, { createContext, useContext, useState } from 'react';

const ClientProviderContext = createContext();

export const ClientContextProvider = ({ children }) => {

    const [networkErr, setNetworkErr] = useState(false);

    return (
        <ClientProviderContext.Provider value={{ networkErr, setNetworkErr }}>
            {children}
        </ClientProviderContext.Provider>
    );
};

export const useClientContextProvider = () => {
    return useContext(ClientProviderContext);
};
