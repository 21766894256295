import { Typography } from '@mui/material';
import React, { useState } from 'react'
import ReactApexChart from 'react-apexcharts';

const Graph = ({ title, category, data, columnWidth }) => {


    const [chartState, setChartState] = useState({
        series: [{
            name: 'Shift',
            data: data
        }],
        options: {
            chart: {
                type: 'bar',
                height: 350
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: columnWidth,
                    endingShape: 'rounded',
                    selectable: false,
                    borderRadius: 7, 
                },
            },
            dataLabels: {
                enabled: true
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent'],

            },
            xaxis: {
                categories: category,
            },
            yaxis: {
                title: {
                    text: 'Shifts'
                }
            },
            fill: {
                opacity: 1
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val + `Shifts`;
                    }
                }
            },
            colors: ['#8fb569', '#8fb569', '#8fb569'],

        },
    });

    return (
        <div>
            <Typography variant='h6' fontWeight={'500'}>{title}</Typography>
            <div >
                <ReactApexChart  options={chartState.options} series={chartState.series} type="bar" height={350} />
            </div>
        </div>
    )
}

export default Graph