import styled from '@emotion/styled';
import { Add, CalendarMonth, ControlPointOutlined } from '@mui/icons-material';
import { Avatar, Badge, Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogTitle, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import ExpirePopup from 'components/common/ExpirePopup';
import dayjs from 'dayjs';
import dataProvider from 'providers/dataProvider';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

const CreatedShifts = ({ clientId }) => {




    let token = localStorage.getItem('token');
    const navigate = useNavigate()
    const [shfit, setshfit] = useState([])
    const [shiftLoading, setshiftLoading] = useState(false)

    const [tokenExpireMsg, setTokenExpireMsg] = useState("")
    const [tokenExpOpen, setTokenExpOpen] = useState(false)

    const [shiftAccept, setShiftAccept] = useState(false)
    const [shiftAcceptData, setShiftAcceptData] = useState({
        shiftId: "",
        title: ""
    })

    const [buttonloading, setButtonLoading] = useState(false)


    const StatusBox = styled(Box)(({ theme }) => ({
        padding: "3px 0",
        width: "70px",
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "5px",
    }));

    useEffect(() => {
        fetchShfit()
    }, [])


    const fetchShfit = async () => {
        setshiftLoading(true)
        dataProvider.getList('shift', {
            params: {
                token: token,
                pagination: { page: 1, perPage: 1000 },
                sort: { order: 'desc', field: '_id' },
                filter: { clientId: clientId }
            }
        })
            .then(({ data }) => {
                // console.log(data)
                setshiftLoading(false)
                setshfit(data)
            })
            .catch(error => {
                // setIsUserLoading(false)
                setshiftLoading(false)
                console.log(error?.response?.data)
                console.log(error?.response?.data?.expire)
                if (error?.response?.data?.expire === true) {
                    setTokenExpireMsg(error?.response?.data?.message)
                    setTokenExpOpen(true)
                }
            })
    };


    const statusColors = {
        'pending': '#FFC72C',
        'ongoing': '#0077c0',
        'completed': '#8cc751',
        'not_fulfilled': '#dc3545'
    };

    const SlotBox = styled(Box)(({ theme }) => ({
        margin: "20px 10px",
        display: "flex",
        alignItems: "center",
        // justifyContent: "space-between"
    }));

    const StatusChip = styled(Chip)(({ theme }) => ({
        width: "100px",
        height: "25px",
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

    }))

    const handleAcceptShiftViewOpen = (shiftId, title) => {
        setShiftAcceptData((prev) => ({ ...prev, shiftId: shiftId, title: title }))
        setShiftAccept(true)
    }

    const handleAcceptShiftViewClose = () => {
        setShiftAcceptData((prev) => ({ ...prev, shiftId: "" }))
        setShiftAccept(false)
    }

    const handleShiftAccept = () => {

        const { shiftId } = shiftAcceptData

        const data = {
            'id': shiftId,
            'status': 1,
        }

        setButtonLoading(true)
        dataProvider.update('shift', data).then((data) => {
            // console.log(data)
            fetchShfit()
            setButtonLoading(false)
            setShiftAccept(false)
        }).catch((e) => {
            setButtonLoading(false)
            // console.log(e)
            // console.log(e?.response?.data?.msg);
        })
    }


    return (
        <>

            {
                tokenExpOpen ? <ExpirePopup tokenExpireMsg={tokenExpireMsg} /> :
                    <Box p={2}>

                        <Stack flexDirection={'row'} justifyContent={"end"}>
                            <Button variant='contained' startIcon={<Add />} sx={{ color: "white" }} onClick={() => navigate(`/shift/create/${clientId}`)}>Shift</Button>
                        </Stack>


                        {
                            shiftLoading ? <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"center"}>
                                <CircularProgress color="primary" size={45} />
                            </Stack> :

                                shfit.length === 0 ?
                                    <Typography sx={{ m: 3, textAlign: "center" }}>Empty Shifts</Typography> :
                                    <>
                                        <Typography variant='h6' pb={2} textTransform={'uppercase'} textAlign={'center'} >Created Shifts</Typography>
                                        <TableContainer >
                                            <Table sx={{ minWidth: 650, '& th, & td': { padding: '10px' } }} size="small" aria-label="a dense table">
                                                <TableHead >
                                                    <TableRow>
                                                        <TableCell sx={{ width: "10%" }}>
                                                            <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                                Title
                                                            </Typography>
                                                        </TableCell>

                                                        <TableCell >
                                                            <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                                Staff type
                                                            </Typography>
                                                        </TableCell>


                                                        <TableCell  >
                                                            <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                                Date
                                                            </Typography>
                                                        </TableCell>



                                                        <TableCell >
                                                            <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                                Status
                                                            </Typography>
                                                        </TableCell>


                                                        <TableCell >
                                                            <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                                Actions
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        shfit.map((shift) => (
                                                            <TableRow
                                                                key={shift._id}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell ><Typography sx={{ fontSize: '1rem' }}>{shift?.title}</Typography></TableCell>
                                                                <TableCell ><Typography sx={{ fontSize: '1rem' }}>{shift?.shift_type === 'nurse' ? 'Nurse' : 'HCA'}</Typography></TableCell>

                                                                <TableCell >
                                                                    <div className="slot-date-time">
                                                                        <Typography sx={{ fontSize: '1rem' }}>
                                                                            {new Date(shift.date).toLocaleDateString('en-GB', { timeZone: 'UTC' })}
                                                                            {" - "}
                                                                            {new Date(shift.date_to).toLocaleDateString('en-GB', { timeZone: 'UTC' })}
                                                                        </Typography>
                                                                        <Typography sx={{ fontSize: '.9rem', color: "secondary" }}>{shift?.time_from} - {shift?.time_to}</Typography>
                                                                    </div>
                                                                </TableCell>


                                                                <TableCell >
                                                                    {/* <StatusBox sx={{ backgroundColor: statusColors[shift?.shift_status] }}>
                                                                        {shift?.shift_status.charAt(0).toUpperCase() + shift?.shift_status.slice(1)}
                                                                    </StatusBox> */}

                                                                    <StatusChip label={shift?.shift_status.replace('_', ' ').charAt(0).toUpperCase() + shift?.shift_status.replace('_', ' ').slice(1)} sx={{ backgroundColor: statusColors[shift?.shift_status] }}>
                                                                    </StatusChip>
                                                                </TableCell>
                                                                <TableCell>

                                                                    {
                                                                        shift?.status !== 0 &&
                                                                        <ShiftViewBlock shiftId={shift?._id} shiftStatus={shift?.shift_status} />
                                                                    }


                                                                    {
                                                                        shift?.status === 0 &&
                                                                        <Button size='small' variant='contained' sx={{ color: "white" }} onClick={() => handleAcceptShiftViewOpen(shift._id, shift?.title)}>
                                                                            Approve
                                                                        </Button>
                                                                    }
                                                                </TableCell>

                                                            </TableRow>
                                                        ))
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </>
                        }

                    </Box>
            }


            <Dialog
                open={shiftAccept}
                onClose={() => handleAcceptShiftViewClose()}
                scroll='paper'
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                fullWidth
            >
                <DialogTitle id="scroll-dialog-title">
                    <Typography variant='h6'>{`ARE YOU SURE YOU WANT TO APPROVE THIS SHIFT?`}</Typography>

                </DialogTitle>

                <DialogActions>
                    <Button onClick={() => handleAcceptShiftViewClose()}>Cancel</Button>
                    <Button onClick={() => handleShiftAccept()} disabled={buttonloading} >
                        {
                            buttonloading ? <CircularProgress size={15} color='primary' /> : 'Approve'
                        }

                    </Button>
                </DialogActions>
            </Dialog>


        </>
    )
}


const ShiftViewBlock = ({ shiftId, shiftStatus }) => {

    let token = localStorage.getItem('token');

    useEffect(() => {
        fetchRequestData()
    }, [shiftId])


    const [flag, setFlag] = useState(false)
    const [fetchLoading, setFetchLoading] = useState(false)
    const navigate = useNavigate()

    const [tokenExpireMsg, setTokenExpireMsg] = useState("")
    const [tokenExpOpen, setTokenExpOpen] = useState(false)


    const fetchRequestData = () => {

        setFetchLoading(true)
        shiftStatus !== 'completed' &&
            dataProvider.getList('shift/single/request/' + shiftId, {
                params: {
                    token: token,
                    pagination: {
                        page: 1,
                        perPage: 1000
                    },
                    sort: { order: 'desc', field: '_id' },
                    filter: {}
                }
            }).then(({ data }) => {
                // console.log(data);
                setFlag(data)
                setFetchLoading(false)
            }).catch((err) => {
                setFetchLoading(false)
                if (err?.response?.data?.expire === true) {
                    setTokenExpireMsg(err?.response?.data?.message)
                    setTokenExpOpen(true)
                }
            })
    }

    return (
        <>
            {
                tokenExpOpen ? <ExpirePopup tokenExpireMsg={tokenExpireMsg} /> :

                    <Tooltip title='Requests'>

                        {
                            shiftStatus !== 'completed' ?
                                <IconButton onClick={() => navigate(`/shift/${shiftId}`)} disabled={shiftStatus === 'not_fulfilled'}>
                                    <Badge badgeContent={<span style={{ color: 'white' }}>
                                        {fetchLoading ? <CircularProgress size={8} sx={{ color: "white" }} /> : flag.length}</span>}
                                        color="primary">
                                        <CalendarMonth sx={{ color: shiftStatus !== 'not_fulfilled' ? '#0066b2' : 'grey' }} />
                                    </Badge>

                                </IconButton> :
                                <IconButton onClick={() => navigate(`/shift/${shiftId}`)} disabled={shiftStatus === 'not_fulfilled'}>
                                    <CalendarMonth sx={{ color: shiftStatus !== 'not_fulfilled' ? '#0066b2' : 'grey' }} />
                                </IconButton>

                        }

                    </Tooltip>
            }
        </>
    )
}

export default CreatedShifts