import Dashboard from "views/client/dashboard/Dashboard";
import ShiftCreate from "views/client/shift/create";
import ShiftListing from "views/client/shift/index";
import ShiftView from "views/client/shift/requests";
import Users from "views/client/customers/index";
import ViewUser from "views/client/customers/view";
import ProfileEdit from "views/client/profile/edit";
import ShiftEdit from "views/client/shift/edit";
import ErrorPage from "components/common/404";
import ShfitRequests from "views/client/shift_requests";

var routes = [
    {
        path: "/",
        name: "Dashboard",
        component: Dashboard,
        layout: "/client",
        show: true
    },
    {
        divider: true,
        dividername: 'Users'
    },

    {
        path: "/shift",
        name: "ShiftListing",
        component: ShiftListing,
        layout: "/client",
        show: true
    },
    {
        path: "/recent_shifts",
        name: "Recent Shifts",
        icon: "places1",
        component: ShfitRequests,
        layout: "/client",
        show: true
    },
    {
        path: "/shift/create",
        name: "ShiftCreate",
        component: ShiftCreate,
        layout: "/client",
        show: true
    },
    {
        path: "/shift/:shiftId",
        name: "ShiftView",
        icon: "places1",
        component: ShiftView,
        layout: "/client",
        show: true
    },
    // {
    //     path: "/shift/edit/:shiftId",
    //     name: "Shift",
    //     icon: "places1",
    //     component: ShiftEdit,
    //     layout: "/client",
    //     show: true
    // },
    {
        path: "/staff",
        name: "UserListing",
        component: Users,
        layout: "/client",
        show: true
    },
    {
        path: "/staff/:userid",
        name: "EditUser",
        icon: "places1",
        component: ViewUser,
        layout: "/client",
        show: true
    },
    {
        path: "/profile/:userid",
        name: "ProfileEdit",
        icon: "places1",
        component: ProfileEdit,
        layout: "/client",
        show: true
    },
    {
        path: "*",
        name: "ErrorPage",
        icon: "places1",
        component: ErrorPage,
        layout: "/client",
        show: true
    },

]

export default routes;