import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, Paper, Snackbar, Stack, TextField, Tooltip, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { AddPhotoAlternate, Delete, Edit } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';
import dataProvider from 'providers/dataProvider';
import { useAdminContextProvider } from 'providers/adminContextProvider';
const Slider = () => {

    let token = localStorage.getItem('token');

    const [sliderData, setSliderData] = useState([])

    const [sliderModal, setSliderModal] = useState({
        create: false,
        edit: false,
        delete: false
    })

    const { setNetworkErr } = useAdminContextProvider()

    const [snackbar, setSnackbar] = useState(false)

    const [sliderTitle, setSliderTitle] = useState("")

    const [selectedImage, setSelectedImage] = useState({
        file: "",
        blob: ""
    })

    const [buttonLoading, setButtonLoading] = useState(false)
    const [typeLoading, setTypeLoading] = useState(false)

    const [editSlider, setEditSlider] = useState({
        id: "",
        title: "",
        image: ""
    })

    const [deleteItems, setDeleteItems] = useState({
        id: "",
        title: ""
    })

    useEffect(() => {
        fetchSlider()
    }, [])

    const fetchSlider = () => {
        setTypeLoading(true)
        dataProvider.getList('sliders', {
            params: {
                token: token,
                pagination: { page: 1, perPage: 1000 },
                sort: { order: 'desc', field: 'id' },
                filter: {}
            }
        })
            .then(({ data }) => {
                // console.log(data)
                setSliderData(data)
                setTypeLoading(false)
            })
            .catch(error => {
                setTypeLoading(false)

                if (error.message === 'Network Error') {
                    setNetworkErr(true)
                }

                // console.log(error?.response?.data)
                // console.log(error?.response?.data?.expire)
                // if (error?.response?.data?.expire === true) {
                //     setTokenExpireMsg(error?.response?.data?.message)
                //     setTokenExpOpen(true)
                // }
            })
    }

    const SlotBox = styled(Box)(({ theme }) => ({
        margin: "20px 10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    }));


    const handleSliderImage = () => {
        const fileInput = document.getElementById("sliderFile")
        if (fileInput) {
            fileInput.click();
        }
    };


    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            let imageBlob = URL.createObjectURL(selectedFile)
            setSelectedImage((prevState) => ({
                ...prevState,
                file: selectedFile,
                blob: imageBlob
            }))
        }
    }

    const hadleCloseFile = () => {
        setSelectedImage((prevState) => ({
            ...prevState,
            file: "",
            blob: ""
        }))
    }


    // Create
    const handleCreateSlider = () => {
        if (sliderTitle === '' || selectedImage.file === '') {
            setSnackbar(true)
        } else {
            let data = {
                'title': sliderTitle,
                'image': selectedImage.file
            }

            setButtonLoading(true)
            dataProvider.createSlider('sliders', data).then((data) => {
                console.log(data);
                setSliderModal((prevState) => ({ ...prevState, create: false }))
                fetchSlider()
                setSelectedImage((prevState) => ({ ...prevState, blob: "", file: "" }))
                setButtonLoading(false)
            }).catch((e) => {
                setButtonLoading(false)
                console.log(e)

            })
        }
    }


    const hanleCloseCreateSlider = () => {
        setSliderModal((prevState) => ({ ...prevState, create: false }))
        setSelectedImage((prevState) => ({ ...prevState, blob: "", file: "" }))
        setSliderTitle("")
    }

    // Edit

    const handleEditSlider = (id, title, image) => {
        setEditSlider((prev) => ({ ...prev, id, title, image }))
        setSliderModal((prevState) => ({ ...prevState, edit: true }))
    }


    const hanleCloseEditSlider = () => {
        setSliderModal((prevState) => ({ ...prevState, edit: false }))
        setSelectedImage((prevState) => ({ ...prevState, blob: "", file: "" }))
        setEditSlider((prev) => ({ ...prev, id: "", title: "", image: "" }))
    }


    const handleEditSliderConfrim = () => {
        const { id, title } = editSlider
        let notification_image = selectedImage.file
        if (title === '') {
            setSnackbar(true)
        } else {
            let data = {
                'id': id,
                'title': title,
                // 'image': selectedImage.file
            }


            if (notification_image !== '' && notification_image !== null) {
                data["image"] = notification_image
            }

            setButtonLoading(true)
            dataProvider.updateSlider('sliders', data).then((data) => {
                console.log(data);
                fetchSlider()
                setButtonLoading(false)
                setSelectedImage((prevState) => ({ ...prevState, blob: "", file: "" }))
                setSliderModal((prevState) => ({ ...prevState, edit: false }))
            }).catch((e) => {
                setButtonLoading(false)
                console.log(e)

            })
        }
    }


    // Delete
    const handleDeleteOpen = (id, title) => {
        setDeleteItems((prev) => ({ ...prev, id, title }))
        setSliderModal((prevState) => ({ ...prevState, delete: true }))
    }

    const handleDeleteClose = () => {
        setDeleteItems((prev) => ({ ...prev, id: "", title: "" }))
        setSliderModal((prevState) => ({ ...prevState, delete: false }))
    }

    const handleDeleteConfirm = () => {
        let data = {
            "id": deleteItems.id
        }

        setButtonLoading(true)
        dataProvider.delete('sliders', data).then((data) => {

            setButtonLoading(false)
            setSliderModal((prevState) => ({ ...prevState, delete: false }))
            fetchSlider()
        }).catch((e) => {
            console.log(e)
            setButtonLoading(false)
        })
    }


    return (
        <>
            <Paper sx={{ marginTop: "20px" }}>
                <Box p={2}>
                    <SlotBox>
                        <Typography variant='h6' py={2} textTransform={'uppercase'} >Slider listing</Typography>

                        <Tooltip title='Create Staff'>
                            <Button variant='contained'
                                sx={{ color: "white", backgroundImage: "linear-gradient(98deg, #9cc177, #6ba134 94%)" }}
                                onClick={() => setSliderModal((prevState) => ({ ...prevState, create: true }))}
                            >Create</Button>
                        </Tooltip>
                    </SlotBox>
                    {
                        typeLoading ? <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"center"}>
                            <CircularProgress color="primary" size={45} />
                        </Stack> :
                            sliderData.length === 0 ?
                                <Typography sx={{ m: 3, textAlign: "center" }}>Empty Sliders</Typography>
                                :
                                <TableContainer>
                                    <Table sx={{ minWidth: 650, '& th, & td': { padding: '10px' } }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ width: "40%" }}>
                                                    <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                        Title
                                                    </Typography>
                                                </TableCell>

                                                <TableCell sx={{ width: "40%" }}>
                                                    <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                        Image
                                                    </Typography>
                                                </TableCell>
                                                <TableCell sx={{ width: "20%" }}>
                                                    <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                        Action
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {

                                                sliderData.map((slider) => (
                                                    <>
                                                
                                                        <TableRow
                                                            key={slider._id}
                                                            
                                                            
                                                        >

                                                            <TableCell ><Typography sx={{ fontSize: '1rem' }}>{slider?.title}</Typography></TableCell>
                                                            <TableCell >
                                                                <img src={slider?.image} alt="" srcset="" style={{
                                                                    maxWidth: "205px",
                                                                    minWidth: "100px"
                                                                }} height={"85px"} />
                                                            </TableCell>
                                                            <TableCell>
                                                                <Tooltip title='Edit'>
                                                                    <IconButton onClick={() => handleEditSlider(slider?.id, slider?.title, slider?.image)} >
                                                                        <Edit sx={{ color: '#0066b2' }} />
                                                                    </IconButton>
                                                                </Tooltip>

                                                                <Tooltip title='Delete'>
                                                                    <IconButton onClick={() => handleDeleteOpen(slider?.id, slider?.title,)}>
                                                                        <Delete sx={{ color: 'red' }} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </TableCell>
                                                        </TableRow>

                                                    </>
                                                ))
                                            }

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                    }


                </Box>
            </Paper>


            {/* Create */}
            <Dialog open={sliderModal.create} onClose={() => hanleCloseCreateSlider()} fullWidth>
                <DialogTitle textTransform={'uppercase'}>Create Slider</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} >

                        <Grid item md={12}>
                            <TextField
                                margin="dense"
                                label="Title"
                                type="text"
                                fullWidth
                                variant="outlined"
                                name="staff_type"
                                onChange={(e) => setSliderTitle(e.target.value)}
                            />
                        </Grid>

                        <Grid item md={12} xs={12}>
                            <TextField id="outlined-basic"
                                label="Select an Image"
                                variant="outlined"
                                fullWidth
                                onClick={handleSliderImage}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <AddPhotoAlternate />
                                        </InputAdornment>
                                    ),
                                    readOnly: true,
                                }}
                            />
                        </Grid>


                        {
                            selectedImage.blob &&
                            <Grid item md={12}>
                                <Paper sx={{
                                    padding: "10px",
                                    height: "120px",
                                    width: "120px",
                                    position: "relative",
                                    marginBottom: "10px",
                                }}>

                                    <FontAwesomeIcon
                                        icon={faWindowClose}
                                        size='lg'
                                        style={{ color: "#f10909", position: "absolute", top: "0", right: "0", cursor: "pointer" }}
                                        onClick={() => hadleCloseFile()}
                                    />
                                    <img src={selectedImage.blob} alt="" srcset="" width={"100%"} height={"100%"} />
                                </Paper>
                            </Grid>
                        }


                        <input
                            type="file"
                            id='sliderFile'
                            onChange={(e) => handleFileChange(e)}
                            accept="image/*"
                            style={{ display: 'none' }}
                        />
                    </Grid>


                </DialogContent>
                <DialogActions>
                    <Button onClick={() => hanleCloseCreateSlider()}>Cancel</Button>
                    <Button
                        onClick={() => handleCreateSlider()}
                        disabled={buttonLoading}
                    >
                        {
                            buttonLoading ? <CircularProgress size={15} color='primary' /> : 'Create'
                        }

                    </Button>
                </DialogActions>
            </Dialog>


            {/* Edit */}
            <Dialog open={sliderModal.edit} onClose={() => hanleCloseEditSlider()} fullWidth>
                <DialogTitle textTransform={'uppercase'}>Create Slider</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} >

                        <Grid item md={12}>
                            <TextField
                                margin="dense"
                                label="Title"
                                type="text"
                                fullWidth
                                variant="outlined"
                                name="staff_type"
                                value={editSlider.title}
                                onChange={(e) => setEditSlider((prev) => ({ ...prev, title: e.target.value }))}
                            />
                        </Grid>

                        <Grid item md={12} xs={12}>
                            <TextField id="outlined-basic"
                                label="Select an Image"
                                variant="outlined"
                                fullWidth
                                onClick={handleSliderImage}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <AddPhotoAlternate />
                                        </InputAdornment>
                                    ),
                                    readOnly: true,
                                }}
                            />
                        </Grid>


                        {
                            selectedImage.blob &&
                            <Grid item md={12}>
                                <Paper sx={{
                                    padding: "10px",
                                    height: "120px",
                                    width: "120px",
                                    position: "relative",
                                    marginBottom: "10px",
                                }}>

                                    <FontAwesomeIcon
                                        icon={faWindowClose}
                                        size='lg'
                                        style={{ color: "#f10909", position: "absolute", top: "0", right: "0", cursor: "pointer" }}
                                        onClick={() => hadleCloseFile()}
                                    />
                                    <img src={selectedImage.blob} alt="" srcset="" width={"100%"} height={"100%"} />
                                </Paper>
                            </Grid>
                        }


                        <input
                            type="file"
                            id='sliderFile'
                            onChange={(e) => handleFileChange(e)}
                            accept="image/*"
                            style={{ display: 'none' }}
                        />
                    </Grid>


                </DialogContent>
                <DialogActions>
                    <Button onClick={() => hanleCloseEditSlider()}>Cancel</Button>
                    <Button
                        onClick={() => handleEditSliderConfrim()}
                        disabled={buttonLoading}
                    >
                        {
                            buttonLoading ? <CircularProgress size={15} color='primary' /> : 'Edit'
                        }

                    </Button>
                </DialogActions>
            </Dialog>


            {/* Delete */}
            <Dialog open={sliderModal.delete} onClose={() => handleDeleteClose()} fullWidth>
                <DialogTitle >{`Are you want to delete ${deleteItems.title}`} </DialogTitle>

                <DialogActions>
                    <Button onClick={() => handleDeleteClose()}>Cancel</Button>
                    <Button
                        onClick={() => handleDeleteConfirm()}
                        disabled={buttonLoading}
                        color='error'
                    >
                        {
                            buttonLoading ? <CircularProgress size={15} color='error' /> : 'Delete'
                        }

                    </Button>
                </DialogActions>
            </Dialog>


            {/* Error Snack bar */}
            <Snackbar open={snackbar}

                autoHideDuration={6000}
                onClose={() => setSnackbar(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={() => setSnackbar(false)} variant='filled' severity="error" sx={{ width: '100%' }}>
                    {'Please fill all fields'}
                </Alert>
            </Snackbar>
        </>
    )
}

export default Slider