import React, { useEffect, useState } from 'react'
import 'assets/css/admin/edit-user.css'
import { Avatar, Box, CircularProgress, Grid, Paper, Stack, Typography } from '@mui/material'
import { PersonOutline, MailOutlineOutlined, PhoneIphoneOutlined, ManageAccountsOutlined, CalendarMonthOutlined } from '@mui/icons-material'
import { Link, useNavigate, useParams } from 'react-router-dom'
import dataProvider from 'providers/dataProvider'
import 'assets/css/client/view-user.css'
import CertificateTable from './certificate'
import CustomerRequests from './shift_requests'
import styled from '@emotion/styled'

const ViewUser = () => {

    const { userid } = useParams()
    let loggedUserId = localStorage.getItem('userId');



    let token = localStorage.getItem('token');

    const navigate = useNavigate()


    const [oneUserDetails, setOneUserDetails] = useState({})

    const [isUserLoading, setIsUserLoading] = useState(false)

    const [showPages, setShowPages] = useState('certificate')



    useEffect(() => {
        fetchOneUserData()
    }, [])



    const fetchOneUserData = () => {
        setIsUserLoading(true)
        dataProvider.getOne('users/customers', { id: userid }).then(({ data }) => {
            // console.log(data)
            setOneUserDetails(data.user)
            setIsUserLoading(false)
        }).catch((err) => {
            // console.log(err?.response?.data?.message)
            if (err?.response?.data?.message) {
                navigate("*")
            }
            setIsUserLoading(false)

        })
    }


    const getInitials = (name) => {
        return name ? name[0].toUpperCase() : '';
    }


    let certificateTableProps = {
        userid: userid,
    }

    let requestProps = {
        customerId: userid,
        clientId: loggedUserId,
        token:token
    }

    const showPagesCondition = (pages) => {
        setShowPages((prevPages) => (prevPages === pages ? 'certificate' : pages));
    }

 


    return (
        <>
            <Grid container spacing={1} my={2}>
                <Grid item md={4} xs={12}>

                    <Paper sx={{ position: "sticky", top: "0", '@media (max-width: 600px)': { position: 'static', top: 'auto' } }}>
                        {
                            isUserLoading ?
                                <Stack py={2} flexDirection={"row"} justifyContent={"center"} alignItems={"center"}>
                                    <CircularProgress color="primary" size={45} />
                                </Stack>
                                :
                                <Box p={1.5} >
                                    <Stack flexDirection={"row"} alignItems={"center"} gap={1} mt={1}>
                                        <Avatar src={oneUserDetails?.avatar_path}>{getInitials(oneUserDetails?.first_name)}</Avatar>
                                        <Typography fontWeight={'bold'}>{oneUserDetails?.first_name}{" "}{oneUserDetails?.last_name}</Typography>
                                    </Stack>


                                    <Typography my={2} fontWeight={'bold'}>Account Details</Typography>

                                    <Stack flexDirection={"column"} gap={2}>
                                        <Stack flexDirection={"row"} alignItems={"center"} gap={2}>
                                            <PersonOutline fontSize='small' />
                                            <Typography>{oneUserDetails?.first_name}{" "}{oneUserDetails?.last_name}</Typography>
                                        </Stack>

                                        <Stack flexDirection={"row"} alignItems={"center"} gap={2}>
                                            <MailOutlineOutlined fontSize='small' />
                                            <Typography>{oneUserDetails?.email}</Typography>
                                        </Stack>

                                        <Stack flexDirection={"row"} alignItems={"center"} gap={2}>
                                            <PhoneIphoneOutlined fontSize='small' />
                                            <Typography>+353{" "}{oneUserDetails?.phone}</Typography>
                                        </Stack>

                                        <Stack flexDirection={"row"} alignItems={"center"} gap={2}>
                                            <CalendarMonthOutlined fontSize='small' />
                                            <Link className='react-link' style={{ textDecoration: 'underline' }} onClick={() => showPagesCondition('request')} >
                                                Shift
                                            </Link>
                                        </Stack>

                                        {/* <Stack flexDirection={"row"} alignItems={"center"} gap={2}>
                                            <ManageAccountsOutlined fontSize='small' />
                                            <Typography>{oneUserDetails?.role === 'customer' && 'Staff'}</Typography>
                                        </Stack> */}
                                    </Stack>
                                </Box>
                        }
                    </Paper>
                </Grid>

                <Grid item md={8} xs={12}>
                    {
                        showPages === 'certificate' ? (
                            <Paper>
                                <CertificateTable {...certificateTableProps} />
                            </Paper>
                        ) :
                            (
                                <Paper sx={{ padding: "10px" }}>
                                    <CustomerRequests {...requestProps} />
                                </Paper>
                            )
                    }

                </Grid>
            </Grid>

        </>
    )
}

export default ViewUser