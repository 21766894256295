
import "assets/css/admin/adminsidebar.css"
import "assets/css/admin/admin-layout.css"
import React, { useState } from 'react'
import routes from 'routes/Client'
import { Route, Routes } from 'react-router-dom'
import ClientSidebar from "components/sidebar/ClientSidebar"
import ClientHeader from "components/header/ClientHeader"
import styled from "@emotion/styled"
import { Box } from "@mui/material"
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useClientContextProvider } from 'providers/clientContextProvider'
import Err500 from "components/common/500"

const ClientLayout = () => {

    const { networkErr } = useClientContextProvider()

    const [dark, setDark] = useState(false)

   


    const getRoutes = () => {
        return routes.map((prop, key) => {
            if (prop.layout === "/client") {
                return (
                    <Route
                        path={prop.path}
                        element={<prop.component />}
                        key={key}
                        exact
                    />
                );
            } else {
                return null;
            }
        });
    };

    const SideBarBox = styled(Box)(({ theme }) => ({
        width: "270px",
        boxShadow: theme.palette.sidebar.boxShadow,
        borderRight: theme.palette.sidebar.borderRight,
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
        [theme.breakpoints.up('xl')]: {
            width: "350px",  // Adjust the width for large devices
        },
        position: "fixed",
        zIndex: "1",
        top: "0",
        left: "0",
        height: "100%",
        overflowY: "auto",
        maxHeight: "100vh",
        backgroundColor: theme.palette.backgroundColor.main,
        '&::-webkit-scrollbar': {
            width: '0', // Set the width of the scrollbar
        },
    }));


    const ContentBox = styled(Box)(({ theme }) => ({
        marginLeft: "270px",
        [theme.breakpoints.up('xl')]: {
            marginLeft: "350px",  // Adjust the width for large devices
        },
        padding: "10px",
        backgroundColor: theme.palette.backgroundColor.main,
        // width: "100%",
        // backgroundColor: "#121212",
        height: "100%",
        minHeight: "100vh",
        [theme.breakpoints.down('md')]: {
            marginLeft: "0",
        },
    }));

    const theme = createTheme({
        palette: {
            // mode: 'dark', 
            primary: {
                main: '#8fb569',
            },
            secondary: {
                main: '#1976d2'
            },
            backgroundColor: {
                main: "#F4F5FA"
            },
            sidebar: {
                boxShadow: "7px 0px 12px #fff",
                borderRight: '1px solid #fff'
            },
            sidebarColor: {
                selectedColor: "white",
                unselectedColor: "black"
            }
        },
    });

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
            primary: {
                main: '#8fb569',
            },
            secondary: {
                main: '#1976d2'
            },
            backgroundColor: {
                main: "#121212"
            },
            sidebar: {
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                borderRight: '1px solid #121212'
            },
            sidebarColor: {
                selectedColor: "black",
                unselectedColor: "white"
            }
        },
    });
    return (
        <>
            <ThemeProvider theme={dark === false ? theme : darkTheme}>
                {/* <Box sx={{ display: "flex" }}> */}
                <SideBarBox>
                    <ClientSidebar />
                </SideBarBox>
                <ContentBox>
                    {
                        networkErr ? <Err500 /> :
                            <div >
                                <header>
                                    <ClientHeader setDark={setDark} dark={dark} />
                                </header>

                                <section >
                                    <Routes>
                                        {getRoutes(routes)}
                                    </Routes>
                                </section>
                            </div>
                    }

                </ContentBox>
                {/* </Box> */}
            </ThemeProvider>

        </>
    )
}

export default ClientLayout