import React from 'react'
import "assets/css/admin/mobile-view-sidebar.css"
import logo from "assets/images/hirehoodLogo.png"
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { Box, Button, Divider, Drawer, List, ListItem, ListItemButton, Typography, useTheme } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { CalendarMonth, LanguageOutlined, LocationOnOutlined, RequestPageOutlined, SupervisorAccountOutlined } from '@mui/icons-material';
import styled from '@emotion/styled';

const MobileViewClientSidebar = ({ setIsOpenDrawer }) => {

  let location = useLocation();
  let navigate = useNavigate()



  const navigation = [
    {
        title: "Dashboard",
        href: "/",
        icon: <HomeOutlinedIcon />
    },
    {
        divider: true,
        dividername: 'Users'
    },
    {
        title: "Staffs",
        href: "/staff",
        icon: <AccountCircleOutlinedIcon />
    },
    {
        divider: true,
        dividername: 'Shift Management'
    },
    {
        title: "Shift Management",
        href: "/shift",
        icon: <CalendarMonth />
    },
    // {
    //     title: "Request",
    //     href: "/Request",
    //     icon: <RequestPageOutlined />
    // },
];




  const StyledDrawer = styled(Drawer)(({ theme }) => ({
    '& .MuiDrawer-paper': {
      width: '20%',
      boxSizing: 'border-box',
      border: "none",
      backgroundColor: "#F4F5FA",
      overflowY: 'auto', // Hide scrollbar by default
      '&::-webkit-scrollbar': {
        width: '0', // Set the width of the scrollbar
      },

    },
  }));

  const StyledListItemButtons = styled(ListItemButton)(({ theme }) => ({
    borderTopRightRadius: "30px",
    borderBottomRightRadius: "30px",
    margin: "2px 0",
    height: "45px",
  }));

  const handlePageClick = (href) => {
    navigate(href)
    setIsOpenDrawer(false)
  }


  const theme = useTheme();
  const selectedColor = theme.palette.sidebarColor.selectedColor;
  const unselectedColor = theme.palette.sidebarColor.unselectedColor;


  const handleCloseDrawer = (nav) => {
    navigate(nav)
    setIsOpenDrawer(false)
  }


  return (
    <>
      <Box>
        <Box sx={{
          display: "flex", alignItems: "center",
          // position: 'fixed',
          zIndex: 1,
          // backgroundColor: "#F4F5FA",
          width: "17%",
          padding: "10px"
        }}>
          <img src={logo} alt="" width={"60px"} height={"60px"} />
          <Typography variant='button' fontSize={"1.2rem"}>hirehood</Typography>
        </Box>
      </Box>
      <Box sx={{
        width: '90%',
        // marginTop: "80px"
      }}>
        <List>
          {
            navigation.map((navs) => (
              navs.divider ? (
                <Divider textAlign='left' sx={{ margin: "20px 0" }}>
                   <Typography fontSize={'.9rem'} sx={{ color: location.pathname === navs.href ? selectedColor : unselectedColor }}>{navs.dividername}</Typography>
                </Divider>
              ) :
                (
                  <ListItem disablePadding  >
                    <StyledListItemButtons onClick={() => handleCloseDrawer(navs.href)}
                      sx={{
                        backgroundImage: location.pathname === navs.href && 'linear-gradient(98deg, #9cc177, #6ba134 94%)',
                        boxShadow: location.pathname === navs.href && '0px 4px 8px -4px rgba(58, 53, 65, 0.42)',
                        color: location.pathname === navs.href ? selectedColor : unselectedColor
                      }}
                    >

                      <Box sx={{ display: 'flex', alignItems: "center", gap: "10px" }}>
                        <span>{navs.icon}</span>
                        <Typography>{navs.title}</Typography>
                      </Box>
                    </StyledListItemButtons>
                  </ListItem>
                )
            ))
          }
        </List>
      </Box>
    </>
  )
}

export default MobileViewClientSidebar