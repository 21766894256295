import React, { useState } from 'react'
import { TableBody, TableContainer, Table, TableHead, TableRow, TableCell, Tabs, Tab, CircularProgress } from '@mui/material'
import { Typography, Box, Stack, IconButton, Tooltip, Avatar } from '@mui/material'
import { Add, CheckCircle, CreditCardOff, CreditScore, DoNotDisturb, Remove, RemoveCircleOutline, Visibility } from '@mui/icons-material'
import UnpaidIcon from 'assets/images/unpaid.svg'
import PaidIcon from 'assets/images/paid.svg'
import { TabContext, TabPanel } from '@mui/lab'


const TransactionTable = ({ transactions, withdrawals, withDrawModalOpen, transactionModalOpen, loading, transactionLoading, withDrawalLoading }) => {

    //console.log(transactions);
    const [val, setVal] = useState('one');

    const handleTab = (e, newVal) => {
        setVal(newVal);
    };


    return (
        <>

            <Box>
                <TabContext value={val}>
                    <Tabs
                        value={val}
                        onChange={handleTab}
                        textColor="primary"
                        indicatorColor="primary"
                    >
                        <Tab value="one" label="Transactions" />
                        <Tab value="two" label="Withdrawal Requests" />
                    </Tabs>
                    <TabPanel value='one'>
                        {
                            transactionLoading ? <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"center"}>
                                <CircularProgress />
                            </Stack>
                                :
                                transactions.length === 0 ?
                                    <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"center"}>
                                        <Typography>Empty Payments</Typography>
                                    </Stack> :
                                    <Box>
                                        <TableContainer>
                                            <Table sx={{ minWidth: 650, '& th, & td': { padding: '10px' } }} size="small" aria-label="a dense table">
                                                <TableHead>
                                                    <TableRow>

                                                        <TableCell  >
                                                            <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                                Date
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell  >
                                                            <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                                Staff
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell  >
                                                            <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                                Employer
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell  >
                                                            <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                                Shift Name
                                                            </Typography>
                                                        </TableCell>

                                                        <TableCell  >
                                                            <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                                Total Pay
                                                            </Typography>
                                                        </TableCell>


                                                        <TableCell  >
                                                            <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                                Action
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        transactions?.map((transaction) => (
                                                            <TableRow
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                key={transaction?._id}
                                                            >
                                                                <TableCell ><Typography sx={{ fontSize: '1rem' }} >{transaction?.created_on ? new Date(transaction.created_on).toLocaleDateString('en-GB') : ''}</Typography></TableCell>
                                                                <TableCell >
                                                                    <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                                                                        <Avatar></Avatar>
                                                                        <Typography sx={{ fontSize: '1rem' }}>
                                                                            {transaction?.userId?.first_name}
                                                                            {" "}
                                                                            {transaction?.userId?.last_name}
                                                                        </Typography>
                                                                    </Stack>
                                                                </TableCell>
                                                                <TableCell >
                                                                    <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                                                                        <Avatar></Avatar>
                                                                        <Typography sx={{ fontSize: '1rem' }}>
                                                                            {transaction?.shiftId?.clientId?.first_name}
                                                                            {" "}
                                                                            {transaction?.shiftId?.clientId?.last_name}
                                                                        </Typography>
                                                                    </Stack>
                                                                </TableCell>
                                                                <TableCell >
                                                                    <Typography sx={{ fontSize: '1rem' }}>
                                                                        {transaction?.shiftId?.title}
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell ><Typography sx={{ fontSize: '1rem' }} >{`$${transaction?.total_fee}`}
                                                                    {/* {` ${transaction?.type}`} */}
                                                                </Typography></TableCell>



                                                                <TableCell>

                                                                    <Tooltip title='View' >
                                                                        <IconButton onClick={() => transactionModalOpen(transaction)}>
                                                                            <Visibility sx={{ color: '#0066b2' }} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                        }

                    </TabPanel>


                    <TabPanel value='two'>
                        {
                            withDrawalLoading ? <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"center"}>
                                <CircularProgress />
                            </Stack>
                                :
                                withdrawals.length === 0 ?
                                    <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"center"}>
                                        <Typography>Empty Payments</Typography>
                                    </Stack> :
                                    <Box >
                                        <TableContainer>
                                            <Table sx={{ minWidth: 650, '& th, & td': { padding: '10px' } }} size="small" aria-label="a dense table">
                                                <TableHead>
                                                    <TableRow>

                                                        <TableCell  >
                                                            <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                                Date
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell  >
                                                            <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                                Staff
                                                            </Typography>
                                                        </TableCell>

                                                        <TableCell  >
                                                            <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                                Status
                                                            </Typography>
                                                        </TableCell>


                                                        <TableCell  >
                                                            <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                                Action
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        withdrawals?.map((transaction) => (
                                                            <TableRow
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                key={transaction?._id}
                                                            >
                                                                <TableCell ><Typography sx={{ fontSize: '1rem' }} >{transaction?.created_on ? new Date(transaction.created_on).toLocaleDateString('en-GB') : ''}</Typography></TableCell>
                                                                <TableCell >
                                                                    <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                                                                        <Avatar></Avatar>
                                                                        <Typography sx={{ fontSize: '1rem' }}>
                                                                            {transaction?.userId?.first_name}
                                                                            {" "}
                                                                            {transaction?.userId?.last_name}
                                                                        </Typography>
                                                                    </Stack>
                                                                </TableCell>
                                                                <TableCell ><Typography sx={{ fontSize: '1rem' }} >
                                                                    {/* {`$${transaction?.total_fee}`}
                                                        {` ${transaction?.type}`} */}
                                                                    {/* {` ${transaction?.status}`} */}
                                                                    <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                                                        {
                                                                            transaction?.status === 1 ?
                                                                                <>
                                                                                    <CheckCircle style={{ color: '#8fb569', width: "22px", height: "22px" }} />
                                                                                    <Typography fontWeight='bold'>Paid</Typography>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <DoNotDisturb style={{ color: 'red', width: "22px", height: "22px" }} />
                                                                                    <Typography fontWeight='bold'>Unpaid</Typography>
                                                                                </>
                                                                        }
                                                                    </Box>
                                                                </Typography></TableCell>



                                                                <TableCell>

                                                                    <Tooltip title='View' >
                                                                        <IconButton onClick={() => withDrawModalOpen(transaction)}>
                                                                            <Visibility sx={{ color: '#0066b2' }} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                        }

                    </TabPanel>
                </TabContext>
            </Box>


        </>
    )
}

export default TransactionTable