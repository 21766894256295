import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import dataProvider from 'providers/dataProvider';
import "assets/css/client/shift-request.css"
import { Alert, Avatar, Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogTitle, Divider, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, ListItemButton, MenuItem, Paper, Select, Slide, Snackbar, Stack, TextField, Typography, useTheme } from '@mui/material';
import { AccessTime, AccessTimeOutlined, AccountCircleOutlined, Assignment, CalendarMonth, CalendarMonthOutlined, Cancel, CheckCircle, DescriptionOutlined, Email, EmailOutlined, HighlightOff, Home, LockClockOutlined, PeopleAltOutlined, PersonAddAlt1Outlined, Phone, PhoneIphoneOutlined, PhoneIphoneSharp, QueryBuilderOutlined, TitleOutlined, TocOutlined } from '@mui/icons-material';
import HomeOutlined from '@mui/icons-material/HomeOutlined';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ExpirePopup from 'components/common/ExpirePopup';
import dayjs from 'dayjs';
import { faFilePdf, faTasks, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import html2pdf from 'html2pdf.js';
import styled from '@emotion/styled';
import hirehoodLogo from 'assets/images/hirehoodLogo.png'
import useMediaQuery from '@mui/material/useMediaQuery';
import AppToast from 'components/common/Toast';
import { useToast } from 'hooks/useToast';



const ShiftRequests = () => {
    const { shiftId } = useParams()
    const { palette } = useTheme()
    const { toastOpen, toastMessage, showToast, hideToast } = useToast();
    const errrorLight = palette?.error?.light

    let loggedUserId = localStorage.getItem('userId');

    let token = localStorage.getItem('token');

    const navigate = useNavigate()

    const isMobile = useMediaQuery('(min-width:600px)');


    const [requestLoading, setRequestLoading] = useState(false)
    const [shiftLoading, setShiftLoading] = useState(false)
    const [openAssign, setOpenAssign] = useState(false)
    const [buttonLoading, setButtonLoading] = useState(false)

    const [requestData, setRequestData] = useState([])
    const [shiftData, setShiftData] = useState({})
    const [customerData, setCustomerData] = useState([])

    const [customerId, setCustomerId] = useState("")
    const [idErr, setIdErr] = useState(false)

    const [tokenExpireMsg, setTokenExpireMsg] = useState("")
    const [tokenExpOpen, setTokenExpOpen] = useState(false)
    const [pdfLoading, setPdfLoading] = useState(false)

    const [approveData, setApproveData] = useState({
        shiftId: "",
        requestId: ""
    })

    const [rejectData, setRejectData] = useState({
        requestId: "",
    })

    const [approve, setApprove] = useState(false)
    const [reject, setReject] = useState(false)





    useEffect(() => {
        fetchOneShiftData()
        fetchRequestData()
        // fetchUsersData()
    }, [])


    const fetchRequestData = async () => {
        setRequestLoading(true)
        dataProvider.getList('shift/single/request/' + shiftId, {
            params: {
                token: token,
                pagination: {
                    page: 1,
                    perPage: 1000
                },
                sort: { order: 'desc', field: '_id' },
                filter: {}
            }
        }).then(({ data }) => {
            setRequestData(data)
            setRequestLoading(false)
        }).catch((error) => {
            setRequestLoading(false)
            if (error?.response?.data?.expire === true) {
                setTokenExpireMsg(error?.response?.data?.message)
                setTokenExpOpen(true)
            }
        })
    };

    const fetchOneShiftData = () => {
        setShiftLoading(true)
        dataProvider.getOne('shift', { id: shiftId }).then(({ data }) => {
            // console.log(data.requestId?._id)
            // console.log(data)
            if (!data?.requestId?._id) {
                fetchUsersData(data?.shift_type)
            }
            setShiftData(data)
            setShiftLoading(false)
        }).catch((err) => {
            // console.log(err?.response?.data?.message)
            if (err?.response?.data?.message) {
                navigate("*")
            }
            setShiftLoading(false)
        })
    }

    // for staff assigning 
    const fetchUsersData = async (shiftType) => {
        dataProvider.getList('users/customers',
            {
                params: {
                    token: token,
                    pagination: {
                        page: 1,
                        perPage: 1000
                    },
                    sort: { order: 'desc', field: 'id' },
                    filter: {
                        role: 'customer',
                        status: 1,
                        staff_role: shiftType
                    }
                }
            })
            .then(({ data }) => {
                // console.log(data);
                setCustomerData(data)
            })
            .catch(error => {
                console.log(error?.response?.data)
                console.log(error?.response?.data?.expire)
                if (error?.response?.data?.expire === true) {
                    setTokenExpireMsg(error?.response?.data?.message)
                    setTokenExpOpen(true)
                }
            })
    };

    const handleCustomerId = (e) => {
        setIdErr(false)
        const { name, value } = e.target;
        // console.log(value);
        setCustomerId(value)
    }


    let isPdfGenerationInProgress = false;

    const handlePdf = async (staff) => {
        // Check if PDF generation is already in progress
        if (isPdfGenerationInProgress) {
            return;
        }

        // Set the flag to indicate that PDF generation is now in progress
        isPdfGenerationInProgress = true;
        setPdfLoading(true)

        const { title, shift_type, clientId, locationId, areaId, date, time_from, time_to, description, customerId,
            distance, ending_point, starting_point, client_sign, customer_sign, hospital_name, ward, floor, breaking_hours, date_to, breaking_reason } = staff


        try {
            const clientSignBase64 = await convertToBase64(client_sign);
            const customerSignBase64 = await convertToBase64(customer_sign);
            const logoBase64 = await convertToBase64(hirehoodLogo)

            const htmlContent =
                `
            <div>
            <div style="display: flex;align-items: center;margin: 10px 0;">
                <img src=${logoBase64} style="width: 80px; height: 80px;" />
                <h2>Hirehood</h2>
            </div>
            <hr>
            <div>
                <h2 style="text-align: center;text-transform: uppercase;margin: 30px 0;">Time Sheet Details</h2>
        
                <div style="display: flex; justify-content: space-between; margin: 10px;">
                    <div style="flex-basis: calc(50% - 5px);">
                        <div
                            style="display: flex; flex-direction: column; gap: 20px; padding: 10px; border: 1px solid #ccc;border-radius: 5px;">
        
                            <div style="display: flex;align-items: center;gap: 10px;">
                                <h3>Title</h3>
                                <span>:</span>
                                <p>${title}</p>
                            </div>
        
        
                            <div style="display: flex;align-items: center;gap: 10px;">
                                <h3>Ward</h3>
                                <span>:</span>
                                <p>${ward}</p>
                            </div>
        
        
                            <div style="display: flex;align-items: center;gap: 10px;">
                                <h3>Staff</h3>
                                <span>:</span>
                                <p>${customerId?.first_name}${" "}${customerId?.last_name}</p>
                            </div>
        
        
                            <div style="display: flex;align-items: center;gap: 10px;">
                                <h3>Location</h3>
                                <span>:</span>
                                <p>${locationId?.location}</p>
                            </div>
        
        
                            <div style="display: flex;align-items: center;gap: 10px;">
                                <h3>Date From</h3>
                                <span>:</span>
                                <p>${date && new Date(date).toLocaleDateString('en-GB', { timeZone: 'UTC' })}</p>
                            </div>
        
        
        
                            <div style="display: flex;align-items: center;gap: 10px;">
                                <h3>Time From</h3>
                                <span>:</span>
                                <p>${time_from}</p>
                            </div>
        
                            <div style="display: flex;align-items: center;gap: 10px;">
                                <h3>Starting Eircode</h3>
                                <span>:</span>
                                <p>${starting_point}</p>
                            </div>
        
        
                            <div style="display: flex;align-items: center;gap: 10px;">
                                <h3>Distance</h3>
                                <span>:</span>
                                <p>${distance}</p>
                            </div>
        
                            <div style="display: flex;align-items: center;gap: 10px;">
                                <h3>Break Minutes</h3>
                                <span>:</span>
                                <p>${breaking_hours ? breaking_hours : 'No Break Minutes'}</p>
                            </div>
        
        
                            <!-- Add more left column content here -->
                        </div>
                    </div>
        
                    <div style="flex-basis: calc(50% - 5px);">
                        <div
                            style="display: flex; flex-direction: column; gap: 20px; padding: 10px; border: 1px solid #ccc;border-radius: 5px;">
        
        
                            <div style="display: flex;align-items: center;gap: 10px;">
                                <h3>Hosptial Name</h3>
                                <span>:</span>
                                <p>${hospital_name}</p>
                            </div>
        
        
                            <div style="display: flex;align-items: center;gap: 10px;">
                                <h3>Manager name</h3>
                                <span>:</span>
                                <p>${floor}</p>
                            </div>
        
                            <div style="display: flex;align-items: center;gap: 10px;">
                                <h3>Employer</h3>
                                <span>:</span>
                                <p>${clientId?.first_name}${" "}${clientId?.last_name}</p>
                            </div>
        
        
                            <div style="display: flex;align-items: center;gap: 10px;">
                                <h3>Area</h3>
                                <span>:</span>
                                <p>${areaId?.area}</p>
                            </div>
        
                            <div style="display: flex;align-items: center;gap: 10px;">
                                <h3>Date To</h3>
                                <span>:</span>
                                <p>${date_to && new Date(date_to).toLocaleDateString('en-GB', { timeZone: 'UTC' })}</p>
                            </div>
        
        
        
                            <div style="display: flex;align-items: center;gap: 10px;">
                                <h3>Time To</h3>
                                <span>:</span>
                                <p>${time_to}</p>
                            </div>
        
        
                            <div style="display: flex;align-items: center;gap: 10px;">
                                <h3>Shfit Type</h3>
                                <span>:</span>
                                <p>${shift_type}</p>
                            </div>
        
        
        
        
                            <div style="display: flex;align-items: center;gap: 10px;">
                                <h3>Ending Eircode</h3>
                                <span>:</span>
                                <p>${ending_point}</p>
                            </div>
        
                            <!-- Add more right column content here -->
                        </div>
                    </div>
                </div>
        
        
                <div style="margin: 10px;">
                    <div
                        style="display: flex; flex-direction: column; gap: 20px; padding: 10px; border: 1px solid #ccc;border-radius: 5px;">
        
        
                        <div style="display: ${breaking_reason ? 'flex' : 'none'};align-items: start;gap: 10px;margin: 10px 0;">
                            <h3>Break Reason</h3>
                            <span>:</span>
                            <p>${breaking_reason ? breaking_reason : 'No Break Reason'}</p>
                        </div>
        
                        <div style="display: flex;align-items: center;gap: 10px;">
                            <h3>Description</h3>
                            <span>:</span>
                            <p>${description}</p>
                        </div>
        
                        <!-- Add more right column content here -->
                    </div>
                </div>
        
        
            </div>
            <hr>
            <div style="margin: 30px 0;">
                <div style="display: flex;align-items:center;justify-content: space-around;">
                    <div>
                        <h3>Signature( Submitted by Staff )</h3>
                        <img src=${customerSignBase64} alt="" style="width: 100px; height: 100px;margin: 10px 0;">
                    </div>
        
                    <div>
                        <h3>Signature( Submitted by Employer )</h3>
                        <img src=${clientSignBase64} alt="" style="width: 100px; height: 100px;margin: 10px 0;">
                    </div>
                </div>
            </div>
        
        </div>
            `

            const element = document.createElement('div');
            element.innerHTML = htmlContent;

            html2pdf(element, {
                margin: 10,
                filename: 'timesheet.pdf',
                image: { type: 'png', quality: 0.98 },
                html2canvas: { scale: 1 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
            }).outputPdf().then((pdfBlob) => {

                isPdfGenerationInProgress = false;
                setPdfLoading(false)

                const blobUrl = URL.createObjectURL(pdfBlob);


                const downloadLink = document.createElement('a');
                downloadLink.href = blobUrl;
                downloadLink.download = 'timesheet.pdf';
                downloadLink.click();


                URL.revokeObjectURL(blobUrl);
            });

        } catch (error) {
            console.error('Error converting client sign to base64:', error);
            showToast('Something went wrong please try again');
            setPdfLoading(false)
        }
        // Example HTML-like content
    };


    const convertToBase64 = (imageUrl) => {
        //console.log(imageUrl);
        return new Promise((resolve, reject) => {
            fetch(imageUrl)
                .then(response => response.blob())
                .then(blob => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        resolve(reader.result);
                    };
                    reader.onerror = reject;
                    reader.readAsDataURL(blob);
                })
                .catch(reject);
        });
    }


    const handleAssignShifttoCustomer = () => {
        if (customerId === "" || customerId === null) {
            setIdErr(true)
            setOpenAssign(false)
        } else {
            const data = {
                'clientId': shiftData?.clientId?._id,
                'shiftId': shiftData?._id,
                'customerId': customerId
            }

            setButtonLoading(true)
            dataProvider.create('shift/admin/approve', data).then((data) => {
                // console.log(data);
                setButtonLoading(false)
                setOpenAssign(false)
                fetchOneShiftData()
                fetchRequestData()
            }).catch((e) => {
                // console.log(e)
                setButtonLoading(false)
                showToast(e?.response?.data?.message);
            })
        }

    }


    const ClientSign = styled(Box)({
        height: "200px",
        border: "1px solid #e4dede",
        margin: "20px",
        borderRadius: "10px",
        padding: "10px"
    })

    const DetailsSign = styled(Box)({
        // height: "170px",
        border: "1px solid #e4dede",
        margin: "20px",
        borderRadius: "10px",
        padding: "10px"
    })

    const ClientSignBox = styled(Box)({
        padding: "10px"
    })

    const getInitials = (name) => {
        return name ? name[0].toUpperCase() : '';
    }


    const handleOpenApprove = (shiftId, requestId) => {
        setApproveData((prevState) => ({
            ...prevState,
            shiftId: shiftId,
            requestId: requestId
        }))
        setApprove(true)
    }

    const handleCloseApprove = () => {
        setApproveData((prevState) => ({
            ...prevState,
            shiftId: "",
            requestId: ""
        }))
        setApprove(false)
    }

    const handleApprveSubmit = () => {
        const { shiftId, requestId } = approveData

        const data = {
            'id': shiftId,
            'requestId': requestId,

        }

        setButtonLoading(true)
        // setButtonLoading(false)
        // setApprove(false)
        // console.log(data);
        dataProvider.update('shift/client', data).then((data) => {
            // console.log(data);
            setButtonLoading(false)
            setApprove(false)
            fetchOneShiftData()
            fetchRequestData()
        }).catch((e) => {
            console.log(e?.response?.data?.message)
            setButtonLoading(false)
            showToast(e?.response?.data?.message);
        })
    }




    const handleRejectModal = (condition, request) => {
        if (condition === "open") {
            let requestId = request?._id;

            setRejectData((prev) => ({ ...prev, requestId }))
            setReject(true)
        } else {
            setRejectData((prev) => ({
                ...prev,
                requestId: "",
            }))
            setReject(false)
        }
    }




    const handleConfirmReject = () => {
        const { requestId } = rejectData

        const data = {
            'id': requestId,
            'status': 'reject'
        }

        setButtonLoading(true)
        dataProvider.update('shift/request', data).then((data) => {
            // console.log(data);
            setButtonLoading(false)
            setReject(false)
            fetchOneShiftData()
            fetchRequestData()

        }).catch((e) => {
            console.log(e?.response?.data?.message)
            showToast(e?.response?.data?.message);
            setButtonLoading(false)
        })

    }




    return (
        <>

            {
                tokenExpOpen ? <ExpirePopup tokenExpireMsg={tokenExpireMsg} /> :
                    <>
                        <Grid container spacing={2} my={2}>

                            {/* Shift Details */}
                            <Grid item md={12}>
                                {
                                    shiftLoading ?
                                        <Paper sx={{ padding: "10px" }}>
                                            <div className="loader-position">
                                                <CircularProgress color="primary" size={45} />
                                            </div>
                                        </Paper> :
                                        // Shift Details
                                        <Paper sx={{ padding: "10px" }}>

                                            <Stack flexDirection={"row"} justifyContent={"space-between"}>
                                                <Typography fontWeight={"bold"}>Shift Details</Typography>
                                                {/* Export PDF */}
                                                {
                                                    shiftData?.shift_status === 'completed' &&
                                                    <Button variant='contained'
                                                        sx={{
                                                            color: "white",
                                                            backgroundImage: "linear-gradient(98deg, #9cc177, #6ba134 94%)",
                                                            width: "100px",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            height: "40px",
                                                        }}
                                                        onClick={() => handlePdf(shiftData)} disabled={pdfLoading}
                                                        endIcon={pdfLoading ? null : <FontAwesomeIcon icon={faFilePdf} style={{ color: "#fff", }} />}
                                                    >
                                                        {
                                                            pdfLoading ? <CircularProgress size={23} sx={{ margin: "0 10px", color: "white" }} /> : 'Export'
                                                        }

                                                    </Button>
                                                }
                                            </Stack>
                                            <Grid container spacing={2} my={2}>

                                                {/* Title */}
                                                <Grid item md={6} xs={12}>
                                                    <TextField
                                                        label="Title"
                                                        variant="outlined"
                                                        fullWidth
                                                        disabled
                                                        value={shiftData?.title}
                                                        size='small'
                                                    />
                                                </Grid>

                                                {/* Hospital Name */}
                                                <Grid item md={6} xs={12}>
                                                    <TextField
                                                        label="Hospital Name"
                                                        variant="outlined"
                                                        fullWidth
                                                        disabled
                                                        value={shiftData?.hospital_name}
                                                        size='small'
                                                    />
                                                </Grid>

                                                {/* Ward */}
                                                <Grid item md={6} xs={12}>
                                                    <TextField
                                                        label="Ward"
                                                        variant="outlined"
                                                        fullWidth
                                                        name='ward'
                                                        value={shiftData?.ward}
                                                        disabled
                                                        size='small'
                                                    />
                                                </Grid>


                                                {/* Cost center */}
                                                <Grid item md={6} xs={12}>
                                                    <TextField
                                                        label="Manger name"
                                                        variant="outlined"
                                                        fullWidth
                                                        name='costCenter'
                                                        value={shiftData?.floor}
                                                        disabled
                                                        size='small'
                                                    />
                                                </Grid>

                                                {/* Location */}
                                                <Grid item md={6} xs={12}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Location</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={10}
                                                            label="Location"
                                                            disabled
                                                            size='small'
                                                        >
                                                            <MenuItem value={10}>{shiftData?.locationId?.location}</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>

                                                {/* Area */}
                                                <Grid item md={6} xs={12}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-area-select-label">Area</InputLabel>
                                                        <Select
                                                            labelId="demo-area-select-label"
                                                            id="demo-area-select-label"
                                                            value={10}
                                                            label="Area"
                                                            disabled
                                                            size='small'
                                                        >
                                                            <MenuItem value={10}>{shiftData?.areaId?.area}</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>



                                                {/* Time from */}
                                                <Grid item md={6} xs={12}>
                                                    <TextField
                                                        labelId="demo-timefrom-select-label"

                                                        label="Time from"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={shiftData?.time_from}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <AccessTimeOutlined />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        disabled
                                                        size='small'
                                                    />
                                                </Grid>

                                                {/* Time to */}
                                                <Grid item md={6} xs={12}>

                                                    <TextField id="outlined-basic"
                                                        labelId="demo-timeto-select-label"
                                                        label="Time to"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={shiftData?.time_to}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <AccessTimeOutlined />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        disabled
                                                        size='small'
                                                    />
                                                </Grid>


                                                {/* Status */}
                                                <Grid item md={6} xs={12}>
                                                    <TextField
                                                        id="outlined-multiline-static"
                                                        label="Status"
                                                        multiline
                                                        value={shiftData?.status === 0 ? 'Inactive' : 'Active'}
                                                        maxRows={4}
                                                        fullWidth
                                                        disabled
                                                        size='small'
                                                    />
                                                </Grid>

                                                {/* Staff Type */}
                                                <Grid item md={6} xs={12}>
                                                    <TextField
                                                        id="shiftType"
                                                        label="Staff type"
                                                        multiline
                                                        value={shiftData?.shift_type}
                                                        maxRows={4}
                                                        fullWidth
                                                        disabled
                                                        size='small'
                                                    />
                                                </Grid>

                                                {/* Date From*/}
                                                <Grid item md={6} xs={12}>
                                                    <TextField
                                                        labelId="demo-date-select-label"
                                                        id="demo-area-date-label"
                                                        label="Date From"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={
                                                            `${new Date(shiftData.date).toLocaleDateString('en-GB', { timeZone: 'UTC' })}`
                                                        }
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <CalendarMonthOutlined />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        size='small'
                                                        disabled
                                                    />
                                                </Grid>

                                                {/* Date To*/}
                                                <Grid item md={6} xs={12}>
                                                    <TextField
                                                        labelId="demo-date-select-label"
                                                        id="demo-area-date-label"
                                                        label="Date To"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={
                                                            `${new Date(shiftData.date_to).toLocaleDateString('en-GB', { timeZone: 'UTC' })}`
                                                        }
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <CalendarMonthOutlined />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        size='small'
                                                        disabled
                                                    />
                                                </Grid>



                                                {/* Description */}
                                                <Grid item md={12} xs={12}>
                                                    <TextField
                                                        id="outlined-multiline-static"
                                                        label="Description"
                                                        multiline
                                                        value={shiftData?.description}
                                                        maxRows={4}
                                                        fullWidth
                                                        disabled
                                                        size='small'
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                }

                            </Grid>

                            <Grid item md={12} xs={12}>
                                <Paper >

                                    {/* Staff Assigning */}
                                    {
                                        shiftLoading ? <div className="loader-position">
                                            <CircularProgress color="primary" size={1} />
                                        </div> :
                                            !shiftData?.requestId?._id &&
                                            <Box sx={{ padding: "10px" }}>
                                                <FormControl fullWidth error={idErr}>
                                                    <InputLabel >Select a Staff</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={customerId}
                                                        label="Select a Customer"
                                                        onChange={handleCustomerId}
                                                        sx={{
                                                            height: '56.5px',
                                                            // minHeight: '1.4375em'
                                                        }}
                                                    >
                                                        {
                                                            customerData.length === 0 ?
                                                                (<MenuItem disabled>Empty Staffs</MenuItem>) :
                                                                customerData.map((customer) => (
                                                                    (<MenuItem key={customer?._id} value={customer?._id}>
                                                                        <List sx={{ width: "100%" }}>
                                                                            <ListItem>
                                                                                <ListItemAvatar>
                                                                                    <Avatar src={customer?.avatar_path}>
                                                                                        {getInitials(customer?.first_name)}
                                                                                    </Avatar>
                                                                                </ListItemAvatar>
                                                                                <ListItemText primary={`${customer?.first_name}${" "}${customer?.last_name}`} />
                                                                            </ListItem>
                                                                        </List>
                                                                    </MenuItem>)
                                                                ))
                                                        }
                                                    </Select>
                                                    <FormHelperText>{idErr && 'Please Select a Staff'}</FormHelperText>
                                                </FormControl>
                                                <Box sx={{ display: "flex", justifyContent: "end", margin: "20px 0" }}>
                                                    <Button variant="contained"
                                                        sx={{ color: "white" }}
                                                        endIcon={<Assignment />}
                                                        onClick={() => setOpenAssign(true)}
                                                        disabled={buttonLoading}
                                                    >
                                                        Assign
                                                    </Button>
                                                </Box>
                                            </Box>
                                    }

                                    {/* ACCEPTED REQUEST */}
                                    {/* if the requestId and customerId  inside shift that means the shift is accepted  */}
                                    {
                                        shiftData?.requestId &&
                                        <>
                                            {/* <Typography fontWeight={"bold"} p={2}>{shiftData?.requestId ? `Accepted Request ${shiftData?.requestId?.requestBy === 'admin' ? '(Admin)' : '(Employer)'}` : 'Requests'}</Typography> */}
                                            <Typography fontWeight={"bold"} p={2}>{`Accepted Request`}</Typography>
                                            <Divider></Divider>
                                            <List sx={{ bgcolor: '#add387', color: "white" }}>
                                                <ListItem >
                                                    <ListItemAvatar>
                                                        <Avatar sx={{ width: "45px", height: "45px" }} src={shiftData?.customerId?.avatar_path}></Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        sx={{ marginLeft: "20px" }}
                                                        primary={<Typography fontWeight='bold'>{shiftData?.customerId?.first_name}{" "}{shiftData?.customerId?.last_name}</Typography>}
                                                        secondary={
                                                            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", marginTop: "5px", color: "white" }}>

                                                                <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                                    <Stack flexDirection={'row'} alignItems={'center'} gap={'5px'}>
                                                                        <EmailOutlined sx={{ fontSize: "15px" }} />
                                                                        <Typography sx={{ fontSize: "15px" }}>{shiftData?.customerId?.email}</Typography>
                                                                    </Stack>

                                                                    <Stack flexDirection={'row'} alignItems={'center'} gap={'5px'}>
                                                                        <PhoneIphoneOutlined sx={{ fontSize: "15px" }} />
                                                                        <Typography sx={{ fontSize: "15px" }}>{shiftData?.customerId?.phone}</Typography>
                                                                    </Stack>
                                                                </Box>


                                                                <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                                    <Stack flexDirection={'row'} alignItems={'center'} gap={'5px'}>
                                                                        <PersonAddAlt1Outlined sx={{ fontSize: "15px" }} />
                                                                        <Typography sx={{ fontSize: "15px" }}>{shiftData?.customerId?.role === 'customer' && 'Staff'}</Typography>
                                                                    </Stack>

                                                                    {/* <Stack flexDirection={'row'} alignItems={'center'} gap={'5px'}>
                                                                        <HomeOutlined sx={{ fontSize: "15px" }} />
                                                                        <Typography sx={{ fontSize: "15px" }}>{shiftData?.customerId?.address}</Typography>
                                                                    </Stack> */}
                                                                </Box>
                                                            </Box>
                                                        }
                                                    />
                                                </ListItem>
                                                {/* <Divider /> */}
                                            </List>
                                        </>
                                    }

                                    {/* Completed Shift Details */}
                                    {
                                        shiftData?.shift_status === 'completed' &&
                                        <Box mt={2}>

                                            {/* <Typography fontWeight={"bold"} >Staff Sign</Typography> */}

                                            <DetailsSign>
                                                <Stack flexDirection={"column"} alignItems={"start"} gap={2} sx={{ padding: "10px" }}>
                                                    <Typography fontWeight={"bold"}>Distance : {shiftData?.distance}</Typography>
                                                    <Typography fontWeight={"bold"}>Starting Eircode : {shiftData?.starting_point}</Typography>
                                                    <Typography fontWeight={"bold"}>Ending Eircode : {shiftData?.ending_point}</Typography>
                                                    <Typography fontWeight={"bold"}>Break Minutes  : {shiftData?.breaking_hours ? shiftData?.breaking_hours : 'Break Minutes is not take'}</Typography>
                                                    {
                                                        shiftData?.breaking_hours === '' &&
                                                        <Typography fontWeight={"bold"}>Break Reason  : {shiftData?.breaking_reason}</Typography>
                                                    }
                                                </Stack>
                                            </DetailsSign>



                                            <ClientSignBox>
                                                <Typography fontWeight={"bold"} >Staff Sign</Typography>

                                                <ClientSign>
                                                    <img src={shiftData?.customer_sign} width='100%' height='100%' loading='eager' style={{ objectFit: "contain" }} />
                                                </ClientSign>
                                            </ClientSignBox>

                                            <ClientSignBox>
                                                <Typography fontWeight={"bold"} >Employer Sign</Typography>
                                                <ClientSign>
                                                    <img src={shiftData?.client_sign} width='100%' height='100%' loading='eager' style={{ objectFit: "contain" }} />
                                                </ClientSign>
                                            </ClientSignBox>

                                        </Box>
                                    }


                                    {/* Shift Requests (Except Accepted Shift) we dont need to show the accepted request */}
                                    {/*  it checks req?._id !== shiftData?.requestId?._id  */}
                                    {
                                        requestLoading ?
                                            <Stack py={2} flexDirection={"row"} justifyContent={"center"} alignItems={"center"}>
                                                <CircularProgress color="primary" size={45} />
                                            </Stack> :
                                            requestData.length === 0 ?
                                                <>
                                                    {

                                                        <Typography sx={{ py: 3, textAlign: "center" }}>Empty Requests</Typography>
                                                    }
                                                </>
                                                :
                                                <>
                                                    {

                                                        // requestData.find(req => req?._id === shiftData?.requestId?._id) && requestData.length > 1 &&
                                                        requestData.filter(req => req?._id !== shiftData?.requestId?._id).length !== 0 &&
                                                        <Typography fontWeight={'bold'} p={2}>{'All Requests'}</Typography>
                                                    }

                                                    <List sx={{ width: '100%', }}>
                                                        {
                                                            requestData.filter(req => req?._id !== shiftData?.requestId?._id).map((req, index) => (
                                                                // shiftData?.requestId?._id !== req?._id &&
                                                                <>
                                                                    <Divider></Divider>
                                                                    <ListItem
                                                                        secondaryAction={
                                                                            !shiftData?.requestId?._id && !shiftLoading && req?.status === 'accept' ?
                                                                                <Stack flexDirection={"row"} gap={"15px"}>

                                                                                    <Button variant="contained"
                                                                                        onClick={() => handleOpenApprove(req?.shiftId?._id, req?._id)}
                                                                                        sx={{ color: "white", width: isMobile ? '150px' : "100px" }}
                                                                                        endIcon={<CheckCircle />}
                                                                                    >Approve</Button>


                                                                                    <Button variant="contained"
                                                                                        onClick={() => handleRejectModal('open', req)}
                                                                                        sx={{ color: "white", width: isMobile ? '150px' : "100px", backgroundColor: errrorLight }}
                                                                                        color='error'
                                                                                        disableRipple
                                                                                        endIcon={<Cancel />}
                                                                                    >Reject</Button>

                                                                                </Stack> :
                                                                                !shiftData?.requestId?._id && !shiftLoading && req?.status === 'reject' &&
                                                                                // <Button variant="contained"
                                                                                //     // onClick={() => handleOpenApprove(req?.shiftId?._id, req?.clientId?._id, req?.customerId?._id)}
                                                                                //     sx={{ color: "white", width: isMobile ? '150px' : "100px"}}
                                                                                //     color='error'
                                                                                //     endIcon={<Cancel />}
                                                                                // >Rejected</Button>
                                                                                <Button variant='outlined' color='error' sx={{ width: isMobile ? '150px' : "100px" }} endIcon={<Cancel />}>Rejected</Button>
                                                                            // <Stack flexDirection={"row"} gap={0.5} alignItems={"center"} >
                                                                            //     <Typography fontSize={'1.2rem'} fontWeight={'bold'} textTransform={'uppercase'} color='red'>Rejected</Typography>
                                                                            //     <Cancel color='error' />
                                                                            // </Stack>
                                                                        }
                                                                        key={req?._id}
                                                                    >
                                                                        <ListItemAvatar>
                                                                            <Avatar sx={{ width: "50px", height: "50px" }} src={req?.customerId?.avatar_path}></Avatar>
                                                                        </ListItemAvatar>
                                                                        <ListItemText
                                                                            sx={{ marginLeft: "20px" }}
                                                                            primary={<Typography fontWeight='bold'>{req?.customerId?.first_name}{" "}{req?.customerId?.last_name}</Typography>}
                                                                            secondary={
                                                                                <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", marginTop: "5px" }}>

                                                                                    <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                                                        <Stack flexDirection={'row'} alignItems={'center'} gap={'5px'}>
                                                                                            <EmailOutlined sx={{ fontSize: "15px" }} />
                                                                                            <Typography sx={{ fontSize: "15px" }}>{req?.customerId?.email}</Typography>
                                                                                        </Stack>

                                                                                        <Stack flexDirection={'row'} alignItems={'center'} gap={'5px'}>
                                                                                            <PhoneIphoneOutlined sx={{ fontSize: "15px" }} />
                                                                                            <Typography sx={{ fontSize: "15px" }}>{req?.customerId?.phone}</Typography>
                                                                                        </Stack>
                                                                                    </Box>


                                                                                    <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                                                        <Stack flexDirection={'row'} alignItems={'center'} gap={'5px'}>
                                                                                            <PersonAddAlt1Outlined sx={{ fontSize: "15px" }} />
                                                                                            <Typography sx={{ fontSize: "15px" }}>{req?.customerId?.role === 'customer' && 'Staff'}</Typography>
                                                                                        </Stack>

                                                                                        <Stack flexDirection={'row'} alignItems={'center'} gap={'5px'}>
                                                                                            <PeopleAltOutlined sx={{ fontSize: "15px" }} />
                                                                                            <Typography sx={{ fontSize: "15px" }}>{req?.customerId?.staff_role}</Typography>
                                                                                        </Stack>
                                                                                    </Box>
                                                                                </Box>
                                                                            }
                                                                        />
                                                                    </ListItem>
                                                                </>
                                                            ))
                                                        }

                                                    </List>
                                                </>
                                    }
                                </Paper>
                            </Grid>


                        </Grid>
                    </>

            }

            {/* Assign Dialog */}
            <Dialog open={openAssign} onClose={() => setOpenAssign(false)} fullWidth>
                <DialogTitle>ARE YOU SURE YOU WANT TO ASSIGN THIS STAFF ?</DialogTitle>

                <DialogActions>
                    <Button onClick={() => setOpenAssign(false)}>Cancel</Button>
                    <Button onClick={() => handleAssignShifttoCustomer()}
                        disabled={buttonLoading}
                    >
                        {
                            buttonLoading ? <CircularProgress size={15} color='primary' /> : 'Assign'
                        }
                    </Button>
                </DialogActions>
            </Dialog>


            {/* Reject Dialoge */}
            <Dialog open={reject} onClose={() => handleRejectModal('close')} fullWidth>
                <DialogTitle>ARE YOU SURE YOU WANT TO REJECT THIS STAFF ?</DialogTitle>

                <DialogActions>
                    <Button onClick={() => handleRejectModal('close')}>Cancel</Button>
                    <Button
                        disabled={buttonLoading}
                        color='error'
                        onClick={() => handleConfirmReject()}
                    >
                        {
                            buttonLoading ? <CircularProgress size={15} color='error' /> : 'Reject'
                        }
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Approve Dialoge */}
            <Dialog open={approve} onClose={() => handleCloseApprove()} fullWidth>
                <DialogTitle>ARE YOU SURE YOU WANT TO APPROVE THIS STAFF ?</DialogTitle>

                <DialogActions>
                    <Button onClick={() => handleCloseApprove()}>Cancel</Button>
                    <Button onClick={() => handleApprveSubmit()}
                        disabled={buttonLoading}
                    >
                        {
                            buttonLoading ? <CircularProgress size={15} color='primary' /> : 'Approve'
                        }
                    </Button>
                </DialogActions>
            </Dialog>

            <AppToast toastOpen={toastOpen} toastClose={hideToast} message={toastMessage} />

        </>
    )
}



export default ShiftRequests