import { jwtDecode } from "jwt-decode";


export default {
    login: ({ email, password }) => {
        const request = new Request('https://api.hirehoodapp.com/auth/login', {
            method: 'POST',
            body: JSON.stringify({ email, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    return Promise.reject();
                }
                return response.json();
            })
            .then(({ token }) => {

                if (token) {
                    const decodedToken = jwtDecode(token);

                    const role = decodedToken.user.role
                    const firstName = decodedToken.user.first_name
                    const userId = decodedToken.user.id
                    // const avatar_path = decodedToken.user.avatar_path

                    // console.log(decodedToken)

                    localStorage.setItem('isLoggedIn', true);
                    localStorage.setItem('role', role)
                    localStorage.setItem('token', token);
                    localStorage.setItem('firstName', firstName);
                    localStorage.setItem('userId', userId);
                    // localStorage.setItem('avatar_path', avatar_path)

                    return Promise.resolve();
                }
                else {
                    localStorage.removeItem('isLoggedIn', true);
                    localStorage.removeItem('role')
                    localStorage.removeItem('token');
                    localStorage.removeItem('firstName');
                    localStorage.removeItem('userId');

                    return Promise.resolve();
                }

            });
    },
    logout: () => {
        localStorage.removeItem('isLoggedIn');
        // localStorage.removeItem('role')
        localStorage.removeItem('token');
        localStorage.removeItem('firstName');
        localStorage.removeItem('userId');
        return Promise.resolve();
    },

    validateToken: (token) => {
        return new Promise((resolve, reject) => {
            const decodedToken = jwtDecode(token);
            resolve(decodedToken);
        });
    }
}




