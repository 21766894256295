import AuthLayout from 'layouts/AuthLayout';
import './App.css';
import AdminLayout from 'layouts/AdminLayout';
import ClientLayout from 'layouts/ClientLayout';
import { AdminContextProvider } from 'providers/adminContextProvider';
import { ClientContextProvider } from 'providers/clientContextProvider'
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';

let isLoggedIn = localStorage.getItem('isLoggedIn');
let role = localStorage.getItem('role');
let token = localStorage.getItem('token');



function App() {



  const [loading, setLoading] = useState(false)


  return (
    <div >
      {
        loading ? <CircularProgress /> :

          isLoggedIn && role === 'admin' ? (
            <AdminContextProvider>
              <AdminLayout />
            </AdminContextProvider>
          ) :
            isLoggedIn && role === 'client' ? (
              <ClientContextProvider>
                <ClientLayout />
              </ClientContextProvider>
            ) :
              (<AuthLayout />)
      }
    </div>
  );
}

export default App;
