import { Avatar, Box, CircularProgress, Grid, IconButton, List, ListItem, ListItemButton, Paper, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import styled from '@emotion/styled';
import dataProvider from 'providers/dataProvider';
import dayjs from 'dayjs';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpirePopup from 'components/common/ExpirePopup';
import { useAdminContextProvider } from 'providers/adminContextProvider';




const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));


const AvailabilityCalender = ({ userid, isMargin }) => {

    const { setNetworkErr } = useAdminContextProvider()
    let token = localStorage.getItem('token');
    const [slotes, setSlotes] = useState([])
    const [formattedDate, setFormattedDate] = useState(dayjs(new Date()).format('dddd'))
    const [slotLoading, setSloatLoading] = useState(false)
    const [id, setId] = useState({})
    const [tokenExpireMsg, setTokenExpireMsg] = useState("")
    const [tokenExpOpen, setTokenExpOpen] = useState(false)
    const [first90Days, setFirst90Days] = useState(generateDates())
    const [firstIndex, setFirstIndex] = useState(0)
    const [lastIndex, setLastIndex] = useState(7)
    const [selectedDate, setSelectedDate] = useState(dayjs().format('DD-MM-YYYY'));
    const [selectedWeek, setSelectedWeek] = useState(0)

    useEffect(() => {
        fetchAvialbilSlotes()
    }, [])


    const fetchAvialbilSlotes = (week) => {
        setSloatLoading(true)
        dataProvider.getList('calendar/availability', {
            params: {
                token: token,
                pagination: { page: 1, perPage: 1000 },
                sort: { order: 'desc', field: 'id' },
                filter: { week: week ? week : 0 }
            }
        })
            .then(({ data }) => {
                setSloatLoading(false)
                setSlotes(data)
                //console.log(data);
            })
            .catch(error => {
                setSloatLoading(false)
                if (error.message === 'Network Error') {
                    setNetworkErr(true)
                }
                if (error?.response?.data?.expire === true) {
                    setTokenExpireMsg(error?.response?.data?.message)
                    setTokenExpOpen(true)
                }
            })
    }


    function generateDates() {
        const today = new Date();
        const options = [];
        for (let i = 0; i <= 90; i++) {
            const date = new Date(today);
            date.setDate(today.getDate() + i);
            options.push({ date });
        }
        return options;
    }

    const handleSelectedDate = (newDate, index) => {
        const getDayFormat = dayjs(newDate).format('dddd');
        setSelectedDate(dayjs(newDate).format('DD-MM-YYYY'))
        setFormattedDate(getDayFormat)
    }

    const moveClick = (condition) => {
        if (condition === 'prev' && firstIndex > 0) {
            setSlotes([])
            setFirstIndex((prev) => prev - 7);
            setLastIndex((prev) => prev - 7);
            const newSelectedDate = dayjs(first90Days[firstIndex - 7]?.date).format('DD-MM-YYYY');
            const getDayFormat = dayjs(first90Days[firstIndex - 7]?.date).format('dddd');
            setSelectedDate(newSelectedDate);
            setFormattedDate(getDayFormat)
            setSelectedWeek((prev) => prev - 1);
            fetchAvialbilSlotes(selectedWeek - 1);
        } else if (condition === 'next' && lastIndex < first90Days.length) {
            setSlotes([])
            setFirstIndex((prev) => prev + 7);
            setLastIndex((prev) => prev + 7);
            const newSelectedDate = dayjs(first90Days[firstIndex + 7]?.date).format('DD-MM-YYYY');
            const getDayFormat = dayjs(first90Days[firstIndex + 7]?.date).format('dddd');
            setSelectedDate(newSelectedDate);
            setFormattedDate(getDayFormat)
            setSelectedWeek((prev) => prev + 1);
            fetchAvialbilSlotes(selectedWeek + 1);
        }
    };

    //

    const getShortDayName = (dayIndex) => {
        const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
        return days[dayIndex];
    };

    const getShortMonthName = (monthIndex) => {
        const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
        return months[monthIndex];
    };

    const getInitials = (name) => {
        return name ? name[0].toUpperCase() : '';
    }

    const CustomPagination = styled(Box)(({ theme }) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px",
        [theme.breakpoints.down("sm")]: {
            gap: "5px",
        },
    }))

    const DateBox = styled(Box)(({ theme }) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: "2px",
        // color: "white",
        padding: "10px 20px",
        borderRadius: "5px",
        cursor: "pointer",
        width: "20px",
        height: "60px",
        [theme.breakpoints.down("sm")]: {
            width: "5px",
        },
    }));


    // Determine which user Notes
    const handleNotes = (id) => {
        setId((prev) => {
            if (prev[id]) {
                const { [id]: removedId, ...newState } = prev;
                return newState;
            } else {
                return { ...prev, [id]: id };
            }
        });

    };


    // Functional Components

    const calendar = () => {
        return (
            <CustomPagination>

                <IconButton onClick={() => moveClick('prev')} disabled={firstIndex === 0}>
                    <ArrowBackIosNewOutlinedIcon sx={{ fontSize: "17px" }} />
                </IconButton>

                <Box display={"flex"} flexDirection={"row"}>
                    {
                        first90Days.slice(firstIndex, lastIndex).map((date, index) => (
                            <DateBox
                                key={index}
                                onClick={() => handleSelectedDate(date?.date, index)}
                                sx={{
                                    backgroundColor: dayjs(date?.date).format('DD-MM-YYYY') === selectedDate ? "#8fb569" : "white",
                                    color: dayjs(date?.date).format('DD-MM-YYYY') === selectedDate ? "white" : "black",

                                }}
                            >
                                <Typography fontSize={"10px"} fontWeight={"bold"}>
                                    {getShortMonthName(date?.date.getMonth())}
                                </Typography>
                                <Typography fontWeight={"bold"} fontSize={"20px"}>
                                    {date?.date.getDate()}
                                </Typography>
                                <Typography fontSize={"10px"} fontWeight={"bold"}>
                                    {getShortDayName(date?.date.getDay())}
                                </Typography>

                            </DateBox>
                        ))
                    }
                </Box>
                <IconButton onClick={() => moveClick('next')}
                    disabled={lastIndex >= first90Days.length}>
                    <ArrowForwardIosOutlinedIcon sx={{ fontSize: "17px" }} />
                </IconButton>
            </CustomPagination>
        )
    }

    const staffList = (slote) => {
        return (
            <List >
                <ListItemButton onClick={() => handleNotes(slote?.id)}>
                    <ListItem secondaryAction={<ExpandMore
                        expand={id[slote?._id]}

                        aria-expanded={id[slote?._id]}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </ExpandMore>}>
                        <Stack display={"flex"} flexDirection={"row"} alignItems={'start'} gap={2}>
                            <Avatar src={slote?.customerId?.avatar_path}>
                                {getInitials(slote?.customerId?.first_name)}
                            </Avatar>
                            <Stack flexDirection={"column"}>
                                <Typography variant='subtitle1'>{slote?.customerId?.first_name}{" "}{slote?.customerId?.last_name}</Typography>
                                <Stack flexDirection={"row"} alignItems={"center"} flexWrap={"wrap"} gap={1}>
                                    {
                                        slote?.slots.map((sloteTime) => (
                                            <Typography variant='subtitle2' sx={{ color: "#909090" }}>{`(${sloteTime}),`}</Typography>
                                        ))
                                    }
                                </Stack>
                            </Stack>
                        </Stack>
                    </ListItem>
                </ListItemButton>

            </List>
        )
    }

    const notes = (slote) => {
        return (
            <Grid item md={12} xs={12} mx={4}>
                {
                    slote?.notes ?
                        <Box sx={{ width: "100%", overflow: 'auto', }}>
                            <Typography variant='subtitle2' fontWeight={"bold"} my={1} textTransform={'uppercase'}>Additional Notes</Typography>
                            <Stack flexDirection={"row"} alignItems={"center"} gap={1}>

                                <Typography sx={{ whiteSpace: 'pre-wrap' }}>{slote?.notes}</Typography>
                            </Stack>
                        </Box> :
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Typography variant='subtitle2' fontWeight={"bold"} my={1} textTransform={'uppercase'}>Empty Notes</Typography>
                        </Box>
                }
            </Grid>
        )
    }

    return (
        <>
            {
                tokenExpOpen ? <ExpirePopup tokenExpireMsg={tokenExpireMsg} /> :
                    <Paper sx={{ padding: "15px", margin: !isMargin && "20px 0" }}>
                        <h4 className='edit-word' style={{ textAlign: "center", textTransform: "uppercase", margin: "20px 0" }}>Available Staffs</h4>
                        {calendar()}
                        {
                            slotLoading ? <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"center"} my={2}>
                                <CircularProgress />
                            </Stack> :
                                slotes?.userAvailability?.[formattedDate]?.length === 0 ?
                                    <Typography sx={{ m: 3, textAlign: "center", fontWeight: "bold" }}>Empty Staffs</Typography>
                                    :
                                    <Grid container spacing={1} my={2}>
                                        {
                                            slotes?.userAvailability?.[formattedDate].map((slote) => (
                                                <Grid item md={12}>
                                                    {staffList(slote)}
                                                    {
                                                        id[slote?._id] &&
                                                        notes(slote)
                                                    }
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                        }
                    </Paper>
            }
        </>
    )
}
export default AvailabilityCalender