import styled from '@emotion/styled';
import { CalendarMonth, Cancel, CheckCircle, HighlightOff, ThumbDown, ThumbUp, ThumbUpAlt, ThumbUpOutlined, Visibility } from '@mui/icons-material';
import { Avatar, Badge, Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogTitle, IconButton, Paper, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import "assets/css/admin/request-listing.css"
import dataProvider from 'providers/dataProvider';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ExpirePopup from 'components/common/ExpirePopup';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';



const ShiftViewBlock = ({ shiftId }) => {

    let token = localStorage.getItem('token');

    useEffect(() => {
        fetchRequestData()
    }, [shiftId])


    const [flag, setFlag] = useState(false)
    const [fetchLoading, setFetchLoading] = useState(false)
    const navigate = useNavigate()

    const [tokenExpireMsg, setTokenExpireMsg] = useState("")
    const [tokenExpOpen, setTokenExpOpen] = useState(false)


    const fetchRequestData = () => {

        setFetchLoading(true)
        dataProvider.getList('shift/single/request/' + shiftId, {
            params: {
                token: token,
                pagination: {
                    page: 1,
                    perPage: 1000
                },
                sort: { order: 'desc', field: '_id' },
                filter: {}
            }
        }).then(({ data }) => {
            setFlag(data)
            setFetchLoading(false)
        }).catch((err) => {
            setFetchLoading(false)
            if (err?.response?.data?.expire === true) {
                setTokenExpireMsg(err?.response?.data?.message)
                setTokenExpOpen(true)
            }
        })
    }

    return (
        <>
            {
                tokenExpOpen ? <ExpirePopup tokenExpireMsg={tokenExpireMsg} /> :
                    <Tooltip title='Requests'>
                        <IconButton onClick={() => navigate(`/shift/${shiftId}`)}>
                            <Badge badgeContent={<span style={{ color: 'white' }}>
                                {fetchLoading ? <CircularProgress size={8} sx={{ color: "white" }} /> : flag.length}</span>}
                                color="primary">
                                <CalendarMonth sx={{ color: '#0066b2' }} />
                            </Badge>

                        </IconButton>
                    </Tooltip>
            }
        </>
    )
}




const AcceptedRequest = ({ token }) => {

    let loggedUserId = localStorage.getItem('userId');


    const [assigningId, setAssigningId] = useState({
        customerId: "",
        clientId: "",
        shiftId: ""
    })

    const [acceptedRequest, setAcceptedShift] = useState([])
    const [openAssign, setOpenAssign] = useState(false)
    const [requestLoading, setRequestLoading] = useState(false)
    const [buttonLoading, setButtonLoading] = useState(false)

    const naviagate = useNavigate()

    useEffect(() => {
        fetchClientAcceptedShift()
    }, [])

    const StatusChip = styled(Chip)(({ theme }) => ({
        width: "100px",
        height: "25px",
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

    }))


    const fetchClientAcceptedShift = () => {
        setRequestLoading(true)
        dataProvider.getList('shift/request', {
            params: {
                token: token,
                pagination: { page: 1, perPage: 1000 },
                sort: { order: 'desc', field: 'id' },
                filter: {
                    clientId: loggedUserId,
                    status: "accept"
                }
            }
        })
            .then(({ data }) => {
                // console.log(data);
                setAcceptedShift(data)
                setRequestLoading(false)

            })
            .catch(error => {
                console.log(error?.response?.data)
                console.log(error?.response?.data?.expire)
                if (error?.response?.data?.expire === true) {
                    //   setTokenExpireMsg(error?.response?.data?.message)
                    //   setTokenExpOpen(true)
                }
                setRequestLoading(false)
            })
    }




    const handleOpenAssign = (clientId, customerId, shiftId) => {
        setAssigningId((prevState) => ({
            ...prevState,
            clientId: clientId,
            customerId: customerId,
            shiftId: shiftId

        }))
        setOpenAssign(true)
    }


    const handleCloseAssign = () => {
        setAssigningId((prevState) => ({
            ...prevState,
            clientId: "",
            customerId: "",
            shiftId: ""

        }))
        setOpenAssign(false)
    }

    const handleAssignShifttoCustomer = () => {

        const { clientId, customerId, shiftId } = assigningId


        const data = {
            'clientId': clientId,
            'shiftId': shiftId,
            'customerId': customerId
        }

        // console.log(data);

        setButtonLoading(true)
        dataProvider.create('shift/admin/approve', data).then((data) => {
            // console.log(data);
            setButtonLoading(false)
            setOpenAssign(false)
            fetchClientAcceptedShift()
        }).catch((e) => {
            console.log(e)
            setButtonLoading(false)
        })

    }




    return (
        <>
            {
                requestLoading ? <Stack flexDirection={"row"} justifyContent={"center"} alignItems={"center"}>
                    <CircularProgress color="primary" size={45} />
                </Stack>
                    :
                    acceptedRequest.length === 0 ||
                        acceptedRequest.filter(request => request?.shiftId !== null && request?.shiftId?.shift_status === 'pending').length === 0 ?
                        <>
                            <Typography variant='h6' m={1}>Accepted Shifts</Typography>
                            <Typography sx={{ m: 3, textAlign: "center" }}>Empty Shifts</Typography>
                        </>
                        :
                        <>
                            <Typography variant='h6' m={1}>Accepted Shifts</Typography>
                            <TableContainer sx={{ margin: "10px 0" }}>
                                <Table sx={{ minWidth: 650, '& th, & td': { padding: '10px' } }} size="small" aria-label="a dense table">
                                    <TableHead >
                                        <TableRow>
                                            <TableCell>
                                                <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                    Shift
                                                </Typography>
                                            </TableCell>
                                            <TableCell >
                                                <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                    Staff
                                                </Typography>
                                            </TableCell>

                                            <TableCell>
                                                <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                    Employer
                                                </Typography>
                                            </TableCell>

                                            <TableCell >
                                                <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                    Status
                                                </Typography>
                                            </TableCell>

                                            <TableCell >
                                                <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                    Actions
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                             acceptedRequest.filter(request => request?.shiftId !== null && request?.shiftId?.shift_status === 'pending').slice(0, 10).map((request) => (
                                                // request?.shiftId !== null && request?.shiftId?.shift_status === 'pending' &&
                                                < TableRow
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        <Typography sx={{ fontSize: '1rem', fontWeight: '600' }}>{request?.shiftId?.title}</Typography>
                                                    </TableCell>
                                                    <TableCell >
                                                        <div className="profile-image-table">
                                                            <Avatar src={request?.customerId?.avatar_path} />
                                                            <Typography sx={{ fontSize: '1rem', fontWeight: '600' }}>{request?.customerId?.first_name}{" "}{request?.customerId?.last_name}</Typography>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <div className="profile-image-table">
                                                            <Avatar src={request?.clientId?.avatar_path} />
                                                            <Typography sx={{ fontSize: '1rem', fontWeight: '600' }}>{request?.clientId?.first_name}{" "}{request?.clientId?.last_name}</Typography>
                                                        </div>
                                                    </TableCell>


                                                    <TableCell >
                                                        <Stack flexDirection={'row'} alignItems={'center'} gap={1}>
                                                            <StatusChip label='Accepted' sx={{ backgroundColor: "#FFC72C" }}>
                                                            </StatusChip>

                                                        </Stack>
                                                    </TableCell>

                                                    <TableCell >
                                                        {/* <Stack flexDirection={'row'} alignItems={'center'} gap={1}>
                                                            <Button variant='contained' onClick={() => handleOpenAssign(shift?.clientId?._id, shift?.customerId._id, shift?.shiftId._id)} size='small' sx={{ color: "white", display: "flex", alignItems: "center", justifyContent: "center", gap: "5px" }}>
                                                                <span>Confirm</span>
                                                                <FontAwesomeIcon icon={faThumbsUp} />
                                                            </Button>
                                                        </Stack> */}
                                                        {/* <ShiftConfirmBlock handleOpenAssign={handleOpenAssign} shift={request} /> */}
                                                        <ShiftViewBlock shiftId={request?.shiftId?._id} />
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }


                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Stack flexDirection={"row"} justifyContent={"end"} m={2} >
                                <Button variant='contained' size='small' sx={{ color: "white" }} onClick={() => naviagate("/shift")}>View all</Button>
                            </Stack>
                        </>
            }


            {/* <Dialog open={openAssign} onClose={() => handleCloseAssign()} fullWidth>
                <DialogTitle>Are you want to confirm ?</DialogTitle>

                <DialogActions>
                    <Button onClick={() => handleCloseAssign()}>Cancel</Button>
                    <Button onClick={() => handleAssignShifttoCustomer()}
                        disabled={buttonLoading}
                    >
                        {
                            buttonLoading ? <CircularProgress size={15} color='primary' /> : 'Assign'
                        }
                    </Button>
                </DialogActions>
            </Dialog> */}


        </>
    )
}

export default AcceptedRequest