import styled from '@emotion/styled';
import { Delete, Edit } from '@mui/icons-material';
import { Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Snackbar, Stack, TextField, Tooltip, Typography, buttonBaseClasses } from '@mui/material'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import dataProvider from 'providers/dataProvider';
import React, { useEffect, useState } from 'react'
import { useAdminContextProvider } from 'providers/adminContextProvider';
import ExpirePopup from 'components/common/ExpirePopup';

const StaffType = () => {

    const { setNetworkErr } = useAdminContextProvider()
    let token = localStorage.getItem('token');


    useEffect(() => {
        fetchStaffType()
    }, [])

    const [modalOpen, setModalOpen] = useState({
        create: false,
        edit: false,
        delete: false
    })

    const [staffTypeData, setStaffTypeData] = useState([])

    const [snackbar, setSnackbar] = useState(false)
    const [buttonLoding, setButtonLoading] = useState(false)
    const [typeLoading, setTypeLoading] = useState(false)

    const [staffType, setStaffType] = useState("")

    const [editData, setEditData] = useState({
        id: "",
        staffType: ""
    })

    const [deleteData, setDeleteData] = useState({
        id: "",
        staffType: ""
    })

    const [tokenExpireMsg, setTokenExpireMsg] = useState("")
    const [tokenExpOpen, setTokenExpOpen] = useState(false)

    const SlotBox = styled(Box)(({ theme }) => ({
        margin: "20px 10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    }));




    const fetchStaffType = () => {
        setTypeLoading(true)
        dataProvider.getList('staff_type', {
            params: {
                token: token,
                pagination: { page: 1, perPage: 1000 },
                sort: { order: 'desc', field: 'id' },
                filter: {}
            }
        })
            .then(({ data }) => {
                // console.log(data)
                setStaffTypeData(data)
                setTypeLoading(false)
            })
            .catch(error => {
                setTypeLoading(false)

                if (error.message === 'Network Error') {
                    setNetworkErr(true)
                }

                // console.log(error?.response?.data)
                // console.log(error?.response?.data?.expire)
                if (error?.response?.data?.expire === true) {
                    setTokenExpireMsg(error?.response?.data?.message)
                    setTokenExpOpen(true)
                }
            })
    }


    const handleCreateStaffType = () => {
        if (staffType === '' || staffType === undefined) {
            setSnackbar(true)
        } else {

            let data = {
                'type': staffType
            }

            setButtonLoading(true)
            dataProvider.create('staff_type', data).then((data) => {
                console.log(data);
                setModalOpen((prevState) => ({ ...prevState, create: false }))
                fetchStaffType()
                setButtonLoading(false)
            }).catch((e) => {
                console.log(e)
                setButtonLoading(false)
            })
        }
    }


    //Edit
    const handleEditOpen = (id, type) => {
        setEditData((prev) => ({ ...prev, id: id, staffType: type }))
        setModalOpen((prevState) => ({ ...prevState, edit: true }))
    }

    const handleEditClose = () => {
        setEditData((prev) => ({ ...prev, id: "", staffType: "" }))
        setModalOpen((prevState) => ({ ...prevState, edit: false }))
    }

    const handleEditConfirm = () => {

        if (editData.staffType === '') {
            setSnackbar(true)
        } else {


            let data = {
                'id': editData.id,
                'type': editData.staffType
            }
            setButtonLoading(true)
            dataProvider.update('staff_type', data).then((data) => {
                console.log(data)
                setModalOpen((prevState) => ({ ...prevState, edit: false }))
                fetchStaffType()
                setButtonLoading(false)
            }).catch((e) => {
                console.log(e)
                setButtonLoading(false)
            })
        }



    }


    //Delete
    const handleDeleteOpen = (id, type) => {
        setDeleteData((prev) => ({ ...prev, id: id, staffType: type }))
        setModalOpen((prevState) => ({ ...prevState, delete: true }))
    }

    const handleDeleteClose = () => {
        setDeleteData((prev) => ({ ...prev, id: "", staffType: "" }))
        setModalOpen((prevState) => ({ ...prevState, delete: false }))
    }

    const handleConfirmDelete = () => {

        let data = {
            'id': deleteData.id,
        }

        setButtonLoading(true)
        dataProvider.delete('staff_type', data).then((data) => {
            setDeleteData((prev) => ({ ...prev, id: "", staffType: "" }))
            setModalOpen((prevState) => ({ ...prevState, delete: false }))
            fetchStaffType()
            setButtonLoading(false)
        }).catch((e) => {
            console.log(e)
            setButtonLoading(false)
        })
    }


    return (
        <>
            {
                tokenExpOpen ? <ExpirePopup tokenExpireMsg={tokenExpireMsg}  /> :
                    <Paper sx={{ marginTop: "20px" }}>
                        <Box p={2}>
                            <SlotBox>
                                <Typography variant='h6' py={2} textTransform={'uppercase'} >Staff Type listing</Typography>

                                <Tooltip title='Create Staff'>
                                    <Button variant='contained'
                                        sx={{ color: "white", backgroundImage: "linear-gradient(98deg, #9cc177, #6ba134 94%)" }}
                                        onClick={() => setModalOpen((prevState) => ({ ...prevState, create: true }))}
                                    >Create</Button>
                                </Tooltip>
                            </SlotBox>
                            {
                                typeLoading ? <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"center"}>
                                    <CircularProgress color="primary" size={45} />
                                </Stack> :
                                    staffTypeData.length === 0 ?
                                        <Typography sx={{ m: 3, textAlign: "center" }}>Empty Staff Type</Typography>
                                        :
                                        <TableContainer>
                                            <Table sx={{ minWidth: 650, '& th, & td': { padding: '10px' } }} size="small" aria-label="a dense table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell sx={{ width: "80%" }}>
                                                            <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                                Staff Type
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell sx={{ width: "20%" }}>
                                                            <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                                Action
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {

                                                        staffTypeData.map((staffType) => (
                                                            <>
                                                                <TableRow
                                                                    key={staffType._id}
                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                >

                                                                    <TableCell ><Typography sx={{ fontSize: '1rem' }}>{staffType?.type}</Typography></TableCell>
                                                                    <TableCell>
                                                                        <Tooltip title='Edit'>
                                                                            <IconButton onClick={() => handleEditOpen(staffType?._id, staffType?.type)} >
                                                                                <Edit sx={{ color: '#0066b2' }} />
                                                                            </IconButton>
                                                                        </Tooltip>

                                                                        <Tooltip title='Delete'>
                                                                            <IconButton onClick={() => handleDeleteOpen(staffType?._id, staffType?.type)} >
                                                                                <Delete sx={{ color: 'red' }} />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </TableCell>
                                                                </TableRow>

                                                            </>
                                                        ))
                                                    }

                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                            }


                        </Box>
                    </Paper>
            }



            {/* Create */}
            <Dialog open={modalOpen.create} onClose={() => setModalOpen((prevState) => ({ ...prevState, create: false }))} fullWidth>
                <DialogTitle textTransform={'uppercase'}>Create Staff Type</DialogTitle>
                <DialogContent>

                    <TextField
                        margin="dense"
                        label="Staff Type"
                        type="text"
                        fullWidth
                        variant="outlined"
                        name="staff_type"
                        onChange={(e) => setStaffType(e.target.value)}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setModalOpen((prevState) => ({ ...prevState, create: false }))}>Cancel</Button>
                    <Button disabled={buttonLoding} onClick={() => handleCreateStaffType()}>
                        {
                            buttonLoding ? <CircularProgress size={15} color='primary' /> : 'Create'
                        }
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Edit */}
            <Dialog open={modalOpen.edit} onClose={() => handleEditClose()} fullWidth>
                <DialogTitle textTransform={'uppercase'}>Edit Staff Type</DialogTitle>
                <DialogContent>

                    <TextField
                        margin="dense"
                        label="Staff Type"
                        type="text"
                        fullWidth
                        variant="outlined"
                        name="staff_type"
                        onChange={(event) => setEditData((prev) => ({ ...prev, staffType: event.target.value }))}
                        value={editData.staffType}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleEditClose()}>Cancel</Button>
                    <Button disabled={buttonLoding} onClick={() => handleEditConfirm()}>
                        {
                            buttonLoding ? <CircularProgress size={15} color='primary' /> : 'Edit'
                        }
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Delete */}
            <Dialog
                open={modalOpen.delete}
                onClose={() => handleDeleteClose()}
            >
                <DialogTitle >
                    {`Are you sure  want to delete  ${deleteData.staffType}?`}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => handleDeleteClose()}>Cancel</Button>
                    <Button onClick={() => handleConfirmDelete()} color='error' disabled={buttonLoding}>
                        {
                            buttonLoding ? <CircularProgress size={15} color='error' /> : 'Delete'
                        }
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Error Snack bar */}
            <Snackbar open={snackbar}

                autoHideDuration={6000}
                onClose={() => setSnackbar(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={() => setSnackbar(false)} variant='filled' severity="error" sx={{ width: '100%' }}>
                    {'Please fill staff type'}
                </Alert>
            </Snackbar>



        </>
    )
}

export default StaffType