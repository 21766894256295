import { Alert, Button, CircularProgress, FormControl, FormHelperText, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Snackbar, Stack, TextField, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import "assets/css/admin/shift.css"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ArrowBackOutlined } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { DatePicker, DesktopDatePicker, DesktopTimePicker, TimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import dataProvider from 'providers/dataProvider';
import { useEffect } from 'react';
import ExpirePopup from 'components/common/ExpirePopup';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';



const CreateShift = () => {

  let token = localStorage.getItem('token');



  const { userid } = useParams();

  const navigate = useNavigate()


  const [shiftDetails, setShiftDetails] = useState({
    title: "",
    locationId: "",
    areaId: "",
    description: "",
    hospitalName: "",
    ward: "",
    costCenter: "",
    status: 1
  })



  const [date, setDate] = useState("")
 

  const [addedTimeFrom, setAddedTimeFrom] = useState("")
  const [addedTimeTo, setAddedTimeTo] = useState("")
  
  const [timeFrom, setTimeFrom] = useState("")
  const [timeTo, setTimeTo] = useState("")
  const [timeFromOrigin, setTimeFromOrgin] = useState("")
  const [timeToOrigin, setTimeToOrgin] = useState(dayjs().startOf('day').format('hh:mm A'));

  const [selectedLocationId, setSelectedLocationId] = useState(null)

  const [shiftNullAlert, setShiftNullAlert] = useState(false)
  const [error, setError] = useState('')

  const [locationLoading, setLocationLoading] = useState(false)
  const [areaLoading, setAreaLoading] = useState(false)
  const [buttonloading, setButtonLoading] = useState(false)

  const [clientFetchLoading, setClientFetchLoading] = useState(false)


  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)

  const [isTimePickerOpenFrom, setIsTimePickerOpenFrom] = useState(false)
  const [isTimePickerOpenTo, setIsTimePickerOpenTo] = useState(false)

  const [location, setLocation] = useState([])
  const [area, setArea] = useState([])


  const [tokenExpireMsg, setTokenExpireMsg] = useState("")
  const [tokenExpOpen, setTokenExpOpen] = useState(false)


  const [staffRole, setStaffRole] = useState("")
  const [roleErr, setRoleErr] = useState(false)

  const [staffTypeData, setStaffTypeData] = useState([])
  const [typeLoading, setTypeLoading] = useState(false)

  const [addDateFrom, setaddDateFrom] = useState(null)
  const [addDateTo, setaddDateTo] = useState(null)


  useEffect(() => {
    fetchAreaData()
    fetchLocationData()
    fetchClientData()
    fetchStaffType()
  }, [selectedLocationId])


  const fetchAreaData = () => {

    setAreaLoading(true)
    dataProvider.getList('areas',
      {
        params: {
          token: token,
          pagination: {
            page: 1,
            perPage: 1000
          },
          sort: { order: 'desc', field: 'id' },
          filter: { locationId: selectedLocationId }
        }
      })
      .then(({ data }) => {
        setArea(data)
        setAreaLoading(false)
      })
      .catch(error => {
        setAreaLoading(false)
        // console.log(error)
        // console.log(error?.response?.data)
        // console.log(error?.response?.data?.expire)
        if (error?.response?.data?.expire === true) {
          setTokenExpireMsg(error?.response?.data?.message)
          setTokenExpOpen(true)
        }
      })
  }


  const fetchLocationData = () => {
    setLocationLoading(true)
    dataProvider.getList('locations',
      {
        params: {
          token: token,
          pagination: {
            page: 1,
            perPage: 1000
          },
          sort: { order: 'desc', field: 'id' },
          filter: {}
        }
      })
      .then(({ data }) => {
        setLocation(data)
        setLocationLoading(false)
      })
      .catch(error => {
        setLocationLoading(false)
        // console.log(error?.response?.data)
        // console.log(error?.response?.data?.expire)
        if (error?.response?.data?.expire === true) {
          setTokenExpireMsg(error?.response?.data?.message)
          setTokenExpOpen(true)
        }
      })
  }



  const fetchClientData = () => {
    setClientFetchLoading(true)
    dataProvider.getOne('users/employers', { id: userid }).then(({ data }) => {
      setShiftDetails((prevState) => ({
        ...prevState,
        hospitalName: data?.hospital_name,
        costCenter: data?.manager_name
      }))


      setClientFetchLoading(false)
    }).catch((err) => {
      // console.log(err?.response?.data?.message)
      if (err?.response?.data?.message) {
        navigate("*")
      }
      setClientFetchLoading(false)
    })
  }


  const fetchStaffType = () => {
    setTypeLoading(true)
    dataProvider.getList('staff_type', {
      params: {
        token: token,
        pagination: { page: 1, perPage: 1000 },
        sort: { order: 'desc', field: 'id' },
        filter: {}
      }
    })
      .then(({ data }) => {
        // console.log(data)
        setStaffTypeData(data)
        setTypeLoading(false)
      })
      .catch(error => {
        setTypeLoading(false)
        console.log(error?.response?.data)
        console.log(error?.response?.data?.expire)
        // if (error?.response?.data?.expire === true) {
        //     setTokenExpireMsg(error?.response?.data?.message)
        //     setTokenExpOpen(true)
        // }
      })
  }






  const handleChange = (e) => {
    const { name, value } = e.target;
    setShiftDetails((prevState) => ({
      ...prevState,
      [name]: value
    }))

    if (name === 'locationId') {
      setSelectedLocationId(value)
    }
  }


  const handleDate = (newDate) => {

    // Format for '2023-12-08T04:43:23.054Z'
    const formattedDateISOString = dayjs(newDate).toISOString();
    // Format for '2023-12-08'
    const formattedDate = dayjs(newDate).format('YYYY-MM-DD');
    setDate(formattedDate);

    // setIsDatePickerOpen(false)
  }


  const handleFromTime = (time) => {
    const formattedTime = dayjs(time).format('hh:mm A');
    const twentyFourFormat = dayjs(time).format('HH:mm')

    setAddedTimeFrom(`T${twentyFourFormat}:00.000Z`)
    setTimeFrom(formattedTime)
    // setTimeFromOrgin(time)
    setaddDateFrom(time)

  }

  const handleToTime = (time) => {
    const formattedTime = dayjs(time).format('hh:mm A');
    const twentyFourFormat = dayjs(time).format('HH:mm')

    setAddedTimeTo(`T${twentyFourFormat}:00.000Z`)
    // setTimeToOrgin(time)
    setTimeTo(formattedTime)
    setaddDateTo(time)
  }



  const handleRoleChange = (e) => {
    const { name, value } = e.target;
    setStaffRole(value)
  }





  const handleShiftSubmit = () => {

    const { title, areaId, locationId, status, description, costCenter, hospitalName, ward } = shiftDetails

    if (title === '' ||
      description === '' ||
      areaId === "" || areaId === null ||
      locationId === "" || locationId === null ||
      date === "" || date === null ||
      timeFrom === "" || timeFrom === null || timeFrom === 'Invalid Date' ||
      timeTo === "" || timeTo === null || timeTo === 'Invalid Date' ||
      staffRole === '' || staffRole === null ||
      costCenter === '' || costCenter === null ||
      hospitalName === '' || hospitalName === null || !hospitalName ||
      ward === '' || ward === null
    ) {
      setShiftNullAlert(true)
      setError("Please fill all fields")
    } else {


      let updatedDate;
      if (addDateTo <= addDateFrom) {
        updatedDate = dayjs(date).add(1, 'day').format('YYYY-MM-DD');
      }



      const data = {
        'title': title,
        'clientId': userid,
        'locationId': locationId,
        'areaId': areaId,
        'date': `${date}${addedTimeFrom}`,
        'date_to': `${updatedDate ? updatedDate : date}${addedTimeTo}`,
        'time_from': timeFrom,
        'time_to': timeTo,
        'description': description,
        'status': status,
        'shift_type': staffRole,
        //
        'hospital_name': hospitalName,
        'floor': costCenter,
        'ward': ward,
        'type': 'admin',
        'direct': 1
      }

      // console.log(data)

      setButtonLoading(true)
      // setButtonLoading(false)
      dataProvider.create('shift', data).then((data) => {
        setButtonLoading(false)
        navigate(`/employer/edit/${userid}`)
      }).catch((e) => {
        setButtonLoading(false)
        // console.log(e)
        // console.log(e?.response?.data?.message);
        if (e?.response?.data?.message) {
          setShiftNullAlert(true)
          setError("An error occurred while creating the shift. Please try again.")
        }
      })
    }
  }






  return (
    <>

      {
        tokenExpOpen ? <ExpirePopup tokenExpireMsg={tokenExpireMsg} /> :
          <>
            <Grid container spacing={2} my={2}>
              <Grid item md={12}>
                <Paper sx={{ padding: "10px" }}>
                  <Tooltip title='Back'>
                    <IconButton onClick={() => navigate(`/employer/edit/${userid}`)} >
                      <ArrowBackOutlined />
                    </IconButton>
                  </Tooltip>

                  <Typography variant='h6' py={2} sx={{ textAlign: "center", textTransform: "uppercase" }}>Create Shift</Typography>

                  <Grid container spacing={2} >

                    {/* Title */}
                    <Grid item md={6} xs={12}>
                      <TextField id="outlined-basic"
                        label="Title"
                        variant="outlined"
                        fullWidth
                        name='title'
                        onChange={handleChange}
                      />
                    </Grid>

                    {/* Hospital Name */}
                    <Grid item md={6} xs={12}>
                      <TextField id="outlined-basic"
                        label="Hospital Name"
                        variant="outlined"
                        fullWidth
                        name='hospitalName'
                        // onChange={handleChange}
                        value={clientFetchLoading ? 'Loading' : shiftDetails.hospitalName === undefined ? 'Hospital name is not added' : shiftDetails.hospitalName}
                        disabled
                      />
                    </Grid>

                    {/* Ward */}
                    <Grid item md={6} xs={12}>
                      <TextField id="outlined-basic"
                        label="Ward"
                        variant="outlined"
                        fullWidth
                        name='ward'
                        onChange={handleChange}
                      />
                    </Grid>

                    {/* Cost center */}
                    <Grid item md={6} xs={12}>
                      <TextField id="outlined-basic"
                        label="Manger name"
                        variant="outlined"
                        fullWidth
                        name='costCenter'
                        onChange={handleChange}
                        disabled={clientFetchLoading}
                        value={clientFetchLoading ? 'Loading' : shiftDetails.costCenter === undefined ? '' : shiftDetails.costCenter}
                      />
                    </Grid>

                    {/* Location */}
                    <Grid item md={6} xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Location</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Location"
                          name='locationId'
                          value={shiftDetails.locationId}
                          onChange={handleChange}
                        >

                          {locationLoading ? (
                            // Render a loader while location data is being fetched
                            <MenuItem disabled>Loading...</MenuItem>
                          ) : (
                            // Render the location data once it is available
                            location.map((locationData) => (
                              locationData?.status === 1 &&
                              <MenuItem key={locationData?._id} value={locationData?._id}>
                                {locationData?.location}
                              </MenuItem>
                            ))
                          )}

                        </Select>
                      </FormControl>
                    </Grid>

                    {/* Area */}
                    <Grid item md={6} xs={12}>
                      <FormControl fullWidth>
                        <InputLabel >Area</InputLabel>
                        <Select
                          label="Area"
                          name='areaId'
                          value={shiftDetails.areaId}
                          onChange={handleChange}
                        >
                          {
                            areaLoading ? (
                              // Render a loader while location data is being fetched
                              <MenuItem disabled>Loading...</MenuItem>
                            ) :
                              (

                                area.length === 0 ?
                                  (<MenuItem disabled>Select a location</MenuItem>) :
                                  area.map((areaData) => (
                                    areaData?.status === 1 &&
                                    (<MenuItem key={areaData?._id} value={areaData?._id}>{areaData?.area}</MenuItem>)
                                  ))
                              )
                          }
                        </Select>
                      </FormControl>
                    </Grid>

                    {/* Status */}
                    <Grid item md={6} xs={12}>
                      <FormControl fullWidth>
                        <InputLabel >Status</InputLabel>
                        <Select

                          label="Status"
                          name='status'
                          onChange={handleChange}
                          value={shiftDetails.status}
                        >
                          <MenuItem value={1}>Active</MenuItem>
                          <MenuItem value={0}>Inactive</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    {/* Select Staff Type */}
                    <Grid item md={6} xs={12}>
                      <FormControl fullWidth error={roleErr} >
                        <InputLabel >Select  Staff Type</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={staffRole}
                          label="Select a Customer"
                          onChange={handleRoleChange}
                        >
                          {
                            typeLoading ? (

                              <MenuItem disabled>Loading...</MenuItem>
                            ) :
                              staffTypeData?.map((type) => (
                                <MenuItem value={type?.type}>{type?.type}</MenuItem>
                              ))
                          }

                        </Select>
                        <FormHelperText>{roleErr && 'Please Select a Role'}</FormHelperText>
                      </FormControl>
                    </Grid>

                    {/* Date */}
                    <Grid item md={12} xs={12}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        {/* <div onClick={handleFieldClick} style={{ position: 'relative', width: '100%' }}> */}
                        <DesktopDatePicker
                          open={isDatePickerOpen}
                          onClose={() => setIsDatePickerOpen(false)}
                          label="Date"
                          format="DD-MM-YYYY"
                          slotProps={{
                            textField: {
                              onClick: () => setIsDatePickerOpen(true),
                            },
                          }}
                          onChange={(e) => handleDate(e)}
                          disablePast
                          // renderInput={(params) => <input {...params} />}
                          // style={{ position: 'absolute', top: '100%', zIndex: 1 }}
                          sx={{ width: "100%" }}

                        />
                        {/* </div> */}
                      </LocalizationProvider>
                    </Grid>


                    {/* Time From */}
                    <Grid item md={6} xs={12}>
                      {/* <div onClick={handleTimeFiledClickFrom} style={{ position: 'relative', width: '100%' }}> */}
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopTimePicker
                          open={isTimePickerOpenFrom}
                          onClose={() => setIsTimePickerOpenFrom(false)}
                          label="Time From"
                          sx={{ width: "100%" }}
                          timeSteps={{ hours: 2, minutes: 30 }}
                          onChange={(e) => handleFromTime(e)}
                          shouldDisableTime={(value, timeView) => {
                            return timeTo === dayjs(value).format('hh:mm A')
                          }}
                          slotProps={{
                            textField: {
                              onClick: () => setIsTimePickerOpenFrom(true),
                            },
                          }}
                          disabled={date === ''}
                          style={{ position: 'absolute', top: '100%', zIndex: 1 }}
                        />
                      </LocalizationProvider>
                      {/* </div> */}
                    </Grid>

                    {/* Time To */}
                    <Grid item md={6} xs={12}>
                      {/* <div onClick={handleTimeFiledClickTo} style={{ position: 'relative', width: '100%' }}> */}
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopTimePicker
                          open={isTimePickerOpenTo}
                          onClose={() => setIsTimePickerOpenTo(false)}
                          label="Time To"
                          sx={{ width: "100%" }}
                          timeSteps={{ hours: 2, minutes: 30 }}
                          shouldDisableTime={(value, timeView) => {
                            return timeFrom === dayjs(value).format('hh:mm A')
                          }}
                          disabled={timeFrom === ''}
                          onChange={(e) => handleToTime(e)}
                          slotProps={{
                            textField: {
                              onClick: () => setIsTimePickerOpenTo(true),
                            },
                          }}
                          style={{ position: 'absolute', top: '100%', zIndex: 1 }}
                        />
                      </LocalizationProvider>
                      {/* </div> */}
                    </Grid>

                    {/* Description */}
                    <Grid item md={12} xs={12}>
                      <TextField
                        id="outlined-multiline-static"
                        label="Description"
                        multiline
                        rows={4}
                        fullWidth
                        placeholder='Description....'
                        name='description'
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>

                  <Stack flexDirection={'row'} justifyContent={"end"} my={2}>
                    <Button
                      variant="contained"
                      sx={{
                        color: 'white',
                        width: '150px',
                        height: '40px',
                        position: 'relative',
                      }}
                      onClick={() => handleShiftSubmit()}
                      disabled={buttonloading}
                    >
                      {buttonloading ? (
                        <CircularProgress
                          size={25}
                          sx={{
                            color: 'white',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12.5px',
                            marginLeft: '-12.5px',
                          }}
                        />
                      ) : (
                        'Save'
                      )}
                    </Button>
                  </Stack>


                </Paper>
              </Grid>
            </Grid>

            <Snackbar open={shiftNullAlert}
              autoHideDuration={6000}
              onClose={() => setShiftNullAlert(false)}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <Alert onClose={() => setShiftNullAlert(false)} severity="error" variant="filled" sx={{ width: '100%' }}>
                {error}
              </Alert>
            </Snackbar>
          </>
      }


    </>
  )
}

export default CreateShift