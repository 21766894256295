import React, { useEffect, useState } from 'react'
import 'assets/css/admin/edit-user.css'
import dataProvider from 'providers/dataProvider'
import { Alert, Box, Button, CircularProgress, Grid, IconButton, Paper, Snackbar, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CheckCircle, ContactMailOutlined, DoNotDisturb, Download, HandshakeOutlined, SendOutlined, ThumbUp, ThumbUpAltOutlined, Visibility } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faLock, faLockOpen, faPaperPlane, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Document, Page, pdfjs } from 'react-pdf';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { ArrowForwardIosOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
const CertificateTable = ({ userid }) => {

    pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
    let loggedUserId = localStorage.getItem('userId');

    const [certficateData, setCertficateData] = useState([])
    const [iscertificateModalOpen, setIsCertificateModalOpen] = useState(false)
    const [isDataLoading, setIsDataLoading] = useState(false)
    const [sendeRequestData, setSendRequestData] = useState({
        doc_id: "",
        customerId: ""
    })

    const [buttonLoading, setbuttonLoading] = useState(false)
    const [successSnackbar, setSuccessSnackBar] = useState(false)

    const [viewcertitificateModal, setCertificateViewModal] = useState(false)
    const [docDetails, setDocDetails] = useState({
        title: "",
        docPath: ""
    })

    const [pageNumber, setPageNumber] = useState(1)
    const [numPages, setNumPages] = useState(null);

    const [certificateLoading, setCertificateLoading] = useState({})

    const navigate = useNavigate()


    useEffect(() => {
        fetchcertficateData()
    }, [])

    const fetchcertficateData = () => {
        setIsDataLoading(true)
        dataProvider.getoneDoc('users/customers', { id: userid, cid: loggedUserId }).then(({ data }) => {
            setIsDataLoading(false)
            // console.log(data)
            setCertficateData(data?.docs)
        }).catch((err) => {
            setIsDataLoading(false)
            if (err?.response?.data?.message) {
                navigate("*")
            }
        })
    }

    const handleOpenCertificateModal = (doc_id, customerId) => {
        setIsCertificateModalOpen(true)
        setSendRequestData((prevState) => ({
            ...prevState,
            doc_id: doc_id,
            customerId: customerId
        }))
    }

    const handleCloseCertificateModal = () => {
        setIsCertificateModalOpen(false)
        setSendRequestData((prevState) => ({
            ...prevState,
            doc_id: "",
            customerId: ""
        }))
    }

    const handleConfirmRequest = () => {
        const { doc_id, customerId } = sendeRequestData

        const data = {
            'documentId': doc_id,
            'customerId': customerId,
            'clientId': loggedUserId,
            'status': 'request',
        }

        setbuttonLoading(true)
        dataProvider.create('documents/request', data).then((data) => {
            // console.log(data);
            setbuttonLoading(false)
            setIsCertificateModalOpen(false)
            setSuccessSnackBar(true)
            fetchcertficateData()
        }).catch((e) => {
            setbuttonLoading(false)
            // console.log(e)
            // console.log(e?.response?.data?.msg);
        })
    }

    const handleViewCertificateModal = (title, docPath) => {

        setDocDetails((prevState) => ({
            ...prevState,
            title: title,
            docPath: docPath
        }))
        setCertificateViewModal(true)
    }

    const handleCloseCertificateViewModal = () => {
        setDocDetails((prevState) => ({
            ...prevState,
            title: "",
            docPath: ""
        }))
        setPageNumber(1)
        setCertificateViewModal(false)
    }

    const handleLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);

    };

    const isPdf = (path) => {
        return path.toLowerCase().endsWith('.pdf');
    };


    const handleDownloadCertificate = (doc_path, id) => {
        let checkPdf = isPdf(doc_path)
        if (checkPdf) {
            downloadPdf(doc_path, id)
        } else {
            downloadImage(doc_path, id)
        }
    }


    const downloadImage = (imageUrl, id) => {

        const fileName = 'certificate.jpg';

        setCertificateLoading((prevState) => ({
            ...prevState,
            [id]: true
        }))
        fetch(imageUrl)
            .then(response => response.blob())
            .then(blob => {
                // Create a temporary link element
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);

                // Append the link to the body
                document.body.appendChild(link);

                // Programmatically click the link to trigger the download
                link.click();

                // Remove the link
                link.parentNode.removeChild(link);
                setCertificateLoading((prevState) => ({
                    ...prevState,
                    [id]: false
                }))
            })
            .catch(error => {
                console.error('Error downloading image:', error);
                setCertificateLoading((prevState) => ({
                    ...prevState,
                    [id]: false
                }))
            });
    }

    const downloadPdf = (pdfUrl, id) => {
        const fileName = 'certificate.pdf'; // Set the file name with .pdf extension

        setCertificateLoading((prevState) => ({
            ...prevState,
            [id]: true
        }))
        fetch(pdfUrl)
            .then(response => response.blob())
            .then(blob => {
                // Create a temporary link element
                const url = window.URL.createObjectURL(new Blob([blob], { type: 'application/pdf' })); // Set content type as application/pdf
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);

                // Append the link to the body
                document.body.appendChild(link);

                // Programmatically click the link to trigger the download
                link.click();

                // Remove the link
                link.parentNode.removeChild(link);
                setCertificateLoading((prevState) => ({
                    ...prevState,
                    [id]: false
                }))
            })
            .catch(error => {
                console.error('Error downloading image:', error);
                setCertificateLoading((prevState) => ({
                    ...prevState,
                    [id]: false
                }))
            });
    }



    return (
        <>

            {
                // isDataLoading ?
                //     <div className="loader-position">
                //         <CircularProgress color="primary" size={45} />
                //     </div>
                //     :
                //     certficateData.length === 0 ?
                //         <Paper  sx={{ padding: "10px" }}>
                //             <Typography sx={{ m: 3, textAlign: "center" }}>Empty Certificates</Typography>
                //         </Paper> :

                isDataLoading ?

                    <Stack py={2} flexDirection={"row"} justifyContent={"center"} alignItems={"center"}>
                        <CircularProgress color="primary" size={45} /></Stack>
                    :
                    certficateData.length === 0 ?

                        <Typography py={2} textAlign={"center"} fontWeight={"bold"}>Empty Certificates</Typography>
                        :
                        <Box p={2}>
                            {/* <h4 className='edit-word' style={{ textAlign: "center", textTransform: "uppercase" }}>Certificates</h4> */}
                            <Typography variant='h6' sx={{ textTransform: "uppercase", textAlign: "center" }}>Certificates</Typography>


                            <TableContainer sx={{ margin: "10px 0" }}>
                                <Table sx={{ minWidth: 650, '& th, & td': { padding: '10px' } }} size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell >
                                                <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                    Title
                                                </Typography>
                                            </TableCell>
                                            <TableCell >
                                                <Typography sx={{ fontSize: '1rem', }} fontWeight="bold">
                                                    Status
                                                </Typography>
                                            </TableCell>

                                            <TableCell >
                                                <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                    Actions
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            certficateData.map((certficate) => (
                                                <TableRow
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >

                                                    <TableCell ><Typography sx={{ fontSize: '1rem' }}>{certficate?.title}</Typography></TableCell>
                                                    <TableCell ><Typography sx={{ fontSize: '1rem' }}>

                                                        <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                                            {
                                                                certficate?.status === 1 ?
                                                                    <>
                                                                        <CheckCircle style={{ color: '#8fb569', width: "22px", height: "22px" }} />
                                                                        <Typography fontWeight='bold'>Active</Typography>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <DoNotDisturb style={{ color: 'red', width: "22px", height: "22px" }} />
                                                                        <Typography fontWeight='bold'>Inactive</Typography>
                                                                    </>
                                                            }
                                                        </Box>
                                                    </Typography></TableCell>
                                                    <TableCell >
                                                        <Stack flexDirection={"row"} alignItems={"center"} gap={1}>

                                                            {/* <p>{user?.request?.status}</p> */}
                                                            <Tooltip title='Send Request'>
                                                                <IconButton disabled={certficate?.request?.status}
                                                                    onClick={() => handleOpenCertificateModal(certficate?._id, certficate?.customerId)}>
                                                                    <FontAwesomeIcon
                                                                        icon={
                                                                            (certficate?.request?.status === "approve") ? faLockOpen :
                                                                                (certficate?.request?.status === "request") ? faSpinner :
                                                                                    (certficate?.request?.status === "reject") ? faLock : faLock
                                                                        }
                                                                        // spin={user?.request?.status === "request"}
                                                                        style={{
                                                                            color:
                                                                                (certficate?.request?.status) === "approve" ? ('#8fb569') :
                                                                                    (certficate?.request?.status) === "reject" ? ('#F40009') : ('#0066b2'),
                                                                            fontSize: '20px'
                                                                        }}
                                                                    />
                                                                </IconButton>
                                                            </Tooltip>

                                                            {
                                                                certficate?.request?.status === "approve" &&
                                                                <Tooltip title='View certificate'>
                                                                    <IconButton onClick={() => handleViewCertificateModal(certficate?.title, certficate?.doc_path)}>
                                                                        <Visibility style={{ color: '#0066b2' }} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            }

                                                            {
                                                                certificateLoading[certficate?._id] ?
                                                                    <CircularProgress color="primary" size={28} /> :
                                                                    certficate?.request?.status === "approve" &&
                                                                    <Tooltip title='Download'>
                                                                        <IconButton onClick={() => handleDownloadCertificate(certficate?.doc_path, certficate?._id)}>
                                                                            <Download style={{ color: '#0066b2' }} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                            }
                                                        </Stack>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>


            }

            {/* Send Request View Modal */}
            <Dialog
                open={iscertificateModalOpen}
                onClose={() => handleCloseCertificateModal()}
            >
                <DialogTitle >
                    {`ARE YOU SURE YOU WANT TO SEND THIS REQUEST ?`}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => handleCloseCertificateModal()}>Cancel</Button>
                    <Button onClick={() => handleConfirmRequest()} autoFocus color='primary' disabled={buttonLoading}>
                        {
                            buttonLoading ? <CircularProgress size={15} color='primary' /> : 'Send'
                        }
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Success Send Request Snackbar */}
            <Snackbar
                open={successSnackbar}
                autoHideDuration={6000}
                onClose={() => setSuccessSnackBar(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert
                    onClose={() => setSuccessSnackBar(false)}
                    // severity="success"
                    variant="filled"  // or "outlined", based on your preference
                    sx={{ width: '100%', color: "white", backgroundImage: "linear-gradient(98deg, #9cc177, #6ba134 94%)" }}
                >
                    Request sent successfully! 
                </Alert>
            </Snackbar>


            {/* Certificate View modal */}
            <Dialog
                open={viewcertitificateModal}
                onClose={() => handleCloseCertificateViewModal()}
                scroll='paper'
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                fullWidth
            >
                <DialogTitle id="scroll-dialog-title">Certificate{" "}{docDetails.title}</DialogTitle>
                <DialogContent >
                    <DialogContentText
                        id="scroll-dialog-description"
                        tabIndex={-1}
                    >
                        {
                            isPdf(docDetails.docPath) ? (
                                // <div >
                                //     <Document file={docDetails.docPath} onLoadSuccess={handleLoadSuccess} >
                                //         <Page
                                //             renderTextLayer={false}
                                //             renderAnnotationLayer={false}
                                //             customTextRenderer={false}
                                //             pageNumber={pageNumber} />
                                //     </Document>
                                // </div>

                                <Grid item md={12}>
                                    <Box sx={{
                                        padding: "10px",
                                        height: "500px",
                                        width: "100%",
                                        position: "relative",
                                        marginBottom: "10px",
                                    }}>



                                        {/* <embed src=
                                            {docDetails.docPath}
                                            width="100%"
                                            height="100%" /> */}


                                        <Document file={docDetails.docPath} onLoadSuccess={handleLoadSuccess} >
                                            <Page
                                                renderTextLayer={false}
                                                renderAnnotationLayer={false}
                                                customTextRenderer={false}
                                                pageNumber={pageNumber} />
                                        </Document>
                                    </Box>
                                </Grid>
                            ) : (
                                <img src={docDetails.docPath} alt="Certificate" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                            )
                        }

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {
                        isPdf(docDetails.docPath) && <Box >
                            <Tooltip title='Prev'>
                                <IconButton onClick={() => setPageNumber((prevPage) => prevPage - 1)} disabled={pageNumber <= 1}>
                                    <ArrowBackIosNewOutlinedIcon

                                    />
                                </IconButton>
                            </Tooltip>


                            <Button>{pageNumber}</Button>

                            <Tooltip title='Next'>
                                <IconButton onClick={() => setPageNumber((prevPage) => prevPage + 1)}
                                    disabled={pageNumber >= numPages}>
                                    <ArrowForwardIosOutlined
                                        fontSize='small'

                                    />
                                </IconButton>
                            </Tooltip >
                        </Box>
                    }

                    <Button onClick={() => handleCloseCertificateViewModal()}>Ok</Button>
                </DialogActions>
            </Dialog>

        </>
    )
}

export default CertificateTable