import React, { useState } from 'react'
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CircularProgress, Typography } from '@mui/material';
import { ThumbUp } from '@mui/icons-material';
import dataProvider from 'providers/dataProvider';
const RequestViewModal = ({ isRequestViewOpen, hanldeRequestViewClose, setRequestViewOpen, selectedDetails,fetchRequestData }) => {

  const { id, docPath } = selectedDetails
  const [buttonLoading, setButtonLoading] = useState(false)


  const hanleRequestConfirm = () => {

    let data = {
      'id': id,
      'status': 'approve'
    }

    setButtonLoading(true)
    dataProvider.update('documents/request', data).then((data) => {
      // console.log(data)
      setButtonLoading(false)
      setRequestViewOpen(false)
      fetchRequestData()
    }).catch((e) => {
      setButtonLoading(false)
      // console.log(e)
    })



  }


  return (
    <>
      <Dialog
        open={isRequestViewOpen}
        onClose={() => hanldeRequestViewClose()}
        scroll='paper'
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
      >
        <DialogTitle id="scroll-dialog-title">
          <Typography variant='h6'>ARE YOU SURE YOU WANT TO APPROVE THIS REQUEST?</Typography>

        </DialogTitle>
        {/* <DialogContent >
          <DialogContentText
            id="scroll-dialog-description"
            tabIndex={-1}
          >
          </DialogContentText>
        </DialogContent> */}
        <DialogActions>
          <Button onClick={() => hanldeRequestViewClose()}>Cancel</Button>
          <Button onClick={() => hanleRequestConfirm()} disabled={buttonLoading}>
            {
              buttonLoading ? <CircularProgress size={15} color='primary' /> : 'Approve'
            }
          </Button>
        </DialogActions>
      </Dialog>


    </>
  )
}

export default RequestViewModal