import React, { useEffect, useState } from 'react'
import "assets/css/admin/admin-user.css"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Avatar, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import dataProvider from 'providers/dataProvider';
import styled from '@emotion/styled';
import ExpirePopup from 'components/common/ExpirePopup';
import { useAdminContextProvider } from 'providers/adminContextProvider';
const AdminListing = () => {


    let token = localStorage.getItem('token');
    const { setNetworkErr } = useAdminContextProvider()

    const [isdeleteOpen, setIdDeleteOpen] = useState(false);
    const [isUserLoading, setIsUserLoading] = useState(false)
    const [userDetails, setUserDetails] = useState([])


    const [tokenExpireMsg, setTokenExpireMsg] = useState("")
    const [tokenExpOpen, setTokenExpOpen] = useState(false)


    useEffect(() => {
        fetchUsersData()
    }, []);

    const navigate = useNavigate()


    const handleClickOpen = () => {
        setIdDeleteOpen(true);
    };

    const handleClose = () => {
        setIdDeleteOpen(false);
    };

    const fetchUsersData = async () => {
        setIsUserLoading(true)
        dataProvider.getList('users', {
            params: {
                token: token,
                pagination: { page: 1, perPage: 20 },
                sort: { order: 'desc', field: 'id' },
                filter: { role: 'admin' }
            }
        })
            .then(({ data }) => {
                // console.log(data)
                setUserDetails(data)
                setIsUserLoading(false)
            })
            .catch(error => {
                setIsUserLoading(false)
                // console.log(error?.response?.data)
                // console.log(error?.response?.data?.expire)

                if (error.message === 'Network Error') {
                    setNetworkErr(true)
                }

                if (error?.response?.data?.expire === true) {
                    setTokenExpireMsg(error?.response?.data?.message)
                    setTokenExpOpen(true)
                }
            })
    };

    const getInitials = (name) => {
        return name ? name[0].toUpperCase() : '';
    }

    const SlotBox = styled(Box)(({ theme }) => ({
        margin: "20px 10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    }));


    return (
        <>
            {
                tokenExpOpen ? <ExpirePopup tokenExpireMsg={tokenExpireMsg} /> :
                    <Paper sx={{ marginTop: "20px" }}>
                        <Box p={2} >

                            <SlotBox>
                                <Typography variant='h6' py={2} textTransform={'uppercase'} >Admin listing</Typography>
                            </SlotBox>

                            {
                                isUserLoading ? <div className="loader-position">
                                    <CircularProgress color="primary" size={45} />
                                </div>
                                    :
                                    userDetails.length === 0 ?
                                        <Typography sx={{ m: 3, textAlign: "center" }}>Empty Admin</Typography>
                                        :
                                        <TableContainer>
                                            <Table sx={{ minWidth: 650, '& th, & td': { padding: '10px' } }} size="small" aria-label="a dense table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell >
                                                            <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                                Admin
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell >
                                                            <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                                Email
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell >
                                                            <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                                Phone Number
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell >
                                                            <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                                Role
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell >
                                                            <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                                Actions
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        userDetails.map((user) => (
                                                            <TableRow
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell component="th" scope="row">
                                                                    <div className="profile-image-table">
                                                                        <Avatar src={user?.avatar_path} >{getInitials(user?.first_name)}</Avatar>
                                                                        <Typography sx={{ fontSize: '1rem', fontWeight: '600' }}>
                                                                            {user?.first_name}
                                                                            {" "}
                                                                            {user?.last_name}
                                                                        </Typography>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell ><Typography sx={{ fontSize: '1rem' }}>{user.email}</Typography></TableCell>
                                                                <TableCell ><Typography sx={{ fontSize: '1rem' }}>{user.phone}</Typography></TableCell>
                                                                <TableCell ><Typography sx={{ fontSize: '1rem' }}>{user.role}</Typography></TableCell>
                                                                <TableCell >
                                                                    <div className="user-action-buttons">
                                                                        <Tooltip title='Edit'>
                                                                            <IconButton onClick={() => navigate(`/admin/${user._id}`)}>
                                                                                <EditIcon sx={{ color: '#0066b2' }} />
                                                                            </IconButton>
                                                                        </Tooltip>

                                                                    </div>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                            }


                        </Box>
                    </Paper>
            }
        </>
    )
}

export default AdminListing