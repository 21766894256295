
import AdminListing from "views/admin/admin/index";
import AreaList from "views/admin/area/index";
import ClientCreate from "views/admin/client/create";
import EditClient from "views/admin/client/view";
import ClientsList from "views/admin/client/index";
import Dashboard from "views/admin/dashboard/Dashboard";
import LocationList from "views/admin/location/index";
import RequestListing from "views/admin/document_request/index";
import CreateShift from "views/admin/shifts/create";
import ShiftListing from "views/admin/shifts/index";
import CreateUser from "views/admin/customer/create";
import EditUser from "views/admin/customer/view";
import Customers from "views/admin/customer/index";
import ShiftRequests from "views/admin/shifts/requests";
import EditAdmin from "views/admin/admin/view";
import ShiftEdit from "views/admin/shifts/edit";
import ErrorPage from "components/common/404";
import NotificationCreate from "views/admin/notification/create";
import Err500 from "components/common/500";
import NotificationListing from "views/admin/notification";
import AvailabilityCalender from "views/admin/available_staffs/availabilty";
import ShfitRequests from "views/admin/shift_requests/index";
import SideBarShiftCreate from "views/admin/shifts/create2";
import StaffType from "views/admin/staff_type";
import EmployerType from "views/admin/employer_type";
import Slider from "views/admin/slider/slider";
import Payments from "views/admin/payments";
import WithdrawalRequests from "views/admin/withdrawal_requests";





var routes = [
    {
        path: "/",
        name: "Dashboard",
        component: Dashboard,
        layout: "/admin",
        show: true
    },
    {
        path: "/employer",
        name: "Employer",
        icon: "places1",
        component: ClientsList,
        layout: "/admin",
        show: true
    },
    {
        path: "/employer/create",
        name: "ClientCreate",
        icon: "places1",
        component: ClientCreate,
        layout: "/admin",
        show: true
    },
    {
        path: "/employer/edit/:userid",
        name: "ClientCreate",
        icon: "places1",
        component: EditClient,
        layout: "/admin",
        show: true
    },
    {
        path: "/admin",
        name: "Admin",
        icon: "places1",
        component: AdminListing,
        layout: "/admin",
        show: true
    },
    {
        path: "/admin/:userid",
        name: "Admin",
        icon: "places1",
        component: EditAdmin,
        layout: "/admin",
        show: true
    },
    {
        path: "/staff",
        name: "Customer",
        icon: "places1",
        component: Customers,
        layout: "/admin",
        show: true
    },
    {
        path: "/staff_type",
        name: "Customer",
        icon: "places1",
        component: StaffType,
        layout: "/admin",
        show: true
    },
    {
        path: "/employer_type",
        name: "Customer",
        icon: "places1",
        component: EmployerType,
        layout: "/admin",
        show: true
    },
    {
        path: "/available",
        name: "Availble Staff",
        icon: "places1",
        component: AvailabilityCalender,
        layout: "/admin",
        show: true
    },
    {
        path: "/staff/create",
        name: "CreateUser",
        icon: "places1",
        component: CreateUser,
        layout: "/admin",
        show: true
    },
    {
        path: "/staff/:userid",
        name: "EditUser",
        icon: "places1",
        component: EditUser,
        layout: "/admin",
        show: true
    },
    {
        path: "/shift",
        name: "Shift",
        icon: "places1",
        component: ShiftListing,
        layout: "/admin",
        show: true
    },
    {
        path: "/recent_shifts",
        name: "Recent Shifts",
        icon: "places1",
        component: ShfitRequests,
        layout: "/admin",
        show: true
    },
    {
        path: "/shift/:shiftId",
        name: "Shift",
        icon: "places1",
        component: ShiftRequests,
        layout: "/admin",
        show: true
    },
    {
        path: "/shift/edit/:shiftId",
        name: "Shift",
        icon: "places1",
        component: ShiftEdit,
        layout: "/admin",
        show: true
    },
    {
        path: "/shift/create/:userid",
        name: "CreateShift",
        icon: "places1",
        component: CreateShift,
        layout: "/admin",
        show: true
    },
    {
        path: "/shift/create",
        name: "SideBarCreateShift",
        icon: "places1",
        component: SideBarShiftCreate,
        layout: "/admin",
        show: true
    },
    {
        path: "/payments",
        name: "Payments",
        icon: "places1",
        component: Payments,
        layout: "/admin",
        show: true
    },

    {
        path: "/location",
        name: "LocationList",
        icon: "places1",
        component: LocationList,
        layout: "/admin",
        show: true
    },
    {
        path: "/area",
        name: "Shift",
        icon: "places1",
        component: AreaList,
        layout: "/admin",
        show: true
    },
    {
        path: "/slider",
        name: "Slider",
        icon: "places1",
        component: Slider,
        layout: "/admin",
        show: true
    },
    {
        path: "/request",
        name: "Request",
        icon: "places1",
        component: RequestListing,
        layout: "/admin",
        show: true
    },
    {
        path: "/notification",
        name: "Notification",
        icon: "places1",
        component: NotificationListing,
        layout: "/admin",
        show: true
    },
    {
        path: "/notification/create",
        name: "Notification",
        icon: "places1",
        component: NotificationCreate,
        layout: "/admin",
        show: true
    },

    // {
    //     path: "/err500",
    //     name: "Notification",
    //     icon: "places1",
    //     component: Err500,
    //     layout: "/admin",
    //     show: true
    // },
    {
        path: "*",
        name: "ErrorPage",
        icon: "places1",
        component: ErrorPage,
        layout: "/admin",
        show: true
    },
]

export default routes;