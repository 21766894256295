import React, { useState } from 'react'
import 'assets/css/admin/admin-header.css'
import { Avatar, Divider, Drawer, Icon, IconButton, List, ListItemAvatar, ListItemIcon, ListItemText, Menu, MenuItem, Typography, useTheme } from '@mui/material'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Logout, PersonAdd, Settings, WbSunnyOutlined } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import authProvider from 'providers/authProvider';
import AdminSidebar from 'components/sidebar/AdminSidebar';
import MobileViewSideBar from 'components/sidebar/MobileViewSideBar';
import MobileViewClientSidebar from 'components/sidebar/MobileViewClientSidebar';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';

const ClientHeader = ({ setDark, dark }) => {


    let firstName = localStorage.getItem('firstName');
    let avatar_path = localStorage.getItem('avatar_path')
    let loggedUserId = localStorage.getItem('userId');

    const navigate = useNavigate()



    let role = localStorage.getItem('role');

    const [isOpenAccount, setIsOpenAccount] = useState(null);
    const [isOpenDrawer, setIsOpenDrawer] = useState(false);


    const handleOpenDrawer = () => {
        setIsOpenDrawer(true)
    }


    const getInitials = (name) => {
        return name ? name[0].toUpperCase() : '';
    }



    const handleOpenAccount = (event) => {
        setIsOpenAccount(event.currentTarget);
    };



    const handleGoProfile = () => {
        navigate(`/profile/${loggedUserId}`)
        setIsOpenAccount(null);
    };

    const handleCloseAccount = () => {
        setIsOpenAccount(null);
    };

    const handleLogout = () => {
        authProvider.logout().then((response) => {
            window.location.href = "/";
        }).catch((err) => {
            console.log(err)
        })
    }


    const StyledDrawer = styled(Drawer)(({ theme }) => ({
        '& .MuiDrawer-paper': {
            width: '50%',
            boxSizing: 'border-box',
            border: "none",
            backgroundColor: backgroundColor,
            overflowY: 'auto', // Hide scrollbar by default
            '&::-webkit-scrollbar': {
                width: '0', // Set the width of the scrollbar
            },

        },
    }));

    const theme = useTheme();
    const selectedColor = theme.palette.sidebarColor.selectedColor;
    const unselectedColor = theme.palette.sidebarColor.unselectedColor;
    const backgroundColor = theme.palette.backgroundColor.main;

    return (
        <>
            <header className='admin-header'>

                <div className="heder-right-items">
                    <IconButton onClick={() => handleOpenDrawer()}>
                        <MenuIcon />
                    </IconButton>
                </div>

                <div className="admin-header-items">

                    <IconButton onClick={() => setDark((prevState) => !prevState)}>
                        {
                            dark === false ? <WbSunnyOutlined sx={{ fontSize: '27px' }} /> : <DarkModeOutlinedIcon sx={{ fontSize: '27px' }} />
                        }
                    </IconButton>
                    {/* 
                    <IconButton>
                        <NotificationsNoneIcon sx={{ fontSize: '27px' }} />
                    </IconButton> */}


                    <IconButton
                        id="demo-positioned-button"
                        onClick={handleOpenAccount}
                    >
                        <Avatar alt="User" src={avatar_path} className='userImage-avatar'>{getInitials(firstName)}</Avatar>
                        <span className="active-online"> </span>
                    </IconButton>


                    <Menu
                        id="demo-positioned-menu"
                        anchorEl={isOpenAccount}
                        open={Boolean(isOpenAccount)}
                        onClose={handleCloseAccount}
                    >
                        <MenuItem onClick={handleCloseAccount}>

                            <ListItemAvatar>
                                <Avatar alt="User" src={avatar_path} className='userImage-avatars'>{getInitials(firstName)}</Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={firstName}
                                secondary={role === 'client' && 'Employer'}
                            />

                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={handleGoProfile} >
                            <ListItemIcon>
                                <PersonOutlinedIcon fontSize="small" />
                            </ListItemIcon>
                            <Typography>Profile</Typography>
                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={handleLogout}>
                            <ListItemIcon>
                                <Logout fontSize="small" />
                            </ListItemIcon>
                            <Typography>Logout</Typography>
                        </MenuItem>
                    </Menu>
                </div>

                <StyledDrawer
                    anchor='left'
                    open={isOpenDrawer}
                    onClose={() => setIsOpenDrawer(false)}
                >
                    <MobileViewClientSidebar setIsOpenDrawer={setIsOpenDrawer} />
                </StyledDrawer>

            </header>
        </>
    )
}

export default ClientHeader