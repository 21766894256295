import styled from '@emotion/styled';
import { Cancel, CheckCircle, HighlightOff, ThumbDown, ThumbDownAlt, ThumbUp, Undo, Visibility } from '@mui/icons-material';
import { Avatar, Box, Button, CircularProgress, IconButton, Paper, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import "assets/css/admin/request-listing.css"
import RequestViewModal from './approve';
import RejectRequestViewModal from './reject';
import dataProvider from 'providers/dataProvider';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ExpirePopup from 'components/common/ExpirePopup';
import { useAdminContextProvider } from 'providers/adminContextProvider';


const RequestListing = () => {

    let token = localStorage.getItem('token');
    const { setNetworkErr } = useAdminContextProvider()

    const [isRequestViewOpen, setRequestViewOpen] = useState(false)
    const [isRejectView, setIrejectView] = useState(false)
    const [fetchLoading, setFetchLoading] = useState(false)

    const [tokenExpireMsg, setTokenExpireMsg] = useState("")
    const [tokenExpOpen, setTokenExpOpen] = useState(false)



    const [requestData, setRequestData] = useState([])


    const [alignment, setAlignment] = useState('');

    const [selectedDetails, setSelecteDetails] = useState({
        id: "",
    })

    const [condition, setCondition] = useState('')


    const CustomTable = styled(Table)({
        // borderCollapse: "separate"
    });


    useEffect(() => {
        fetchRequestData()
    }, [])


    const fetchRequestData = async () => {
        setFetchLoading(true)
        dataProvider.getList('documents/request',
            {
                params: {
                    token: token,
                    pagination: {
                        page: 1,
                        perPage: 1000
                    },
                    sort: { order: 'desc', field: 'id' },
                    filter: {}
                }
            })
            .then(({ data }) => {
                setRequestData(data)
                setFetchLoading(false)
            })
            .catch(error => {
                setFetchLoading(false)
                // console.log(error);
                // console.log(error?.response?.data)
                // console.log(error?.response?.data?.expire)
                if (error.message === 'Network Error') {
                    setNetworkErr(true)
                  }
                if (error?.response?.data?.expire === true) {
                    setTokenExpireMsg(error?.response?.data?.message)
                    setTokenExpOpen(true)
                }
            })
    };



    //Approve Modal

    const handleRequestViewOpen = (id) => {
        setRequestViewOpen(true)
        setSelecteDetails((prevState) => ({
            ...prevState,
            id: id,
        }))
    }

    const hanldeRequestViewClose = () => {
        setRequestViewOpen(false)
        setSelecteDetails((prevState) => ({
            ...prevState,
            id: "",
        }))
        setAlignment("")
        setCondition("")
    }


    //Approve Modal


    //Reject Modal
    const handleRejectViewOpen = (id) => {
        setIrejectView(true)
        setSelecteDetails((prevState) => ({
            ...prevState,
            id: id,
        }))
    }

    const handleRejectViewClose = () => {
        setIrejectView(false)
        setSelecteDetails((prevState) => ({
            ...prevState,
            id: "",
        }))
        setAlignment("")
        setCondition("")
    }


    // switch for approve and reject

    const handleApproveChange = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    //Props

    const requestViewProps = {
        isRequestViewOpen: isRequestViewOpen,
        hanldeRequestViewClose: hanldeRequestViewClose,
        setRequestViewOpen: setRequestViewOpen,
        selectedDetails: selectedDetails,
        fetchRequestData: fetchRequestData,
    }

    const rejectViewProps = {
        isRejectView: isRejectView,
        handleRejectViewClose: handleRejectViewClose,
        setIrejectView: setIrejectView,
        selectedDetails: selectedDetails,
        fetchRequestData: fetchRequestData,
    }


    const SlotBox = styled(Box)(({ theme }) => ({
        margin: "20px 10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    }));



    const handleToggleButtonClick = (value, req) => {
        if (value === 'approve') {
            setCondition(value)
            handleRequestViewOpen(req?._id);
        } else if (value === 'reject') {
            setCondition(value)
            handleRejectViewOpen(req?._id);
        }
    };


    return (

        <>

            {
                tokenExpOpen ? <ExpirePopup tokenExpireMsg={tokenExpireMsg} /> :
                    <Paper sx={{ marginTop: "20px" }}>
                        <Box p={2} >

                            <SlotBox>
                                <Typography variant='h6' py={2} textTransform={'uppercase'} >Request listing</Typography>
                            </SlotBox>


                            {/* Request View  */}
                            <RequestViewModal  {...requestViewProps} />
                            {/* Request View  */}

                            {/* Reject View */}
                            <RejectRequestViewModal {...rejectViewProps} />
                            {/* Reject View */}
                            {
                                fetchLoading ? <div className="loader-position">
                                    <CircularProgress color="primary" size={45} />
                                </div>
                                    :
                                    requestData.length === 0 ?
                                        <Typography sx={{ m: 3, textAlign: "center" }}>Empty Requests</Typography>
                                        :
                                        <TableContainer >
                                            <CustomTable sx={{ minWidth: 650, '& th, & td': { padding: '10px' } }} size="small" aria-label="a dense table">
                                                <TableHead >
                                                    <TableRow>
                                                        <TableCell sx={{ width: "30%" }} >
                                                            <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                                Employer
                                                            </Typography>
                                                        </TableCell>

                                                        <TableCell sx={{ width: "30%" }} >
                                                            <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                                Staff
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell sx={{ width: "30%" }} >
                                                            <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                                Title
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell sx={{ width: "30%" }} >
                                                            <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                                Actions
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        requestData.map((req) => (
                                                            req?.documentId !== null &&
                                                            <TableRow
                                                                key={req?._id}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell >
                                                                    <div className="profile-image-table">
                                                                        <Avatar src={req?.clientId?.avatar_path} />
                                                                        <Typography sx={{ fontSize: '1rem', fontWeight: '600' }}>{req?.clientId?.first_name}</Typography>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">
                                                                    <div className="profile-image-table">
                                                                        <Avatar src={req?.customerId?.avatar_path} />
                                                                        <Typography sx={{ fontSize: '1rem', fontWeight: '600' }}>{req?.customerId?.first_name}</Typography>
                                                                    </div>
                                                                </TableCell>

                                                                <TableCell component="th" scope="row">
                                                                    <Typography sx={{ fontSize: '1rem', fontWeight: '600' }}>{req?.documentId?.title}</Typography>
                                                                </TableCell>
                                                                <TableCell
                                                                    sx={{
                                                                        // backgroundColor: "red",
                                                                    }}
                                                                >


                                                                    <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                                                        <Box>
                                                                            <ToggleButtonGroup
                                                                                color="primary"
                                                                                value={alignment}
                                                                                exclusive
                                                                                onChange={handleApproveChange}
                                                                                // size="small"
                                                                                sx={{ width: "1px", height: "5px" }}
                                                                            >
                                                                                <ToggleButton
                                                                                    disabled={req?.status === 'approve'}
                                                                                    value={req?._id}
                                                                                    onClick={() => handleToggleButtonClick('approve', req)}
                                                                                    style={{
                                                                                        backgroundColor: condition === 'approve' && alignment === req?._id || req?.status === 'approve' ? '#8fb569' : 'inherit',
                                                                                    }}
                                                                                >
                                                                                    <Tooltip title='Accept'>
                                                                                        <CheckIcon
                                                                                            sx={{ color: condition === 'approve' && alignment === req?._id || req?.status === 'approve' ? 'white' : 'inherit' }}
                                                                                            fontSize='5px'
                                                                                        />
                                                                                    </Tooltip>
                                                                                </ToggleButton>
                                                                                <ToggleButton
                                                                                    disabled={req?.status === 'reject'}
                                                                                    value={req?._id}
                                                                                    onClick={() => handleToggleButtonClick('reject', req)}
                                                                                    style={{
                                                                                        backgroundColor: condition === 'reject' && alignment === req?._id || req?.status === 'reject' ? '#E62020' : 'inherit',
                                                                                    }}
                                                                                >
                                                                                    <Tooltip title='Reject'>
                                                                                        <CloseIcon sx={{ color: condition === 'reject' && alignment === req?._id || req?.status === 'reject' ? 'white' : 'inherit' }}
                                                                                            fontSize='5px'
                                                                                        />
                                                                                    </Tooltip>
                                                                                </ToggleButton>
                                                                            </ToggleButtonGroup>
                                                                        </Box>

                                                                        {/* <Box sx={{ display: "flex", alignItems: "center", gap: '10px' }}>


                                                                            {
                                                                                req?.status === 'reject' &&
                                                                                <Button variant='contained'
                                                                                    size='small' sx={{ color: "white",  }}
                                                                                    startIcon={<Undo />}
                                                                                    onClick={() => handleRequestViewOpen(req?._id)}
                                                                                >Undo</Button>
                                                                            }
                                                                            {
                                                                                req?.status === 'reject' &&
                                                                                <Button
                                                                                    variant='contained'
                                                                                    size='small'
                                                                                    sx={{ color: "white" }}
                                                                                    // startIcon={<ThumbDownAlt />}
                                                                                    disabled
                                                                                >
                                                                                    Request declined
                                                                                </Button>
                                                                            }

                                                                        </Box> */}
                                                                    </Stack>

                                                                </TableCell>
                                                            </TableRow>
                                                        ))
                                                    }
                                                </TableBody>
                                            </CustomTable>
                                        </TableContainer>
                            }
                        </Box>
                    </Paper>
            }
        </>
    )
}



const ToggleButtonBlock = ({ alignment, handleApproveChange, handleRequestViewOpen, handleRejectViewOpen, req, setCondition, condition }) => {


    const handleToggleButtonClick = (value) => {
        if (value === 'approve') {
            setCondition(value)
            handleRequestViewOpen(req?._id);
        } else if (value === 'reject') {
            setCondition(value)
            handleRejectViewOpen(req?._id);
        }
    };




    return (

        <>




        </>
    )
}

export default RequestListing