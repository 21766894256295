import React, { useState } from 'react'
import 'assets/css/login.css'
import logo from "assets/images/hirehoodLogo.png"
import { Alert, Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Paper, Snackbar, Stack, TextField, Typography } from '@mui/material'
import { VisibilityOutlined, VisibilityOffOutlined, InputTwoTone } from '@mui/icons-material'
import { Link, useNavigate } from 'react-router-dom';
import authProvider from 'providers/authProvider';
import styled from '@emotion/styled'
import bgImage from '../../assets/images/nwbg.png'


const Login = () => {



  const [showPassword, setShowPassword] = useState(false);
  const [isalertOpen, setIsAlertOpen] = useState(false)

  const [isLoginLoading, setIsLoginLoading] = useState(false)
  const [isLoginError, setIsloginErr] = useState(false)

  const navigate = useNavigate()

  const [registerDetails, setRegisterDetails] = useState({
    email: "",
    password: "",
  })


  const [registerError, setRegisterError] = useState({
    email: "",
    password: "",
  })

  const [isRegisterError, setIsRegisterError] = useState({
    isEmail: false,
    isPassword: false
  })

  let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  let passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]).{6,}$/;

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChage = (e, regex, isError) => {
    const { name, value } = e.target;
    setRegisterDetails((prevState) => ({ ...prevState, [name]: value }))
    handleSetError(value, regex, name, isError)
  }


  const handleSetError = (inputValue, regex, filedname, isError) => {
    if (!regex.test(inputValue)) {
      setRegisterError((prevState) => ({ ...prevState, [filedname]: getErrorMessage(filedname) }))
      setIsRegisterError((prevState) => ({ ...prevState, [isError]: true }))
    } else {
      setRegisterError((prevState) => ({ ...prevState, [filedname]: '' }))
      setIsRegisterError((prevState) => ({ ...prevState, [isError]: false }))
    }
  }


  const getErrorMessage = (filedname) => {
    switch (filedname) {
      case "email":
        return "Invalid email format"
      case "password":
        return "Password contains six characters, capital, number, special character";
      default:
        return "Invalid format"
    }

  }


  const handleSubmit = () => {
    const { email, password } = registerDetails
    let hasErr = false;

    const setErr = (errorfiled, inputField, errorText) => {
      setIsRegisterError((prevState) => ({ ...prevState, [errorfiled]: true }))
      setRegisterError((prevState) => ({ ...prevState, [inputField]: errorText }))
      hasErr = true
    }

    if (email === '') setErr('isEmail', 'email', 'Email is required')
    if (password === '') setErr('isPassword', 'password', 'Password is required')

    if (!hasErr) {

      setIsLoginLoading(true)
      authProvider.login({ email, password }).then((response) => {
        setIsLoginLoading(false)
        window.location.href = "/";
      }).catch((err) => {
        setIsLoginLoading(false)
        setIsloginErr(true)
        console.log(err);
      })
    }
  }

  const handleClose = () => {
    setIsloginErr(false)
  }


  const BgImage1 = styled(Box)(({ theme }) => ({

  }));




  const BgImage2 = styled(Box)(({ theme }) => ({
    backgroundImage: `url(${bgImage})`,
    backgroundRepeat: "no-repeat",
    height: "100vh",
    width: "100%",
    backgroundSize: "100% 100%",
    transform: "scaleX(-1)",
  }));

  const LoginGrid = styled(Box)(({ theme }) => ({
    boxShadow: "0px 2px 1px -1px rgba(58, 53, 65, 0.2), 0px 1px 1px 0px rgba(58, 53, 65, 0.14), 0px 1px 3px 0px rgba(58, 53, 65, 0.12)",
    borderRadius: "6px",
  }));



  return (
    <>
      <Box sx={{
        backgroundImage: `url(${bgImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
        width: "100%",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}>
        <Box p={3}
          sx={{
            boxShadow: "0px 2px 1px -1px rgba(58, 53, 65, 0.2), 0px 1px 1px 0px rgba(58, 53, 65, 0.14), 0px 1px 3px 0px rgba(58, 53, 65, 0.12)",
            borderRadius: "6px",
            minHeight: "40px",
            width: "400px",
            backgroundColor: "white"
          }}
        >
          <Stack flexDirection={'row'} alignItems={'center'} gap={1} justifyContent={"center"}>
            <img src={logo} alt="" width={"60px"} height={"60px"} />
            <Typography variant='h6' textTransform={"uppercase"}>hirehood</Typography>
          </Stack>

          <Stack my={2}>
            <Typography variant='h5' sx={{ color: "rgba(58, 53, 65, 0.87)", fontSize: "1.4993rem" }} fontWeight={"bold"}>Welcome to Hirehood! 👋🏻</Typography>
            <Typography sx={{ color: "grey", marginTop: "10px" }}>Please sign-in to your account and start the adventure</Typography>
          </Stack>

          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <TextField
                id="outlined-email-input"
                label="Email"
                type="Email"
                name='email'
                onChange={(e) => handleChage(e, emailRegex, 'isEmail')}
                autoComplete="off"
                fullWidth
                color='primary'
                error={isRegisterError.isEmail}
                helperText={registerError.email && registerError.email}
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <FormControl variant="outlined"
                fullWidth
                error={isRegisterError.isPassword}
              >
                <InputLabel >Password</InputLabel>
                <OutlinedInput

                  type={showPassword ? 'text' : 'password'}
                  name='password'
                  onChange={(e) => handleChage(e, passwordRegex, 'isPassword')}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
                <FormHelperText>{registerError.password && registerError.password}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>

          {/* <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} my={1}>
              <FormControlLabel
                control={<Checkbox />}
                label={<Typography variant="body2">Remember Me</Typography>}
              />
              <Link to='/ForgotPassword' className='react-link' style={{ fontSize: "0.875rem" }}>Forgot Password?</Link>
            </Stack> */}

          <Grid item md={12}>
            <Button variant="contained" fullWidth color='primary'
              style={{ color: 'white', padding: '5px', fontSize: 'medium', margin: '10px 0', height: "40px" }}
              disabled={Object.values(registerError).some((error) => error !== "") || isLoginLoading}
              onClick={() => handleSubmit()}
            >
              {isLoginLoading ? <CircularProgress size={25} sx={{ color: 'white' }} /> : 'Login'}
            </Button>
          </Grid>

          <Stack flexDirection={'row'} gap={1} justifyContent={"center"} my={2}>
            <Typography variant="body2">New on our platform?</Typography>
            <Link to='/Register' className='react-link' style={{ fontSize: "0.875rem" }}>Create an account</Link>
          </Stack>

        </Box>
      </Box>



      {/* <Grid container >
        <Grid item xs={0} sm={3} md={3} lg={4}  >
         
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={4} >
       
        </Grid>

        <Grid item xs={0} sm={3} md={3} lg={4} >
          <BgImage2 />
        </Grid>
      </Grid > */}

      <Snackbar open={isLoginError}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          Invalid Credentials
        </Alert>
      </Snackbar>
    </>
  )
}

export default Login