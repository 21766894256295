import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Button, CircularProgress, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import CreateLocationModal from './create';
import EditLocationModal from './edit';
import DeleteLocationModal from './delete';
import dataProvider from 'providers/dataProvider';
import { CheckCircle, DoNotDisturb } from '@mui/icons-material';
import styled from '@emotion/styled';
import ExpirePopup from 'components/common/ExpirePopup';
import { useAdminContextProvider } from 'providers/adminContextProvider';
const LocationList = () => {

  const navigate = useNavigate()
  let token = localStorage.getItem('token');
  const { setNetworkErr } = useAdminContextProvider()


  const [locationDetails, setLocationDetails] = useState([])



  const [isCreateLocationModal, setIsCreateLocationModal] = useState(false)
  const [isUpdateLocationModal, setIsUpdateLocationModal] = useState(false)
  const [iseDeleteModal, setIsdeleteModal] = useState(false)



  // Errors
  const [isLocationNotNull, setIsLocationNotNull] = useState(false)


  //Loadings
  const [fetchlocationLoading, setFetchlocationLoading] = useState(false)
  const [buttonLoading, setButtonLoading] = useState(false)


  const [selectedLocationDetails, setSelectedLocationDetails] = useState({
    id: "",
    location: "",
    status: 1
  })

  const [deletedDetails, setDeletetedDetails] = useState({
    id: "",
    location: ""
  })


  const [tokenExpireMsg, setTokenExpireMsg] = useState("")
  const [tokenExpOpen, setTokenExpOpen] = useState(false)



  useEffect(() => {
    fetchLocationData()
  }, [])




  const fetchLocationData = () => {
    setFetchlocationLoading(true)
    dataProvider.getList('locations',
      {
        params: {
          token: token,
          pagination: {
            page: 1,
            perPage: 1000
          },
          sort: { order: 'desc', field: 'id' },
          filter: {}
        }
      })
      .then(({ data }) => {
        setLocationDetails(data)
        setFetchlocationLoading(false)
      })
      .catch(error => {
        setFetchlocationLoading(false)
        // console.log(error?.response?.data)
        // console.log(error?.response?.data?.expire)
        if (error.message === 'Network Error') {
          setNetworkErr(true)
        }

        if (error?.response?.data?.expire === true) {
          setTokenExpireMsg(error?.response?.data?.message)
          setTokenExpOpen(true)
        }

      })
  }





  // Create

  const handleCreateLocation = (location, status) => {
    if (location === "") {
      setIsLocationNotNull(true)
    } else {

      let data = {
        'location': location,
        'status': status
      }

      setButtonLoading(true)
      dataProvider.create('locations', data).then((data) => {
        // console.log(data)
        setButtonLoading(false)
        setIsCreateLocationModal(false)
        fetchLocationData()
      }).catch((e) => {
        setButtonLoading(false)
        setIsCreateLocationModal(false)
        // console.log(e)
      })

    }
  }

  // Create




  // Edit
  const handleEditModalOpen = (id, location, status) => {
    setIsUpdateLocationModal(true)
    setSelectedLocationDetails((prevState) => ({
      ...prevState,
      id: id,
      location: location,
      status: status
    }))
  }

  const handleEditCloseModal = () => {
    setIsLocationNotNull(false)
    setIsUpdateLocationModal(false)
    setSelectedLocationDetails((prevState) => ({
      ...prevState,
      id: "",
      location: "",
      status: ""
    }))
  }

  const handleConfirmEdit = (id, location, status) => {


    if (location === "") {
      setIsLocationNotNull(true)
    } else {
      let data = {
        'id': id,
        'location': location,
        'status': status
      }

      setButtonLoading(true)
      dataProvider.update('locations', data).then((data) => {
        // console.log(data)
        setIsUpdateLocationModal(false)
        setSelectedLocationDetails((prevState) => ({
          ...prevState,
          id: "",
          location: "",
          status: ""
        }))
        setButtonLoading(false)
        setIsLocationNotNull(false)
        fetchLocationData()
      }).catch((e) => {
        setButtonLoading(false)
        // console.log(e)
      })
    }

  }
  // Edit



  //Delete
  const handleDeleteModalOpen = (id, location) => {
    setIsdeleteModal(true)
    setDeletetedDetails((prevState) => ({
      ...prevState,
      id: id,
      location: location
    }))
  }

  const handleCloseDeleteModal = () => {
    setIsdeleteModal(false)
    setDeletetedDetails((prevState) => ({
      ...prevState,
      id: "",
    }))
  }

  const handleConfirmDelete = (id) => {

    let data = {
      'id': id
    }

    setButtonLoading(true)
    dataProvider.delete('locations', data).then((data) => {
      setDeletetedDetails((prevState) => ({
        ...prevState,
        id: "",
      }))
      setButtonLoading(false)
      setIsdeleteModal(false)
      fetchLocationData()
    }).catch((e) => {
      // console.log(e)
      setButtonLoading(false)
    })
  }
  //Delete


  //Props
  let createModalProps = {
    isCreateLocationModal: isCreateLocationModal,
    setIsCreateLocationModal: setIsCreateLocationModal,
    handleCreateLocation: handleCreateLocation,
    isLocationNotNull: isLocationNotNull,
    buttonLoading: buttonLoading,
    setIsLocationNotNull: setIsLocationNotNull
  }

  let updateModalProps = {
    isUpdateLocationModal: isUpdateLocationModal,
    handleEditCloseModal: handleEditCloseModal,
    handleConfirmEdit: handleConfirmEdit,
    selectedLocationDetails: selectedLocationDetails,
    setIsUpdateLocationModal: setIsUpdateLocationModal,
    buttonLoading: buttonLoading,
    isLocationNotNull: isLocationNotNull
  }

  let deletedModalProps = {
    iseDeleteModal: iseDeleteModal,
    handleCloseDeleteModal: handleCloseDeleteModal,
    handleConfirmDelete: handleConfirmDelete,
    deletedDetails: deletedDetails,
    buttonLoading: buttonLoading
  }
  //Props

  const SlotBox = styled(Box)(({ theme }) => ({
    margin: "20px 10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  }));

  return (
    <>

      {
        tokenExpOpen ? <ExpirePopup tokenExpireMsg={tokenExpireMsg} /> :
          <Paper sx={{ marginTop: "20px" }}>
            <Box p={2}>

              <SlotBox>
                <Typography variant='h6' py={2} textTransform={'uppercase'} >Location Listing</Typography>
                <Tooltip title='Create user'>
                  <Button variant='contained' sx={{ color: "white", backgroundImage: "linear-gradient(98deg, #9cc177, #6ba134 94%)" }} onClick={() => setIsCreateLocationModal(true)}>Create</Button>
                </Tooltip>
              </SlotBox>


              {/* CreateModal */}
              <CreateLocationModal {...createModalProps} />
              {/* CreateModal */}



              {/* Edit Modal */}
              <EditLocationModal {...updateModalProps} />
              {/* Edit Modal */}


              {/* Delete modal */}
              <DeleteLocationModal {...deletedModalProps} />
              {/* Delete modal */}



              {
                fetchlocationLoading ?
                  <div className="loader-position">
                    <CircularProgress color="primary" size={45} />
                  </div>
                  :
                  locationDetails.length === 0 ?
                    <Typography sx={{ m: 3, textAlign: "center" }}>Empty Locations</Typography>
                    :
                    <TableContainer>
                      <Table sx={{ minWidth: 650, '& th, & td': { padding: '10px' } }} size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ width: "40%" }} >
                              <Typography sx={{ fontSize: '1rem' }} fontWeight="bold" >
                                Location
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ width: "40%" }} >
                              <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                Status
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ width: "40%" }} >
                              <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                Actions
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>


                          {
                            locationDetails.map((locData) => (
                              <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell ><Typography sx={{ fontSize: '1rem' }}>{locData?.location}</Typography></TableCell>
                                <TableCell >
                                  <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                    {
                                      locData?.status === 1 ?
                                        <>
                                          <CheckCircle style={{ color: '#8fb569', width: "22px", height: "22px" }} />
                                          <Typography fontWeight='bold'>Active</Typography>
                                        </>
                                        :
                                        <>
                                          <DoNotDisturb style={{ color: 'red', width: "22px", height: "22px" }} />
                                          <Typography fontWeight='bold'>Inactive</Typography>
                                        </>
                                    }
                                  </Box>
                                </TableCell>
                                <TableCell >
                                  <div className="user-action-buttons">
                                    <Tooltip title='Edit'>
                                      <IconButton onClick={() => handleEditModalOpen(locData?._id, locData?.location, locData?.status)}>
                                        <EditIcon sx={{ color: '#0066b2' }} />
                                      </IconButton>
                                    </Tooltip>

                                    <Tooltip title='Delete'>
                                      <IconButton onClick={() => handleDeleteModalOpen(locData?._id, locData?.location)}>
                                        <DeleteIcon sx={{ color: 'red' }} />
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                </TableCell>
                              </TableRow>
                            ))
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
              }
            </Box>
          </Paper>
      }


    </>
  )
}

export default LocationList