import { Alert, Slide, Snackbar } from '@mui/material';
import React from 'react'

const AppToast = ({ toastOpen, toastClose, message }) => {
    const SlideTransition = (props) => {
        return <Slide {...props} direction="up" />;
    };
    return (
        <Snackbar
            open={toastOpen}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            autoHideDuration={3000}
            onClose={toastClose}
            TransitionComponent={SlideTransition}
        >
            <Alert
                onClose={toastClose}
                severity="error"
                variant="filled"
                sx={{ width: '100%' }}
            >
                {message}
            </Alert>
        </Snackbar>
    )
}

export default AppToast