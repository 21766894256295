import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'

const EditLocationModal = ({ isUpdateLocationModal, handleEditCloseModal, handleConfirmEdit,
    selectedLocationDetails, buttonLoading, isLocationNotNull }) => {



    const { id, location, status } = selectedLocationDetails

    const [createLocationDetails, setCreatelocationDetails] = useState({
        status: 1,
        location: ''
    })


    useEffect(() => {
        setCreatelocationDetails({
            status: status || 1,
            location: location || ''
        });
    }, [selectedLocationDetails]);


    const handleChange = (e) => {
        const { name, value } = e.target
        setCreatelocationDetails((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }





    return (
        <>
            <Dialog open={isUpdateLocationModal} onClose={() => handleEditCloseModal()} fullWidth>
                <DialogTitle>Edit Location</DialogTitle>
                <DialogContent>

                    <TextField
                        margin="dense"
                        label="Location"
                        type="text"
                        fullWidth
                        variant="outlined"
                        name="location"
                        onChange={(event) => handleChange(event)}
                        value={createLocationDetails.location}
                        error={isLocationNotNull}
                        helperText={isLocationNotNull && 'Please fill this field'}
                    />


                    <FormControl fullWidth sx={{ margin: "10px 0" }}>
                        <InputLabel id="demo-simple-select-label">Status</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Status"
                            onChange={(event) => handleChange(event)}
                            name="status"
                            value={createLocationDetails.status}
                        >
                            <MenuItem value={0}>Inactive</MenuItem>
                            <MenuItem value={1}>Active</MenuItem>
                        </Select>
                    </FormControl>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleEditCloseModal()}>Cancel</Button>
                    <Button onClick={() => handleConfirmEdit(id, createLocationDetails.location, createLocationDetails.status)}
                        disabled={buttonLoading}
                    >
                        {
                            buttonLoading ? <CircularProgress size={15} color='primary' /> : 'Edit'
                        }
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default EditLocationModal