import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Avatar, Badge, Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Pagination, Paper, Select, Stack, TextField, Tooltip, Typography, makeStyles } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import "assets/css/admin/shift-listing.css"
import { ArrowDownwardOutlined, ArrowUpwardOutlined, CalendarMonth, Cancel, CheckCircle, Close, Delete, FilterAlt, ModeEdit, SearchOffOutlined, SearchOutlined, Visibility, VisibilityOutlined } from '@mui/icons-material';
import { styled } from '@mui/system';
import dataProvider from 'providers/dataProvider';
import ViewShift from 'views/admin/shifts/view';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import ExpirePopup from 'components/common/ExpirePopup';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { DatePicker, DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { useClientContextProvider } from 'providers/clientContextProvider'

const ShiftListing = () => {

    let userId = localStorage.getItem('userId');
    let token = localStorage.getItem('token');
    const { setNetworkErr } = useClientContextProvider()

    const CustomPagination = styled(Pagination)(({ theme }) => ({
        '& .MuiPaginationItem-page.Mui-selected': {
            color: 'white',
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                backgroundColor: theme.palette.primary.dark,
            },
        },
    }));

    const CustomTable = styled(Table)({
        // borderCollapse: "separate"
    });

    const [shiftViewModal, setShiftViewModal] = useState(false)

    const [shiftDetailData, setShiftDetailsData] = useState([])

    const [shiftLoading, setShiftLoading] = useState(false)

    const [selectedShiftDetails, setSelectedShiftDetails] = useState({})

    const [shiftLength, setShiftLength] = useState(null)

    const [order, setOrder] = useState('desc');
    const [field, setField] = useState('id')
    const [clickCount, setClickCount] = useState(1);
    let [paginationCount, setPaginationCount] = useState(1)

    const [tokenExpireMsg, setTokenExpireMsg] = useState("")
    const [tokenExpOpen, setTokenExpOpen] = useState(false)

    const [pdfLoading, setPdfLoading] = useState(false)

    const [filterData, setFilterData] = useState({
        shiftType: "",
        // date: "",
        from: "",
        to: "",
        shiftStatus: ""
    })


    const [filterFields, setFilterFields] = useState({
        clientId: userId,
        // date:"2024-02-29T21:00:00.000Z"
    })


    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)


    const [staffTypeData, setStaffTypeData] = useState([])
    const [typeLoading, setTypeLoading] = useState(false)

    const [datePickerFrom, setdatePickerFrom] = useState(false)
    const [datePickerTo, setdatePickerTo] = useState(false)


    const [disabledDate, setDisabledDate] = useState({
        startDate: "",
        endDate: ""
    })


    const [deleteOpen, setDeleteOpen] = useState(false)
    const [deleteItems, setDeleteItems] = useState({
        id: "",
        title: ""
    })

    const [buttonloading, setButtonLoading] = useState(false)

    useEffect(() => {
        fetchShiftData()
        fetchStaffType()
    },
        [
            order, field, paginationCount, filterFields
        ])




    const fetchShiftData = () => {
        setShiftLoading(true)
        dataProvider.getList('shift',
            {
                params: {
                    token: token,
                    pagination: {
                        page: paginationCount,
                        perPage: 10
                    },
                    sort: { order: order, field: field },
                    filter: filterFields
                }
            })
            .then(({ data }) => {
                setShiftLength(data.length)
                // console.log(data);
                setShiftDetailsData(data)
                setShiftLoading(false)
            })
            .catch(error => {
                console.log(error)
                setShiftLoading(false)
                if (error.message === 'Network Error') {
                    setNetworkErr(true)
                }
                // console.log(error?.response?.data)
                // console.log(error?.response?.data?.expire)
                if (error?.response?.data?.expire === true) {
                    setTokenExpireMsg(error?.response?.data?.message)
                    setTokenExpOpen(true)
                }
            })
    }


    const fetchStaffType = () => {
        setTypeLoading(true)
        dataProvider.getList('staff_type', {
            params: {
                token: token,
                pagination: { page: 1, perPage: 1000 },
                sort: { order: 'desc', field: 'id' },
                filter: {}
            }
        })
            .then(({ data }) => {
                // console.log(data)
                setStaffTypeData(data)
                setTypeLoading(false)
            })
            .catch(error => {
                setTypeLoading(false)
                if (error.message === 'Network Error') {
                    setNetworkErr(true)
                }
                // console.log(error?.response?.data)
                // console.log(error?.response?.data?.expire)
                // if (error?.response?.data?.expire === true) {
                //     setTokenExpireMsg(error?.response?.data?.message)
                //     setTokenExpOpen(true)
                // }
            })
    }






    const navigate = useNavigate()



    // View Modals
    const handleOpenShiftViewModal = (shift) => {
        setShiftViewModal(true)
        setSelectedShiftDetails(shift)
    }

    const handleCloseShiftViewModal = () => {
        setShiftViewModal(false)
    }


    // Filter logics

    const handleDateFilter = () => {

        setClickCount((prevCount) => prevCount + 1);

        if (clickCount % 3 === 0) {
            setOrder('desc');
            setField('id');
        } else {
            setOrder(order === 'desc' ? 'asc' : 'desc');
            setField('date');
        }


    };

    const handleUserFilter = () => {
        setClickCount((prevCount) => prevCount + 1);

        if (clickCount % 3 === 0) {
            setOrder('desc');
            setField('id');
        } else {
            setOrder(order === 'desc' ? 'asc' : 'desc');
            setField('clientId');
        }
    }

    const handlePrevPage = () => {
        setPaginationCount(paginationCount -= 1)
    }

    const hanldNextPage = () => {
        setPaginationCount(paginationCount += 1)
    }

    // Filter logics

    // pdf generator
    const handlePdf = (shiftDetailData) => {

        const pdfDoc = new jsPDF();

        const title = 'Shift Details';

        const headerColor = '#8fb569';

        const columns = ['Title', 'Shift Type', 'Date From', 'Date To', 'Client', 'Time From', 'Time To', 'Status'];


        const rows = shiftDetailData.map((rowData) => [
            rowData.title,
            rowData.shift_type,
            rowData.date && new Date(rowData.date).toLocaleDateString('en-GB', { timeZone: 'UTC' }),
            rowData.date_to && new Date(rowData.date_to).toLocaleDateString('en-GB', { timeZone: 'UTC' }),
            rowData.clientId?.first_name,
            rowData?.time_from,
            rowData?.time_to,
            rowData?.shift_status.charAt(0).toUpperCase() + rowData?.shift_status.slice(1)
        ]);

        pdfDoc.text(title, 14, 10);


        pdfDoc.autoTable({
            head: [columns],
            body: rows,
            startY: 20,
            theme: 'plain', // Optional: 'striped', 'grid', 'plain'
            headStyles: {
                fillColor: headerColor,
                textColor: '#ffffff', // Optional: Text color for header
            },
        });

        // Save the PDF or open it in a new tab
        pdfDoc.save('shift_details.pdf');
    };




    const convertToBase64 = (imageUrl) => {
        return new Promise((resolve, reject) => {
            fetch(imageUrl)
                .then(response => response.blob())
                .then(blob => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        resolve(reader.result);
                    };
                    reader.onerror = reject;
                    reader.readAsDataURL(blob);
                })
                .catch(reject);
        });
    }

    const SlotBox = styled(Box)(({ theme }) => ({
        margin: "20px 10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    }));

    const StatusBox = styled(Box)(({ theme }) => ({
        width: "100px",
        padding: "3px 0",
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "5px",
    }));

    const StatusChip = styled(Chip)(({ theme }) => ({
        width: "100px",
        height: "25px",
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

    }))

    const statusColors = {
        'pending': '#FFC72C',
        'ongoing': '#0077c0',
        'completed': '#8cc751',
        'not_fulfilled': '#dc3545'
    };




    const handleChange = (e) => {
        const { name, value } = e.target;
        setFilterData((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    const handleDate = (newDate) => {

        // console.log(newDate);
        // var options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        // var dateFormatter = new Intl.DateTimeFormat('en-US', { timeZone: 'UTC' }, options);
        // var shiftDate = dateFormatter.format(newShift).replace(/\//g, '-');
        // const utcDate = dayjs(shiftDate).format('YYYY-MM-DD')



        const yymmdd = dayjs(newDate).format('YYYY-MM-DD');
        // console.log(yymmdd);

        var newShift = new Date(yymmdd);
        newShift.setUTCHours(23, 59, 59);
        var utcTime = newShift.toISOString();
        console.log(utcTime);




        // console.log(formattedDate);
        setFilterData((prevState) => ({
            ...prevState,
            date: utcTime
        }))
    }





    const handleFilterValue = () => {
        if (filterData.shiftType !== "") setFilterFields((prevState) => ({ ...prevState, ["shift_type"]: filterData.shiftType }))
        if (filterData.from !== "" && filterData.to !== "") {
            setFilterFields((prevState) => ({
                ...prevState,
                date: {
                    ...prevState.date,
                    from: filterData.from
                }
            }));
            // setFilterFields((prevState) => ({ ...prevState, ["date"]: filterData.from }))
        }
        if (filterData.to !== "" && filterData.from !== "") {
            setFilterFields((prevState) => ({
                ...prevState,
                date: {
                    ...prevState.date,
                    to: filterData.to
                }
            }));
        }
        if (filterData.shiftStatus !== "") setFilterFields((prevState) => ({ ...prevState, ["shift_status"]: filterData.shiftStatus }))
        setPaginationCount(1)
    }
    // console.log(filterFields);

    const handleDateFrom = (newDate) => {
        if (newDate) {
            const yymmdd = dayjs(newDate).format('YYYY-MM-DD');
            var newShift = new Date(yymmdd);
            newShift.setUTCHours(23, 59, 59);
            var utcTime = newShift.toISOString();

            setFilterData((prevState) => ({
                ...prevState,
                from: utcTime
            }))

            setDisabledDate((prev) => ({
                ...prev,
                startDate: newDate
            }))
        }

    }


    const handleDateTo = (newDate) => {
        if (newDate) {
            const yymmdd = dayjs(newDate).format('YYYY-MM-DD');
            var newShift = new Date(yymmdd);
            newShift.setUTCHours(23, 59, 59);
            var utcTime = newShift.toISOString();

            setFilterData((prevState) => ({
                ...prevState,
                to: utcTime
            }))
        }
    }

    const handleDeleteOpen = (id, title) => {
        setDeleteOpen(true)
        setDeleteItems((prev) => ({ ...prev, id: id, title: title }))
    }

    const handleDeleteClose = () => {
        setDeleteOpen(false)
        setDeleteItems((prev) => ({ ...prev, id: "" }))
    }

    const hadleConfrimDelete = (id) => {
        let data = {
            'id': id
        }

        console.log(data)

        setButtonLoading(true)
        // setButtonLoading(false)
        dataProvider.delete('shift', data).then((data) => {
            setButtonLoading(false)
            setDeleteOpen(false)
            fetchShiftData()
        }).catch((e) => {
            console.log(e)
            setButtonLoading(false)
        })
    }

    const handleShiftTypeClear = () => {
        setFilterData((prev) => ({ ...prev, shiftType: "" }))
        setFilterFields((prev) => {
            const { shift_type, ...rest } = prev;
            return rest;
        });

    }

    const handleStatusClear = () => {
        setFilterData((prev) => ({ ...prev, shiftStatus: "" }))
        setFilterFields((prev) => {
            const { shift_status, ...rest } = prev;
            return rest;
        });
    }


    return (
        <>
            {
                tokenExpOpen ? <ExpirePopup tokenExpireMsg={tokenExpireMsg} /> :
                    <Paper sx={{ marginTop: "20px" }}>
                        <Box p={2}>


                            <SlotBox>
                                <Typography variant='h6' py={2} textTransform={'uppercase'} >Shift listing</Typography>


                                <Stack flexDirection={"row"} alignItems={"center"} gap={1}>

                                    {
                                        // shiftDetailData.length !== 0 &&
                                        <Button variant='contained' sx={{
                                            color: "white",
                                            // backgroundImage: "linear-gradient(98deg, #9cc177, #6ba134 94%)",
                                            width: "100px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}
                                            onClick={() => handlePdf(shiftDetailData)}
                                            disabled={pdfLoading || shiftDetailData.length === 0}
                                            endIcon={pdfLoading ? null : <FontAwesomeIcon icon={faFilePdf} style={{ color: "#fff", }}
                                            />}
                                        >
                                            {
                                                pdfLoading ? <CircularProgress size={23} sx={{ margin: "0 10px", color: "white" }} /> : 'Export'
                                            }

                                        </Button>
                                    }

                                    <Tooltip title='Create shift'>
                                        <Button variant='contained'
                                            sx={{ color: "white", backgroundImage: "linear-gradient(98deg, #9cc177, #6ba134 94%)" }}
                                            onClick={() => navigate('/shift/create')}>Create</Button>
                                    </Tooltip>
                                </Stack>
                            </SlotBox>

                            {/* Filters */}
                            <Grid container spacing={2} my={3} alignItems={'center'} >

                                {/* Date From */}
                                <Grid item md={6} xs={12}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DesktopDatePicker
                                            label='Start Date'
                                            open={datePickerFrom}
                                            onClose={() => setdatePickerFrom(false)}
                                            format="DD-MM-YYYY"
                                            onChange={handleDateFrom}
                                            slotProps={{
                                                field: {
                                                    clearable: true, onClear: () => {
                                                        setFilterData((prevState) => ({
                                                            ...prevState,
                                                            from: "",
                                                            // to: ""
                                                        }))

                                                        setDisabledDate((prev) => ({
                                                            ...prev,
                                                            startDate: "",
                                                            endDate: ""
                                                        }))

                                                        setFilterFields((prev) => {
                                                            const { from, ...rest } = prev;
                                                            return rest;
                                                        });
                                                    }
                                                },
                                                textField: {
                                                    onClick: () => setdatePickerFrom(true),
                                                },
                                            }}
                                            style={{ position: 'absolute', top: '100%', zIndex: 1 }}
                                            sx={{ width: "100%" }}
                                        />
                                    </LocalizationProvider>
                                </Grid>

                                {/* Date To */}
                                <Grid item md={6} xs={12}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>

                                        <DesktopDatePicker
                                            label='End Date'
                                            open={datePickerTo}
                                            onClose={() => setdatePickerTo(false)}
                                            format="DD-MM-YYYY"
                                            onChange={handleDateTo}
                                            slotProps={{
                                                field: {
                                                    clearable: true, onClear: () => {
                                                        setFilterData((prevState) => ({
                                                            ...prevState,
                                                            to: ""
                                                        }))

                                                        setDisabledDate((prev) => ({
                                                            ...prev,
                                                            endDate: ""
                                                        }))

                                                        setFilterFields((prev) => {
                                                            const { to, ...rest } = prev;
                                                            return rest;
                                                        });
                                                    }
                                                },
                                                textField: {
                                                    onClick: () => setdatePickerTo(true),
                                                },
                                            }}
                                            shouldDisableDate={(value, timeView) => {
                                                return disabledDate.startDate >= value
                                            }}
                                            disabled={disabledDate.startDate === ''}

                                            style={{ position: 'absolute', top: '100%', zIndex: 1 }}
                                            sx={{ width: "100%" }}
                                        />
                                    </LocalizationProvider>
                                </Grid>

                                {/* Staff Type */}
                                <Grid item md={4} xs={12}>
                                    <TextField
                                        style={{ width: "100%" }}
                                        name='shiftType'
                                        select
                                        label="Staff type"
                                        value={filterData.shiftType}
                                        onChange={handleChange}
                                        // margin="normal"
                                        variant="outlined"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start">
                                                    {
                                                        filterData.shiftType &&
                                                        <IconButton onClick={() => handleShiftTypeClear()}>
                                                            <Close sx={{ color: '#909090' }} />
                                                        </IconButton>
                                                    }

                                                </InputAdornment>
                                            )
                                        }}
                                    >
                                        {
                                            typeLoading ? (

                                                <MenuItem disabled>Loading...</MenuItem>
                                            ) :
                                                staffTypeData?.map((type) => (
                                                    <MenuItem value={type?.type}>{type?.type}</MenuItem>
                                                ))
                                        }
                                    </TextField>


                                    {/* <FormControl fullWidth >
    <InputLabel id="demo-simple-select-label">Staff type</InputLabel>
    <Select
      name='shiftType'
      value={filterData.shiftType}
      label="Staff type"
      onChange={handleChange}

    >
     

    </Select>
  </FormControl> */}
                                </Grid>

                                {/* Status */}
                                <Grid item md={4} xs={12}>
                                    <TextField
                                        style={{ width: "100%" }}
                                        name='shiftStatus'
                                        select
                                        label="Status"
                                        value={filterData.shiftStatus}
                                        onChange={handleChange}
                                        // margin="normal"
                                        variant="outlined"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start">
                                                    {
                                                        filterData.shiftStatus &&
                                                        <IconButton onClick={() => handleStatusClear()}>
                                                            <Close sx={{ color: '#909090' }} />
                                                        </IconButton>
                                                    }

                                                </InputAdornment>
                                            )
                                        }}
                                    >
                                        <MenuItem value={'pending'}>Pending</MenuItem>
                                        <MenuItem value={'ongoing'}>Ongoing</MenuItem>
                                        <MenuItem value={'completed'}>Completed</MenuItem>
                                    </TextField>


                                    {/* <FormControl fullWidth>
    <InputLabel id="demo-simple-select-label">Status</InputLabel>
    <Select
      name='shiftStatus'
      value={filterData.shiftStatus}
      label="Shift Type"
      onChange={handleChange}
    >


    </Select>
  </FormControl> */}
                                </Grid>

                                {/* Filter Button */}
                                <Grid item md={4} flexDirection={'row'} xs={12}>
                                    <Button
                                        variant='contained'
                                        sx={{
                                            color: "white", backgroundImage: "linear-gradient(98deg, #9cc177, #6ba134 94%)",
                                            width: "100%",
                                            height: "53px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}
                                        endIcon={<FilterAlt />}
                                        onClick={() => handleFilterValue()}
                                    >Filter</Button>
                                </Grid>

                            </Grid>



                            {
                                shiftLoading ? <div className="loader-position">
                                    <CircularProgress color="primary" size={45} />
                                </div>
                                    :
                                    shiftDetailData.length === 0 ?
                                        <Typography sx={{ m: 3, textAlign: "center" }}>Empty Shifts</Typography>
                                        :
                                        <>

                                            {/* Shift View */}
                                            <ViewShift
                                                shiftViewModal={shiftViewModal}
                                                handleOpenShiftViewModal={handleOpenShiftViewModal}
                                                handleCloseShiftViewModal={handleCloseShiftViewModal}
                                                selectedShiftDetails={selectedShiftDetails}
                                            />
                                            {/* Shift View */}

                                            <TableContainer >
                                                <CustomTable sx={{ minWidth: 650, '& th, & td': { padding: '10px' } }} size="small" aria-label="a dense table">
                                                    <TableHead >
                                                        <TableRow>
                                                            <TableCell sx={{ width: "20%" }}>
                                                                <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                                    Title
                                                                </Typography>
                                                            </TableCell>

                                                            <TableCell sx={{ width: "20%" }}>
                                                                <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                                    Staff type
                                                                </Typography>
                                                            </TableCell>

                                                            <TableCell sx={{ width: "30%" }}>
                                                                <div className="custom-shift-table" onClick={() => handleDateFilter()}>

                                                                    <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                                        Date
                                                                    </Typography>

                                                                    <IconButton>
                                                                        {(order === 'asc' && field === 'date') ? (
                                                                            <ArrowDownwardOutlined sx={{ color: "#808080", fontSize: "20px" }} />
                                                                        ) : (order === 'desc' && field === 'date') ? (
                                                                            <ArrowUpwardOutlined sx={{ color: "#808080", fontSize: "20px" }} />
                                                                        ) : null}
                                                                    </IconButton>
                                                                </div>
                                                            </TableCell>


                                                            <TableCell sx={{ width: "20%" }}>

                                                                <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                                    Employer
                                                                </Typography>
                                                            </TableCell>


                                                            <TableCell sx={{ width: "10%" }}>
                                                                <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                                    Status
                                                                </Typography>
                                                            </TableCell>



                                                            <TableCell sx={{ width: "40%" }} >
                                                                <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                                    Actions
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            shiftDetailData.map((shift) => (
                                                                <TableRow
                                                                    key={shift._id}
                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                >
                                                                    <TableCell ><Typography sx={{ fontSize: '1rem' }}>{shift?.title}</Typography></TableCell>
                                                                    <TableCell ><Typography sx={{ fontSize: '1rem' }}>{shift?.shift_type}</Typography></TableCell>
                                                                    <TableCell >
                                                                        <div className="slot-date-time">
                                                                            <Typography sx={{ fontSize: '1rem' }}>
                                                                                {/* {shift.date && dayjs(shift.date).format('YYYY-MM-DD')} */}
                                                                                {new Date(shift?.date).toLocaleDateString('en-GB', { timeZone: 'UTC' })}
                                                                                {" "}
                                                                                {" - "}
                                                                                {" "}
                                                                                {new Date(shift?.date_to).toLocaleDateString('en-GB', { timeZone: 'UTC' })}
                                                                            </Typography>
                                                                            <Typography sx={{ fontSize: '.9rem', color: "#808080" }}>{shift?.time_from} - {shift?.time_to}</Typography>
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell component="th" scope="row">
                                                                        <div className="profile-image-table">
                                                                            <Avatar src={shift?.clientId?.avatar_path} />
                                                                            <Typography sx={{ fontSize: '1rem', fontWeight: '600' }}>{shift?.clientId?.first_name}</Typography>
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell >
                                                                        {/* <StatusBox sx={{ backgroundColor: statusColors[shift?.shift_status] }}>
                                                                            {shift?.shift_status.charAt(0).toUpperCase() + shift?.shift_status.slice(1)}
                                                                        </StatusBox> */}

                                                                        <StatusChip label={shift?.shift_status.replace('_', ' ').charAt(0).toUpperCase() + shift?.shift_status.replace('_', ' ').slice(1)} sx={{ backgroundColor: statusColors[shift?.shift_status] }}>
                                                                        </StatusChip>
                                                                    </TableCell>
                                                                    <TableCell >
                                                                        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>

                                                                            {/* Approve/Not Approve */}
                                                                            {
                                                                                shift?.status === 0 ?
                                                                                    <Tooltip title='Not Approved'>
                                                                                        <IconButton >
                                                                                            <Cancel color='error' />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                    :
                                                                                    <Tooltip title='Approved'>
                                                                                        <IconButton >
                                                                                            <CheckCircle color='primary' />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                            }


                                                                            {/* Edit */}
                                                                            {/* {
                                                                                (shift?.shift_status === 'pending' || shift?.shift_status === 'ongoing') &&
                                                                                (
                                                                                    <Tooltip title='Edit'>
                                                                                        <IconButton onClick={() => navigate(`/shift/edit/${shift?._id}`)}>
                                                                                            <EditIcon sx={{ color: '#0066b2' }} />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                )
                                                                            } */}

                                                                            {/* View */}
                                                                            <Tooltip title='View'>
                                                                                <IconButton onClick={() => handleOpenShiftViewModal(shift)}>
                                                                                    <Visibility sx={{ color: '#0066b2' }} />
                                                                                </IconButton>
                                                                            </Tooltip>


                                                                            <ShiftViewBlock shiftId={shift?._id} state={shift} shiftStatus={shift?.shift_status} />

                                                                            {/* Delete  */}
                                                                            {
                                                                                (shift?.shift_status === 'pending') &&
                                                                                <Tooltip title='Delete'>
                                                                                    <IconButton onClick={() => handleDeleteOpen(shift?._id, shift?.title)}>
                                                                                        <Delete color='error' />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            }


                                                                        </Box>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))
                                                        }
                                                    </TableBody>
                                                </CustomTable>
                                            </TableContainer>

                                            {/* <div className="pagination">
                            <CustomPagination
                                count={10}
                                color="primary"
                                page={currentPage}
                                onChange={handlePageChange}
                                showFirstButton
                                showLastButton
                            />
                        </div> */}

                                            <Stack flexDirection={"row"} justifyContent={"flex-end"} alignItems={"center"} gap={1}>

                                                <Tooltip >
                                                    <IconButton disabled={paginationCount === 1} onClick={() => handlePrevPage()}>
                                                        <ArrowBackIosNewOutlinedIcon sx={{ fontSize: "17px" }} />
                                                    </IconButton>
                                                </Tooltip>


                                                <Fab size="small" color="primary" elevation="0" sx={{ color: 'white', boxShadow: 'none' }}>
                                                    {paginationCount}
                                                </Fab>


                                                <IconButton disabled={shiftLength < 10} onClick={() => hanldNextPage()}>
                                                    <ArrowForwardIosOutlinedIcon sx={{ fontSize: "17px" }} />
                                                </IconButton>
                                            </Stack>
                                        </>
                            }

                        </Box>
                    </Paper>
            }


            <Dialog
                open={deleteOpen}
                onClose={() => handleDeleteClose()}
            >
                <DialogTitle >
                    {`Are you sure  want to delete shift ${deleteItems.title}?`}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => handleDeleteClose()}>Cancel</Button>
                    <Button onClick={() => hadleConfrimDelete(deleteItems.id)} color='error' disabled={buttonloading}>
                        {
                            buttonloading ? <CircularProgress size={15} color='error' /> : 'Delete'
                        }

                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}


const ShiftViewBlock = ({ shiftId, state, shiftStatus }) => {

    const navigate = useNavigate()

    let userId = localStorage.getItem('userId');

    let token = localStorage.getItem('token');
    const [flag, setFlag] = useState(false)
    const [fetchLoading, setFetchLoading] = useState(false)

    const [tokenExpireMsg, setTokenExpireMsg] = useState("")
    const [tokenExpOpen, setTokenExpOpen] = useState(false)

    useEffect(() => {
        fetchRequestData()
    }, [shiftId])

    const fetchRequestData = () => {

        setFetchLoading(true)
        shiftStatus !== 'completed' &&
            dataProvider.getList('shift/single/request/' + shiftId, {
                params: {
                    token: token,
                    pagination: {
                        page: 1,
                        perPage: 1000
                    },
                    sort: { order: 'desc', field: '_id' },
                    filter: { clientId: userId }
                }
            }).then(({ data }) => {
                setFlag(data)
                setFetchLoading(false)
            }).catch((error) => {
                setFetchLoading(false)
                if (error?.response?.data?.expire === true) {
                    setTokenExpireMsg(error?.response?.data?.message)
                    setTokenExpOpen(true)
                }
            })
    }


    return (
        <>
            {
                tokenExpOpen ? <ExpirePopup tokenExpireMsg={tokenExpireMsg} /> :
                    <Tooltip title='Requests'>
                        {
                            shiftStatus !== 'completed' ?
                                <IconButton onClick={() => navigate(`/shift/${shiftId}`)} disabled={shiftStatus === 'not_fulfilled'}>
                                    <Badge badgeContent={<span style={{ color: 'white' }}>
                                        {fetchLoading ? <CircularProgress size={8} sx={{ color: "white" }} /> : flag.length}</span>}
                                        color="primary">
                                         <CalendarMonth sx={{ color: shiftStatus !== 'not_fulfilled' ? '#0066b2' : 'grey' }} />
                                    </Badge>

                                </IconButton> :
                                <IconButton onClick={() => navigate(`/shift/${shiftId}`)} disabled={shiftStatus === 'not_fulfilled'}>
                                     <CalendarMonth sx={{ color: shiftStatus !== 'not_fulfilled' ? '#0066b2' : 'grey' }} />
                                </IconButton>

                        }
                    </Tooltip>
            }
        </>
    )
}

export default ShiftListing