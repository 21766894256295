import React from 'react'
import ErrorPic from 'assets/images/error.png'
import styled from '@emotion/styled';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import hirehoodLogo from 'assets/images/hirehoodLogo.png'
const ErrorPage = () => {


    const ErrorBox = styled(Box)(({ theme }) => ({
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }));


    const ImageBox = styled(Box)(({ theme }) => ({
        width: "500px",
        height: "300px"
    }));



    const theme = useTheme();
    const unselectedColor = theme.palette.sidebarColor.unselectedColor;
    let isLoggedIn = localStorage.getItem('isLoggedIn');
    const navigate = useNavigate()


    return (
        <>

            {
                !isLoggedIn &&
                <Stack flexDirection={"row"} alignItems={'center'} m={2} onClick={() => navigate("/")} sx={{ cursor: "pointer" }}>
                    <img src={hirehoodLogo} alt="" width={"60px"} height={"60px"} />
                    <Typography variant='h6' textTransform={"uppercase"}>hirehood</Typography>
                </Stack>
            }
            <ErrorBox>
                <Stack flexDirection={"column"} justifyContent={"center"} alignItems={"center"} >
                    <Typography variant='h2' sx={{ color: unselectedColor }}>404</Typography>
                    <Typography variant='h5' sx={{ color: unselectedColor }}>Page Not Found</Typography>
                    <Typography variant='body1' my={1} sx={{ color: unselectedColor }}>We couldn′t find the page you are looking for. </Typography>
                    <ImageBox>
                        <img src={ErrorPic} alt="" srcset="" width={"100%"} height={"100%"} />
                    </ImageBox>

                    <Button variant='contained' sx={{ color: "white", margin: "20px 0" }} onClick={() => navigate("/")}>back to home</Button>
                </Stack>
            </ErrorBox>
        </>
    )
}

export default ErrorPage