import styled from '@emotion/styled';
import { Alert, Button, CircularProgress, Grid, IconButton, InputAdornment, Paper, Snackbar, Stack, TextField, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import SendIcon from '@mui/icons-material/Send';
import { AddPhotoAlternate, ArrowBackOutlined, CancelOutlined } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';
import dataProvider from 'providers/dataProvider';
import { useNavigate } from 'react-router-dom';
const NotificationCreate = () => {

    const navigate = useNavigate()

    const [notificationForm, setNotificationForm] = useState({
        title: "",
        content: "",
    })

    const [selectedImage, setSelectedImage] = useState({
        file: "",
        blob: ""
    })

    const [err, setErr] = useState(false)
    const [success, setSuccess] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)

    const handleChange = (e) => {
        const { name, value } = e.target
        setNotificationForm((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }


    const handleNotificationImg = () => {
        const fileInput = document.getElementById("profileFile")
        if (fileInput) {
            fileInput.click();
        }
    };


    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            let imageBlob = URL.createObjectURL(selectedFile)
            setSelectedImage((prevState) => ({
                ...prevState,
                file: selectedFile,
                blob: imageBlob
            }))
        }
    }

    const hadleCloseFile = () => {
        setSelectedImage((prevState) => ({
            ...prevState,
            file: "",
            blob: ""
        }))
    }

    const handleSendNotificatoin = () => {
        const { content, title } = notificationForm
        let notification_image = selectedImage.file


        if (content === '' || title === '') {
            setErr(true)
        } else {

            let data = {
                'title': title,
                'description': content,
            }


            if (notification_image !== '' && notification_image !== null) {
                data["notification_image"] = notification_image
            }


            setBtnLoading(true)

            dataProvider.createNotification('notification', data).then((data) => {

                // console.log(data);
                setBtnLoading(false)
                setSuccess(true)
                navigate("/notification")
            }).catch((e) => {
                console.log(e)
                setBtnLoading(false)
            })

        }
    }






    const SendButton = styled(Button)(({ theme }) => ({
        color: "white",
        width: "100px",
        display: "flex",
        alignItems: "center",
        gap: "5px"
    }));



    return (
        <>
            <Grid container spacing={2} my={2}>
                <Grid item md={12}>
                    <Paper sx={{ padding: "15px" }}>

                        <Tooltip title='Back'>
                            <IconButton onClick={() => navigate(`/notification`)} >
                                <ArrowBackOutlined />
                            </IconButton>
                        </Tooltip>

                        <Typography variant='h6' textTransform={'uppercase'} textAlign={"center"} py={2}>Notifications</Typography>
                        <Grid container spacing={2} >
                            <Grid item md={12} xs={12}>
                                <TextField id="outlined-basic"
                                    label="Title"
                                    variant="outlined"
                                    fullWidth
                                    name='title'
                                    onChange={(e) => handleChange(e)}
                                />
                            </Grid>



                            <Grid item md={12} xs={12}>
                                <TextField id="outlined-basic"
                                    label="Select an Image"
                                    variant="outlined"
                                    fullWidth
                                    onClick={handleNotificationImg}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <AddPhotoAlternate />
                                            </InputAdornment>
                                        ),
                                        readOnly: true,
                                    }}
                                />
                            </Grid>


                            {
                                selectedImage.blob &&
                                <Grid item md={12}>
                                    <Paper sx={{
                                        padding: "10px",
                                        height: "120px",
                                        width: "120px",
                                        position: "relative",
                                        marginBottom: "10px",
                                    }}>

                                        <FontAwesomeIcon
                                            icon={faWindowClose}
                                            size='lg'
                                            style={{ color: "#f10909", position: "absolute", top: "0", right: "0", cursor: "pointer" }}
                                            onClick={() => hadleCloseFile()}
                                        />
                                        <img src={selectedImage.blob} alt="" srcset="" width={"100%"} height={"100%"} />
                                    </Paper>
                                </Grid>
                            }


                            <input
                                type="file"
                                id='profileFile'
                                onChange={(e) => handleFileChange(e)}
                                accept="image/*"
                                style={{ display: 'none' }}
                            />

                            <Grid item md={12} xs={12}>
                                <TextField
                                    id="outlined-multiline-static"
                                    label="Content"
                                    multiline
                                    rows={4}
                                    fullWidth
                                    placeholder='Content....'
                                    name='content'
                                    onChange={(e) => handleChange(e)}
                                />
                            </Grid>



                            <Grid item md={12} xs={12}>
                                <Stack flexDirection={"row"} justifyContent={"end"}>
                                    <SendButton variant='contained' onClick={() => handleSendNotificatoin()} disabled={btnLoading}>
                                        {/* {btnLoading ? <CircularProgress size={15} sx={{ color: "white" }} /> : "Send"} */}

                                        <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"center"} gap={1}>
                                            {
                                                btnLoading ? <CircularProgress size={25
                                                } sx={{ color: "white" }} /> : <>
                                                    <Typography variant='button'>Send</Typography>
                                                    <SendIcon sx={{ fontSize: "18px" }} />
                                                </>
                                            }
                                        </Stack>
                                    </SendButton>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>


            <Snackbar open={err}
                autoHideDuration={6000}
                onClose={() => setErr(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={() => setErr(false)} severity="error" variant="filled" sx={{ width: '100%' }}>
                    <Typography>Please fill all fields</Typography>
                </Alert>
            </Snackbar>

            {/* Success Send Request Snackbar */}
            <Snackbar
                open={success}
                autoHideDuration={3000}
                onClose={() => setSuccess(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert
                    onClose={() => setSuccess(false)}
                    // severity="success"
                    variant="filled"  // or "outlined", based on your preference
                    sx={{ width: '100%', color: "white", backgroundImage: "linear-gradient(98deg, #9cc177, #6ba134 94%)" }}
                >
                    Notification sented successfully!
                </Alert>
            </Snackbar>
        </>
    )
}

export default NotificationCreate