import { ThemeProvider, createTheme } from '@mui/material';
import React from 'react'
import { Route, Routes } from 'react-router-dom';
import routes from 'routes/Auth';

const AuthLayout = () => {

  const getRoutes = () => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.path}
            element={<prop.component />}
            key={key}
            exact
          />
        );
      } else {
        return null;
      }
    });
  };


  const theme = createTheme({
    palette: {
      // mode: 'dark', 
      primary: {
        main: '#8fb569',
      },
      secondary: {
        main: '#1976d2'
      },
      backgroundColor: {
        main: "#F4F5FA"
      },
      sidebarColor: {
        selectedColor: "white",
        unselectedColor: "black"
      }
    },
  });


  return (
    <div>
      <ThemeProvider theme={theme}>

        <Routes>
          {getRoutes(routes)}
        </Routes>

      </ThemeProvider>
    </div>
  )
}

export default AuthLayout