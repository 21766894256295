import React from 'react'
import logo from "assets/images/hirehoodLogo.png"
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { Box, Button, Divider, Drawer, ListItemButton, Typography, useTheme } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { CalendarMonth, RequestPageOutlined } from '@mui/icons-material';
import { List, ListItem, ListItemIcon, ListItemText, Paper } from '@mui/material';

import styled from '@emotion/styled';


const ClientSidebar = () => {


    let location = useLocation();
    const navigate = useNavigate()


    const navigation = [
        {
            title: "Dashboard",
            href: "/",
            icon: <HomeOutlinedIcon />
        },
        {
            divider: true,
            dividername: 'Users'
        },
        {
            title: "Staffs",
            href: "/staff",
            icon: <AccountCircleOutlinedIcon />
        },
        {
            divider: true,
            dividername: 'Shift Management'
        },
        {
            title: "Shift Management",
            href: "/shift",
            icon: <CalendarMonth />
        },
        {
            title: "Create Shift",
            href: "/shift/create",
            icon: <CalendarMonth />
        },
        {
            title: "Recent Shifts",
            href: "/recent_shifts",
            icon: <RequestPageOutlined />
        },
        // {
        //     title: "Request",
        //     href: "/Request",
        //     icon: <RequestPageOutlined />
        // },
    ];


    const StyledDrawer = styled(Drawer)(({ theme }) => ({
        '& .MuiDrawer-paper': {
            width: '270px',
            boxSizing: 'border-box',
            border: "none",
            backgroundColor: backgroundColor,
            overflowY: 'auto', // Hide scrollbar by default
            '&::-webkit-scrollbar': {
                width: '0', // Set the width of the scrollbar
            },

        },
    }));

    const StyledListItemButtons = styled(ListItemButton)(({ theme }) => ({
        borderTopRightRadius: "30px",
        borderBottomRightRadius: "30px",
        margin: "2px 0",
        height: "45px",
    }));

    const theme = useTheme();
    const selectedColor = theme.palette.sidebarColor.selectedColor;
    const unselectedColor = theme.palette.sidebarColor.unselectedColor;
    const backgroundColor = theme.palette.backgroundColor.main;

    return (
        <>
            {/* <StyledDrawer variant="permanent" anchor="left" > */}

            <Box>
                <Box sx={{
                    display: "flex", alignItems: "center",
                    // position: 'fixed',
                    zIndex: 1, backgroundColor: backgroundColor,
                    width: "17%",
                    padding: "10px",
                    cursor: "pointer"
                }}
                    onClick={() => navigate("/")}
                >
                    <img src={logo} alt="" width={"60px"} height={"60px"} />
                    <Typography variant='button' fontSize={"1.2rem"} sx={{ color: unselectedColor }}>hirehood</Typography>
                </Box>
            </Box>
            <Box sx={{
                width: '90%',
                // marginTop: "80px"
            }}>
                <List>
                    {
                        navigation.map((navs, index) => (
                            navs.divider ? (
                                <Divider key={index} textAlign='left' sx={{ margin: "20px 0" }}>
                                    <Typography fontSize={'.9rem'} sx={{ color: location.pathname === navs.href ? selectedColor : unselectedColor }}>{navs.dividername}</Typography>
                                </Divider>
                            ) :
                                (
                                    <ListItem disablePadding key={index}>
                                        <StyledListItemButtons onClick={() => navigate(navs.href)}
                                            sx={{
                                                backgroundImage: location.pathname === navs.href && 'linear-gradient(98deg, #9cc177, #6ba134 94%)',
                                                boxShadow: location.pathname === navs.href && '0px 4px 8px -4px rgba(58, 53, 65, 0.42)',
                                                color: location.pathname === navs.href ? selectedColor : unselectedColor
                                            }}
                                        >

                                            <Box sx={{ display: 'flex', alignItems: "center", gap: "10px" }}>
                                                <span>{navs.icon}</span>
                                                <Typography>{navs.title}</Typography>
                                            </Box>
                                        </StyledListItemButtons>
                                    </ListItem>
                                )
                        ))
                    }
                </List>
            </Box>
            {/* </StyledDrawer> */}
        </>
    )
}

export default ClientSidebar