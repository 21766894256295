import ErrorPage from "components/common/404";
import ForgotPassword from "views/auth/ForgotPassword";
import Login from "views/auth/Login";
import Register from "views/auth/Register";
import ResetPassword from "views/auth/ResetPassword";


var routes = [
    {
        path: "/",
        name: "Login",
        icon: "places1",
        component: Login,
        layout: "/auth",
        show: true
    },
    {
        path: "/Register",
        name: "Register",
        icon: "places1",
        component: Register,
        layout: "/auth",
        show: true
    },
    // {
    //     path: "/ForgotPassword",
    //     name: "ForgotPassword",
    //     icon: "places1",
    //     component: ForgotPassword,
    //     layout: "/auth",
    //     show: true
    // },
    // {
    //     path: "/ResetPassword",
    //     name: "ResetPassword",
    //     icon: "places1",
    //     component: ResetPassword,
    //     layout: "/auth",
    //     show: true
    // },
    {
        path: "*",
        name: "ErrorPage",
        icon: "places1",
        component: ErrorPage,
        layout: "/auth",
        show: true
    },
]

export default routes;