import React, { useEffect, useState } from 'react'
import 'assets/css/admin/create-user.css'
import { Alert, Box, Button, CircularProgress, FormHelperText, Grid, IconButton, InputAdornment, MenuItem, OutlinedInput, Paper, Select, Snackbar, TextField, Tooltip } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { AccountCircleOutlined, ArrowBackOutlined, EmailOutlined, HttpsOutlined, LocalPhoneOutlined, ManageAccountsOutlined, VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material'
import dataProvider from 'providers/dataProvider';
import styled from '@emotion/styled';

const CreateUser = () => {

  const navigate = useNavigate();

  let token = localStorage.getItem('token');

  const [showPassword, setShowPassword] = useState(false);
  const [confirmshowPassword, setConfirmShowPassword] = useState(false);

  const [buttonloading, setButtonLoading] = useState(false)

  const [errroModal, setErrorModal] = useState(false)

  const [credentialError, setCrendentialError] = useState("")
  const [staffRole, setStaffRole] = useState("")

  const [userDetails, setUserDetails] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: ""
  })


  const [userDetailsError, setUserDetailsError] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: ""
  })

  const [isUserDetailsError, setIsUserDetailsError] = useState({
    isfirst_name: false,
    islast_name: false,
    isEmail: false,
    isPhoneNumber: false,
    isPassword: false,
    isConfirmPassword: false
  })

  const [staffTypeData, setStaffTypeData] = useState([])
  const [typeLoading, setTypeLoading] = useState(false)

  const [roleErr, setRoleErr] = useState(false)

  const firstNameRegex = /^[a-zA-Z\- ']+$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneNumberRegex = /^\d{9}$/;
  let passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]).{6,}$/;

  useEffect(() => {
    fetchStaffType()
  }, [])



  const fetchStaffType = () => {
    setTypeLoading(true)
    dataProvider.getList('staff_type', {
      params: {
        token: token,
        pagination: { page: 1, perPage: 1000 },
        sort: { order: 'desc', field: 'id' },
        filter: {}
      }
    })
      .then(({ data }) => {
        // console.log(data)
        setStaffTypeData(data)
        setTypeLoading(false)
      })
      .catch(error => {
        setTypeLoading(false)
        console.log(error?.response?.data)
        console.log(error?.response?.data?.expire)
        // if (error?.response?.data?.expire === true) {
        //     setTokenExpireMsg(error?.response?.data?.message)
        //     setTokenExpOpen(true)
        // }
      })
  }


  const handleInputChange = (e, regex, isError) => {

    const { name, value } = e.target

    if (name === 'confirmPassword') {

      setUserDetails((prevState) => ({ ...prevState, confirmPassword: value }))

      handleInputErrors(value, regex, name, isError, userDetails.password)

    } else {
      setUserDetails((prevState) => ({ ...prevState, [name]: value }))

      handleInputErrors(value, regex, name, isError)
    }
  }



  const handleInputErrors = (inputValue, regex, filedname, isError, checkingPassword) => {


    // if (!regex.test(inputValue) || (filedname === "confirmPassword" && inputValue !== checkingPassword)) {

    //   setUserDetailsError((prevState) => ({ ...prevState, [filedname]: getErrorMessage(filedname) }))
    //   setIsUserDetailsError((prevState) => ({ ...prevState, [isError]: true }))

    // } else {
    //   setUserDetailsError((prevState) => ({ ...prevState, [filedname]: "" }))
    //   setIsUserDetailsError((prevState) => ({ ...prevState, [isError]: false }))

    // }


    if (!regex.test(inputValue)) {
      setUserDetailsError((prevState) => ({
        ...prevState,
        [filedname]: getErrorMessage(filedname)
      }));

      setIsUserDetailsError((prevState) => ({
        ...prevState,
        [isError]: true
      }));
    } else if (filedname === "confirmPassword" && inputValue !== checkingPassword) {
      setUserDetailsError((prevState) => ({
        ...prevState,
        [filedname]: getErrorMessage("confirmPasswordMismatch")
      }));

      setIsUserDetailsError((prevState) => ({
        ...prevState,
        [isError]: true
      }));
    } else {
      setUserDetailsError((prevState) => ({
        ...prevState,
        [filedname]: ""
      }));

      setIsUserDetailsError((prevState) => ({
        ...prevState,
        [isError]: false
      }));
    }
  }



  const getErrorMessage = (filedname) => {
    switch (filedname) {
      case "first_name":
        return "Invalid  format";
      case "last_name":
        return "Invalid  format";
      case "email":
        return "Invalid email format";
      case "phoneNumber":
        return "Phone number contains  9 digits"
      case "password":
        return "Password contains six characters, capital, number, special character";
      case "confirmPassword":
        return "Password contains six characters, capital, number, special character";
      case "confirmPasswordMismatch":
        return "Password does not match";
      default:
        return "Invalid format"
    }
  }



  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickConfirmShowPassword = () => setConfirmShowPassword((confirmshowPassword) => !confirmshowPassword);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleRoleChange = (e) => {
    const { name, value } = e.target;
    setStaffRole(value)
    setRoleErr(false)
  }



  const handleSubmit = () => {
    const { first_name, last_name, email, phoneNumber, password, confirmPassword } = userDetails;
    let hasError = false;

    const setError = (errorfield, errorStatus, filedname, fieldError) => {
      setIsUserDetailsError((prevState) => ({ ...prevState, [errorfield]: errorStatus }));
      setUserDetailsError((prevState) => ({ ...prevState, [filedname]: fieldError }))
      if (staffRole === '' || staffRole === null) {
        setRoleErr(errorStatus)
      }
      hasError = true;
    };



    let errorWord = 'Please fill all fields'

    if (first_name === '') setError('isfirst_name', true, 'first_name', 'First Name is required');
    if (last_name === '') setError('islast_name', true, 'last_name', 'Last Name is required');
    if (email === '') setError('isEmail', true, 'email', 'Email is required');
    if (phoneNumber === '') setError('isPhoneNumber', true, 'phoneNumber', 'Phone number is required');
    if (password === '') setError('isPassword', true, 'password', 'Password is required');
    if (confirmPassword === '') setError('isConfirmPassword', true, 'confirmPassword', 'Confirm password is required');
    if (staffRole === '' || staffRole === null) setError('', true, '', '')
    if (password !== confirmPassword) setError('isConfirmPassword', true, 'confirmPassword', 'Password does not match');

    if (!hasError) {

      const data = {
        'first_name': first_name,
        'last_name': last_name,
        'email': email,
        'phone': phoneNumber,
        'password': password,
        'role': 'customer',
        'staff_role': staffRole
      }

      // console.log(data);

      setButtonLoading(true)
      // setButtonLoading(false)



      dataProvider.create('users/staff', data).then((data) => {
        navigate('/staff')
        setButtonLoading(false)
      }).catch((e) => {
        console.log(e)
        console.log(e?.response?.data?.msg);
        setButtonLoading(false)
        setCrendentialError(e?.response?.data?.msg)
        setErrorModal(true)
      })
    }
  };

  const CreateButton = styled(Button)(({ theme }) => ({
    color: "white",
    // backgroundImage: "linear-gradient(98deg, #9cc177, #6ba134 94%)",
    height: "40px",
    width: "100px",
    display: "flex",
    justifyContent: "center"

  }));



  return (
    <>
      <Paper sx={{ margin: "20px 0" }}>
        <Box p={2} >
          <Tooltip title='Back'>
            <IconButton onClick={() => navigate('/staff')}>
              <ArrowBackOutlined />
            </IconButton>
          </Tooltip>

          <h3 className='newuserText' style={{ marginBottom: "20px" }}>Create a new Staff</h3>

          <Grid container spacing={2} >

            {/* First name */}
            <Grid item md={6} xs={12}>
              <TextField id="outlined-basic"
                label="Firstname"
                variant="outlined"
                name='first_name'
                onChange={(e) => handleInputChange(e, firstNameRegex, 'isfirst_name')}
                fullWidth
                error={isUserDetailsError.isfirst_name}
                helperText={userDetailsError.first_name && userDetailsError.first_name}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleOutlined />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            {/* Last Name */}
            <Grid item md={6} xs={12}>
              <TextField id="outlined-basic"
                label="Lastname"
                variant="outlined"
                name='last_name'
                onChange={(e) => handleInputChange(e, firstNameRegex, 'islast_name')}
                fullWidth
                error={isUserDetailsError.islast_name}
                helperText={userDetailsError.last_name && userDetailsError.last_name}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleOutlined />
                    </InputAdornment>
                  ),
                }}

              />
            </Grid>

            {/* Email */}
            <Grid item md={6} xs={12}>
              <TextField id="outlined-basic"
                label="Email"
                type='email'
                variant="outlined"
                fullWidth
                error={isUserDetailsError.isEmail}
                helperText={userDetailsError.email && userDetailsError.email}
                name='email'
                onChange={(e) => handleInputChange(e, emailRegex, 'isEmail')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailOutlined />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            {/* Phone number */}
            <Grid item md={6} xs={12}>
              <TextField id="outlined-basic"
                label="Phone number"
                type='number'
                variant="outlined"
                fullWidth
                name='phoneNumber'
                onChange={(e) => handleInputChange(e, phoneNumberRegex, 'isPhoneNumber')}
                error={isUserDetailsError.isPhoneNumber}
                helperText={userDetailsError.phoneNumber && userDetailsError.phoneNumber}
                InputProps={{
                  startAdornment: <InputAdornment position="start">+353</InputAdornment>,
                }}
              />
            </Grid>

            {/* Role */}
            <Grid item md={12} xs={12}>
              <FormControl fullWidth error={roleErr} >
                <InputLabel >Select Staff Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={staffRole}
                  label="Select a Customer"
                  onChange={handleRoleChange}
                >
                  {
                    typeLoading ? (

                      <MenuItem disabled>Loading...</MenuItem>
                    ) :
                      staffTypeData.length === 0 ?
                        <MenuItem disabled>Staff type is not added</MenuItem> :
                        staffTypeData?.map((type) => (
                          <MenuItem value={type?.type}>{type?.type}</MenuItem>
                        ))
                  }
                </Select>
                <FormHelperText>{roleErr && 'Please Select a Role'}</FormHelperText>
              </FormControl>
            </Grid>

            {/* Password */}
            <Grid item md={6} xs={12}>
              <FormControl variant="outlined" fullWidth error={isUserDetailsError.isPassword} required >
                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  name='password'
                  onChange={(e) => handleInputChange(e, passwordRegex, 'isPassword')}
                  startAdornment={
                    <InputAdornment position="start">
                      <HttpsOutlined />
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
                <FormHelperText>{userDetailsError.password && userDetailsError.password}</FormHelperText>
              </FormControl>
            </Grid>

            {/* Confrim Password */}
            <Grid item md={6} xs={12}>
              <FormControl variant="outlined" fullWidth error={isUserDetailsError.isConfirmPassword} >
                <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-confrim-password"
                  type={confirmshowPassword ? 'text' : 'password'}
                  name='confirmPassword'
                  onChange={(e) => handleInputChange(e, passwordRegex, 'isConfirmPassword')}
                  startAdornment={ // This is where you can add the InputAdornment
                    <InputAdornment position="start">
                      <HttpsOutlined />
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickConfirmShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {confirmshowPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Confirm Password"
                />
                <FormHelperText>{userDetailsError.confirmPassword && userDetailsError.confirmPassword}</FormHelperText>
              </FormControl>
            </Grid>

          </Grid>

          <Box sx={{ display: "flex", justifyContent: "end", marginTop: "10px" }}>

            <CreateButton
              variant='contained'
              disabled={Object.values(userDetailsError).some((error) => error !== "") || buttonloading}
              onClick={() => handleSubmit()}
            >
              {
                buttonloading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : 'Create'
              }
            </CreateButton>

          </Box>


        </Box>


        <Snackbar open={errroModal}
          autoHideDuration={6000}
          onClose={() => setErrorModal(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert onClose={() => setErrorModal(false)} severity="error" sx={{ width: '100%' }}>
            {credentialError}
          </Alert>
        </Snackbar>

      </Paper>
    </>
  )
}

export default CreateUser