import React, { useEffect, useState } from 'react'
import 'assets/css/admin/edit-user.css'
import dataProvider from 'providers/dataProvider'
import { Avatar, Box, Button, Chip, CircularProgress, Fab, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import 'assets/css/client/customer-shift.css'
import { CheckCircle, DoNotDisturb, Download, Visibility } from '@mui/icons-material'
import ViewShift from 'views/admin/shifts/view';
import ExpirePopup from 'components/common/ExpirePopup'
import hirehoodLogo from 'assets/images/hirehoodLogo.png'
import html2pdf from 'html2pdf.js';

const CustomerShifts = ({ userid, oneUserDetails }) => {


  let token = localStorage.getItem('token');

  const [customerShiftData, setCustomerShiftData] = useState([])
  const [prevShiftData, setPrevShiftData] = useState([])
  const [upComignShiftData, setUpComingShiftData] = useState([])

  const [selectedChipDetails, setSelectedChipDetails] = useState("next")

  const [tokenExpireMsg, setTokenExpireMsg] = useState("")
  const [tokenExpOpen, setTokenExpOpen] = useState(false)


  const [upcomingLoading, setUpComingLoading] = useState(false)
  const [previousLoading, setPreviousLoading] = useState(false)

  const [pdfLoading, setPdfLoading] = useState(false)


  const [certificateLoading, setCertificateLoading] = useState({})



  useEffect(() => {
    // fetchCustomerShift()
    fetchUpComingShift()
    fetchPreviousShift()
  }, [])

  // const fetchCustomerShift = () => {
  //   dataProvider.getList('shift/request', {
  //     params: {
  //       token: token,
  //       pagination: { page: 1, perPage: 1000 },
  //       sort: { order: 'desc', field: 'id' },
  //       filter: { customerId: userid }
  //     }
  //   })
  //     .then(({ data }) => {
  //       // console.log(data)
  //       setCustomerShiftData(data)
  //     })
  //     .catch(error => {
  //       console.log(error?.response?.data)
  //       console.log(error?.response?.data?.expire)
  //       if (error?.response?.data?.expire === true) {
  //         setTokenExpireMsg(error?.response?.data?.message)
  //         setTokenExpOpen(true)
  //       }
  //     })
  // }

  const fetchUpComingShift = () => {
    setUpComingLoading(true)
    dataProvider.getOne('shift/customer/upcoming', { id: userid }).then(({ data }) => {
      setUpComingLoading(false)
      setUpComingShiftData(data)
    }).catch((err) => {
      console.log(err)
      setUpComingLoading(false)
    })
  }

  const fetchPreviousShift = () => {
    setPreviousLoading(true)
    dataProvider.getOne('shift/customer/previous', { id: userid }).then(({ data }) => {
      setPreviousLoading(false)
      setPrevShiftData(data)
    }).catch((err) => {
      console.log(err)
      setPreviousLoading(false)
    })
  }

  const hanldleChipClick = (shifts) => {
    setSelectedChipDetails(shifts)
  }



  let isPdfGenerationInProgress = false;

  const handlePdf = async (shift) => {
    // Check if PDF generation is already in progress
    if (isPdfGenerationInProgress) {
      return;
    }

    // Set the flag to indicate that PDF generation is now in progress
    isPdfGenerationInProgress = true;
    setPdfLoading(true)
    setCertificateLoading((prevState) => ({
      ...prevState,
      [shift?._id]: true
    }))

    const { title, shift_type, client, location, area, date, time_from, time_to, description, customerId,
      distance, ending_point, starting_point, client_sign, customer_sign, hospital_name, ward, floor, breaking_hours, date_to, breaking_reason } = shift


    console.log(breaking_hours);


    try {
      const clientSignBase64 = await convertToBase64(client_sign);
      const customerSignBase64 = await convertToBase64(customer_sign);
      const logoBase64 = await convertToBase64(hirehoodLogo)

      const htmlContent =
        `
  <div>
  <div style="display: flex;align-items: center;margin: 10px 0;">
      <img src=${logoBase64} style="width: 80px; height: 80px;" />
      <h2>Hirehood</h2>
  </div>
  <hr>
  <div>
      <h2 style="text-align: center;text-transform: uppercase;margin: 30px 0;">Time Sheet Details</h2>


      <div style="display: flex; justify-content: space-between; margin: 10px;">
          <div style="flex-basis: calc(50% - 5px);">
              <div
                  style="display: flex; flex-direction: column; gap: 20px; padding: 10px; border: 1px solid #ccc;border-radius: 5px;">

                  <div style="display: flex;align-items: center;gap: 10px;">
                      <h3>Title</h3>
                      <span>:</span>
                      <p>${title}</p>
                  </div>


                  <div style="display: flex;align-items: center;gap: 10px;">
                      <h3>Ward</h3>
                      <span>:</span>
                      <p>${ward}</p>
                  </div>


                  <div style="display: flex;align-items: center;gap: 10px;">
                      <h3>Staff</h3>
                      <span>:</span>
                      <p>${oneUserDetails?.first_name}${" "}${oneUserDetails?.last_name}</p>
                  </div>


                  <div style="display: flex;align-items: center;gap: 10px;">
                      <h3>Location</h3>
                      <span>:</span>
                      <p>${location[0]?.location}</p>
                  </div>


                  <div style="display: flex;align-items: center;gap: 10px;">
                      <h3>Date</h3>
                      <span>:</span>
                      <p>${date && new Date(date).toLocaleDateString('en-GB', { timeZone: 'UTC' })}${" - "}
                      ${date_to
        && new Date(date_to).toLocaleDateString('en-GB', { timeZone: 'UTC' })
        }
    </p >
                  </div >

                  <div style="display: flex;align-items: center;gap: 10px;">
                      <h3>Time From</h3>
                      <span>:</span>
                      <p>${time_from}</p>
                  </div>



                  <div style="display: flex;align-items: center;gap: 10px;">
                      <h3>Starting Eircode</h3>
                      <span>:</span>
                      <p>${starting_point}</p>
                  </div>


                  <div style="display: flex;align-items: center;gap: 10px;">
                      <h3>Distance</h3>
                      <span>:</span>
                      <p>${distance}</p>
                  </div>

                  <div style="display: flex;align-items: center;gap: 10px;">
                      <h3>Break Minutes</h3>
                      <span>:</span>
                      <p>${breaking_hours ? breaking_hours : 'No Break Minutes'}</p>
                  </div>

              </div >
          </div >

  <div style="flex-basis: calc(50% - 5px);">
    <div
      style="display: flex; flex-direction: column; gap: 20px; padding: 10px; border: 1px solid #ccc;border-radius: 5px;">


      <div style="display: flex;align-items: center;gap: 10px;">
        <h3>Hosptial Name</h3>
        <span>:</span>
        <p>${hospital_name}</p>
      </div>


      <div style="display: flex;align-items: center;gap: 10px;">
        <h3>Manager name</h3>
        <span>:</span>
        <p>${floor}</p>
      </div>

      <div style="display: flex;align-items: center;gap: 10px;">
        <h3>Employer</h3>
        <span>:</span>
        <p>${client[0]?.first_name}${" "}${client[0]?.last_name}</p>
      </div>


      <div style="display: flex;align-items: center;gap: 10px;">
        <h3>Area</h3>
        <span>:</span>
        <p>${area[0]?.area}</p>
      </div>



      <div style="display: flex;align-items: center;gap: 10px;">
        <h3>Shfit Type</h3>
        <span>:</span>
        <p>${shift_type}</p>
      </div>


      <div style="display: flex;align-items: center;gap: 10px;">
        <h3>Time To</h3>
        <span>:</span>
        <p>${time_to}</p>
      </div>

      <div style="display: flex;align-items: center;gap: 10px;">
        <h3>Ending Eircode</h3>
        <span>:</span>
        <p>${ending_point}</p>
      </div>
    </div>
  </div>
      </div >


  <div style="margin: 10px;">
    <div
      style="display: flex; flex-direction: column; gap: 20px; padding: 10px; border: 1px solid #ccc;border-radius: 5px;">


      <div style="display: ${breaking_reason ? 'flex' : 'none'};align-items: start;gap: 10px;margin: 10px 0;">
        <h3>Break Reason</h3>
        <span>:</span>
        <p>${breaking_reason ? breaking_reason : 'No Break Reson'}</p>
      </div>

      <div style="display: flex;align-items: center;gap: 10px;">
        <h3>Description</h3>
        <span>:</span>
        <p>${description}</p>
      </div>
    </div>
  </div>






  </div >
  <hr>
    <div style="margin: 20px 0;">
      <div style="display: flex;align-items:center;justify-content: space-around;">
        <div>
          <h3>Signature( Submitted by Staff )</h3>
          <img src=${customerSignBase64} alt="" style="width: 100px; height: 100px;margin: 10px 0;">
        </div>

        <div>
          <h3>Signature( Submitted by Employer )</h3>
          <img src=${clientSignBase64} alt="" style="width: 100px; height: 100px;margin: 10px 0;">
        </div>
      </div>
    </div>

  </div>
`


      const element = document.createElement('div');
      element.innerHTML = htmlContent;

      html2pdf(element, {
        margin: 10,
        filename: 'timesheet.pdf',
        image: { type: 'png', quality: 0.98 },
        html2canvas: { scale: 1 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
      }).outputPdf().then((pdfBlob) => {

        isPdfGenerationInProgress = false;
        setPdfLoading(false)
        setCertificateLoading((prevState) => ({
          ...prevState,
          [shift?._id]: false
        }))

        const blobUrl = URL.createObjectURL(pdfBlob);


        const downloadLink = document.createElement('a');
        downloadLink.href = blobUrl;
        downloadLink.download = 'timesheet.pdf';
        downloadLink.click();


        URL.revokeObjectURL(blobUrl);
      });

    } catch (error) {
      console.error('Error converting client sign to base64:', error);
    }



    // Example HTML-like content

  };



  const convertToBase64 = (imageUrl) => {
    return new Promise((resolve, reject) => {
      fetch(imageUrl)
        .then(response => response.blob())
        .then(blob => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve(reader.result);
          };
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
        .catch(reject);
    });
  }





  return (
    <>

      {

        tokenExpOpen ? <ExpirePopup tokenExpireMsg={tokenExpireMsg} /> :
          <>
            <Box p={2}>
              <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'space-around'}>
                <Chip sx={{ backgroundColor: selectedChipDetails === 'next' ? '#8fb569' : 'defaultColor', width: "150px" }} label={<span style={{ color: selectedChipDetails === 'next' ? 'white' : 'black' }} >Upcoming Shifts</span>} onClick={() => hanldleChipClick('next')} />
                <Chip sx={{ backgroundColor: selectedChipDetails === 'prev' ? '#8fb569' : 'defaultColor', width: "150px" }} label={<span style={{ color: selectedChipDetails === 'prev' ? 'white' : 'black' }}>Previous Shifts</span>} onClick={() => hanldleChipClick('prev')} />
              </Stack>


              {

                upcomingLoading && selectedChipDetails === 'next' ?
                  <div className="loader-position">
                    <CircularProgress color="primary" size={45} />
                  </div>
                  :

                  upComignShiftData.length === 0 && selectedChipDetails === 'next' ?
                    <Typography sx={{ m: 3, textAlign: "center" }}>Empty Shifts</Typography>
                    :
                    selectedChipDetails === 'next' &&
                    <TableContainer sx={{ margin: "20px 0" }}>
                      <Table sx={{ minWidth: 650, '& th, & td': { padding: '10px' } }} size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell >
                              <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                Title
                              </Typography>
                            </TableCell>
                            <TableCell >
                              <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                Date
                              </Typography>
                            </TableCell>
                            <TableCell >
                              <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                Employer
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>

                          {
                            selectedChipDetails === 'next' && upComignShiftData.map((shift) => (
                              <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >

                                <TableCell ><Typography sx={{ fontSize: '1rem' }}>{shift?.title}</Typography></TableCell>
                                <TableCell >
                                  <div className="slot-date-time">
                                    <Typography sx={{ fontSize: '1rem' }}>
                                      {new Date(shift?.date).toLocaleDateString('en-GB', { timeZone: 'UTC' })}
                                      {" "}
                                      {" - "}
                                      {" "}
                                      {new Date(shift?.date_to).toLocaleDateString('en-GB', { timeZone: 'UTC' })}
                                    </Typography>
                                    <Typography sx={{ fontSize: '.9rem' }}>{shift?.time_from} - {shift?.time_to}</Typography>
                                  </div>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <div className="profile-image-table">
                                    <Avatar src={shift?.client[0]?.avatar_path} />
                                    <Typography sx={{ fontSize: '1rem', fontWeight: '600' }}>{shift?.client[0]?.first_name}</Typography>
                                  </div>
                                </TableCell>

                              </TableRow>
                            ))
                          }


                        </TableBody>
                      </Table>
                    </TableContainer>
              }




              {

                previousLoading && selectedChipDetails === 'prev' ?
                  <div className="loader-position">
                    <CircularProgress color="primary" size={45} />
                  </div>
                  :
                  prevShiftData.length === 0 && selectedChipDetails === 'prev' ?
                    <Typography sx={{ m: 3, textAlign: "center" }}>Empty Shifts</Typography>
                    :
                    selectedChipDetails === 'prev' &&
                    <TableContainer sx={{ margin: "20px 0" }}>
                      <Table sx={{ minWidth: 650, '& th, & td': { padding: '10px' } }} size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell >
                              <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                Title
                              </Typography>
                            </TableCell>
                            <TableCell >
                              <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                Date
                              </Typography>
                            </TableCell>
                            <TableCell >
                              <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                Employer
                              </Typography>
                            </TableCell>
                            <TableCell >
                              <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                Time Sheet
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {

                            selectedChipDetails === 'prev' && prevShiftData.map((shift) => (
                              <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >

                                <TableCell ><Typography sx={{ fontSize: '1rem' }}>{shift?.title}</Typography></TableCell>
                                <TableCell >
                                  <div className="slot-date-time">
                                    <Typography sx={{ fontSize: '1rem' }}>
                                      {new Date(shift?.date).toLocaleDateString('en-GB', { timeZone: 'UTC' })}
                                      {" "}
                                      {" - "}
                                      {" "}
                                      {new Date(shift?.date_to).toLocaleDateString('en-GB', { timeZone: 'UTC' })}
                                    </Typography>
                                    <Typography sx={{ fontSize: '.9rem' }}>{shift?.time_from} - {shift?.time_to}</Typography>
                                  </div>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <div className="profile-image-table">
                                    <Avatar src={shift?.client[0]?.avatar_path} />
                                    <Typography sx={{ fontSize: '1rem', fontWeight: '600' }}>{shift?.client[0]?.first_name}</Typography>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <Stack flexDirection={"row"} alignItems={"center"}>

                                    {
                                      certificateLoading[shift?._id] ?
                                        <CircularProgress color="primary" size={28} sx={{ marginLeft: "8px" }} />
                                        :
                                        <Tooltip title='Download'>
                                          <IconButton onClick={() => handlePdf(shift)}>
                                            <Download sx={{ color: '#0066b2' }} />
                                          </IconButton>
                                        </Tooltip>
                                    }

                                  </Stack>

                                </TableCell>

                              </TableRow>
                            ))
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
              }
            </Box>

          </>
      }



      {/* Certificate View Modal */}
      {/* <Dialog
        open={iscertificateModalOpen}
        onClose={() => setIsCertificateModalOpen(false)}
        scroll='paper'
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
      >
        <DialogTitle id="scroll-dialog-title">Certificate{" "}{certificateTitle}</DialogTitle>
        <DialogContent >
          <DialogContentText
            id="scroll-dialog-description"
            tabIndex={-1}
          >
            {
              isPdf(docPath) ? (
                <div >
                  <Document file={docPath} onLoadSuccess={handleLoadSuccess} >
                    <Page
                      renderTextLayer={false}
                      renderAnnotationLayer={false}
                      customTextRenderer={false}
                      pageNumber={pageNumber} />
                  </Document>
                </div>
              ) : (
                <img src={docPath} alt="Certificate" style={{ maxWidth: '100%', maxHeight: '100%' }} />
              )
            }

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {
            isPdf(docPath) && <Box >
              <Tooltip title='Prev'>
                <IconButton onClick={() => setPageNumber((prevPage) => prevPage - 1)} disabled={pageNumber <= 1}>
                  <ArrowBackIosNewOutlinedIcon
                    fontSize='small' sx={{ color: '#A9A9A9' }}
                  />
                </IconButton>
              </Tooltip>


              <Button>{pageNumber}</Button>

              <Tooltip title='Next'>
                <IconButton onClick={() => setPageNumber((prevPage) => prevPage + 1)}
                  disabled={pageNumber >= numPages}>
                  <ArrowForwardIosOutlined
                    fontSize='small'
                    sx={{ color: '#A9A9A9' }}
                  />
                </IconButton>
              </Tooltip >
            </Box>
          }

          <Button onClick={() => setIsCertificateModalOpen(false)}>Ok</Button>
        </DialogActions>
      </Dialog> */}
    </>
  )
}

export default CustomerShifts