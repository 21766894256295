import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'

const DeleteAreaModal = ({ iseDeleteModal, handleCloseDeleteModal, handleConfirmDelete, selectedDeleteDetails, buttonloading }) => {

    const { id, area } = selectedDeleteDetails

    return (
        <>
            <Dialog
                open={iseDeleteModal}
                onClose={() => handleCloseDeleteModal()}
            >
                <DialogTitle >
                    {`Are you sure want to delete area ${area} ?`}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => handleCloseDeleteModal()}>Cancel</Button>
                    <Button onClick={() => handleConfirmDelete(id)} autoFocus color='error' disabled={buttonloading}>
                        {
                            buttonloading ? <CircularProgress size={15} color='error' /> : 'Delete'
                        }
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default DeleteAreaModal