import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Avatar, Badge, Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Pagination, Paper, Select, Stack, TextField, Tooltip, Typography, makeStyles } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import "assets/css/admin/shift-listing.css"
import { ArrowBack, ArrowDownwardOutlined, ArrowForward, ArrowUpwardOutlined, CalendarMonth, Close, Delete, DeleteOutline, FilterAlt, ModeEdit, Search, SearchOffOutlined, SearchOutlined, Visibility, VisibilityOutlined } from '@mui/icons-material';
import { styled } from '@mui/system';
import dataProvider from 'providers/dataProvider';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import ViewShift from './view';
import ExpirePopup from 'components/common/ExpirePopup';
import EditIcon from '@mui/icons-material/Edit';
import dayjs from 'dayjs';
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faLeftRight } from '@fortawesome/free-solid-svg-icons';
import { DatePicker, DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useAdminContextProvider } from 'providers/adminContextProvider';
const ShiftListing = () => {

  let token = localStorage.getItem('token');
  const { setNetworkErr } = useAdminContextProvider()


  const CustomTable = styled(Table)({
    // borderCollapse: "separate"
  });

  const navigate = useNavigate()


  const [shiftDetailData, setShiftDetailsData] = useState([])
  const [employers, setEmployers] = useState([])
  const [employerLoading, setEmployerLoading] = useState(true)


  const [shiftLoading, setShiftLoading] = useState(false)
  const [shiftViewModal, setShiftViewModal] = useState(false)

  const [selectedShiftDetails, setSelectedShiftDetails] = useState({})

  const [shiftLength, setShiftLength] = useState(null)

  const [order, setOrder] = useState('desc');
  const [field, setField] = useState('id')
  const [clickCount, setClickCount] = useState(1);
  let [paginationCount, setPaginationCount] = useState(1)

  const [tokenExpireMsg, setTokenExpireMsg] = useState("")
  const [tokenExpOpen, setTokenExpOpen] = useState(false)


  const [pdfLoading, setPdfLoading] = useState(false)

  const [datePickerFrom, setdatePickerFrom] = useState(false)
  const [datePickerTo, setdatePickerTo] = useState(false)

  const [disabledDate, setDisabledDate] = useState({
    startDate: "",
    endDate: ""
  })

  const [filterData, setFilterData] = useState({
    shiftType: "",
    // date: "",
    from: "",
    to: "",
    shiftStatus: "",
    employer: ""
  })

  const [filterFields, setFilterFields] = useState({

  })


  const [staffTypeData, setStaffTypeData] = useState([])
  const [typeLoading, setTypeLoading] = useState(false)

  const [shiftAccept, setShiftAccept] = useState(false)
  const [shiftAcceptData, setShiftAcceptData] = useState({
    shiftId: "",
    title: ""
  })

  const [deleteModal, setDeleteModal] = useState(false);

  const [deleteId, setDeleteId] = useState("")

  const [buttonloading, setButtonLoading] = useState(false)


  useEffect(() => {
    fetchShiftData()
    fetchStaffType()
    fetchEmployer()
  }, [order, field, paginationCount, filterFields])

  const fetchShiftData = () => {
    setShiftLoading(true)
    dataProvider.getList('shift',

      {
        params: {
          token: token,
          pagination: {
            page: paginationCount,
            perPage: 10
          },
          sort: { order: order, field: field },
          filter: filterFields
        }
      })
      .then(({ data }) => {
        //console.log(data);
        setShiftLength(data.length)
        setShiftDetailsData(data)
        setShiftLoading(false)
      })
      .catch(error => {
        // console.log(error)
        setShiftLoading(false)
        // console.log(error?.message)
        // console.log(error?.response?.data?.expire)
        if (error.message === 'Network Error') {
          setNetworkErr(true)
        }


        if (error?.response?.data?.expire === true) {
          setTokenExpireMsg(error?.response?.data?.message)
          setTokenExpOpen(true)
        }
      })
  }

  const fetchStaffType = () => {
    setTypeLoading(true)
    dataProvider.getList('staff_type', {
      params: {
        token: token,
        pagination: { page: 1, perPage: 1000 },
        sort: { order: 'desc', field: 'id' },
        filter: {}
      }
    })
      .then(({ data }) => {
        // console.log(data)
        setStaffTypeData(data)
        setTypeLoading(false)
      })
      .catch(error => {
        setTypeLoading(false)
        console.log(error?.response?.data)
        console.log(error?.response?.data?.expire)

        if (error.message === 'Network Error') {
          setNetworkErr(true)
        }
        if (error?.response?.data?.expire === true) {
          setTokenExpireMsg(error?.response?.data?.message)
          setTokenExpOpen(true)
        }
      })
  }


  const fetchEmployer = () => {
    setEmployerLoading(true)
    dataProvider.getList('users/employers', {
      params: {
        token: token,
        pagination: { page: 1, perPage: 1000 },
        sort: { order: 'desc', field: '_id' },
        filter: {}
      }
    })
      .then(({ data }) => {
        //console.log(data);
        setEmployers(data)
        setEmployerLoading(false)
      })
      .catch(error => {
        // console.log(error?.response?.data)
        // console.log(error?.response?.data?.expire)
        if (error.message === 'Network Error') {
          setNetworkErr(true)
        }
        if (error?.response?.data?.expire === true) {
          setTokenExpireMsg(error?.response?.data?.message)
          setTokenExpOpen(true)
        }
        setEmployerLoading(false)
      })
  }



  const handleOpenShiftViewModal = (shift) => {
    setShiftViewModal(true)
    setSelectedShiftDetails(shift)
  }

  const handleCloseShiftViewModal = () => {
    setShiftViewModal(false)
  }


  let shiftViewProps = {
    shiftViewModal: shiftViewModal,
    handleOpenShiftViewModal: handleOpenShiftViewModal,
    handleCloseShiftViewModal: handleCloseShiftViewModal,
    selectedShiftDetails: selectedShiftDetails,
    setShiftViewModal: setShiftViewModal
  }


  // Filter logics 

  const handleDateFilter = () => {

    setClickCount((prevCount) => prevCount + 1);

    if (clickCount % 3 === 0) {
      setOrder('desc');
      setField('id');
    } else {
      setOrder(order === 'desc' ? 'asc' : 'desc');
      setField('date');
    }
  };



  const handlePrevPage = () => {
    setPaginationCount((prevCount) => prevCount - 1)
  }

  const hanldNextPage = () => {
    setPaginationCount((prevCount) => prevCount + 1)
  }

  const SlotBox = styled(Box)(({ theme }) => ({
    margin: "20px 10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  }));



  const handlePdf = (shiftDetailData) => {

    const pdfDoc = new jsPDF();

    const title = 'Shift Details';

    const headerColor = '#8fb569';


    const columns = ['Title', 'Shift Type', 'Date From', 'Date To', 'Client', 'Time From', 'Time To', 'Status'];


    const rows = shiftDetailData.map((rowData) => [
      rowData.title,
      rowData.shift_type,
      rowData.date && new Date(rowData.date).toLocaleDateString('en-GB', { timeZone: 'UTC' }),
      rowData.date_to && new Date(rowData.date_to).toLocaleDateString('en-GB', { timeZone: 'UTC' }),
      rowData.clientId?.first_name,
      rowData?.time_from,
      rowData?.time_to,
      rowData?.shift_status.charAt(0).toUpperCase() + rowData?.shift_status.slice(1)
    ]);

    pdfDoc.text(title, 14, 10);


    pdfDoc.autoTable({
      head: [columns],
      body: rows,
      startY: 20,
      theme: 'plain', // Optional: 'striped', 'grid', 'plain'
      headStyles: {
        fillColor: headerColor,
        textColor: '#ffffff', // Optional: Text color for header
      },
    });

    // Save the PDF or open it in a new tab
    pdfDoc.save('shift_details.pdf');
  };



  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilterData((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }


  const handleDate = (newDate) => {
    // const formattedDate = dayjs(newDate).format('YYYY-MM-DD');
    // setFilterData((prevState) => ({
    //   ...prevState,
    //   date: formattedDate
    // }))


    const yymmdd = dayjs(newDate).format('YYYY-MM-DD');
    // console.log(yymmdd);

    var newShift = new Date(yymmdd);
    newShift.setUTCHours(23, 59, 59);
    var utcTime = newShift.toISOString();
    // console.log(utcTime);




    // console.log(formattedDate);
    setFilterData((prevState) => ({
      ...prevState,
      date: utcTime
    }))
  }



  const handleDateFrom = (newDate) => {

    if (newDate) {
      const yymmdd = dayjs(newDate).format('YYYY-MM-DD');
      var newShift = new Date(yymmdd);
      newShift.setUTCHours(23, 59, 59);
      var utcTime = newShift.toISOString();

      setFilterData((prevState) => ({
        ...prevState,
        from: utcTime
      }))

      setDisabledDate((prev) => ({
        ...prev,
        startDate: newDate
      }))
    }


  }


  const handleDateTo = (newDate) => {
    if (newDate) {
      const yymmdd = dayjs(newDate).format('YYYY-MM-DD');
      var newShift = new Date(yymmdd);
      newShift.setUTCHours(23, 59, 59);
      var utcTime = newShift.toISOString();

      setFilterData((prevState) => ({
        ...prevState,
        to: utcTime
      }))
    }

  }


  // Search Submit
  const handleFilterValue = () => {
    if (filterData.shiftType !== "") setFilterFields((prevState) => ({ ...prevState, ["shift_type"]: filterData.shiftType }))
    if (filterData.from !== "" && filterData.to !== "") {
      setFilterFields((prevState) => ({
        ...prevState,
        date: {
          ...prevState.date,
          from: filterData.from
        }
      }));
    }
    if (filterData.to !== "" && filterData.from !== "") {
      setFilterFields((prevState) => ({
        ...prevState,
        date: {
          ...prevState.date,
          to: filterData.to
        }
      }));
    }
    if (filterData.shiftStatus !== "") setFilterFields((prevState) => ({ ...prevState, ["shift_status"]: filterData.shiftStatus }))
    if (filterData.employer !== "") {
      setFilterFields((prevState) => ({ ...prevState, clientId: { ...prevState.clientId, _id: filterData?.employer } }))
    }
    setPaginationCount(1)
  }





  const StatusBox = styled(Box)(({ theme }) => ({
    width: "100px",
    padding: "3px 0",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px",
  }));

  const StatusChip = styled(Chip)(({ theme }) => ({
    width: "100px",
    height: "25px",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

  }))


  // const statusColors = {
  //   'pending': '#FFC72C',
  //   'ongoing': '#0077c0',
  //   'completed': '#8cc751',
  //   'not_fulfilled': '#dc3545'
  // };

  const statusColors = {
    'pending': {
      color: '#FFC72C',
      name: 'Pending'
    },
    'ongoing': {
      color: '#0077c0',
      name: 'Ongoing'
    },
    'completed': {
      color: '#8cc751',
      name: 'Completed'
    },
    'not_fulfilled': {
      color: '#dc3545',
      name: 'Not Fulfilled'
    }
  };

  const handleAcceptShiftViewOpen = (shiftId, title) => {
    setShiftAcceptData((prev) => ({ ...prev, shiftId: shiftId, title: title }))
    setShiftAccept(true)
  }

  const handleAcceptShiftViewClose = () => {
    setShiftAcceptData((prev) => ({ ...prev, shiftId: "" }))
    setShiftAccept(false)
  }



  const handleShiftTypeClear = () => {
    setFilterData((prev) => ({ ...prev, shiftType: "" }))
    setFilterFields((prev) => {
      const { shift_type, ...rest } = prev;
      return rest;
    });

  }

  const handleStatusClear = () => {
    setFilterData((prev) => ({ ...prev, shiftStatus: "" }))
    setFilterFields((prev) => {
      const { shift_status, ...rest } = prev;
      return rest;
    });
  }

  const handleEmplyerNameClear = () => {
    setFilterData((prev) => ({ ...prev, employer: "" }))
    setFilterFields((prev) => {
      const { clientId, ...rest } = prev;
      return rest;
    });
  }


  // Shift Accept
  const handleShiftAccept = () => {

    const { shiftId } = shiftAcceptData

    const data = {
      'id': shiftId,
      'status': 1,
    }

    setButtonLoading(true)
    dataProvider.update('shift', data).then((data) => {
      // console.log(data)
      fetchShiftData()
      setButtonLoading(false)
      setShiftAccept(false)
    }).catch((e) => {
      setButtonLoading(false)
      // console.log(e)
      // console.log(e?.response?.data?.msg);
    })
  }

  // Delete

  const openDeleteModal = (id, name) => {
    setDeleteModal(true)
    setDeleteId(id)
  }

  const closeDeleteModal = (id, name) => {
    setDeleteModal(false)
    setDeleteId("")
  }

  const confirmDeleteShift = () => {

    let data = {
      'id': deleteId
    }

    setButtonLoading(true)

    dataProvider.delete('shift', data).then((data) => {
      setButtonLoading(false)
      setDeleteModal(false)
      fetchShiftData()
    }).catch((e) => {

    })


  }


  //console.log(filterData, 'filterData');
  //console.log(filterFields, 'filterFields');

  return (

    <>
      {
        tokenExpOpen ? <ExpirePopup tokenExpireMsg={tokenExpireMsg} /> :
          <Paper sx={{ marginTop: "20px" }}>
            <Box p={2} >


              <SlotBox>
                <Typography variant='h6' py={2} textTransform={'uppercase'} >Shift listing</Typography>

                {
                  // shiftDetailData.length !== 0 &&
                  <Button variant='contained' sx={{
                    color: "white",

                    width: "100px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                    onClick={() => handlePdf(shiftDetailData)}
                    disabled={pdfLoading || shiftDetailData.length === 0}
                    endIcon={pdfLoading ? null : <FontAwesomeIcon icon={faFilePdf} style={{ color: "#fff", }}
                    />}
                  >
                    {
                      pdfLoading ? <CircularProgress size={23} sx={{ margin: "0 10px", color: "white" }} /> : 'Export'
                    }

                  </Button>
                }

              </SlotBox>

              {/* Filters */}
              <Grid container spacing={2} my={3} alignItems={'center'} >

                {/* Date From */}
                <Grid item md={4} xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      label='Start Date'
                      open={datePickerFrom}
                      onClose={() => setdatePickerFrom(false)}
                      format="DD-MM-YYYY"
                      onChange={handleDateFrom}
                      slotProps={{
                        field: {
                          clearable: true, onClear: () => {
                            setFilterData((prevState) => ({
                              ...prevState,
                              from: "",
                              // to: ""
                            }))

                            setDisabledDate((prev) => ({
                              ...prev,
                              startDate: "",
                              endDate: ""
                            }))

                            setFilterFields((prev) => {
                              const { date, ...rest } = prev;
                              return rest;
                            });
                          }
                        },
                        textField: {
                          onClick: () => setdatePickerFrom(true),
                        },
                      }}
                      // shouldDisableDate={(value, timeView) => {
                      //   return disabledDate.endDate <= value 
                      // }}
                      style={{ position: 'absolute', top: '100%', zIndex: 1 }}
                      sx={{ width: "100%" }}
                    />
                  </LocalizationProvider>
                </Grid>

                {/* Date To */}
                <Grid item md={4} xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>

                    <DesktopDatePicker
                      label='End Date'
                      open={datePickerTo}
                      onClose={() => setdatePickerTo(false)}
                      format="DD-MM-YYYY"
                      onChange={handleDateTo}
                      slotProps={{
                        field: {
                          clearable: true, onClear: () => {
                            setFilterData((prevState) => ({
                              ...prevState,
                              to: ""
                            }))

                            setDisabledDate((prev) => ({
                              ...prev,
                              endDate: ""
                            }))

                            setFilterFields((prev) => {
                              const { date, ...rest } = prev;
                              return rest;
                            });
                          }
                        },
                        textField: {
                          onClick: () => setdatePickerTo(true),
                        },
                      }}
                      shouldDisableDate={(value, timeView) => {
                        return disabledDate.startDate >= value
                      }}
                      disabled={disabledDate.startDate === ''}

                      style={{ position: 'absolute', top: '100%', zIndex: 1 }}
                      sx={{ width: "100%" }}
                    />
                  </LocalizationProvider>
                </Grid>


                {/* Employer */}
                <Grid item md={4} xs={12}>
                  <TextField
                    style={{ width: "100%" }}
                    name='employer'
                    select
                    label="Select an employer"
                    value={filterData?.employer}
                    onChange={handleChange}
                    // margin="normal"
                    variant="outlined"
                    placeholder='Select an employer'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          {
                            filterData.employer &&
                            <IconButton onClick={() => handleEmplyerNameClear()}>
                              <Close sx={{ color: '#909090' }} />
                            </IconButton>
                          }

                        </InputAdornment>
                      )
                    }}
                  >
                    {
                      employerLoading ? <MenuItem disabled>Loading...</MenuItem> :
                        employers?.length === 0 ?
                          <MenuItem disabled>Empty Employers</MenuItem> :
                          employers?.map((employer) => (
                            <MenuItem value={employer?._id}>{employer?.first_name}{" "}{employer?.last_name}</MenuItem>
                          ))
                    }


                  </TextField>
                </Grid>

                {/* Staff Type */}
                <Grid item md={4} xs={12}>
                  <TextField
                    style={{ width: "100%" }}
                    name='shiftType'
                    select
                    label="Staff type"
                    value={filterData.shiftType}
                    onChange={handleChange}
                    // margin="normal"
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          {
                            filterData.shiftType &&
                            <IconButton onClick={() => handleShiftTypeClear()}>
                              <Close sx={{ color: '#909090' }} />
                            </IconButton>
                          }

                        </InputAdornment>
                      )
                    }}
                  >
                    {
                      typeLoading ? (

                        <MenuItem disabled>Loading...</MenuItem>
                      ) :
                        staffTypeData?.map((type, index) => (
                          <MenuItem value={type?.type} key={index}>{type?.type}</MenuItem>
                        ))
                    }
                  </TextField>


                  {/* <FormControl fullWidth >
                    <InputLabel id="demo-simple-select-label">Staff type</InputLabel>
                    <Select
                      name='shiftType'
                      value={filterData.shiftType}
                      label="Staff type"
                      onChange={handleChange}

                    >
                     

                    </Select>
                  </FormControl> */}
                </Grid>

                {/* Status */}
                <Grid item md={4} xs={12}>
                  <TextField
                    style={{ width: "100%" }}
                    name='shiftStatus'
                    select
                    label="Status"
                    value={filterData.shiftStatus}
                    onChange={handleChange}
                    // margin="normal"
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          {
                            filterData.shiftStatus &&
                            <IconButton onClick={() => handleStatusClear()}>
                              <Close sx={{ color: '#909090' }} />
                            </IconButton>
                          }

                        </InputAdornment>
                      )
                    }}
                  >
                    <MenuItem value={'pending'}>Pending</MenuItem>
                    <MenuItem value={'ongoing'}>Ongoing</MenuItem>
                    <MenuItem value={'not_fulfilled'}>Not Fulfilled</MenuItem>
                    <MenuItem value={'completed'}>Completed</MenuItem>
                  </TextField>
                </Grid>

                {/* Filter Button */}
                <Grid item md={4} flexDirection={'row'} xs={12}>
                  <Button
                    variant='contained'
                    color='primary'
                    sx={{
                      color: "white",
                      width: "100%",
                      height: "53px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                    endIcon={<FilterAlt />}
                    onClick={() => handleFilterValue()}
                  >
                    Filter
                  </Button>
                </Grid>

              </Grid>

              {
                shiftLoading ? <div className="loader-position">
                  <CircularProgress color="primary" size={45} />
                </div>
                  :
                  <>
                    {
                      shiftDetailData.length === 0 ? <Typography sx={{ m: 3, textAlign: "center", fontWeight: "bold" }}>Empty Shifts</Typography>
                        :
                        <>
                          <TableContainer >
                            <CustomTable sx={{ minWidth: 650, '& th, & td': { padding: '10px' } }} size="small" aria-label="a dense table">
                              <TableHead >
                                <TableRow>
                                  <TableCell width={"30%"} >
                                    <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                      Title
                                    </Typography>
                                  </TableCell>

                                  <TableCell width={"20%"}>
                                    <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                      Staff type
                                    </Typography>
                                  </TableCell>

                                  <TableCell width={"50%"}>
                                    <div className="custom-shift-table" onClick={() => handleDateFilter()}>
                                      <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                        Date
                                      </Typography>

                                      <IconButton>
                                        {(order === 'asc' && field === 'date') ? (
                                          <ArrowDownwardOutlined sx={{ color: "#808080", fontSize: "20px" }} />
                                        ) : (order === 'desc' && field === 'date') ? (
                                          <ArrowUpwardOutlined sx={{ color: "#808080", fontSize: "20px" }} />
                                        ) : null}
                                      </IconButton>
                                    </div>
                                  </TableCell>

                                  <TableCell >
                                    <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                      Staff
                                    </Typography>
                                  </TableCell>

                                  <TableCell >
                                    <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                      Employer
                                    </Typography>
                                  </TableCell>

                                  <TableCell >
                                    <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                      Status
                                    </Typography>
                                  </TableCell>


                                  <TableCell >
                                    <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                      Actions
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {
                                  shiftDetailData.map((shift) => (
                                    <TableRow
                                      key={shift._id}
                                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                      <TableCell>
                                        {shift?.title && shift?.title.split(' ').length > 8 ? (
                                          <Tooltip title={shift?.title}>
                                            <Typography
                                              sx={{ fontSize: '1rem' }}
                                            >
                                              {shift?.title.split(' ').slice(0, 8).join(' ') + '...'}
                                            </Typography>
                                          </Tooltip>
                                        ) : (
                                          <Typography
                                            sx={{ fontSize: '1rem' }}
                                          >
                                            {shift?.title}
                                          </Typography>
                                        )}
                                      </TableCell>
                                      <TableCell ><Typography sx={{ fontSize: '1rem' }}>{shift?.shift_type}</Typography></TableCell>
                                      <TableCell >
                                        <div className="slot-date-time">
                                          <Typography variant='subtitle1'>
                                            {/* {shift.date && dayjs(shift.date, { dateFormat: 'DD-MM-YYYY' }).format('DD-MM-YYYY')} */}
                                            {new Date(shift?.date).toLocaleDateString('en-GB', { timeZone: 'UTC' })}
                                            {" "}
                                            {" - "}
                                            {" "}
                                            {new Date(shift?.date_to).toLocaleDateString('en-GB', { timeZone: 'UTC' })}
                                          </Typography>
                                          <Typography variant='subtitle2' >{shift?.time_from} - {shift?.time_to}</Typography>
                                        </div>
                                      </TableCell>
                                      {/* Staff */}
                                      <TableCell component="th" scope="row">
                                        {
                                          shift?.customerId ?
                                            <div className="profile-image-table">
                                              <Avatar src={shift?.customerId?.avatar_path} />
                                              <Typography sx={{ fontSize: '1rem', fontWeight: '600' }}>{shift?.customerId?.first_name}</Typography>
                                            </div> : <Typography textAlign={"center"}>-</Typography>
                                        }
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        <div className="profile-image-table">
                                          <Avatar src={shift?.clientId?.avatar_path} />
                                          <Typography sx={{ fontSize: '1rem', fontWeight: '600' }}>{shift?.clientId?.first_name}</Typography>
                                        </div>
                                      </TableCell>
                                      <TableCell >

                                        <StatusChip label={statusColors[shift?.shift_status]["name"]}
                                          sx={{ backgroundColor: statusColors[shift?.shift_status]["color"] }}>
                                        </StatusChip>

                                      </TableCell>
                                      <TableCell >
                                        <Box sx={{ display: "flex", alignItems: "center", gap: "1px" }}>
                                          {
                                            (shift?.shift_status === 'pending' || shift?.shift_status === 'ongoing') &&
                                            (
                                              <Tooltip title='Edit'>
                                                <IconButton onClick={() => navigate(`/shift/edit/${shift?._id}`)}>
                                                  <EditIcon sx={{ color: '#0066b2' }} />
                                                </IconButton>
                                              </Tooltip>
                                            )
                                          }

                                          <Tooltip title='View'>
                                            <IconButton onClick={() => handleOpenShiftViewModal(shift)}>
                                              <Visibility sx={{ color: '#0066b2' }} />
                                            </IconButton>
                                          </Tooltip>

                                          {
                                            shift?.status !== 0 &&
                                            <ShiftViewBlock shiftId={shift?._id} shiftStatus={shift?.shift_status} />
                                          }


                                          {
                                            shift?.status === 0 &&
                                            <Button size='small' variant='contained' sx={{ color: "white" }} onClick={() => handleAcceptShiftViewOpen(shift._id, shift?.title)}>
                                              Approve
                                            </Button>
                                          }


                                          <Tooltip title='Delete'>
                                            <IconButton onClick={() => openDeleteModal(shift?._id, shift?.title)} >
                                              <Delete color='error' />
                                            </IconButton>
                                          </Tooltip>
                                          {/* <p>{shift?._id}</p> */}
                                        </Box>
                                      </TableCell>
                                    </TableRow>
                                  ))
                                }
                              </TableBody>
                            </CustomTable>
                          </TableContainer>

                          {/* Pagination */}
                          <Stack flexDirection={"row"} justifyContent={"flex-end"} alignItems={"center"} gap={1}>
                            <Tooltip title='Prev' >
                              <IconButton disabled={paginationCount === 1} onClick={() => handlePrevPage()}>
                                <ArrowBackIosNewOutlinedIcon sx={{ fontSize: "17px" }} />
                              </IconButton>
                            </Tooltip>


                            <Fab size="small" color="primary" elevation="0" sx={{ color: 'white', boxShadow: 'none' }}>
                              {paginationCount}
                            </Fab>

                            <Tooltip title='Next' >
                              <IconButton disabled={shiftLength < 10} onClick={() => hanldNextPage()}>
                                <ArrowForwardIosOutlinedIcon sx={{ fontSize: "17px" }} />
                              </IconButton>
                            </Tooltip>
                          </Stack>

                        </>
                    }

                  </>
              }
            </Box>

          </Paper >
      }



      <Dialog
        open={shiftAccept}
        onClose={() => handleAcceptShiftViewClose()}
        scroll='paper'
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
      >
        <DialogTitle id="scroll-dialog-title">
          <Typography variant='h6'>{`ARE YOU SURE YOU WANT TO APPROVE THIS SHIFT?`}</Typography>

        </DialogTitle>

        <DialogActions>
          <Button onClick={() => handleAcceptShiftViewClose()}>Cancel</Button>
          <Button onClick={() => handleShiftAccept()} disabled={buttonloading} >
            {
              buttonloading ? <CircularProgress size={15} color='primary' /> : 'Approve'
            }

          </Button>
        </DialogActions>
      </Dialog>



      <Dialog
        open={deleteModal}
        onClose={() => closeDeleteModal()}
        scroll='paper'
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
      >
        <DialogTitle id="scroll-dialog-title">
          <Typography variant='h6'>{`ARE YOU SURE YOU WANT TO DELETE THIS SHIFT?`}</Typography>

        </DialogTitle>

        <DialogActions>
          <Button onClick={() => closeDeleteModal()}>Cancel</Button>
          <Button onClick={() => confirmDeleteShift()} disabled={buttonloading} color='error'>
            {
              buttonloading ? <CircularProgress size={15} color='error' /> : 'Delete'
            }

          </Button>
        </DialogActions>
      </Dialog>


      {/* ShiftView Modal */}
      <ViewShift    {...shiftViewProps} />
      {/* ShiftView Modal */}
    </>
  )
}


const ShiftViewBlock = ({ shiftId, shiftStatus }) => {

  let token = localStorage.getItem('token');

  useEffect(() => {
    fetchRequestData()
    //console.log("Mounted variables:");
  }, [shiftId])


  const [flag, setFlag] = useState(false)
  const [fetchLoading, setFetchLoading] = useState(false)
  const navigate = useNavigate()

  const [tokenExpireMsg, setTokenExpireMsg] = useState("")
  const [tokenExpOpen, setTokenExpOpen] = useState(false)


  const fetchRequestData = () => {

    setFetchLoading(true)
    shiftStatus !== 'completed' &&
      dataProvider.getList('shift/single/request/' + shiftId, {
        params: {
          token: token,
          pagination: {
            page: 1,
            perPage: 1000
          },
          sort: { order: 'desc', field: '_id' },
          filter: {}
        }
      }).then(({ data }) => {
        // console.log(data);
        setFlag(data)
        setFetchLoading(false)
      }).catch((err) => {
        setFetchLoading(false)
        if (err?.response?.data?.expire === true) {
          setTokenExpireMsg(err?.response?.data?.message)
          setTokenExpOpen(true)
        }
      })
  }

  return (
    <>
      {
        tokenExpOpen ? <ExpirePopup tokenExpireMsg={tokenExpireMsg} /> :

          <Tooltip title='Requests'>

            {
              shiftStatus !== 'completed' ?
                <IconButton onClick={() => navigate(`/shift/${shiftId}`)} disabled={shiftStatus === 'not_fulfilled'}>
                  <Badge badgeContent={<span style={{ color: 'white' }}>
                    {fetchLoading ? <CircularProgress size={8} sx={{ color: "white" }} /> : flag.length}</span>}
                    color="primary">
                    <CalendarMonth sx={{ color: shiftStatus !== 'not_fulfilled' ? '#0066b2' : 'grey' }} />
                  </Badge>

                </IconButton> :
                <IconButton onClick={() => navigate(`/shift/${shiftId}`)} disabled={shiftStatus === 'not_fulfilled'}>
                  <CalendarMonth sx={{ color: shiftStatus !== 'not_fulfilled' ? '#0066b2' : 'grey' }} />
                </IconButton>

            }

          </Tooltip>
      }
    </>
  )
}

export default ShiftListing