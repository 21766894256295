import React, { useEffect, useState } from 'react'
import 'assets/css/admin/edit-user.css'
import { Alert, Avatar, Box, Button, CircularProgress, FormControl, FormHelperText, Grid, IconButton, Input, InputAdornment, InputLabel, MenuItem, OutlinedInput, Paper, Select, Snackbar, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { PersonOutline, MailOutlineOutlined, PhoneIphoneOutlined, ManageAccountsOutlined, CameraAltOutlined, CalendarMonthOutlined, DocumentScannerOutlined, VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import dataProvider from 'providers/dataProvider'
import styled from '@emotion/styled'

const ProfileEdit = () => {
    const { userid } = useParams()


    const navigate = useNavigate()

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let phoneNumberRegex = /^\d{9}$/;
    const firstNameRegex = /^[a-zA-Z\- ']+$/;
    const addressRegex = /^[a-zA-Z0-9\s.,#-]+$/;
    let passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]).{6,}$/;

    const [typeLoading, setTypeLoading] = useState(false)
    const [employerTypeData, setEmployerTypeData] = useState([])



    const [updatedDetails, setUpdatedDetails] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phoneNumber: "",
        password: "",
        address: "",
        employerType: "",
        mangerName: "",
        hospitalName: ""
    })

    const [updatedDetailsError, setUpdatedDetailsError] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phoneNumber: "",
        password: "",
        address: "",
    })


    const [isUpdatedDetails, setIsUpdatedDetails] = useState({
        isFirst_name: "",
        isLast_name: "",
        isEmail: "",
        isPhoneNumber: "",
        isPassword: "",
        isAddress: "",
    })


    const [oneUserDetails, setOneUserDetails] = useState({})
    const [credentialError, setCredentialError] = useState("")

    const [selectedImage, setSelectedImage] = useState({ file: null, url: null })

    const [isUserLoading, setIsUserLoading] = useState(false)
    const [isUpdateButtonLoading, setIsUpdateButtonLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const [isupdateErrModal, setIsUpdatedErrModal] = useState(false)



    const [showPages, setShowPages] = useState('edit')



    useEffect(() => {
        fetchOneUserData()
        fetchEmployerType()
    }, [])



    const fetchOneUserData = () => {
        setIsUserLoading(true)
        dataProvider.getOne('users', { id: userid }).then(({ data }) => {
            setOneUserDetails(data)
            setUpdatedDetails((prevState) => ({
                ...prevState,
                first_name: data.first_name,
                last_name: data.last_name,
                email: data.email,
                phoneNumber: data.phone,
                address: data.address,
                employerType: data?.employer_type,
                mangerName: data?.manager_name,
                hospitalName: data?.hospital_name
            }))
            setIsUserLoading(false)
        }).catch((err) => {
            if (err?.response?.data?.message) {
                navigate("*")
            }
            setIsUserLoading(false)

        })
    }


    const updatedDetailsChange = (e, regex, errorValue) => {
        const { name, value } = e.target;
        setUpdatedDetails((prevState) => ({ ...prevState, [name]: value }))
        handleUpdateErrors(value, regex, name, errorValue)
    }


    const handleUpdateErrors = (inputValue, regex, fieldName, errorValue) => {
        if (inputValue.trim() === "") {
            // If the field is empty, clear the error message and set error state to false
            setUpdatedDetailsError((prevState) => ({ ...prevState, [fieldName]: "" }));
            setIsUpdatedDetails((prevState) => ({ ...prevState, [errorValue]: false }));
        } else if (!regex.test(inputValue)) {
            // If the field is not empty but doesn't match the regex, show the error message
            setUpdatedDetailsError((prevState) => ({ ...prevState, [fieldName]: getErrorMessage(fieldName) }));
            setIsUpdatedDetails((prevState) => ({ ...prevState, [errorValue]: true }));
        } else {
            // If the field is not empty and matches the regex, clear the error message and set error state to false
            setUpdatedDetailsError((prevState) => ({ ...prevState, [fieldName]: "" }));
            setIsUpdatedDetails((prevState) => ({ ...prevState, [errorValue]: false }));
        }
    }


    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];

        if (selectedFile) {
            const imageUrl = URL.createObjectURL(selectedFile);
            setSelectedImage((prevState) => ({
                ...prevState,
                file: selectedFile,
                url: imageUrl
            }))
        }
    }

    const handleProfileImageSelect = () => {
        const fileInput = document.getElementById("profileFile")
        if (fileInput) {
            fileInput.click();
        }
    };


    const getErrorMessage = (filedname) => {
        switch (filedname) {
            case "email":
                return "Invalid email format";
            case "phoneNumber":
                return "Phone number contains 9 digits"
            case "first_name":
                return "Invalid  format"
            case "last_name":
                return "Invalid  format"
            case "password":
                return "Password contains six characters, capital, number, special character";
            case "address":
                return "Invalid  format"
            default:
                return "Invalid format"
        }
    }


    const handleClickShowPassword = () => setShowPassword((show) => !show);


    const getInitials = (name) => {
        return name ? name[0].toUpperCase() : '';
    }


    const handleEditSubmit = () => {
        const { first_name, last_name, phoneNumber, password, address, email, employerType, mangerName, hospitalName } = updatedDetails;
        let avatar_path = selectedImage.file


        let hasError = false;


        const setError = (errorfield, errorStatus, filedname, fieldError) => {
            setIsUpdatedDetails((prevState) => ({ ...prevState, [errorfield]: errorStatus }));
            setUpdatedDetailsError((prevState) => ({ ...prevState, [filedname]: fieldError }))
            hasError = true;
        };

        let errorWord = 'Please fill this field'

        if (email === '') setError('isEmail', true, 'email', errorWord);
        if (phoneNumber === '') setError('isPhoneNumber', true, 'phoneNumber', errorWord);



        if (!hasError) {

            let data = {
                'id': userid,
                'email': email,
                'phone': phoneNumber,
            }

            if (first_name !== '') data["first_name"] = first_name
            if (last_name !== '') data["last_name"] = last_name
            if (password !== '') data["password"] = password
            if (address !== '' && address !== undefined) {
                data["address"] = address
            }
            if (employerType !== '' && employerType !== undefined) {
                data["employer_type"] = employerType
            }
            if (mangerName !== '' && mangerName !== undefined) {
                data["manager_name"] = mangerName
            }
            if (hospitalName !== '' && hospitalName !== undefined) {
                data["hospital_name"] = hospitalName
            }
            if (selectedImage.file !== null && selectedImage.file !== "") {
                data["avatar_path"] = avatar_path
            }

            setIsUpdateButtonLoading(true)

            dataProvider.updateProfile('users', data).then((data) => {
                setIsUpdateButtonLoading(false)

                setUpdatedDetails((prevState) => ({
                    ...prevState,
                    password: ""
                }))
                setSelectedImage((prevState) => ({
                    ...prevState,
                    file: "",
                    url: ""
                }))
                fetchOneUserData()
                // console.log(data)
            }).catch((e) => {
                // console.log(e)
                setIsUpdatedErrModal(true)
                setCredentialError(e.response.data.message)
                setIsUpdateButtonLoading(false)
            })
        }

    }

    const handleEmployerType = (e) => {
        const { name, value } = e.target;
        setUpdatedDetails((prevState) => ({
            ...prevState,
            employerType: value
        }))
    }

    const fetchEmployerType = () => {
        setTypeLoading(true)
        dataProvider.getList('employer_type', {
            params: {
                token: '',
                pagination: { page: 1, perPage: 1000 },
                sort: { order: 'desc', field: 'id' },
                filter: {}
            }
        })
            .then(({ data }) => {
                // console.log(data)
                setEmployerTypeData(data)
                setTypeLoading(false)
            })
            .catch(error => {
                setTypeLoading(false)

                // if (error.message === 'Network Error') {
                //   setNetworkErr(true)
                // }


            })
    }


    return (
        <>
            <Grid container spacing={1} my={2}>
                <Grid item md={4} xs={12} >
                    <Paper sx={{ position: "sticky", top: "0" }}>
                        {
                            isUserLoading ?
                                <Stack py={2} flexDirection={"row"} justifyContent={"center"} alignItems={"center"}>
                                    <CircularProgress color="primary" size={45} />
                                </Stack>
                                :
                                <Box p={1.5} >
                                    <Stack flexDirection={"row"} alignItems={"center"} gap={1} mt={1}>
                                        <Avatar src={oneUserDetails?.avatar_path}>{getInitials(oneUserDetails?.first_name)}</Avatar>
                                        <Typography fontWeight={'bold'}>{oneUserDetails?.first_name}{" "}{oneUserDetails?.last_name}</Typography>
                                    </Stack>


                                    <Typography my={2} fontWeight={'bold'}>Account Details</Typography>

                                    <Stack flexDirection={"column"} gap={2}>
                                        <Stack flexDirection={"row"} alignItems={"center"} gap={2}>
                                            <PersonOutline fontSize='small' />
                                            <Typography>{oneUserDetails?.first_name}{" "}{oneUserDetails?.last_name}</Typography>
                                        </Stack>

                                        <Stack flexDirection={"row"} alignItems={"center"} gap={2}>
                                            <MailOutlineOutlined fontSize='small' />
                                            <Typography>{oneUserDetails?.email}</Typography>
                                        </Stack>

                                        <Stack flexDirection={"row"} alignItems={"center"} gap={2}>
                                            <PhoneIphoneOutlined fontSize='small' />
                                            <Typography>+353{" "}{oneUserDetails?.phone}</Typography>
                                        </Stack>

                                        {/* <Stack flexDirection={"row"} alignItems={"center"} gap={2}>
                                            <ManageAccountsOutlined fontSize='small' />
                                            <Typography>{oneUserDetails?.role}</Typography>
                                        </Stack> */}


                                    </Stack>
                                </Box>
                        }
                    </Paper>
                </Grid>

                <Grid item md={8} xs={12}>
                    <Paper>
                        {
                            isUserLoading ? <Stack py={2} flexDirection={"row"} justifyContent={"center"} alignItems={"center"}>
                                <CircularProgress color="primary" size={45} />
                            </Stack>
                                :
                                <Box p={1.5}>
                                    <Typography my={2} fontWeight={'bold'}>Edit</Typography>
                                    <Grid container>
                                        <Grid item md={6} xs={6}>
                                            <Stack flexDirection={'column'} gap={2.6}>


                                                {/* FirstName */}
                                                <TextField
                                                    id="standard-basic"
                                                    type='text'
                                                    variant="standard"
                                                    fullWidth
                                                    name='first_name'
                                                    label='First name'
                                                    placeholder='First Name'
                                                    onChange={(e) => updatedDetailsChange(e, firstNameRegex, 'isFirst_name')}
                                                    helperText={updatedDetailsError.first_name && updatedDetailsError.first_name}
                                                    error={isUpdatedDetails.isFirst_name}
                                                    value={updatedDetails.first_name}
                                                />

                                                {/* Last name */}
                                                <TextField
                                                    id="standard-basic"
                                                    type='text'
                                                    variant="standard"
                                                    fullWidth
                                                    name='last_name'
                                                    label='First name'
                                                    placeholder='Last Name'
                                                    onChange={(e) => updatedDetailsChange(e, firstNameRegex, 'isLast_name')}
                                                    helperText={updatedDetailsError.last_name && updatedDetailsError.last_name}
                                                    error={isUpdatedDetails.isLast_name}
                                                    value={updatedDetails.last_name}
                                                />

                                                {/* Email*/}
                                                <TextField
                                                    id="standard-basic"
                                                    type='email'
                                                    placeholder='Email'
                                                    variant="standard"
                                                    fullWidth
                                                    name='email'
                                                    label='Email'
                                                    onChange={(e) => updatedDetailsChange(e, emailRegex, 'isEmail')}
                                                    helperText={updatedDetailsError.email && updatedDetailsError.email}
                                                    error={isUpdatedDetails.isEmail}
                                                    value={updatedDetails.email}
                                                />

                                                {/* Phone*/}
                                                <TextField
                                                    id="standard-basic"
                                                    type='number'
                                                    placeholder="Phone number"
                                                    variant="standard"
                                                    fullWidth
                                                    name='phoneNumber'
                                                    label='Phone'
                                                    onChange={(e) => updatedDetailsChange(e, phoneNumberRegex, 'isPhoneNumber')}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">+353</InputAdornment>,
                                                    }}
                                                    helperText={updatedDetailsError.phoneNumber && updatedDetailsError.phoneNumber}
                                                    error={isUpdatedDetails.isPhoneNumber}
                                                    value={updatedDetails.phoneNumber}
                                                />

                                                {/* Employer Type*/}
                                                <TextField
                                                    id="employer-type"
                                                    select
                                                    label="Employer Type"
                                                    value={updatedDetails.employerType}
                                                    onChange={handleEmployerType}
                                                    variant="standard"
                                                >
                                                    {
                                                        typeLoading ? (

                                                            <MenuItem disabled>Loading...</MenuItem>
                                                        ) :
                                                            employerTypeData.length === 0 ?
                                                                <MenuItem disabled>Staff type is not added</MenuItem> :
                                                                employerTypeData?.map((type) => (
                                                                    <MenuItem value={type?.type}>{type?.type}</MenuItem>
                                                                ))
                                                    }
                                                </TextField>

                                                {/* Hospital/Nursing Home*/}
                                                <TextField
                                                    id="standard-basic"
                                                    type='text'
                                                    placeholder="Hospital/Nursing Home"
                                                    variant="standard"
                                                    fullWidth
                                                    label='Hospital/Nursing Home'
                                                    value={updatedDetails.hospitalName}
                                                    onChange={(e) => setUpdatedDetails((prevState) => ({
                                                        ...prevState,
                                                        hospitalName: e.target.value
                                                    }))}
                                                />

                                                {/* Manager Name */}
                                                <TextField
                                                    id="standard-basic"
                                                    type='text'
                                                    placeholder="Manager name"
                                                    variant="standard"
                                                    fullWidth
                                                    value={updatedDetails.mangerName}
                                                    label='Manager name'
                                                    onChange={(e) => setUpdatedDetails((prevState) => ({
                                                        ...prevState,
                                                        mangerName: e.target.value
                                                    }))}
                                                />

                                                {/* Address */}
                                                <TextField
                                                    id="standard-basic"
                                                    type='text'
                                                    name='address'
                                                    placeholder="Address"
                                                    multiline
                                                    maxRows={4}
                                                    variant="standard"
                                                    fullWidth
                                                    label='Address'
                                                    onChange={(e) => updatedDetailsChange(e, addressRegex, 'isAddress')}
                                                    value={updatedDetails.address}
                                                />

                                                {/* Password */}
                                                <FormControl variant="outlined"
                                                    fullWidth
                                                    error={isUpdatedDetails.isPassword}
                                                >

                                                    <Input
                                                        id="outlined-adornment-password"
                                                        type={showPassword ? 'text' : 'password'}
                                                        name='password'
                                                        onChange={(e) => updatedDetailsChange(e, passwordRegex, 'isPassword')}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword}
                                                                    edge="end"
                                                                >
                                                                    {showPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                        placeholder='Password'
                                                    />
                                                    <FormHelperText>{updatedDetailsError.password && updatedDetailsError.password}</FormHelperText>
                                                </FormControl>

                                            </Stack>
                                        </Grid>
                                        <Grid item md={6} xs={6}>
                                            <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'center'}>
                                                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "10px" }}>
                                                    <Box sx={{ position: "relative" }}>
                                                        <Avatar src={
                                                            selectedImage.url ? (selectedImage.url) : (oneUserDetails?.avatar_path)
                                                        }
                                                            sx={{ width: "80px", height: "80px" }}
                                                        >
                                                            {getInitials(oneUserDetails?.first_name)}
                                                        </Avatar>

                                                        <Tooltip title="Upload Image" placement="right-start">
                                                            <IconButton
                                                                onClick={handleProfileImageSelect}
                                                                sx={{ position: "absolute", bottom: "-2px", right: "0", backgroundColor: "#E8E8E8" }}
                                                            >
                                                                <CameraAltOutlined style={{ fontSize: '15px' }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <input
                                                            type="file"
                                                            id='profileFile'
                                                            onChange={(e) => handleFileChange(e)}
                                                            style={{ display: 'none' }}
                                                        />
                                                    </Box>

                                                    <Button
                                                        variant='contained'
                                                        sx={{
                                                            color: "white",
                                                            // backgroundImage: "linear-gradient(98deg, #9cc177, #6ba134 94%)",
                                                            width: "80px",
                                                            height: "40px",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center"
                                                        }}
                                                        disabled={Object.values(updatedDetailsError).some((error) => error !== "") || isUpdateButtonLoading}
                                                        onClick={() => handleEditSubmit()}
                                                    >
                                                        {isUpdateButtonLoading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : 'Save'}
                                                    </Button>
                                                </Box>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Box>
                        }
                    </Paper>

                    <Snackbar open={isupdateErrModal}
                        autoHideDuration={6000}
                        onClose={() => setIsUpdatedErrModal(false)}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                        <Alert onClose={() => setIsUpdatedErrModal(false)} severity="error" sx={{ width: '100%' }}>
                            {credentialError}
                        </Alert>
                    </Snackbar>
                </Grid>
            </Grid>
        </>
    )
}

export default ProfileEdit