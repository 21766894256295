import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React, { useState } from 'react'

const CreateLocationModal = ({ isCreateLocationModal, setIsCreateLocationModal, handleCreateLocation, isLocationNotNull, buttonLoading, setIsLocationNotNull }) => {


    const [createLocationDetails, setCreatelocationDetails] = useState({
        status: 1,
        location: ""
    })


    const handleChange = (e) => {
        const { name, value } = e.target
        setCreatelocationDetails((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    const handleClose = () => {
        setIsCreateLocationModal(false)
        setCreatelocationDetails((prevState) => ({
            ...prevState,
            location: ""
        }))
        setIsLocationNotNull(false)
    }


    return (
        <>
            <Dialog open={isCreateLocationModal} onClose={() => handleClose()} fullWidth>
                <DialogTitle>Create Location</DialogTitle>
                <DialogContent>

                    <TextField
                        margin="dense"
                        label="Location"
                        type="text"
                        fullWidth
                        variant="outlined"
                        name="location"
                        onChange={(event) => handleChange(event)}
                        error={isLocationNotNull}
                        helperText={isLocationNotNull && 'Please fill this field'}
                    />

                    <FormControl fullWidth sx={{ margin: "10px 0" }}>
                        <InputLabel id="demo-simple-select-label">Status</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Status"
                            onChange={(event) => handleChange(event)}
                            name="status"
                            value={createLocationDetails.status}
                        >
                            <MenuItem value={0}>Inactive</MenuItem>
                            <MenuItem value={1}>Active</MenuItem>
                        </Select>
                    </FormControl>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose()}>Cancel</Button>
                    <Button onClick={() =>
                        handleCreateLocation(createLocationDetails.location, createLocationDetails.status)
                    }
                        disabled={buttonLoading}
                    >
                        {
                            buttonLoading ? <CircularProgress size={15} color='primary' /> : 'Create'
                        }
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default CreateLocationModal