import styled from '@emotion/styled';
import { Avatar, Box, Chip, CircularProgress, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import ExpirePopup from 'components/common/ExpirePopup';
import dayjs from 'dayjs';
import dataProvider from 'providers/dataProvider';
import React, { useEffect, useState } from 'react'

const CustomerRequests = ({ customerId, clientId, token }) => {

    const [requests, setRequests] = useState([])
    const [reqLoading, setReqLoading] = useState(false)


    const [tokenExpireMsg, setTokenExpireMsg] = useState("")
    const [tokenExpOpen, setTokenExpOpen] = useState(false)

    useEffect(() => {
        fetchRequests()
    }, [])


    const fetchRequests = async () => {
        setReqLoading(true)
        dataProvider.getList('shift/request', {
            params: {
                token: token,
                pagination: { page: 1, perPage: 20 },
                sort: { order: 'desc', field: 'id' },
                filter: {
                    customerId: customerId,
                    clientId: clientId
                }
            }
        })
            .then(({ data }) => {
                setRequests(data)
                setReqLoading(false)
            })
            .catch(error => {
                setReqLoading(false)
                console.log(error?.response?.data)
                console.log(error?.response?.data?.expire)
                if (error?.response?.data?.expire === true) {
                    setTokenExpireMsg(error?.response?.data?.message)
                    setTokenExpOpen(true)
                }
            })
    };

    const getInitials = (name) => {
        return name ? name[0].toUpperCase() : '';
    }

    const StatusBox = styled(Box)(({ theme }) => ({
        padding: "3px 0",
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "5px",
        width: "80px"
    }));

  const statusColors = {
        'pending': '#FFC72C',
        'ongoing': '#0077c0',
        'completed': '#8cc751',
        'not_fulfilled': '#dc3545'
    };


    const StatusChip = styled(Chip)(({ theme }) => ({
        width: "100px",
        height: "25px",
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

    }))


    return (
        <>
            {
                tokenExpOpen ? <ExpirePopup tokenExpireMsg={tokenExpireMsg} /> :
                    reqLoading ? <Stack flexDirection={"row"} justifyContent={"center"} alignItems={"center"}>
                        <CircularProgress color="primary" size={45} />
                    </Stack>
                        :
                        requests.length === 0 ?
                            <Typography py={2} textAlign={"center"} fontWeight={"bold"}>Empty Shifts</Typography>
                            :
                            <>
                                <Typography variant='h6' textTransform={"uppercase"} textAlign={"center"} py={2}>Shifts</Typography>
                                <TableContainer>
                                    <Table sx={{ minWidth: 650, '& th, & td': { padding: '10px' } }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell >
                                                    <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                        Title
                                                    </Typography>
                                                </TableCell>
                                                <TableCell >
                                                    <Typography sx={{ fontSize: '1rem', }} fontWeight="bold">
                                                        Staff type
                                                    </Typography>
                                                </TableCell>
                                                <TableCell >
                                                    <Typography sx={{ fontSize: '1rem', }} fontWeight="bold">
                                                        Date
                                                    </Typography>
                                                </TableCell>
                                                <TableCell >
                                                    <Typography sx={{ fontSize: '1rem', }} fontWeight="bold">
                                                        Employer
                                                    </Typography>
                                                </TableCell>
                                                <TableCell >
                                                    <Typography sx={{ fontSize: '1rem' }} fontWeight="bold">
                                                        Status
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                requests.map((request) => (
                                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                        <TableCell ><Typography sx={{ fontSize: '1rem' }}>{request?.shiftId?.title}</Typography></TableCell>
                                                        <TableCell ><Typography sx={{ fontSize: '1rem' }}>{request?.shift_type === 'nurse' ? 'Nurse' : 'HCA'}</Typography></TableCell>
                                                        <TableCell >
                                                            <div className="slot-date-time">
                                                                <Typography sx={{ fontSize: '1rem' }}>
                                                                    {request?.shiftId?.date_to && dayjs(request?.shiftId?.date_to, { dateFormat: 'DD-MM-YYYY' }).format('DD-MM-YYYY')}
                                                                </Typography>
                                                                <Typography sx={{ fontSize: '.9rem', color: "#808080" }}>{request?.shiftId?.time_from} - {request?.shiftId?.time_to}</Typography>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell >
                                                            <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                                                                <Avatar src={request?.clientId?.avatar_path} >{getInitials(request?.clientId?.first_name)}</Avatar>
                                                                <Typography sx={{ fontSize: '1rem', fontWeight: '600' }}>
                                                                    {request?.clientId?.first_name}
                                                                    {" "}
                                                                    {request?.clientId?.last_name}
                                                                </Typography>
                                                            </Stack>
                                                        </TableCell>
                                                        <TableCell >
                                                            {/* <StatusBox sx={{ backgroundColor: statusColors[request?.shiftId?.shift_status] }}>
                                                                {request?.shiftId?.shift_status.charAt(0).toUpperCase() + request?.shiftId?.shift_status.slice(1)}
                                                            </StatusBox> */}


                                                            <StatusChip label={request?.status === 'reject' ? 'Rejected' : request?.shiftId?.shift_status.charAt(0).toUpperCase() + request?.shiftId?.shift_status.slice(1)}
                                                                sx={{ backgroundColor: request?.status === 'reject' ? '#EF0107' : statusColors[request?.shiftId?.shift_status] }}>

                                                            </StatusChip>
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            }

                                        </TableBody>

                                    </Table>
                                </TableContainer>
                            </>
            }

        </>
    )
}

export default CustomerRequests