import httpClient from "config/http-common";
import queryString from 'query-string';

export default {
  getList: (resource, { params }) => {

    const { page, perPage } = params.pagination;
    const order = params.sort.order.toLowerCase();
    const field = (params.sort.field === 'id') ? '_id' : params.sort.field;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify(params.filter),
    };



    const url = `/${resource}?${queryString.stringify(query)}`;

    // console.log(url)
    if (params.token) {
      return httpClient.get(url, { headers: { "Authorization": `Bearer ${params.token}` } }).then(({ data }) =>
      ({
        data: data,
      }));
    }
    else {
      return httpClient.get(url).then(({ data }) => ({
        data: data,
      }));
    }
  },

  getOne: (resource, params) => {
    return httpClient(`/${resource}/${params.id}`).then(({ data }) => ({ data: data }));
  },

  getoneDoc: (resource, params) => {
    return httpClient(`/${resource}/${params.id}?cid=${params.cid}`).then(({ data }) => ({ data: data }));
  },

  create: (resource, params) => {
    const url = `/${resource}`;

    // console.log(url)
    return httpClient.post(url, params).then(({ data }) => ({
      data: data
    }));
  },

  update: (resource, params) => {
    const url = `/${resource}/${params.id}/`;
    return httpClient.put(url, params).then(({ data }) => ({
      data: data
    }));
  },

  // update: (resource, params) => {
  //   const url = `/${resource}/${params.id}/`;
  //   const headers = {
  //     "Authorization": `Bearer ${params?.token}`,
  //   };
  //   // console.log(params.token);

  //   return httpClient.put(url, params, { headers }).then(({ data }) => ({
  //     data: data
  //   }));
  // },


  delete: (resource, params) => {
    const url = `/${resource}/${params.id}`;
    return httpClient.delete(url, params).then(({ data }) => ({
      data: data
    }));
  },

  deleteUser: (resource, params) => {
    const url = `/${resource}/${params.id}`;
    const token = localStorage.getItem('token');

    return httpClient.delete(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: params,
    }).then(({ data }) => ({
      data: data,
    }));
  },

  updateProfile: (resource, params) => {
    var formData = new FormData();
    if (params.avatar_path && params.avatar_path !== undefined) {
      formData.append('avatar_path', params.avatar_path);
    }
    for (var key in params) {
      if (typeof (params[key]) !== 'object') {
        formData.append(key, params[key]);
      }
      else {
        formData.append(key, JSON.stringify(params[key]));
      }
    }
    const url = `/${resource}/${params.id}`;
    return httpClient.put(url, formData).then(({ data }) => ({
      data: data
    }));
  },

  createNotification: (resource, params) => {
    var formData = new FormData();
    if (params.notification_image && params.notification_image !== undefined) {
      formData.append('notification_image', params.notification_image);
    }
    for (var key in params) {
      if (typeof (params[key]) !== 'object') {
        formData.append(key, params[key]);
      }
      else {
        formData.append(key, JSON.stringify(params[key]));
      }
    }
    const url = `/${resource}`;
    return httpClient.post(url, formData).then(({ data }) => ({
      data: data
    }));
  },


  uploadCertificate: (resource, params) => {
    var formData = new FormData();
    if (params.document && params.document !== undefined) {
      formData.append('document', params.document);
    }
    for (var key in params) {
      if (typeof (params[key]) !== 'object') {
        formData.append(key, params[key]);
      }
      else {
        if (key != 'document')
          formData.append(key, JSON.stringify(params[key]));
      }
    }
    const url = `/${resource}`;
    return httpClient.post(url, formData).then(({ data }) => ({
      data: data
    }));
  },

  updateCertificate: (resource, params) => {
    var formData = new FormData();
    if (params.document && params.document !== undefined) {
      formData.append('document', params.document);
    }
    for (var key in params) {
      if (typeof (params[key]) !== 'object') {
        formData.append(key, params[key]);
      }
      else {
        formData.append(key, JSON.stringify(params[key]));
      }
    }
    const url = `/${resource}/${params.id}`;
    return httpClient.put(url, formData).then(({ data }) => ({
      data: data
    }));
  },

  createSlider: (resource, params) => {
    var formData = new FormData();
    if (params.image && params.image !== undefined) {
      formData.append('image', params.image);
    }
    for (var key in params) {
      if (typeof (params[key]) !== 'object') {
        formData.append(key, params[key]);
      }
      else {
        formData.append(key, JSON.stringify(params[key]));
      }
    }
    const url = `/${resource}`;
    return httpClient.post(url, formData).then(({ data }) => ({
      data: data
    }));
  },

  updateSlider: (resource, params) => {
    var formData = new FormData();
    if (params.image && params.image !== undefined) {
      formData.append('image', params.image);
    }
    for (var key in params) {
      if (typeof (params[key]) !== 'object') {
        formData.append(key, params[key]);
      }
      else {
        formData.append(key, JSON.stringify(params[key]));
      }
    }
    const url = `/${resource}/${params.id}`;

    return httpClient.put(url, formData).then(({ data }) => ({
      data: data
    }));
  },


  updateSettings: (resource, params) => {
    const url = `/${resource}`;
    //console.log(url);
    return httpClient.put(url, params).then(({ data }) => ({
      data: data
    }));
  },


  updateTransaction: (resource, params) => {
    const url = `/${resource}/${params.id}/`;
    const headers = {
      "Authorization": `Bearer ${params?.token}`,
    };
    // console.log(params.token);

    return httpClient.put(url, params, { headers }).then(({ data }) => ({
      data: data
    }))
  },


}