import React, { useState } from 'react'
import 'assets/css/admin/admin-header.css'
import { Avatar, Badge, Box, Divider, Drawer, Icon, IconButton, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Menu, MenuItem, Typography, makeStyles, useTheme, useThemeProps } from '@mui/material'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Image, Logout, NotificationsNone, PersonAdd, Settings, WbSunnyOutlined } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import authProvider from 'providers/authProvider';
import AdminSidebar from 'components/sidebar/AdminSidebar';
import MobileViewSideBar from 'components/sidebar/MobileViewSideBar';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';


const AdminHeader = ({ setDark, dark }) => {

 

  let firstName = localStorage.getItem('firstName');
  let avatar_path = localStorage.getItem('avatar_path')
  let loggedUserId = localStorage.getItem('userId');

  const navigate = useNavigate()



  let role = localStorage.getItem('role');

  const [isOpenAccount, setIsOpenAccount] = useState(null);
  const [notificationOpen, setNotificationOpen] = useState(null)
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);


  const handleOpenDrawer = () => {
    setIsOpenDrawer(true)
  }


  const getInitials = (name) => {
    return name ? name[0].toUpperCase() : '';
  }



  const handleOpenAccount = (event) => {
    setIsOpenAccount(event.currentTarget);
  };

  const handleGoProfile = () => {
    navigate(`/admin/${loggedUserId}`)
    setIsOpenAccount(null);
  };

  const handleCloseAccount = () => {
    setIsOpenAccount(null);
  };

  const handleLogout = () => {
    authProvider.logout().then((response) => {
      window.location.href = "/";
    }).catch((err) => {
      console.log(err)
    })
  }

  const StyledDrawer = styled(Drawer)(({ theme }) => ({
    '& .MuiDrawer-paper': {
      width: '50%',
      boxSizing: 'border-box',
      border: "none",
      backgroundColor: backgroundColor,
      overflowY: 'auto', // Hide scrollbar by default
      '&::-webkit-scrollbar': {
        width: '0', // Set the width of the scrollbar
      },

    },
  }));

  const StyledIconButton = styled(IconButton)(({ theme }) => ({
    display: "none",
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  }))

  const NotifficationBadge = styled(Box)(({ theme }) => ({
    width: "18px",
    height: "18px",
    backgroundColor: "#8fb569",
    borderRadius: "50%",
    display: 'flex',
    alignItems: "center",
    justifyContent: "center",
    position: 'absolute',
    top: "0",
    right: "0"
  }));

  const theme = useTheme();
  const selectedColor = theme.palette.sidebarColor.selectedColor;
  const unselectedColor = theme.palette.sidebarColor.unselectedColor;
  const backgroundColor = theme.palette.backgroundColor.main;

  return (
    <>
      <header className='admin-header'>


        <StyledIconButton onClick={() => handleOpenDrawer()}>
          <MenuIcon />
        </StyledIconButton>


        <div className="admin-header-items">

          <IconButton onClick={() => setDark((prevState) => !prevState)}>
            {
              dark === false ? <WbSunnyOutlined sx={{ fontSize: '27px' }} /> : <DarkModeOutlinedIcon sx={{ fontSize: '27px' }} />
            }

          </IconButton>

          {/* <IconButton id='notification-menu' onClick={(event) => setNotificationOpen(event.currentTarget)}>
            <NotificationsNone sx={{ fontSize: '27px', position: 'relative' }} />
            <NotifficationBadge><Typography fontSize={'small'} sx={{ color: "white" }}>5</Typography></NotifficationBadge>
          </IconButton>

          <Menu
            id="notification-menu"
            anchorEl={notificationOpen}
            open={Boolean(notificationOpen)}
            onClose={() => setNotificationOpen(null)}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            PaperProps={{
              style: {
                width: '350px',
              },
            }}
          >
            <Typography fontSize={"1.2 rem"} m={1}>Notifications</Typography>
           
            <MenuItem >
              <Box>
                <List disablePadding>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar >
                        J
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="John cancelled  New Shift" secondary="27-02-2024" />
                  </ListItem>
                </List>
              </Box>
            </MenuItem>
           
            <MenuItem>
              <Box>
                <List disablePadding>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar >
                        J
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="John cancelled  New Shift" secondary="27-02-2024" />
                  </ListItem>
                </List>
              </Box>
            </MenuItem>
          </Menu> */}


          <IconButton
            id="demo-positioned-button"
            onClick={handleOpenAccount}
          >
            <Avatar alt="User" src={avatar_path} className='userImage-avatar'>{getInitials(firstName)}</Avatar>
            <span className="active-online"> </span>
          </IconButton>


          <Menu
            id="demo-positioned-menu"
            anchorEl={isOpenAccount}
            open={Boolean(isOpenAccount)}
            onClose={handleCloseAccount}
          >
            <MenuItem onClick={handleCloseAccount}>
              <ListItemAvatar>
                <Avatar alt="User" src={avatar_path} className='userImage-avatars'>{getInitials(firstName)}</Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={firstName}
                secondary={role === 'admin' && 'Admin'}
              />
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => handleGoProfile()} >
              <ListItemIcon>
                <PersonOutlinedIcon fontSize="small" />
              </ListItemIcon>
              <Typography>Profile</Typography>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              <Typography>Logout</Typography>
            </MenuItem>
          </Menu>
        </div>

        <StyledDrawer
          anchor='left'
          open={isOpenDrawer}
          onClose={() => setIsOpenDrawer(false)}
        >
          <MobileViewSideBar setIsOpenDrawer={setIsOpenDrawer} />
        </StyledDrawer>

      </header>
    </>
  )
}

export default AdminHeader