import { useState } from 'react';

export const useToast = () => {
    const [toastOpen, setToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState('');

    const showToast = (message) => {
        setToastOpen(true);
        setToastMessage(message);
    };

    const hideToast = () => {
        setToastOpen(false);
        setToastMessage('');
    };

    return {
        toastOpen,
        toastMessage,
        showToast,
        hideToast,
    };
};